import React, { useState, useEffect } from 'react';
import Constant from '../../Constant/UserLayout';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Url from '../../Elements/Api/Api';
import { useSelector } from 'react-redux';
import './Client.css';
import Modal from '../ManageLeads/Modal';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import Header from '../../Constant/Header';

const AddClients = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [id, setId] = useState('');
  const token = useSelector((state) => state.token);
  const [clientData, setClientData] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [isNestedUpdateModalOpen, setIsNestedUpdateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  const closeUpdateModal = () => setIsUpdateModalOpen(false);
  const closeNestedUpdateModal = () => setIsNestedUpdateModalOpen(false);

  //dynamic input fields
  const [inputFields, setInputFields] = useState([
    { value1: '', value2: '', value3: '', value4: '' }
  ]);

  const inputParam = inputFields.map((field, key) => ({
    [`contact_person_name_${key}`]: field.value1,
    [`contact_person_email_${key}`]: field.value2,
    [`contact_person_mobile_${key}`]: field.value3,
    [`contact_person_designation_${key}`]: field.value4
  }));

  const handleInputChange1 = (index, fieldName, value) => {
    const newInputFields = [...inputFields];
    newInputFields[index][fieldName] = value;
    setInputFields(newInputFields);
  };

  const handleAddFields = () => {
    // Check the total number of fields added
    if (inputFields.length < 3) {
      setInputFields([
        ...inputFields,
        { value1: '', value2: '', value3: '', value4: '' }
      ]);
    }
  };

  const handleRemoveFields = (index) => {
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
  };

  const [userData, setUserData] = useState({
    first_person_name: '',
    first_person_email: '',
    first_person_contact_no: '',
    second_person_name: '',
    second_person_email: '',
    second_person_contact_no: '',
    organisation: '',
    address: '',
    gst_no: '',
    notes: '',
    owner_name: '',
    owner_email: '',
    owner_phone: ''
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    var keyToRemove = e.target.name;
    setUserData({
      ...userData,
      [name]: value
    });
    setError({ ...error, [e.target.name]: '' });

  };

  const submitHandler = (e) => {
    setLoading(true);
    // e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const params = {
      gst_no: userData.gst_no,
      organisation: userData.organisation,
      address: userData.address,
      notes: userData.notes,
      industry_id: id,
      clientInfo: inputParam,
      owner_name: userData.owner_name,
      owner_email: userData.owner_email,
      owner_phone: userData.owner_phone
    };
    axios
      .post(Url.BASEURL + 'addfrontclient', params, config)
      .then((value) => {
        if (value && value.data.status == 200) {
          // openModal(true);
          // navigate('/listclients');
          // toast('Client added Successfully');
          setClientData(value.response);
          setIsNestedUpdateModalOpen(true);
          setLoading(false);

          // navigate('/manageclients');
          // setTimeout(() => {
          // }, 2000);
        } else {
          setError(value.data.errors);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
    // }
  };
  // function to add industry list
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'allindustry', config)
      .then((value) => setIndustry(value.data.response))
      .catch((err) => console.log(err));
  }, []);

  // function to change industry

  const IndustryChangeHandler = (event) => {
    setId(event.target.value);
  };
  const reloadPage = () => {
    navigate('/manageclients');
  };
  const closeAllUpdateModals = () => {
    closeNestedUpdateModal();
    closeUpdateModal();
  };
  return (
    <div>
      <Constant>
        <Header
          onpage_link="Manage Client"
          heading="Add Client Form"
          nested_breadcrum="Add client"
        />

        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="col-xxl-12 mb-5 mb-xl-10">
              {/*begin::Chart widget 8*/}
              <div className="card card-flush h-xl-100">
                {/* {loading && <div className="overlay" />} */}

                {loading ? (
                  <>
                    {/* <div className="overlay" /> */}
                    <div className="loader-container">
                      <ClipLoader
                        color={'#50CD89'}
                        loading={loading}
                        size={60}
                      />
                    </div>
                  </>
                ) : null}

                <div
                  className={
                    loading ? 'myclass card-body pt-6' : 'card-body pt-6'
                  }
                  // style={{ opacity: loading ? 0.5 : 1 }}
                >
                  {/* organisation details start here */}

                  <p className="h5 m-0 px-1 py-2">Organisation Details:</p>
                  <div className="clientinfo-box">
                    <div className="dynamic-input ">
                      <div className="row align-items-center justify-content-between">
                        <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">
                          <div className="col-5">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span className="required">GSTIN Number</span>
                            </label>
                            <input
                              // style={{
                              //   borderColor:
                              //     userData.gst_no.length > 12 ? 'red' : null
                              // }}
                              maxLength={15}
                              onChange={handleInputChange}
                              name="gst_no"
                              value={userData.gst_no}
                              type="text"
                              className="form-control"
                              placeholder="Enter GSTIN Number"
                            />
                            {error && error.gst_no ? (
                              <div style={{ color: 'red' }}>{error.gst_no}</div>
                            ) : null}
                          </div>
                          <div className="col-5">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span className="required">Organisation</span>
                            </label>
                            <input
                              onChange={handleInputChange}
                              value={userData.organisation}
                              name="organisation"
                              type="text"
                              className="form-control "
                              placeholder="Enter Organisation"
                            />
                            {error && error.organisation ? (
                              <div style={{ color: 'red' }}>
                                {error.organisation}
                              </div>
                            ) : null}
                          </div>

                          <div className="col-5">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span className="required">Address</span>
                            </label>
                            <input
                              onChange={handleInputChange}
                              value={userData.address}
                              name="address"
                              type="text"
                              className="form-control"
                              placeholder="Enter Address"
                            />
                            {error && error.address ? (
                              <div style={{ color: 'red' }}>
                                {error.address}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-5">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span className="required">Industry Type</span>
                            </label>
                            <select
                              style={{ height: '45px' }}
                              className="form-control"
                              value={id}
                              // value={selectedClient}
                              onChange={IndustryChangeHandler}
                            >
                              <option value="">Industry Type</option>
                              {industry.map((e, index) => (
                                <option value={e.id} key={index}>
                                  {e.industry_type}
                                </option>
                              ))}
                            </select>
                            {error && error.industry_id ? (
                              <div style={{ color: 'red' }}>
                                {error.industry_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="h5 m-0 px-1 py-2">Client Details:</p>
                  <div className="clientinfo-box">
                    <div className="dynamic-input ">
                      <div
                        style={{
                          flexDirection: 'row',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <div className="col-4 p-2">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>Owner Name</span>
                          </label>
                          <input
                            onChange={handleInputChange}
                            value={userData.owner_name}
                            name="owner_name"
                            type="text"
                            className="form-control"
                            placeholder="Enter Owner Name"
                          />
                          {error && error.address ? (
                            <div style={{ color: 'red' }}>{error.address}</div>
                          ) : null}
                        </div>
                        <div className="col-4 p-2">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>Owner Email</span>
                          </label>
                          <input
                            onChange={handleInputChange}
                            value={userData.owner_email}
                            name="owner_email"
                            type="text"
                            className="form-control"
                            placeholder="Enter Owner Email"
                          />
                          {error && error.address ? (
                            <div style={{ color: 'red' }}>{error.address}</div>
                          ) : null}
                        </div>
                        <div className="col-4 p-2">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>Owner Contact Number</span>
                          </label>
                          {/* <input
                            onChange={handleInputChange}
                            value={userData.owner_phone}
                            name="owner_phone"
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Enter Owner Contact Number"
                            maxLength={10}
                          /> */}

                          <input
                            maxLength={10}
                            // onInput={(e) =>
                            //   (e.target.value = e.target.value.replace(
                            //     /[^0-9]/g,
                            //     ''
                            //   ))
                            // }
                            onChange={handleInputChange}
                            value={userData.owner_phone}
                            name="owner_phone"
                            type="text"
                            className="form-control"
                            id="exampleInputEmail10"
                            placeholder="Enter Contact Number"
                          />

                          {error && error.address ? (
                            <div style={{ color: 'red' }}>{error.address}</div>
                          ) : null}
                        </div>
                      </div>
                      {inputFields.map((field, index) => (
                        <div className="row align-items-center justify-content-between">
                          <div className="col-12 d-flex align-items-center justify-content-between  flex-wrap ">
                            <div className="col-6 p-2 ">
                              <label
                                htmlFor={`contactPersonName${index}`}
                                className="d-flex align-items-center fs-6 fw-semibold mb-2"
                              >
                                <span className="required">
                                  Contact Person Name
                                </span>
                              </label>
                              <input
                                onInput={(e) =>
                                  (e.target.value = e.target.value.replace(
                                    /[^a-zA-Z ]/g,
                                    ''
                                  ))
                                }
                                onChange={(e) =>
                                  handleInputChange1(
                                    index,
                                    'value1',
                                    e.target.value
                                  )
                                }
                                value={field.value1}
                                name="owner_name"
                                type="text"
                                className="form-control"
                                id={`contactPersonName${index}`}
                                placeholder={`Enter Name ${index + 1}`}
                              />
                              {error &&
                                error.clientInfo &&
                                error.clientInfo[index] && (
                                  <div>
                                    {error.clientInfo[index][
                                      `contact_person_name_${index}`
                                    ] && (
                                      <div style={{ color: 'red' }}>
                                        {
                                          error.clientInfo[index][
                                            `contact_person_name_${index}`
                                          ]
                                        }
                                      </div>
                                    )}
                                  </div>
                                )}
                            </div>
                            <div className="col-6 p-2">
                              <label
                                htmlFor={`contactPersonEmail${index}`}
                                className="d-flex align-items-center fs-6 fw-semibold mb-2"
                              >
                                <span className="required">
                                  Contact Person's Email address
                                </span>
                              </label>
                              <input
                                onChange={(e) =>
                                  handleInputChange1(
                                    index,
                                    'value2',
                                    e.target.value
                                  )
                                }
                                value={field.value2}
                                name="owner_email"
                                type="email"
                                className="form-control"
                                id={`contactPersonEmail${index}`}
                                placeholder={`Enter email ${index + 1}`}
                              />
                              {error &&
                                error.clientInfo &&
                                error.clientInfo[index] && (
                                  <div>
                                    {error.clientInfo[index][
                                      `contact_person_email_${index}`
                                    ] && (
                                      <div style={{ color: 'red' }}>
                                        {
                                          error.clientInfo[index][
                                            `contact_person_email_${index}`
                                          ]
                                        }
                                      </div>
                                    )}
                                    {/* Repeat similar checks for other fields (email, mobile, designation) */}
                                  </div>
                                )}
                            </div>
                            <div className="col-6 p-2">
                              <label
                                htmlFor={`contactPersonMobile${index}`}
                                className="d-flex align-items-center fs-6 fw-semibold mb-2"
                              >
                                <span className="required">
                                  Contact Person's Mobile Number
                                </span>
                              </label>
                              <input
                                maxLength={10}
                                onInput={(e) =>
                                  (e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ''
                                  ))
                                }
                                onChange={(e) =>
                                  handleInputChange1(
                                    index,
                                    'value3',
                                    e.target.value
                                  )
                                }
                                value={field.value3}
                                name="owner_contact_no"
                                type="text"
                                className="form-control "
                                id={`contactPersonMobile${index}`}
                                placeholder={`Enter Mobile Number ${index + 1}`}
                              />
                              {error &&
                                error.clientInfo &&
                                error.clientInfo[index] && (
                                  <div>
                                    {error.clientInfo[index][
                                      `contact_person_mobile_${index}`
                                    ] && (
                                      <div style={{ color: 'red' }}>
                                        {
                                          error.clientInfo[index][
                                            `contact_person_mobile_${index}`
                                          ]
                                        }
                                      </div>
                                    )}
                                    {/* Repeat similar checks for other fields (email, mobile, designation) */}
                                  </div>
                                )}
                            </div>
                            <div className="col-6 p-2">
                              <label
                                htmlFor={`contactPersonDesignation${index}`}
                                className="d-flex align-items-center fs-6 fw-semibold mb-2"
                              >
                                <span className="required">
                                  Contact Person Designation
                                </span>
                              </label>
                              <input
                                // onInput={(e) =>
                                //   (e.target.value = e.target.value.replace(
                                //     /[^a-zA-Z]/g,
                                //     ''
                                //   ))
                                // }
                                onChange={(e) =>
                                  handleInputChange1(
                                    index,
                                    'value4',
                                    e.target.value
                                  )
                                }
                                value={field.value4}
                                name="designation_name"
                                type="text"
                                className="form-control"
                                id={`contactPersonDesignation${index}`}
                                placeholder={`Enter Contact Person Designation`}
                              />
                              {error &&
                                error.clientInfo &&
                                error.clientInfo[index] && (
                                  <div>
                                    {error.clientInfo[index][
                                      `contact_person_designation_${index}`
                                    ] && (
                                      <div style={{ color: 'red' }}>
                                        {
                                          error.clientInfo[index][
                                            `contact_person_designation_${index}`
                                          ]
                                        }
                                      </div>
                                    )}
                                    {/* Repeat similar checks for other fields (email, mobile, designation) */}
                                  </div>
                                )}
                            </div>
                          </div>
                          {index > 0 && (
                            <div className="add text-center">
                              <i
                                className="fa-solid fa-circle-xmark"
                                onClick={() => handleRemoveFields(index)}
                              ></i>
                            </div>
                          )}
                          {index === 0 && (
                            <div className="add text-center">
                              <i
                                className="fa-solid fa-circle-plus"
                                onClick={handleAddFields}
                              ></i>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <p className="h5 m-0 px-1 py-2">Additional Info:</p>
                  <div className="clientinfo-box">
                    <div className="dynamic-input ">
                      <div className="row align-items-center justify-content-between">
                        <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">
                          <div className="col-8">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span>Description</span>
                            </label>
                            <input
                              onChange={handleInputChange}
                              name="notes"
                              value={userData.notes}
                              type="text"
                              className="form-control "
                              placeholder="Enter Description"
                            />
                          </div>
                          {/* <div className="col-4">
                            <div className="form-group active_status_flex  align-items-center m-0">
                              <label htmlFor="exampleInputEmail1" className='m-0 px-2'>
                                Active Status
                              </label>
                              <input
                                type="checkbox"
                                // checked={isChecked}
                                // onChange={handleCheckboxChange}
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="py-5 text-center" style={{ float: 'left' }}>
                    <button
                      onClick={reloadPage}
                      type="reset"
                      id="kt_modal_new_target_cancel"
                      className="btn btn-light me-3"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={setIsUpdateModalOpen}
                      // onClick={submitHandler}
                      className="btn btn-primary btn-block"
                    >
                      Submit
                    </button>
                  </div>
                </div>
                {/*end::Body*/}
              </div>
              {/*end::Chart widget 8*/}
            </div>
          </div>

          {isUpdateModalOpen && (
            <Modal
              style={{ display: 'block' }}
              isOpen={isUpdateModalOpen}
              onClose={closeUpdateModal}
            >
              <div style={{ alignSelf: 'center' }}>
                {/* <h1 style={{alignItems:'center'}}>Are You sure</h1> */}
                <h2> Are You sure</h2>
                <h4>You want to Add this Client</h4>
                <button
                  style={{ marginTop: '30px' }}
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setIsUpdateModalOpen(false)}
                  // disabled={isButtonDisabled}
                >
                  Cancel
                </button>
                <button
                  style={{ marginTop: '30px', marginLeft: '20px' }}
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    // DeleteProduct()
                    // alert('frontend');
                    // leadSubmit();
                    submitHandler();
                    setIsUpdateModalOpen(false);
                  }}
                  // }}
                  // disabled={isButtonDisabled}
                >
                  Add
                </button>
              </div>
            </Modal>
          )}

          {isNestedUpdateModalOpen && (
            <Modal
              isOpen={isNestedUpdateModalOpen}
              onClose={closeAllUpdateModals}
            >
              <h2>Client Added successfully </h2>

              <button
                style={{ marginTop: '10px' }}
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  closeAllUpdateModals();
                  navigate('/manageclients');
                  // navigate('/manageleads');
                }}
              >
                OK
              </button>
            </Modal>
          )}
        </div>
      </Constant>
    </div>
  );
};

export default AddClients;
