import DataTable from 'react-data-table-component';
// import Header from './Header2';
import Constant from './UserLayout';
import { Link } from 'react-router-dom';
import { usePagination } from '@table-library/react-table-library/pagination';

import React, { useState } from 'react';
import Header from './Header';
const staticData = [
  // Your static data goes here
  { id: 1, name: 'Harish Yadav', date: ' 03/09/2022', status: 1 },
  { id: 2, name: ' Balveer Singh', date: ' 03/08/2022', status: 0 },
  { id: 3, name: 'Rahul Kumar', date: ' 03/09/2022', status: 1 },
  { id: 4, name: 'Amit Singh', date: ' 03/07/2022', status: 1 },
  { id: 5, name: 'Nikhil', date: ' 03/09/2022', status: 1 },
  { id: 6, name: 'Ashok Kumar', date: ' 03/09/2022', status: 0 },
  { id: 7, name: 'Rajat Sharma', date: ' 03/09/2022', status: 1 },
  { id: 8, name: 'Deepak Mishra', date: ' 03/09/2022', status: 1 },
  { id: 9, name: 'Raghav Sood', date: ' 03/09/2022', status: 1 },
  { id: 10, name: 'Mahesh kumar', date: ' 03/09/2022', status: 0 },
  { id: 11, name: 'suresh Sharma', date: ' 03/09/2022', status: 1 },
  { id: 12, name: 'Ritesh Goyal', date: ' 03/09/2022', status: 1 }
];

const itemsPerPage = 5;

const Test = (props) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the start and end indexes of the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = staticData.slice(indexOfFirstItem, indexOfLastItem);

  // Change the page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div>
      <Constant>
        <Header
          btnName="Add Lead"
          onpage_link="Manage Lead"
          heading="Manage Lead"
          btn_path="addlead"
        />
       <div className="app-sidebar-footer d-flex align-items-center px-8 pb-10" id="kt_app_sidebar_footer">
  {/*begin::User*/}
  <div className>
    {/*begin::User info*/}
    <div className="d-flex align-items-center" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-overflow="true" data-kt-menu-placement="top-start">
      <div className="d-flex flex-center cursor-pointer symbol symbol-circle symbol-40px">
        <img src="<?php echo SITE_URL ?>assets/media/avatars/300-1.jpg" alt="image" />
      </div>
      {/*begin::Name*/}
      <div className="d-flex flex-column align-items-start justify-content-center ms-3">
        <span className="text-gray-500 fs-8 fw-semibold">Hello</span>
        <a href="#" className="text-gray-800 fs-7 fw-bold text-hover-primary">{/*?= $uinfo[0]-*/}fullname ?&gt;</a>
      </div>
      {/*end::Name*/}
    </div>
    {/*end::User info*/}
    {/*begin::User account menu*/}
    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px" data-kt-menu="true">
      {/*begin::Menu item*/}
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          {/*begin::Avatar*/}
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src="<?php echo SITE_URL ?>assets/media/avatars/300-1.jpg" />
          </div>
          {/*end::Avatar*/}
          {/*begin::Username*/}
          <div className="d-flex flex-column">
            <div className="fw-bold d-flex align-items-center fs-5">{/*?= $uinfo[0]-*/}fullname ?&gt;
            </div>
            <a href="#" className="fw-semibold text-muted text-hover-primary fs-7">{/*?= $uinfo[0]-*/}email ?&gt;</a>
          </div>
          {/*end::Username*/}
        </div>
      </div>
      {/*end::Menu item*/}
      {/*begin::Menu separator*/}
      <div className="separator my-2" />
      {/*end::Menu separator*/}
      {/*begin::Menu item*/}
      <div className="menu-item px-5">
        <a href="?io=user-overview" className="menu-link px-5">Profile</a>
      </div>
      {/*end::Menu item*/}
      {/*begin::Menu item*/}
      <div className="menu-item px-5">
        <a href="?io=change-password" className="menu-link px-5">
          {/* <span class="menu-text">My Projects</span>
                  <span class="menu-badge">
                      <span class="badge badge-light-danger badge-circle fw-bold fs-7">3</span>
                  </span> */}
          Change Password
        </a>
      </div>
      {/*end::Menu item*/}
      {/*begin::Menu separator*/}
      <div className="separator my-2" />
      {/*end::Menu separator*/}
      {/*begin::Menu item*/}
      <div className="menu-item px-5">
        <a href="?io=logout" className="menu-link px-5">Logout</a>
      </div>
      {/*end::Menu item*/}
    </div>
    {/*end::User account menu*/}
  </div>
  {/*end::User*/}
</div>

      </Constant>
    </div>
  );
};

export default Test;
