import React,{useState,useEffect} from 'react'

const CommonApi = () => {
const[loading,setloading]=useState("")    


  return (
    <div></div>
  )
}

export default CommonApi