import React, { useState, useEffect } from 'react';
import Constant from '../../Constant/UserLayout';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Url from '../../Elements/Api/Api';
import { useSelector } from 'react-redux';
import Modal from '../../Screens/ManageLeads/Modal';
import Header from '../../Constant/Header';

const ClientInfo = () => {
  const clientId = useParams();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const [id, setId] = useState('');
  const token = useSelector((state) => state.token);
  const [clientData, setClientData] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [isChecked, setIsChecked] = useState(0);
  const [id2, setId2] = useState('');
  const [updatedClientData, setupdatededClientData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [timeoutError, setTimeoutError] = useState('');
  const [inputFields, setInputFields] = useState([]);
  //   modal funciton
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleInputChange = (index, fieldName, value) => {
    setInputFields((prevInputFields) => {
      const updatedFields = [...prevInputFields];
      updatedFields[index][fieldName] = value;
      return updatedFields;
    });
  };

  const handleAddFields = () => {
    // Check the total number of fields added
    if (inputFields.length < 3) {
      setInputFields([...inputFields, {}]);
    }
  };

  const handleRemoveFields = (index) => {
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
  };

  const handleInputChange1 = (event) => {
    const { name, value } = event.target;
    setClientData({ ...clientData, [name]: value });
    // setName(value);
    // setEmail(value);
  };
  // setError({ ...error, [e.target.name]: '' });


  //fetching specific client data
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(Url.BASEURL + 'find-client/' + clientId.clientId, config)
      .then((value) => {
        if (value && value.data.status === 200) {
          // toast('Client update Successfully');
          setClientData(value.data.response);
          setIsChecked(value.data.response.enabled);
          setId(value.data.response.staff_id);
          setId2(value.data.response.industry_id);
          setInputFields(value.data.response.clientInfo);
          // navigate('/listclients');
          // setTimeout(() => {
          //
          // }, 2000);
        } else {
          // setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  //   function for update client details

  const submitHandler = () => {
    navigate('/manageclients');
  };
  // function to add industry list
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(Url.BASEURL + 'allindustry', config)
      .then((value) => setIndustry(value.data.response))
      .catch((err) => console.log(err));
  }, []);

  // function to change industry

  const IndustryChangeHandler = (event) => {
    setId2(event.target.value);
  };
  const reloadPage = () => {
    navigate('/manageclients');
  };

  return (
    <div>
      <Constant>
        <Header
          path1={'dashboard'}
          onpage_link="Manage Client"
          heading="Client Info Form"
          nested_breadcrum="Update client"
        />

        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            {isOpen && (
              <div className="modal">
                <div className="modal-contents">
                  <span className="close" onClick={closeModal}>
                    &times;
                  </span>
                  <h2>Alert!</h2>
                  <p>{timeoutError}</p>
                </div>
              </div>
            )}

            <div className="col-xxl-12 mb-5 mb-xl-10">
              {/*begin::Chart widget 8*/}
              <div className="card card-flush h-xl-100">
                {/*begin::Body*/}
                <div className="card-body pt-6">
                  {/* organisation details start here */}
                  <p className="h5 m-0 px-1 py-2">Organisation Details:</p>
                  <div className="clientinfo-box">
                    <div className="dynamic-input ">
                      <div className="row align-items-center justify-content-between">
                        <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">
                          <div className="col-5">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span className="required">GSTIN Number</span>
                            </label>
                            <input
                              style={{
                                cursor: 'not-allowed',
                                pointerEvents: 'none'
                              }}
                              readOnly
                              maxLength={15}
                              name="gst_no"
                              value={clientData.gst_no}
                              type="text"
                              className="form-control form-control-solid"
                              placeholder="Enter GSTIN Number"
                            />
                            {error && error.gst_no ? (
                              <div style={{ color: 'red' }}>{error.gst_no}</div>
                            ) : null}
                          </div>
                          <div className="col-5">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span className="required">Organisation</span>
                            </label>
                            <input
                              style={{
                                cursor: 'not-allowed',
                                pointerEvents: 'none'
                              }}
                              readOnly
                              onChange={handleInputChange1}
                              value={clientData.organisation}
                              name="organisation"
                              type="text"
                              className="form-control form-control-solid"
                              placeholder="Enter Organisation"
                            />
                            {error && error.organisation ? (
                              <div style={{ color: 'red' }}>
                                {error.organisation}
                              </div>
                            ) : null}
                          </div>

                          <div className="col-5">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span className="required">Address</span>
                            </label>
                            <input
                              style={{
                                cursor: 'not-allowed',
                                pointerEvents: 'none'
                              }}
                              readOnly
                              onChange={handleInputChange1}
                              value={clientData.address}
                              name="address"
                              type="text"
                              className="form-control form-control-solid"
                              placeholder="Enter Address"
                            />
                            {error && error.address ? (
                              <div style={{ color: 'red' }}>
                                {error.address}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-5">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span className="required">Industry Type</span>
                            </label>
                            <select
                              style={{
                                cursor: 'not-allowed',
                                pointerEvents: 'none',
                                height: '45px'
                              }}
                              readOnly
                              className="form-control"
                              value={id2}
                              // value={selectedClient}
                              onChange={IndustryChangeHandler}
                            >
                              {industry.map((e) => {
                                return (
                                  <option value={e.id}>
                                    {e.industry_type}
                                  </option>
                                );
                              })}
                              <option
                                style={{
                                  cursor: 'not-allowed',
                                  pointerEvents: 'none'
                                }}
                                readOnly
                                value={id2}
                              >
                                Select Industry
                              </option>
                            </select>
                            {error && error.industry_id ? (
                              <div style={{ color: 'red' }}>
                                {error.industry_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="h5 m-0 px-1 py-2">Client Details:</p>
                  <div className="clientinfo-box">
                    <div className="dynamic-input ">
                      <div
                        style={{
                          flexDirection: 'row',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <div className="col-3">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Owner Name</span>
                          </label>
                          <input
                            readOnly
                            onChange={handleInputChange}
                            value={clientData.owner_name}
                            name="owner_name"
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Enter Owner Name"
                          />
                          {error && error.address ? (
                            <div style={{ color: 'red' }}>{error.address}</div>
                          ) : null}
                        </div>
                        <div className="col-3">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Owner Email</span>
                          </label>
                          <input
                            readOnly
                            onChange={handleInputChange}
                            value={clientData.owner_email}
                            name="owner_email"
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Enter Owner Email"
                          />
                          {error && error.address ? (
                            <div style={{ color: 'red' }}>{error.address}</div>
                          ) : null}
                        </div>
                        <div className="col-3">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">
                              Owner Contact Number
                            </span>
                          </label>
                          <input
                            readOnly
                            onChange={handleInputChange}
                            value={clientData.owner_phone}
                            name="owner_phone"
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Enter Owner Contact Number"
                          />
                          {error && error.address ? (
                            <div style={{ color: 'red' }}>{error.address}</div>
                          ) : null}
                        </div>
                      </div>
                      {inputFields.map((field, index) => (
                        <div
                          className="row align-items-center justify-content-between"
                          key={index}
                        >
                          <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">
                            <div className="col-5">
                              <label
                                htmlFor={`contactPersonName${index}`}
                                className="d-flex align-items-center fs-6 fw-semibold mb-2"
                              >
                                <span className="required">
                                  Contact Person Name
                                </span>
                              </label>
                              <input
                                style={{
                                  cursor: 'not-allowed',
                                  pointerEvents: 'none'
                                }}
                                readOnly
                                onInput={(e) =>
                                  (e.target.value = e.target.value.replace(
                                    /[^a-zA-Z ]/g,
                                    ''
                                  ))
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    `contact_person_name_${index}`,
                                    e.target.value
                                  )
                                }
                                value={field[`contact_person_name_${index}`]}
                                name="owner_name"
                                type="text"
                                className="form-control form-control-solid"
                                id={`contactPersonName${index}`}
                                placeholder={`Enter Name ${index + 1}`}
                              />
                              {error &&
                                error.clientInfo &&
                                error.clientInfo[index] && (
                                  <div>
                                    {error.clientInfo[index][
                                      `contact_person_name_${index}`
                                    ] && (
                                      <div style={{ color: 'red' }}>
                                        {
                                          error.clientInfo[index][
                                            `contact_person_name_${index}`
                                          ]
                                        }
                                      </div>
                                    )}
                                  </div>
                                )}
                            </div>
                            <div className="col-5">
                              <label
                                htmlFor={`contactPersonEmail${index}`}
                                className="d-flex align-items-center fs-6 fw-semibold mb-2"
                              >
                                <span className="required">
                                  Contact Person's Email address
                                </span>
                              </label>
                              <input
                                style={{
                                  cursor: 'not-allowed',
                                  pointerEvents: 'none'
                                }}
                                readOnly
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    `contact_person_email_${index}`,
                                    e.target.value
                                  )
                                }
                                value={field[`contact_person_email_${index}`]}
                                name="owner_email"
                                type="email"
                                className="form-control form-control-solid"
                                id={`contactPersonEmail${index}`}
                                placeholder={`Enter email ${index + 1}`}
                              />
                              {error &&
                                error.clientInfo &&
                                error.clientInfo[index] && (
                                  <div>
                                    {error.clientInfo[index][
                                      `contact_person_email_${index}`
                                    ] && (
                                      <div style={{ color: 'red' }}>
                                        {
                                          error.clientInfo[index][
                                            `contact_person_email_${index}`
                                          ]
                                        }
                                      </div>
                                    )}
                                    {/* Repeat similar checks for other fields (email, mobile, designation) */}
                                  </div>
                                )}
                            </div>
                            <div className="col-5">
                              <label
                                htmlFor={`contactPersonMobile${index}`}
                                className="d-flex align-items-center fs-6 fw-semibold mb-2"
                              >
                                <span className="required">
                                  Contact Person's Mobile Number
                                </span>
                              </label>
                              <input
                                style={{
                                  cursor: 'not-allowed',
                                  pointerEvents: 'none'
                                }}
                                readOnly
                                onInput={(e) =>
                                  (e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ''
                                  ))
                                }
                                maxLength={10}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    `contact_person_mobile_${index}`,
                                    e.target.value
                                  )
                                }
                                value={field[`contact_person_mobile_${index}`]}
                                name="owner_contact_no"
                                type="text"
                                className="form-control form-control-solid"
                                id={`contactPersonMobile${index}`}
                                placeholder={`Enter Mobile Number ${index + 1}`}
                              />
                              {error &&
                                error.clientInfo &&
                                error.clientInfo[index] && (
                                  <div>
                                    {error.clientInfo[index][
                                      `contact_person_mobile_${index}`
                                    ] && (
                                      <div style={{ color: 'red' }}>
                                        {
                                          error.clientInfo[index][
                                            `contact_person_mobile_${index}`
                                          ]
                                        }
                                      </div>
                                    )}
                                    {/* Repeat similar checks for other fields (email, mobile, designation) */}
                                  </div>
                                )}
                            </div>
                            <div className="col-5">
                              <label
                                htmlFor={`contactPersonDesignation${index}`}
                                className="d-flex align-items-center fs-6 fw-semibold mb-2"
                              >
                                <span className="required">
                                  Contact Person Designation
                                </span>
                              </label>
                              <input
                                style={{
                                  cursor: 'not-allowed',
                                  pointerEvents: 'none'
                                }}
                                readOnly
                                // onInput={(e) =>
                                //   (e.target.value = e.target.value.replace(
                                //     /[^a-zA-Z]/g,
                                //     ''
                                //   ))
                                // }
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    `contact_person_designation_${index}`,
                                    e.target.value
                                  )
                                }
                                value={
                                  field[`contact_person_designation_${index}`]
                                }
                                name="designation_name"
                                type="text"
                                className="form-control form-control-solid"
                                id={`contactPersonDesignation${index}`}
                                placeholder={`Enter Contact Person Designation`}
                              />
                              {error &&
                                error.clientInfo &&
                                error.clientInfo[index] && (
                                  <div>
                                    {error.clientInfo[index][
                                      `contact_person_designation_${index}`
                                    ] && (
                                      <div style={{ color: 'red' }}>
                                        {
                                          error.clientInfo[index][
                                            `contact_person_designation_${index}`
                                          ]
                                        }
                                      </div>
                                    )}
                                    {/* Repeat similar checks for other fields (email, mobile, designation) */}
                                  </div>
                                )}
                            </div>
                          </div>
                          {index > 0 && (
                            <div className="add text-center">
                              <i
                                className="fa-solid fa-circle-xmark"
                                onClick={() => handleRemoveFields(index)}
                              ></i>
                            </div>
                          )}
                          {index === 0 && (
                            <div className="add text-center"></div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <p className="h5 m-0 px-1 py-2">Additional Info:</p>
                  <div className="clientinfo-box">
                    <div className="dynamic-input ">
                      <div className="row align-items-center justify-content-between">
                        <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">
                          <div className="col-8">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span>Description</span>
                            </label>
                            <input
                              style={{
                                cursor: 'not-allowed',
                                pointerEvents: 'none'
                              }}
                              readOnly
                              onChange={handleInputChange1}
                              name="notes"
                              value={clientData.notes}
                              type="text"
                              className="form-control form-control-solid"
                              placeholder="Enter Description"
                            />
                          </div>
                          {/* <div className="col-4">
                            <div className="form-group active_status_flex  align-items-center m-0">
                              <label htmlFor="exampleInputEmail1" className='m-0 px-2'>
                                Active Status
                              </label>
                              <input
                                type="checkbox"
                                // checked={isChecked}
                                // onChange={handleCheckboxChange}
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="py-5 text-center" style={{ float: 'left' }}>
                    <button
                      onClick={submitHandler}
                      className="btn btn-primary btn-block"
                    >
                      Back
                    </button>
                  </div>
                </div>
                {/*end::Body*/}
              </div>
              {/*end::Chart widget 8*/}
            </div>
          </div>
        </div>
      </Constant>
    </div>
  );
};

export default ClientInfo;
