import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import Url from '../Elements/Api/Api';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setStatus } from '../Elements/Redux/Action/Authaction';
import { setToken } from '../Elements/Redux/Action/Authaction';
import { setName } from '../Elements/Redux/Action/Authaction';
import { attendanceStatus } from '../Elements/Redux/Action/Authaction';
import { setProfileImage } from '../Elements/Redux/Action/Authaction';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import './Auth.css'
import PasswordStrength from '../Elements/PasswordStrength';
import { passwordSchema } from '../Elements/ValidationSchema';
import Swal from 'sweetalert2'


const override = css`
display: block;
margin: 0 auto;
`;

const ResetPassword = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({});
  const [loginResponse, setLoginResponse] = useState([]);
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false)


  const [progress, setProgress] = useState([]);
  const dispatch = useDispatch();
  const savedtoken = useSelector((state) => state.passwordtoken);

  const navigate = useNavigate();

  const submitHandle = async (e) => {
    // e.preventDefault();
    try {
      if (newPassword && confirmPassword) {
        const config = {
          headers: {
            Authorization: `Bearer ${savedtoken}`
          }
        };
        setLoading(true)
        const params = {
          password: newPassword,
          password_confirmation: confirmPassword
        };

        const response = await axios.post(
          Url.BASEURL + 'reset-password',
          params,
          config
        );

        if (response.data.status === 200) {
          const responseData = response.data.response;
          Swal.fire({
            title: "Reset Password",
            text: "Password Reset Successful",
            icon: "success"
          });
          setLoading(false)

          // Dispatch Redux actions
          //   dispatch(setStatus(responseData.manager_status));
          //   dispatch(setToken(responseData.token));
          //   dispatch(setName(responseData.name));
          //   dispatch(attendanceStatus(responseData.today_attendance));
          //   dispatch(setProfileImage(responseData.profile_pic));

          // Navigate to the dashboard
          navigate('/');

          // Store relevant data in localStorage
          //   localStorage.setItem('loginResponse', JSON.stringify(responseData));
          //   localStorage.setItem('toast', true);

        }
      }
    } catch (error) {
      setLoading(false)
      // setError(error.response.data.message)
    }
  };



  const handlePasswordValidation = async ({ options }) => {
    let errors = {};
    try {
      const pswValidate = await passwordSchema.validate({
        newPassword: options.newPassword,
        confirmPassword: options.confirmPassword
      }, {
        abortEarly: false
      });
      if (pswValidate) {
        submitHandle();
      }
    } catch (validationError) {
      if (validationError.name === 'ValidationError') {
        validationError.inner.forEach(err => {
          errors[err.path] = err.message;
        });
      }
    }

    if (Object.keys(errors).length > 0) {
      setError(errors);
    }
  };

  return (
    <>
      {loading &&
        <div className="loader-backdrop">
          <div className="spinner-container">
            <ClipLoader color={'#50CD89'} loading={loading} css={override} size={60} />
          </div>
        </div>
      }
      
      <div>
        <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>
          {/*begin::Authentication - Sign-in */}
          <div className="d-flex flex-column flex-lg-row flex-column-fluid justify-content-center">
            {/*begin::Body*/}
            <div
              className="d-flex flex-column flex-lg-row-fluid py-10"
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >

              {/*begin::Content*/}
              <div className="card">
                <div className="d-flex flex-center flex-column flex-column-fluid">
                  {/*begin::Wrapper*/}
                  <div className="p-10 p-lg-15 mx-auto">
                    {/*begin::Form*/}
                    <form
                      className="form w-100"
                      noValidate="novalidate"
                      id="kt_sign_in_form"
                      data-kt-redirect-url="../dist/index.html"
                      action="#"
                    >
                      <div className='d-flex justify-content-center mb-5'>
                        <div className='image-box col-12'>
                          <img
                            style={{
                              height: '200px',
                              width: "80%",
                              margin: "auto",
                              display: "block",
                            }}
                            alt="Logo"
                            src="dist/assets/media/logos/logo.jpeg"
                            className="theme-light-show logo_filter"
                          />
                        </div>

                      </div>
                      <div className="text-center mb-10">

                        <p className="text-dark mb-3 fs-1 fw-bold" style={{ letterSpacing: "1px" }}>
                          Reset Account Password
                        </p>
                        <p className="text-dark-emphasis mb-3 fs-7 fw-semibold " style={{ letterSpacing: "1px" }}>
                          Enter a new password
                        </p>


                      </div>
                      <div className="fv-row mb-3">
                        {/*begin::Label*/}
                        <label
                          style={{ float: 'left' }}
                          className="form-label fs-6 fw-bold text-dark"
                        >
                          Enter New Password
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input
                          onChange={(e) => {
                            setError(prevErrors => ({ ...prevErrors, newPassword: null }));
                            setNewPassword(e.target.value)
                          }}
                          className="form-control  rounded-1 "
                          type="text"
                          name="email"
                          placeholder='Password'
                        />

                        {/*end::Input*/}
                      </div>
                      <PasswordStrength password={newPassword} />
                      {error && error.newPassword ? (
                        <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                          {error.newPassword}
                        </div>
                      ) : null}

                      <div className="fv-row mb-10">
                        {/*begin::Label*/}
                        <label
                          style={{ float: 'left' }}
                          className="form-label fs-6 fw-bold text-dark"
                        >
                          Confirm Password
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input
                          onChange={(e) => {
                            setError(prevErrors => ({ ...prevErrors, confirmPassword: null }));
                            setConfirmPassword(e.target.value)
                          }}
                          className="form-control rounded-1"
                          type="text"
                          name="email"
                          placeholder='Confirm Password'
                        />
                        {error && error.confirmPassword ? (
                          <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                            {error.confirmPassword}
                          </div>
                        ) : null}
                        {/*end::Input*/}
                      </div>
                      <div className="row align-items-center flex-column">
                        <div className="col-12 d-flex justify-content-center">
                          <div
                            onClick={() => {

                              handlePasswordValidation({
                                options: {
                                  newPassword: newPassword,
                                  confirmPassword: confirmPassword
                                }
                              });
                            }}
                            className="btn btn-primary rounded-1 px-20"
                          >
                            Reset Password
                          </div>
                        </div>
                        <div className="col-12 d-flex justify-content-center align-items-center mt-3 gap-2">
                          <i class="fa-solid fa-angle-left fs-5 text-dark"></i>
                          <div>
                            <Link style={{ float: 'inline-end' }} to="/" className='fs-5 fw-semibold text-dark'>
                              Back to login
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};
export default ResetPassword;
