const initialState = {
  token: null,
  status: null,
  name: null,
  profile_pic: null,
  attendStatus: null,
  alreadyMarkedAttend: null,
  email: null
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.payload.token
      };
    case 'DEL_TOKEN':
      return {
        ...state,
        token: null
      };
    case 'SET_STATUS':
      return {
        ...state,
        status: action.payload.status
      };
    case 'SET_ATTENDSTATUS':
      return {
        ...state,
        attendStatus: action.payload.attendStatus
      };
    case 'SET_EMAIL':
      return {
        ...state,
        email: action.payload.email
      };
    case 'SET_ALREADTATTENDMARKED':
      return {
        ...state,
        alreadyMarkedAttend: action.payload.alreadyMarkedAttend
      };
    case 'DEL_PASSWORD':
      return {
        ...state,
        password: null
      };

    case 'SET_NAME':
      return {
        ...state,
        name: action.payload.name
      };
    case 'SET_PROFILE_IMAGE':
      return {
        ...state,
        profile_pic: action.payload.profile_pic
      };
    case 'SET_PASSWORD_TOKEN':
      return {
        ...state,
        passwordtoken: action.payload.passwordtoken
      };
    case 'SET_STAFF_ID':
      return {
        ...state,
        staffId: action.payload.staffId
      };
      case 'SET_REQUEST_STATUS':
        return {
          ...state,
          requestStatus: action.payload.requestStatus
        };
    default:
      return state;
  }
};
export default authReducer;
