import React, { useEffect, useRef, useState } from 'react';
import Constant from '../../Constant/UserLayout';
// import Header from '../../Constant/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Url from '../../Elements/Api/Api';

import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import { default as ReactSelect } from 'react-select';
import Slider from 'react-slick';
import { FaCircle, FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const ViewLead = ({ text }) => {
  const maxLength = 50;
  const { leadId } = useParams();
  const navigate = useNavigate();
  const [finalCommunication, setFinalCommunication] = useState({});
  const [oldProductId, setOldProductId] = useState(0);
  const [activeOption, setActiveOption] = useState('detail');
  const [dummyData, setDummyData] = useState([]);
  const [dummyData2, setDummyData2] = useState([]);
  const [stage, setStage] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedStage, setSelectedStage] = useState('');
  const [industry, setIndustry] = useState([]);
  const token = useSelector((state) => state.token);
  const [title, setTitle] = useState('');
  const [selectedStageId, setSelectedStageId] = useState('');
  const [searchclient, setSearchClient] = useState('');
  const [searchedClient, setSearchedClient] = useState([]);
  const [error, setError] = useState('');
  const [id, setId] = useState('');
  const [clientData, setClientData] = useState([]);

  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchInputValue2, setSearchInputValue2] = useState('');
  const [communicationStates, setCommunicationStates] = useState([]);

  const [clientOptions, setClientOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [labelText, setLabelText] = useState('New');
  const [businessType, setBusinessType] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [leadtype, setLeadtype] = useState([]);
  const [check, setcheck] = useState('');
  const [communicationlead, setCommunicationlead] = useState([]);

  const [leadData, setUpdateleadData] = useState('');
  const [industryId, setIndustryId] = useState('');
  const [measurementId, setMeasurementId] = useState('');
  const [deleteRow, setDeleteRow] = useState('');
  const [response, setResponse] = useState([]);
  const [isChecked, setIsChecked] = useState(0);
  const [leadCommunication, setLeadCommunation] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [communication, setCommunication] = useState('');
  const [communicationData, setCommunicationData] = useState([
    {
      communication: '',
      communication_date: '',
      isReminder: ''
    }
  ]);

  const [inputFields, setInputFields] = useState([
    { value1: '', value2: '', value3: '', value4: '' }
  ]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [productsData, setproductsData] = useState([]);

  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);

  const [measurementType, setMeasurementType] = useState('');
  const [basePrice, setBasePrice] = useState('');
  const [productCount, setProductCount] = useState('');
  const [productSlideData, setProductSlideData] = useState({
    qty: '',
    kg: '',
    totalQuantity: '',
    price: '',
    priceBeforeDiscount: '',
    discount: '',
    priceAfterDiscount: '',
    gst: '18',
    netValue: ''
  });
  const [totalQuanity, setTotalQuanity] = useState('');
  const [priceBeforeDiscount, setPriceBeforeDiscount] = useState('');
  const [priceAfterDiscount, setPriceAfterDiscount] = useState('');
  const [netValue, setNetValue] = useState('');
  const [detailStage, setDetailStage] = useState('');
  //handler for input change
  const handleProductInputChange = (e) => {
    const { name, value } = e.target;
    if (/^\d*$/.test(value)) {
      // var keyToRemove = e.target.name;
      setProductSlideData({
        ...productSlideData,
        [name]: value
      });
    }
    setError({ ...error, [e.target.name]: '' });
  };
  const handleBasePriceInputChange = (e) => {
    setBasePrice(e.target.value);
  };

  const handleInputChange1 = (index, fieldName, value) => {
    const newInputFields = [...inputFields];
    newInputFields[index][fieldName] = value;
    setInputFields(newInputFields);
  };

  const transformIndustryDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, industry_type }) => ({
      value: id.toString(),
      label: `${industry_type}`
    }));
    return [selectOption, ...dataOptions];
  };
  const IndustryOptions = transformIndustryDataToOptions(industry);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked ? 1 : 0);
  };
  useEffect(() => {
    // setFinalCommunication(communicateData);
    const params = {
      communication: communicationData.communication ?? '',
      communication_date: communicationData.communication_date ?? '',
      is_reminder: isChecked,
      lead_id: leadId
    };
    setFinalCommunication(params);
    const initialStates = communicationlead.map(() => false);
    setCommunicationStates(initialStates);
  }, [communicationlead]);

  // function for toggle readmore/readless
  const toggleReadMore = (index) => {
    const newStates = [...communicationStates];
    newStates[index] = !newStates[index];
    setCommunicationStates(newStates);
  };
  // function for change label

  useEffect(() => {
    // Log selectedOption here, it will have the updated value
    if (selectedOption) {
      setcheck(selectedOption);
    }
  }, [selectedOption]);

  // Function of add clientlisting
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
          'searchstaffclients?' +
          'search=' +
          searchInputValue +
          '&page=',
        config
      )
      .then((value) => {
        setDummyData(value.data.response);
        setSearchedClient(value.data.response.data);
        setClientOptions(
          value.data.response.map((e) => ({
            id: e.id,
            value: e.id, // You can use e.id here if you prefer
            label: e.client
          }))
        );
      })
      .catch((err) => console.log(err));
  }, [searchInputValue]);

  // function for product listing
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'productdropdown?' + 'search=' + '&page=', config)
      .then((value) => setDummyData2(value.data.response.data))
      .catch((err) => console.log(err));
  }, []);

  // state to add new lead

  const handleIndustryChange = (e) => {
    setSelectedIndustry(e.target.value);
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'productdropdown?' + 'search=' + searchInputValue2,
        config
      )
      .then((value) => {
        if (value && value.data.status === 200) {
          setProductOptions(
            value.data.response.map((e) => ({
              id: e.id,
              value: e.id, // You can use e.id here if you prefer
              label: e.item_name
            }))
          );
        }
      })
      .catch((err) => console.log(err));
  }, [searchInputValue, token, selectedOption]);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL + 'searchstaffclients?' + 'search=' + searchInputValue,
        config
      )
      .then((value) => {
        if (value && value.data.status === 200) {
        }
      })
      .catch((err) => console.log(err));
  }, [searchInputValue, token]);

  // function to add net values

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'leadstages', config)
      .then((value) => setStage(value.data.response))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'allindustry', config)
      .then((value) => setIndustry(value.data.response))
      .catch((err) => console.log(err));
  }, []);
  const BusinessTypeChangeHandler = (event) => {
    setBusinessType(event.target.value);
  };

  useEffect((e) => {
    if (searchclient) {
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: leadId
    };
    axios
      .get(Url.BASEURL + 'editlead/' + leadId, config)
      .then((value) => {
        if (value && value.data.status === 200) {
          setUpdateleadData(value.data.response.lead);
          setDetailStage(value.data.response.lead.stage);
          setproductsData(value.data.response.lead.leadproducts);
          setProducts(value.data.response.product_list);
          setLeadtype(value.data.response.measurement_type);
          setProductCount(value.data.response.lead.leadproducts_count);
          //for category and sub category
          setCategoryList(value.data.response.category);
          setSubCategoryList(value.data.response.sub_category);

          setClientData(value.data.response.lead.client_data.clientInfo);
          setIndustryId(value.data.response.client.industry_id);
          setMeasurementId(value.data.response.leadproducts.measurement);
          setSelectedStage(value.data.response.lead.stage);
          setSelectedStageId(value.data.response.lead.stage);
          setTitle(value.data.response.title);
          setLeadCommunation(value.data.response.leadcommunication);
          const ldata = value.data.response.leadproducts;
          if (ldata.length > 0) {
            setOldProductId(ldata.length - 1);
          }
        } else {
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (leadData) {
      setResponse(leadData);
    }
  }, [leadData, deleteRow]);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'communicationcall/' + leadId, config)
      .then((value) => {
        setCommunicationlead(value.data.response);
        setCommunication(
          isExpanded
            ? value.data.response.communication
            : value.data.response.communication.slice(0, maxLength)
        );
      })
      .catch((err) => console.log(err));
  }, [communicationData]);

  const transformStageToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, stage }) => ({
      value: id.toString(),
      label: `${stage}`
    }));
    return [selectOption, ...dataOptions];
  };
  const stageDataOption = transformStageToOptions(stage);

  // functions for
  const sliderRef = useRef(null); // Initialize useRef with null
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => {
      setCurrentSlide(current);
    }
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
      // setCurrentSlideIndex((prevIndex) => prevIndex + 1);
    }
  };
  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
      // setCurrentSlideIndex((prevIndex) => prevIndex - 1);
    }
  };

  const productOptionss = products.map(
    ({ id, product_name, category_related_product }) => ({
      value: id.toString(),
      label: `${product_name}`,
      categoryRelatedProduct: category_related_product
    })
  );
  const categoryOptions = selectedProduct
    ? selectedProduct.categoryRelatedProduct.map((id, name, sub_category) => ({
        value: id.id.toString(),
        label: id.name,
        sub_category: id.sub_category
      }))
    : [];

  const subcategoryOptions = selectedCategory
    ? selectedCategory.sub_category.map((id, name) => ({
        value: id.id.toString(),
        label: id.name
      }))
    : [];

  const transformMeasurementToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name }) => ({
      value: id.toString(),
      label: `${name} `
    }));
    return [selectOption, ...dataOptions];
  };
  const measurementDataOption = transformMeasurementToOptions(leadtype);

  const transformCategoryToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name }) => ({
      value: id.toString(),
      label: `${name} `
    }));
    return [selectOption, ...dataOptions];
  };
  const categoryDataOption = transformCategoryToOptions(categoryList);
  const businessTypeData = [
    { label: 'Existing', value: 'Existing' },
    { label: 'New', value: 'New' }
  ];

  const transformSubCategoryToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name }) => ({
      value: id.toString(),
      label: `${name} `
    }));
    return [selectOption, ...dataOptions];
  };
  const subCategoryDataOption = transformSubCategoryToOptions(subCategoryList);

  return (
    <div>
      <Constant>
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="row">
              <div className="col-8">
                <div
                  className="row"
                  style={{
                    fontSize: '17px',
                    fontWeight: '600',
                    display: 'flex',
                    justifyContent: 'space-around'
                  }}
                >
                  <div
                    onClick={() => setActiveOption('detail')}
                    className={
                      activeOption === 'detail'
                        ? 'active col-3   py-5'
                        : 'non_active col-3  py-5'
                    }
                    style={{
                      color:
                        error && error.title && error.description
                          ? 'red'
                          : 'black',
                      width: 'auto'
                    }}
                  >
                    Details
                  </div>
                  <div
                    onClick={() => setActiveOption('contact')}
                    className={
                      activeOption === 'contact'
                        ? 'active col-3 py-5'
                        : 'non_active col-3 py-5'
                    }
                    style={{
                      color: error && error.owner_name ? 'red' : 'black',
                      width: 'auto'
                    }}
                  >
                    Contact Person
                  </div>
                  <div
                    onClick={() => setActiveOption('product')}
                    className={
                      activeOption === 'product'
                        ? 'active col-3  py-5'
                        : 'non_active col-3   py-5'
                    }
                    style={{ cursor: 'pointer', width: 'auto' }}
                  >
                    Products
                  </div>
                  <div
                    onClick={() => setActiveOption('communication')}
                    className={
                      activeOption === 'communication'
                        ? 'active col-3  py-5'
                        : 'non_active col-3   py-5'
                    }
                    style={{ cursor: 'pointer', width: 'auto' }}
                  >
                    Communication
                  </div>
                </div>
              </div>
            </div>
            {activeOption === 'detail' ? (
              <div className="card-body pt-6">
                {/*begin::Tab content*/}
                <div className="tab-content">
                  <div className="modal-content rounded card">
                    {/*begin::Modal header*/}
                    <div className="modal-header pb-0 border-0 justify-content-end">
                      {/*begin::Close*/}
                      <div
                        className="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                      ></div>
                      {/*end::Close*/}
                    </div>
                    {/*begin::Modal header*/}
                    {/*begin::Modal body*/}
                    <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
                      {/*begin:Form*/}
                      <form
                        id="kt_modal_new_target_form"
                        className="form fv-plugins-bootstrap5 fv-plugins-framework"
                        action="#"
                      >
                        {/*begin::Input group*/}
                        <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                          {/*begin::Label*/}

                          <div className="row">
                            <div className="col-md-12">
                              <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">Title</span>
                              </label>
                              <input
                                type="text"
                                value={leadData?.title}
                                // onChange={handleInputChange}
                                name="title"
                                className="form-control form-control-solid"
                                placeholder="Enter Title"
                              />
                            </div>
                          </div>
                          <div className="row mt-5">
                            <div className="col-md-12">
                              <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">Description</span>
                              </label>
                              <textarea
                                type="text"
                                value={leadData?.description}
                                // onChange={handleInputChange}
                                name="description"
                                className="form-control form-control-solid"
                                placeholder="Enter Description"
                              />
                            </div>
                          </div>

                          <div className="row mt-5">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span className="required">Business Type</span>
                            </label>
                            <ReactSelect
                              value={businessTypeData.find(
                                (e) => e.value == leadData.business_type
                              )}
                              isSearchable={false}
                            />
                          </div>

                          <div className="row mt-5">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span className="required">
                                Expected Close Date
                              </span>
                            </label>
                            <input
                              min={new Date().toISOString().split('T')[0]}
                              type="date"
                              value={leadData.expt_close_date}
                              //   onChange={(e) => setLeadDate(e.target.value)}
                              name="leadDate"
                              className="form-control form-control-solid"
                              placeholder="Enter Expected Close Date"
                            />
                          </div>
                          <div className="row mt-5">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span className="required">Stage</span>
                            </label>
                            <ReactSelect
                              value={stageDataOption.find(
                                (e) => e.value == detailStage
                              )}
                            />
                          </div>
                          {/*end::Label*/}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {activeOption === 'contact' ? (
              <>
                <div className="clientinfo-box">
                  <div className="dynamic-input ">
                    {clientData.map((field, index) => (
                      <div className="row align-items-center justify-content-between">
                        <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">
                          <div className="col-5">
                            <label
                              htmlFor={`contactPersonName${index}`}
                              className="d-flex align-items-center fs-6 fw-semibold mb-2"
                            >
                              <span className="required">
                                Contact Person {index + 1}
                              </span>
                            </label>
                            <input
                              // onInput={(e) =>
                              //   (e.target.value = e.target.value.replace(
                              //     /[^a-zA-Z ]/g,
                              //     ''
                              //   ))
                              // }
                              onChange={(e) =>
                                handleInputChange1(
                                  index,
                                  'value1',
                                  e.target.value
                                )
                              }
                              value={
                                field['contact_person_name_' + index.toString()]
                              }
                              // value={field.contact_person_name_0}
                              name="owner_name"
                              type="text"
                              className="form-control form-control-solid"
                              id={`contactPersonName${index}`}
                              placeholder={`Enter Name ${index + 1}`}
                            />
                            {error &&
                              error.clientInfo &&
                              error.clientInfo[index] && (
                                <div>
                                  {error.clientInfo[index][
                                    `contact_person_name_${index}`
                                  ] && (
                                    <div style={{ color: 'red' }}>
                                      {
                                        error.clientInfo[index][
                                          `contact_person_name_${index}`
                                        ]
                                      }
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                          <div className="col-5">
                            <label
                              htmlFor={`contactPersonEmail${index}`}
                              className="d-flex align-items-center fs-6 fw-semibold mb-2"
                            >
                              <span className="required">
                                Contact Person's Email address
                              </span>
                            </label>
                            <input
                              onChange={(e) =>
                                handleInputChange1(
                                  index,
                                  'value2',
                                  e.target.value
                                )
                              }
                              // value={field.contact_person_email_0}
                              value={
                                field[
                                  'contact_person_email_' + index.toString()
                                ]
                              }
                              name="owner_email"
                              type="email"
                              className="form-control form-control-solid"
                              id={`contactPersonEmail${index}`}
                              placeholder={`Enter email ${index + 1}`}
                            />
                            {error &&
                              error.clientInfo &&
                              error.clientInfo[index] && (
                                <div>
                                  {error.clientInfo[index][
                                    `contact_person_email_${index}`
                                  ] && (
                                    <div style={{ color: 'red' }}>
                                      {
                                        error.clientInfo[index][
                                          `contact_person_email_${index}`
                                        ]
                                      }
                                    </div>
                                  )}
                                  {/* Repeat similar checks for other fields (email, mobile, designation) */}
                                </div>
                              )}
                          </div>
                          <div className="col-5">
                            <label
                              htmlFor={`contactPersonMobile${index}`}
                              className="d-flex align-items-center fs-6 fw-semibold mb-2"
                            >
                              <span className="required">
                                Contact Person's Mobile Number
                              </span>
                            </label>
                            <input
                              maxLength={10}
                              onInput={(e) =>
                                (e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ''
                                ))
                              }
                              onChange={(e) =>
                                handleInputChange1(
                                  index,
                                  'value3',
                                  e.target.value
                                )
                              }
                              value={
                                field[
                                  'contact_person_mobile_' + index.toString()
                                ]
                              }
                              // value={field.contact_person_mobile_0}
                              name="owner_contact_no"
                              type="text"
                              className="form-control form-control-solid"
                              id={`contactPersonMobile${index}`}
                              placeholder={`Enter Mobile Number ${index + 1}`}
                            />
                            {error &&
                              error.clientInfo &&
                              error.clientInfo[index] && (
                                <div>
                                  {error.clientInfo[index][
                                    `contact_person_mobile_${index}`
                                  ] && (
                                    <div style={{ color: 'red' }}>
                                      {
                                        error.clientInfo[index][
                                          `contact_person_mobile_${index}`
                                        ]
                                      }
                                    </div>
                                  )}
                                  {/* Repeat similar checks for other fields (email, mobile, designation) */}
                                </div>
                              )}
                          </div>
                          <div className="col-5">
                            <label
                              htmlFor={`contactPersonDesignation${index}`}
                              className="d-flex align-items-center fs-6 fw-semibold mb-2"
                            >
                              <span className="required">
                                Contact Person Designation
                              </span>
                            </label>
                            <input
                              onInput={(e) =>
                                (e.target.value = e.target.value.replace(
                                  /[^a-zA-Z]/g,
                                  ''
                                ))
                              }
                              onChange={(e) =>
                                handleInputChange1(
                                  index,
                                  'value4',
                                  e.target.value
                                )
                              }
                              value={
                                field[
                                  'contact_person_designation_' +
                                    index.toString()
                                ]
                              }
                              name="designation_name"
                              type="text"
                              className="form-control form-control-solid"
                              id={`contactPersonDesignation${index}`}
                              placeholder={`Enter Contact Person Designation`}
                            />
                            {error &&
                              error.clientInfo &&
                              error.clientInfo[index] && (
                                <div>
                                  {error.clientInfo[index][
                                    `contact_person_designation_${index}`
                                  ] && (
                                    <div style={{ color: 'red' }}>
                                      {
                                        error.clientInfo[index][
                                          `contact_person_designation_${index}`
                                        ]
                                      }
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <p className="h5 m-0 px-1 py-2">Organisation Details:</p>
                <div className="clientinfo-box">
                  <div className="dynamic-input ">
                    <div className="row align-items-center justify-content-between">
                      <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">
                        <div className="col-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">GSTIN Number</span>
                          </label>
                          {/* {labelText === 'New' ? ( */}
                          <input
                            maxLength={15}
                            name="gst_no"
                            value={leadData.client.gst_no}
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Enter GSTIN Number"
                          />
                        </div>
                        <div className="col-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Organisation</span>
                          </label>

                          <input
                            value={leadData.client.organisation}
                            name="organisation"
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Enter Organisation"
                          />
                        </div>
                        <div className="col-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Address</span>
                          </label>

                          <input
                            value={leadData.client.address}
                            name="address"
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Enter Address"
                          />
                        </div>
                        <div className="col-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Industry Type</span>
                          </label>
                          {labelText === 'New' ? (
                            <ReactSelect
                              value={IndustryOptions.find(
                                (e) => e.value == leadData.client.industry_id
                              )}
                              options={IndustryOptions}
                              isDisabled={true}
                            />
                          ) : (
                            <ReactSelect
                              // options={clientOptions}
                              options={IndustryOptions}
                              value={id}
                              onChange={(newSelectedOption) => {
                                setId(newSelectedOption);
                              }}
                            />
                          )}
                          {error && error.industry_id ? (
                            <div style={{ color: 'red' }}>
                              {error.industry_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="h5 m-0 px-1 py-2">Additional Info:</p>
                <div className="clientinfo-box">
                  <div className="dynamic-input ">
                    <div className="row align-items-center justify-content-between">
                      <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">
                        <div className="col-8">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>Description</span>
                          </label>
                          <input
                            // onChange={handleInputChange}
                            name="notes"
                            // value={userData.notes}
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Enter Description"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            <Slider {...settings} ref={sliderRef}>
              {productsData.map((data, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  {/* <div key={index}> */}
                  {activeOption === 'product' && (
                    <Form>
                      {/* {productData.map((data, index) => ( */}
                      <Row
                        style={{ marginLeft: '28px' }}
                        className="mt-3 ml-3 "
                        key={index}
                      >
                        <div classNme="row" style={{ display: 'flex' }}>
                          <div className="col-6">
                            <div style={{ fontSize: '22px' }}>
                              Product Details
                            </div>
                          </div>
                        </div>

                        <div
                          style={{ marginLeft: '-17px' }}
                          className="card card-flush h-xl-100"
                        >
                          <div className="row">
                            <Col
                              style={{
                                marginBottom: '16px',
                                marginTop: '12px'
                              }}
                              xs={4}
                            >
                              <Form.Group controlId={`formName${index}`}>
                                <Form.Label>Item Name</Form.Label>
                                {currentSlide < productCount ? (
                                  <ReactSelect
                                    options={[]}
                                    value={productOptionss.find(
                                      (e) => e.value == data.product_id
                                    )}
                                    isSearchable={false}
                                  />
                                ) : (
                                  <ReactSelect
                                    options={productOptionss}
                                    value={selectedProduct}
                                    onChange={(value) => {
                                      setSelectedProduct(value);
                                      setSelectedCategory('');
                                      setSelectedSubcategory('');
                                    }}
                                  />
                                )}
                              </Form.Group>
                            </Col>
                            <Col
                              style={{
                                marginBottom: '16px',
                                marginTop: '12px'
                              }}
                              xs={4}
                            >
                              <Form.Group controlId={`formName${index}`}>
                                <Form.Label>Size</Form.Label>
                                {currentSlide < productCount ? (
                                  <ReactSelect
                                    options={[]} // Assuming categoryDataOption is an array of options
                                    value={categoryDataOption.find(
                                      (e) => e.value == data.category_id
                                    )}
                                    isSearchable={false}
                                  />
                                ) : (
                                  <ReactSelect
                                    options={categoryOptions}
                                    value={selectedCategory}
                                    onChange={(value) => {
                                      setSelectedCategory(value);
                                      setSelectedSubcategory('');
                                    }}
                                    isDisabled={!selectedProduct}
                                  />
                                )}
                              </Form.Group>
                            </Col>

                            <Col
                              style={{
                                marginBottom: '16px',
                                marginTop: '12px'
                              }}
                              xs={4}
                            >
                              <Form.Group controlId={`formName${index}`}>
                                <Form.Label>Class Thickness</Form.Label>
                                {currentSlide < productCount ? (
                                  <ReactSelect
                                    options={[]}
                                    value={subCategoryDataOption.find(
                                      (e) => e.value == data.sub_category_id
                                    )}
                                    isSearchable={false}
                                  />
                                ) : (
                                  <ReactSelect
                                    options={subcategoryOptions}
                                    value={selectedSubcategory}
                                    onChange={setSelectedSubcategory}
                                    isDisabled={!selectedCategory}
                                  />
                                )}
                              </Form.Group>
                            </Col>
                            <Col
                              xs={1}
                              className="d-flex align-items-center"
                            ></Col>
                          </div>
                        </div>

                        <div style={{ fontSize: '22px' }}>Quantity Info</div>
                        <div
                          style={{ marginLeft: '-17px' }}
                          className="card card-flush h-xl-100"
                        >
                          <div className="row">
                            <Col
                              style={{
                                marginBottom: '16px',
                                marginTop: '12px'
                              }}
                              xs={3}
                            >
                              <Form.Group controlId={`formQuantity${index}`}>
                                <Form.Label>Quantity</Form.Label>
                                {currentSlide < productCount ? (
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter quantity"
                                    value={data.product_qty}
                                    name="qty"
                                    qty
                                  />
                                ) : (
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter quantity"
                                    value={productSlideData.qty}
                                    name="qty"
                                    qty
                                    onChange={handleProductInputChange}
                                  />
                                )}
                              </Form.Group>
                            </Col>
                            <Col
                              style={{
                                marginBottom: '16px',
                                marginTop: '12px'
                              }}
                              xs={3}
                            >
                              <div key={index}>
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                  <span className="required">Measurement</span>
                                </label>
                                <ReactSelect
                                  options={
                                    currentSlide < productCount
                                      ? []
                                      : measurementDataOption
                                  }
                                  value={measurementDataOption.find(
                                    (e) => e.value == data.measurement
                                  )}
                                  isSearchable={false}
                                  onChange={(value) => {
                                    setMeasurementType(value);
                                    setBasePrice('0');
                                  }}
                                />
                              </div>
                            </Col>
                            <Col
                              style={{
                                marginBottom: '16px',
                                marginTop: '12px'
                              }}
                              xs={3}
                            >
                              <Form.Group controlId={`formQuantity${index}`}>
                                <Form.Label>Enter Kg</Form.Label>
                                {currentSlide < productCount ? (
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter quantity"
                                    // name="qty"
                                    value={parseInt(data.product_kg)}
                                  />
                                ) : (
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter quantity"
                                    value={productSlideData.kg}
                                    name="kg"
                                    onChange={handleProductInputChange}
                                    disabled={
                                      measurementType.label !== 'Kilogram '
                                    }
                                  />
                                )}
                              </Form.Group>
                            </Col>
                            <Col
                              style={{
                                marginBottom: '16px',
                                marginTop: '12px'
                              }}
                              xs={3}
                            >
                              <Form.Group controlId={`formQuantity${index}`}>
                                <Form.Label>Total Quantity</Form.Label>
                                {currentSlide < productCount ? (
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter quantity"
                                    value={data.total_quantity}
                                    name="totalQuantity"
                                    // onChange={handleProductInputChange}
                                  />
                                ) : (
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter quantity"
                                    value={totalQuanity}
                                    name="totalQuantity"
                                    // onChange={handleProductInputChange}
                                  />
                                )}
                              </Form.Group>
                            </Col>
                          </div>
                        </div>
                        <div style={{ fontSize: '22px' }}>Pricing details</div>
                        <div
                          style={{ marginLeft: '-17px' }}
                          className="card card-flush h-xl-100"
                        >
                          <div className="row">
                            <Col
                              style={{
                                marginBottom: '16px',
                                marginTop: '12px'
                              }}
                              xs={4}
                            >
                              <Form.Group controlId={`formQuantity${index}`}>
                                <Form.Label>Price</Form.Label>
                                {currentSlide < productCount ? (
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter quantity"
                                    value={data.product_price}
                                  />
                                ) : (
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter quantity"
                                    value={basePrice}
                                    name="price"
                                    onChange={handleBasePriceInputChange}
                                  />
                                )}
                              </Form.Group>
                            </Col>
                            <Col
                              style={{
                                marginBottom: '16px',
                                marginTop: '12px'
                              }}
                              xs={4}
                            >
                              <div key={index}>
                                <Form.Group controlId={`formQuantity${index}`}>
                                  <Form.Label>Price Before Discount</Form.Label>
                                  {currentSlide < productCount ? (
                                    <Form.Control
                                      type="number"
                                      placeholder="Enter quantity"
                                      value={data.price_before_discount}
                                      name="priceBeforeDiscount"
                                    />
                                  ) : (
                                    <Form.Control
                                      type="number"
                                      placeholder="Enter quantity"
                                      value={priceBeforeDiscount}
                                      name="priceBeforeDiscount"
                                    />
                                  )}
                                </Form.Group>
                              </div>
                            </Col>
                            <Col
                              style={{
                                marginBottom: '16px',
                                marginTop: '12px'
                              }}
                              xs={4}
                            >
                              <Form.Group controlId={`formQuantity${index}`}>
                                <Form.Label>Discount (%)</Form.Label>
                                {currentSlide < productCount ? (
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter quantity"
                                    value={data.discount}
                                    name="discount"
                                  />
                                ) : (
                                  <Form.Control
                                    // type="number"
                                    placeholder="Enter quantity"
                                    value={productSlideData.discount}
                                    name="discount"
                                    onChange={handleProductInputChange}
                                    // name="qty"
                                  />
                                )}
                              </Form.Group>
                            </Col>
                          </div>

                          <div className="row">
                            <Col
                              style={{
                                marginBottom: '16px',
                                marginTop: '12px'
                              }}
                              xs={4}
                            >
                              <Form.Group controlId={`formQuantity${index}`}>
                                <Form.Label>
                                  Price After Discount(Taxable Amount)
                                </Form.Label>
                                {currentSlide < productCount ? (
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter quantity"
                                    value={data.price_after_discount}
                                    name="priceAfterDiscount"
                                    // onChange={handleProductInputChange}
                                  />
                                ) : (
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter quantity"
                                    value={
                                      productSlideData.discount
                                        ? priceAfterDiscount
                                        : priceBeforeDiscount
                                    }
                                    name="priceAfterDiscount"
                                    // onChange={handleProductInputChange}
                                  />
                                )}
                              </Form.Group>
                            </Col>
                            <Col
                              style={{
                                marginBottom: '16px',
                                marginTop: '12px'
                              }}
                              xs={4}
                            >
                              <div key={index}>
                                <Form.Group controlId={`formQuantity${index}`}>
                                  <Form.Label> GST (%)</Form.Label>
                                  {currentSlide < productCount ? (
                                    <Form.Control
                                      type="number"
                                      placeholder="Enter quantity"
                                      value={data.product_gst}
                                      name="gst"
                                    />
                                  ) : (
                                    <Form.Control
                                      type="number"
                                      placeholder="Enter quantity"
                                      value={productSlideData.gst}
                                      name="gst"
                                      onChange={handleProductInputChange}
                                    />
                                  )}
                                </Form.Group>
                              </div>
                            </Col>
                            <Col
                              style={{
                                marginBottom: '16px',
                                marginTop: '12px'
                              }}
                              xs={4}
                            >
                              <Form.Group controlId={`formQuantity${index}`}>
                                <Form.Label>Net Value</Form.Label>
                                {currentSlide < productCount ? (
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter quantity"
                                    // value={netValue}
                                    name="netValue"
                                    value={data.total_price}
                                    // onChange={handleProductInputChange}
                                  />
                                ) : (
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter quantity"
                                    value={netValue}
                                    name="netValue"
                                  />
                                )}
                              </Form.Group>
                            </Col>
                          </div>
                        </div>
                      </Row>
                      {/* // ))} */}
                    </Form>
                  )}

                  {activeOption === 'product' ? (
                    <>
                      <div style={{ textAlign: 'center', marginTop: '10px' }}>
                        {productsData.map((_, index) => (
                          <FaCircle
                            key={index}
                            style={{
                              cursor: 'pointer',
                              margin: '0 5px',
                              color: index === currentSlide ? 'blue' : 'gray'
                            }}
                            onClick={() => setCurrentSlide(index)}
                          />
                        ))}
                      </div>
                      <div
                        style={{
                          marginTop: '20px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          // backgroundColor: 'red',
                          marginBottom: '5px'
                        }}
                      >
                        {productCount > 1 ? (
                          <Button
                            onClick={prevSlide}
                            style={{
                              padding: '0.5rem 0.8rem',
                              fontSize: '0.9rem'
                            }}
                          >
                            <FaArrowLeft />
                          </Button>
                        ) : null}

                        {/* <Button onClick={addSlider}>Add More</Button> */}
                        {productCount > 1 ? (
                          <Button
                            style={{
                              padding: '0.5rem 0.8rem',
                              fontSize: '0.9rem'
                            }}
                            onClick={nextSlide}
                          >
                            <FaArrowRight />
                          </Button>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
              ))}
            </Slider>
            {activeOption === 'communication' ? (
              <Form>
                <div
                  id="kt_app_content"
                  className="app-content flex-column-fluid"
                >
                  <div
                    id="kt_app_content_container"
                    className=" container-fluid"
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly'
                      }}
                    ></div>
                    <div className="col-xxl-12 mb-5 mb-xl-12">
                      <div className="card card-flush h-xl-100">
                        {/*begin::Tab content*/}
                        <div className="tab-content">
                          <div className=" mb-5 mb-xl-12">
                            <div className="row">
                              <div className="card-body col-12 py-3">
                                {/*begin::Table container*/}
                                <div className="">
                                  {/*begin::Table*/}

                                  {/* Display data in a col-6 col-6 flex layout */}
                                  <div className="row">
                                    {communicationlead.map((e, index) => (
                                      <div className="col-6" key={index}>
                                        <div
                                          className="card card-flush box_shadow mb-12"
                                          style={{
                                            boxShadow:
                                              ' rgba(0, 0, 0, 0.24) 0px 3px 8px;'
                                          }}
                                        >
                                          <div className="card-header justify-content-start align-items-center pt-4">
                                            <div className="symbol symbol-45px me-5"></div>
                                            <h3
                                              style={{
                                                color: 'darkslategrey!important'
                                              }}
                                              className=" fw-semibold fs-6"
                                            >
                                              {isExpanded
                                                ? e.communication
                                                : e.communication.slice(
                                                    0,
                                                    maxLength
                                                  )}
                                              {e.communication.length >
                                                maxLength && (
                                                <span>
                                                  <a
                                                    style={{
                                                      marginLeft: '10px'
                                                    }}
                                                    href="#"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      toggleReadMore(index);
                                                    }}
                                                  >
                                                    {communicationStates[index]
                                                      ? 'Read Less'
                                                      : 'Read More'}
                                                  </a>
                                                </span>
                                              )}
                                            </h3>
                                          </div>
                                          <div className="card-footer">
                                            <div
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                              }}
                                            >
                                              <div className="indicator-label">
                                                <p>communication date</p>
                                                <div>{e.created_at}</div>
                                              </div>
                                              <div className="indicator-label">
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                      'space-between'
                                                  }}
                                                >
                                                  <i
                                                    style={{
                                                      color: 'goldenrod'
                                                    }}
                                                    class="fa-solid fa-star"
                                                  ></i>
                                                  <p>Reminder date</p>
                                                </div>
                                                <div>
                                                  {e.communication_date}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  {/*end::Table*/}
                                </div>
                                {/*end::Table container*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            ) : null}
          </div>
        </div>
      </Constant>
    </div>
  );
};

export default ViewLead;
