import React, { useState, useEffect } from 'react';
import Constant from '../../Constant/UserLayout';
import Header from '../../Constant/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { default as ReactSelect } from 'react-select';
import axios from 'axios';
import Url from '../../Elements/Api/Api';
import { useSelector } from 'react-redux';
import Modal from './Modal';
import { BounceLoader, ClipLoader } from 'react-spinners';
import { logDOM } from '@testing-library/react';
import { css } from '@emotion/react';
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker';
import './Managelead.css'




const Intent = () => {
  const navigate = useNavigate()
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  const [taxAmount, setTaxAmount] = useState('')
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [status, setStatus] = useState(0)
  const [selectedDate, setSelectedDate] = useState('');
  const [edit, setEdit] = useState(false)
  const [editId, setEditId] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [products, setProducts] = useState([]);
  const [leadtype, setLeadtype] = useState([]);
  const [measurementType, setMeasurementType] = useState('');
  const [basePrice, setBasePrice] = useState('');
  const [totalQuanity, setTotalQuanity] = useState('');
  const [priceBeforeDiscount, setPriceBeforeDiscount] = useState('');
  const [priceAfterDiscount, setPriceAfterDiscount] = useState('');
  const [netValue, setNetValue] = useState('');
  const [subtotalValue, setSubtotalValue] = useState('');
  //state for product
  const [productsData, setproductsData] = useState([]);
  const token = useSelector((state) => state.token);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenOne, setIsModalOpenOne] = useState(false)
  const [isModalOpenTwo, setIsModalOpenTwo] = useState(false)
  const [quotationId, setQuotationId] = useState('')
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [brandList, setBrandList] = useState([])
  const [selectedBrand, setSelectedBrand] = useState('')
  const [product_no, setProduct_no] = useState('')



  const id = useParams();
  const qutaId = id.qutationId
  const leadId = id.id


  const [productSlideData, setProductSlideData] = useState({
    qty: '',
    kg: '',
    totalQuantity: '',
    price: '',
    priceBeforeDiscount: '',
    discount: '0',
    priceAfterDiscount: '',
    gst: '18',
    netValue: '',
    // loadingUnloading: '0',
    // freight: '0',
  });

  const [extraData, setExtraData] = useState({
    po_no: '',
    po_date: '',
    loading_data: '',
    unLoading_data: '',
    freight_data: '',
    contact_person_name: '',
    contact_person_number: '',
    sales_station: '',
    sales_transport: '',
    sales_gr_no: '',
    sales_vehicle_no: '',
    sales_driver_name: '',
    sales_driver_number: ''
  })


  useEffect(() => {
    setQuotationId(qutaId)
  }, [])


  //handle date change
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleExtraDataChange = (e) => {
    const { name, value } = e.target;
    setExtraData({ ...extraData, [name]: value })
  }

  const closeModal = () => {
    setIsModalOpen(false);
    setIsModalOpen(false);
    setSelectedProduct('')
    setSelectedCategory('')
    setSelectedSubcategory('')
    setProductSlideData({
      qty: '',
      kg: '',
      totalQuantity: '',
      gst: '18',
      discount: '0'
    })
    setBasePrice('')
    setPriceBeforeDiscount('')
    setPriceAfterDiscount('')
    setNetValue(0)
    setMeasurementType('')
  }
  const closeModalOne = () => { setIsModalOpenOne(false) }
  const closeModalTwo = () => { setIsModalOpenTwo(false) }


  const productOptionss = products.map(
    ({ id, product_name, category_related_product }) => ({
      value: id.toString(),
      label: `${product_name}`,
      categoryRelatedProduct: category_related_product
    })
  );

  //category option
  const categoryOptions = categoryList.map((id, name, sub_category) => ({
    value: id.id.toString(),
    label: id.name,
    sub_category: id.sub_category
  }));

  const subcategoryOptions = subCategoryList.map((id, name) => ({
    value: id.id.toString(),
    label: id.name
  }))



  //onchange handler for state
  const handleProductInputChange = (e) => {
    const { name, value } = e.target;
    // if (/^\d*$/.test(value)) {
    if ((name === 'gst' || name === 'discount') && parseInt(value) > 100) {
      // If greater than 100, set the value to 100
      e.target.value = '100';
    } else {
      // var keyToRemove = e.target.name;
      setProductSlideData({
        ...productSlideData,
        [name]: value
      });
    }
    // }
    // setError({ ...error, [e.target.name]: '' });
  };

  //measurement
  const transformMeasurementToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name }) => ({
      value: id.toString(),
      label: `${name}`
    }));
    return [selectOption, ...dataOptions];
  };
  const measurementDataOption = transformMeasurementToOptions(leadtype);

  const transformBrandToOptions = (data) => {
    const selectOption = { value: '', label: 'select...' };
    const dataOptions = data.map(({ id, bname }) => ({
      value: id.toString(),
      label: `${bname}`
    }));
    return [selectOption, ...dataOptions];
  };
  const BrandOptions = transformBrandToOptions(brandList);

  const getBrandList = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
      const response = await axios.get(Url.BASEURL + 'lead-brand-list', config)
      if (response.data.status === 200) {
        setBrandList(response.data.response)
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getBrandList();
  }, [])

  //calcullation
  useEffect(() => {
    handleSubTotal();
    if (measurementType) {
      handleMultiplications();
    }
    handlePrice();
    if (priceBeforeDiscount && productSlideData.discount) {
      handlePriceDiscount();
    }
    // if (priceAfterDiscount) {
    handleNetAmount();
    // }
  }, [
    measurementType,
    productSlideData,
    totalQuanity,
    basePrice,
    priceBeforeDiscount,
    productSlideData,
    priceAfterDiscount
  ]);

  // const handleMultiplications = () => {
  //   // measurementType.label == 'Kilogram '
  //   if (measurementType.label == 'Kilogram ') {
  //     // alert('hello')
  //     let totalQty = productSlideData.qty * productSlideData.kg;
  //     // setTotalQuanity(totalQty);
  //   } else if (measurementType.label == 'Meter ') {
  //     // setTotalQuanity('');
  //     let totalQty = productSlideData.qty * 6;
  //     // setTotalQuanity(totalQty);
  //   } else if (measurementType.label == 'Feet ') {
  //     // setTotalQuanity('');
  //     let totalQty = productSlideData.qty * 20;
  //     // setTotalQuanity(totalQty);
  //   } else if (measurementType.label == 'Pieces ') {
  //     setTotalQuanity('');
  //     let totalQty = productSlideData.qty * 1;
  //     setTotalQuanity(totalQty);
  //   }
  // };

  const handleMultiplications = () => {
    // measurementType.label == 'Kilogram '
    if (measurementType.label == 'KILOGRAMS') {
      // alert('hello')
      let totalQty = productSlideData.qty * productSlideData.kg;
      // setTotalQuanity(totalQty);
    } else if (measurementType.label == 'METRIC TONS') {
      // setTotalQuanity('');
      // let totalQty = productSlideData.qty * 6;
      // setTotalQuanity(totalQty);
    } else if (measurementType.label == 'METER') {
      // setTotalQuanity('');
      let totalQty = productSlideData.qty * 6;
      // setTotalQuanity(totalQty);
    } else if (measurementType.label == 'FEET') {
      // setTotalQuanity('');
      let totalQty = productSlideData.qty * 20;
      // setTotalQuanity(totalQty);
    } else if (measurementType.label == 'PIECES') {
      setTotalQuanity('');
      let totalQty = productSlideData.qty * 1;
      console.log(productSlideData);
      setTotalQuanity(totalQty);
    }
  };

  const handleBasePriceInputChange = (e) => {
    setBasePrice(e.target.value);
  };

  const handlePrice = () => {
    if (totalQuanity && basePrice) {
      let totalPriceBeforeDiscount = basePrice;
      setPriceBeforeDiscount(totalPriceBeforeDiscount);
    }
  };

  const handlePriceDiscount = () => {
    if (priceBeforeDiscount) {
      let discountPrice =
        (priceBeforeDiscount * productSlideData.discount) / 100;
      let newAmount = priceBeforeDiscount - discountPrice;
      setPriceAfterDiscount(newAmount);
      let totalAmount = parseFloat(newAmount * totalQuanity)
      setTaxAmount(totalAmount.toFixed(2))
    }
  };

  const handleSubTotal = () => {
    let gstAmount = (priceAfterDiscount * productSlideData.gst) / 100;

    let subTotalValue = parseInt(priceAfterDiscount) + parseInt(gstAmount);
    setSubtotalValue(subTotalValue);
  };
  const handleNetAmount = () => {
    // if (priceAfterDiscount) {

    let gstAmount = (taxAmount * productSlideData.gst) / 100;

    let netAmount = parseFloat(taxAmount) + parseFloat(gstAmount);

    if (productSlideData.discount === 100) {
      netAmount = 0;
    }
    netAmount = parseFloat(netAmount)
    setNetValue(netAmount.toFixed(2));
    // }
  };
  const getData = async (leadId) => {
    setLoading(true)
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    await axios
      .get(Url.BASEURL + 'editlead/' + leadId, config)
      .then((value) => {
        if (value && value.data.status === 200) {
          setproductsData(value.data.response.lead.leadproducts);
          setProducts(value.data.response.product_list);
          setLeadtype(value.data.response.measurement_type);
          setCategoryList(value.data.response.category);
          setSubCategoryList(value.data.response.sub_category);
          setStatus(value.data.response.lead.intend_pdf)
          // Set ExtraData only if intend_pdf is 1
          if (value.data.response.lead.intend_pdf === 1) {
            setExtraData({
              po_no: value.data.response.lead.po_no,
              po_date: value.data.response.lead.po_date,
              loading_data: value.data.response.lead.loading_data,
              unLoading_data: value.data.response.lead.unLoading_data,
              freight_data: value.data.response.lead.freight_data,
              contact_person_name: value.data.response.lead.contact_person,
              contact_person_number: value.data.response.lead.contact_person_number,
              sales_station: value.data.response.lead.sales_ship_to,
              sales_transport: value.data.response.lead.sales_transport,
              sales_gr_no: value.data.response.lead.sales_gr_no,
              sales_vehicle_no: value.data.response.lead.sales_vehicle_no,
              sales_driver_name: value.data.response.lead.sales_driver_name,
              sales_driver_number: value.data.response.lead.sales_driver_number
            });
            setSelectedDate(new Date(value.data.response.lead.po_date))
            console.log(value.data.response.lead.po_date);
          }

          setLoading(false)

        } else {
        }
      })
      .catch((err) => console.log(err));
  };


  //setState
  const handleSetState = async (product_no, productId, brand_id, categoryId, subCategoryId, qty, measurement, kg, productPrice, productDiscount, productGst, ProductNetValue, net_price, taxable_amount, total_quantity) => {
    try {
      // console.log(productId, categoryId, subCategoryId, qty, measurement, kg, productPrice, productDiscount, productGst, ProductNetValue, net_price, taxable_amount);
      let product = productOptionss.find(product => product.value == productId)
      setProduct_no(product_no)
      let BrandRelatedProduct = BrandOptions.find(brand => brand.value == brand_id)
      setSelectedBrand(BrandRelatedProduct)
      //geting category from product and change id to value and then setting state
      let categoryRelatedProduct = categoryOptions.find(category => category.value == categoryId)
      categoryRelatedProduct = { ...categoryRelatedProduct, value: categoryRelatedProduct.value, label: categoryRelatedProduct.label }
      delete categoryRelatedProduct.id
      delete categoryOptions.name
      setSelectedCategory(categoryRelatedProduct);

      //geting subcategory
      let subCategory = subcategoryOptions
      let relatedSubCategory = subCategory.find(subCategory => subCategory.value == subCategoryId)
      // console.log(relatedSubCategory);
      relatedSubCategory = { ...relatedSubCategory, value: relatedSubCategory.value, label: relatedSubCategory.label }
      delete relatedSubCategory.id
      delete relatedSubCategory.name
      // console.log(relatedSubCategory);
      setSelectedSubcategory(relatedSubCategory)
      //measurement
      setMeasurementType(measurementDataOption.find(measurements => measurements.value == measurement));
      //set other state
      setBasePrice(productPrice)
      // setNetValue(ProductNetValue)
      setTotalQuanity(total_quantity)
      setProductSlideData({
        qty: qty,
        kg: parseInt(kg),
        price: productPrice,
        discount: productDiscount,
        gst: productGst,

      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getData(leadId)
  }, [])
  const handleProductSubmit = async (product_id) => {
    try {

      let newData = {
        product_no: product_no,
        product_id: product_id,
        brand_id: selectedBrand.value,
        category_id: selectedCategory.value,
        category_name: selectedCategory.label,
        sub_category_id: selectedSubcategory.value,
        sub_category_name: selectedSubcategory.label,
        product_qty: productSlideData.qty,
        measurement: measurementType.value,
        measurement_name: measurementType.label,
        product_kg: productSlideData.kg ? productSlideData.kg : '0',
        total_quantity: totalQuanity,
        product_price: basePrice,
        discount: productSlideData.discount,
        net_price: productSlideData.discount
          ? priceAfterDiscount
          : priceBeforeDiscount,
        taxable_amount: taxAmount,
        product_gst: productSlideData.gst,
        total_price: netValue,

      }

      const index = productsData.findIndex(product =>
        product.product_no != newData.product_no &&
        product.product_id == newData.product_id &&
        product.brand_id == newData.brand_id &&
        product.category_id == newData.category_id &&
        product.sub_category_id == newData.sub_category_id
      )
      console.log(index);
      if (index !== -1) {
        Swal.fire("Product Already Exists Kindly Go for Update")
        setIsModalOpen(false);
        setEdit(false)
      } else {
        const indexToUpdate = productsData.findIndex(product => product.product_no == newData.product_no)
        if (indexToUpdate !== -1) {
          const updatedProductData = [...productsData]; // Create a copy of the original array
          updatedProductData[indexToUpdate] = { ...updatedProductData[indexToUpdate], ...newData };
          // console.log(updatedProductData);
          // Merge the existing object with the new data
          setproductsData(updatedProductData); // Update the state with the modified array
          setEdit(false)
          setIsModalOpen(false);
          setSelectedProduct('')
          setSelectedCategory('')
          setSelectedSubcategory('')
          setProductSlideData({
            qty: '',
            kg: '',
            totalQuantity: '',
            gst: '18',
            discount: '0'
          })
          setTotalQuanity('')
          setBasePrice('')
          setPriceBeforeDiscount('')
          setPriceAfterDiscount('')
          setNetValue(0)
          setMeasurementType('')
          // Swal.fire("Product saved Successfully");
          Swal.fire({
            title: 'Product saved Successfully',
            icon: 'success',
            showCancelButton: false, // Set to true if you want to show a cancel button
            confirmButtonColor: 'green', // Customize the confirm button color
            confirmButtonText: 'OK', // Customize the confirm button text
            allowOutsideClick: false, // Set to true if you want to allow the user to dismiss the modal by clicking outside of it
            // Other customization options...
          })
        }

      }

    } catch (error) {

    }
  }

  const deleteProductHandler = async (product_id) => {
    try {
      const indexToUpdate = productsData.findIndex(product => product.product_id == product_id);
      if (indexToUpdate !== -1) {
        const updatedProductData = [...productsData];
        updatedProductData.splice(indexToUpdate, 1);
        setproductsData(updatedProductData);
        setIsModalOpenOne(false)
      }
      Swal.fire({
        title: 'Product has been sucessfully deleted',
        // icon: 'success',
        // showCancelButton: false, // Set to true if you want to show a cancel button
        confirmButtonColor: 'green', // Customize the confirm button color
        confirmButtonText: 'OK', // Customize the confirm button text
        allowOutsideClick: false, // Set to true if you want to allow the user to dismiss the modal by clicking outside of it
        // Other customization options...
      })

    } catch (error) {
      console.log(error);
    }
  }

  const handleGenrateIntent = async () => {
    try {
      setLoading1(true)


      let updatedProductsData = productsData.map(product => {
        const { lead_brand, lead_category, lead_sub_category, product_gst, hsn_code, ...rest } = product;
        return {
          ...rest,
          gst: product_gst, // Spread the existing properties of the product
          quotation_id: parseInt(quotationId),
          status: 1,
          hsn_code: "null"// Add your new key-value pair
        };
      });

      let data = { ...extraData, po_date: selectedDate }


      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      const params = {
        po_no: data.po_no,
        po_date: data.po_date,
        loading_data: data.loading_data,
        unLoading_data: data.unLoading_data,
        freight_data: data.freight_data,
        contact_person: data.contact_person_name,
        contact_person_number: data.contact_person_number,
        sales_station: data.sales_station,
        sales_transport: data.sales_transport,
        sales_gr_no: data.sales_gr_no,
        sales_vehicle_no: data.sales_vehicle_no,
        sales_driver_name: data.sales_driver_name,
        sales_driver_number: data.sales_driver_number,
        products: updatedProductsData,
        editIntend: status,
      }

      const response = await axios.put(Url.BASEURL + 'add-intendLead/' + leadId, params, config)
      if (response) {
        setLoading1(false)
        navigate('/manageleads')
        if (status === 0) {
          Swal.fire({
            title: 'Sales Order Genrated Successfully',
            icon: 'success',
            showCancelButton: false, // Set to true if you want to show a cancel button
            confirmButtonColor: 'green', // Customize the confirm button color
            confirmButtonText: 'OK', // Customize the confirm button text
            allowOutsideClick: false, // Set to true if you want to allow the user to dismiss the modal by clicking outside of it
            // Other customization options...
          })
        } else {
          Swal.fire({
            title: 'Sales Order Updated Successfully',
            icon: 'success',
            showCancelButton: false, // Set to true if you want to show a cancel button
            confirmButtonColor: 'green', // Customize the confirm button color
            confirmButtonText: 'OK', // Customize the confirm button text
            allowOutsideClick: false, // Set to true if you want to allow the user to dismiss the modal by clicking outside of it
            // Other customization options...
          })
        }


      }

    } catch (err) {
      setLoading1(false)
      console.log(err);
    }
  }
  return (
    <>
      {loading1 && (
        <div className="loader-backdrop">
          <div className="spinner-container">
            <ClipLoader color={'#50CD89'} loading={loading1} css={override} size={60} />
          </div>
        </div>
      )}

      <div>
        {isModalOpenTwo &&
          <Modal
            style={{ display: 'block' }}
            isOpen={isModalOpenTwo}
            onClose={closeModalTwo}
          >

            <div style={{ alignSelf: 'center' }}>
              <h2 className='fs-3'> Are You sure</h2>
              <p className='fw-semibold fs-5'>{status === 0 ? "You want to Genrate Sales Order" : "You want to Update Sales Order"}</p>
              <button
                style={{ marginTop: '30px' }}
                type="button"
                className="btn btn-secondary rounded-1"
                onClick={() => setIsModalOpenTwo(false)}
              // disabled={isButtonDisabled}
              >
                Cancel
              </button>
              <button
                style={{ marginTop: '30px', marginLeft: '20px' }}
                type="button"
                className="btn btn-success rounded-1"
                onClick={handleGenrateIntent}
              // disabled={isButtonDisabled}
              >
                {status === 0 ? "Genrate" : "Update"}
              </button>
            </div>
          </Modal>}
        {isModalOpenOne &&
          <Modal
            style={{ display: 'block' }}
            isOpen={isModalOpenOne}
            onClose={closeModalOne}
          >
            {/* {loading1 && (
              <div className="loader-backdrop">
                <div className="spinner-container">
                  <ClipLoader color={'#50CD89'} loading={loading1} css={override} size={60} />
                </div>
              </div>
            )} */}
            <div style={{ alignSelf: 'center' }}>
              <h2 className='fs-3'> Are You sure</h2>
              <p className='fw-semibold fs-5'>You want to delete this product from intent</p>
              <button
                style={{ marginTop: '30px' }}
                type="button"
                className="btn btn-secondary rounded-1"
                onClick={() => setIsModalOpenOne(false)}
              // disabled={isButtonDisabled}
              >
                Cancel
              </button>
              <button
                style={{ marginTop: '30px', marginLeft: '20px' }}
                type="button"
                className="btn btn-success rounded-1"
                onClick={() => {
                  deleteProductHandler(editId)
                }}
              // disabled={isButtonDisabled}
              >
                Delete
              </button>
            </div>
          </Modal>}
        {isModalOpen &&
          <Modal
            style={{ display: 'block' }}
            isOpen={isModalOpen}
            onClose={closeModal}
          >
            {/* {loading1 && (
              <div className="loader-backdrop">
                <div className="spinner-container">
                  <ClipLoader color={'#50CD89'} loading={loading1} css={override} size={60} />
                </div>
              </div>
            )} */}
            <div style={{ alignSelf: 'center' }}>
              <h2 className='fs-3'>Edit Product</h2>
              <div className='row flex-warap row-gap-3'
                style={{ width: "800px" }}
              >
                <div className='col-4'>
                  <Form.Group>
                    <label className='fw-bold fs-6'>Item Name</label>
                    <ReactSelect
                      options={productOptionss}
                      value={selectedProduct}
                      onChange={(value) => {
                        setSelectedProduct(value);
                        // setSelectedProductName(value.label);
                        setSelectedCategory('');
                        setSelectedSubcategory('');
                      }}
                      maxMenuHeight={'200px'}
                      isDisabled
                    />
                  </Form.Group>
                </div>
                <div className='col-4'>
                  <Form.Group>
                    <label className='fw-bold fs-6'>Brand Name</label>
                    <ReactSelect
                      options={BrandOptions}
                      value={selectedBrand}
                      onChange={(value) => {
                        setSelectedBrand(value);
                        // setSelectedProductName(value.label);
                        setSelectedCategory('');
                        setSelectedSubcategory('');
                      }}
                      maxMenuHeight={'200px'}
                    />
                  </Form.Group>
                </div>
                <div className='col-4'>
                  <Form.Group>
                    <label className='fw-bold fs-6'>Size</label>
                    <ReactSelect
                      options={categoryOptions}
                      value={selectedCategory}
                      onChange={(value) => {
                        setSelectedCategory(value);
                        setSelectedSubcategory('');
                      }}
                      maxMenuHeight={'200px'}
                      isDisabled={!selectedProduct}
                    />
                  </Form.Group>
                </div>
                <div className='col-4'>
                  <Form.Group>
                    <label className='fw-bold fs-6'>Thickness</label>
                    <ReactSelect
                      options={subcategoryOptions}
                      value={selectedSubcategory}
                      onChange={setSelectedSubcategory}
                      isDisabled={!selectedCategory}
                    />
                  </Form.Group>
                </div>
                <div className='col-4'>
                  <Form.Group>
                    <label className='fw-bold fs-6'>Quantity /PCS</label>
                    <Form.Control
                      type="text"
                      placeholder="Enter quantity"
                      className='rounded-1'
                      style={{ padding: "8px" }}
                      value={productSlideData.qty}
                      name="qty"
                      qty
                      onChange={handleProductInputChange}
                      onInput={(e) => {
                        // Remove any characters that are not digits, decimal points, or #
                        e.target.value = e.target.value.replace(/[^\d]/g, '');
                      }}
                    />
                  </Form.Group>
                </div>
                <div className='col-4'>
                  <label className='fw-bold fs-6'>Measurement</label>
                  <ReactSelect
                    options={measurementDataOption}
                    value={measurementType}
                    onChange={(value) => {
                      setMeasurementType(value);
                      setProductSlideData({ ...productSlideData, kg: '' })
                      // setBasePrice('0');
                    }}
                  />
                </div>
                <div className='col-4'>
                  <Form.Group>
                    <label className='fw-bold fs-6'>Enter kg</label>
                    <Form.Control
                      type="text"
                      placeholder="Enter quantity"
                      className='rounded-1'
                      style={{ padding: "8px" }}
                      value={measurementType.label == 'KILOGRAMS' ? productSlideData.kg : 0}
                      name="kg"
                      onChange={handleProductInputChange}
                      disabled={
                        measurementType.label !== 'KILOGRAMS'
                      }
                      onInput={(e) => {
                        // Remove any characters that are not digits, decimal points, or #
                        e.target.value = e.target.value.replace(/[^\d.]/g, '');
                      }}
                    />
                  </Form.Group>
                </div>

                <div className='col-4'>
                  <Form.Group>
                    <label className='fw-bold fs-6'>Total Quantity <span className='fw-normal fs-7'>(after measurement)</span></label>
                    <Form.Control
                      type="text"
                      placeholder="Total Quantity"
                      value={totalQuanity}
                      name="totalQuantity"
                      onChange={(e) => setTotalQuanity(e.target.value)}
                      className='rounded-1'
                      style={{ padding: "8px" }}
                      onInput={(e) => {
                        // Remove any characters that are not digits, decimal points, or #
                        e.target.value = e.target.value.replace(/[^\d.]/g, '');
                      }}
                    />
                  </Form.Group>
                </div>
                <div className='col-4'>
                  <Form.Group>
                    <label className='fw-bold fs-6'> Price /PCS</label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Price"
                      value={
                        basePrice
                      }
                      className='rounded-1'
                      style={{ padding: "8px" }}
                      name="price"
                      onChange={handleBasePriceInputChange}
                      onInput={(e) => {
                        // Remove any characters that are not digits, decimal points, or #
                        e.target.value = e.target.value.replace(/[^\d.]/g, '');
                      }}
                    />
                  </Form.Group>
                </div>

                <div className='col-4'>
                  <Form.Group>
                    <label className='fw-bold fs-6'> Discount (%)</label>
                    <Form.Control
                      // type="number"
                      className='rounded-1'
                      style={{ padding: "8px" }}
                      placeholder="Enter Discount"
                      value={productSlideData.discount}
                      name="discount"
                      onChange={handleProductInputChange}
                      onInput={(e) => {
                        // Remove any characters that are not digits, decimal points, or #
                        e.target.value = e.target.value.replace(/[^\d.]/g, '');
                      }}
                    // name="qty"
                    />
                  </Form.Group>
                </div>
                <div className='col-4'>
                  <Form.Group>
                    <label className='fw-bold fs-6'> Net Price </label>
                    <Form.Control
                      // type="number"
                      className='rounded-1'
                      style={{ padding: "8px" }}
                      placeholder="Price After Discount"
                      value={
                        productSlideData.discount
                          ? priceAfterDiscount
                          : priceBeforeDiscount
                      }
                      name="priceAfterDiscount"
                      onChange={(e) => e.preventDefault()}
                    />
                  </Form.Group>
                </div>
                <div className='col-4'>
                  <Form.Group>
                    <label className='fw-bold fs-6'>Taxable Amount</label>
                    <Form.Control
                      // type="number"
                      className='rounded-1'
                      style={{ padding: "8px" }}
                      placeholder="Price Before Discount"
                      value={taxAmount}
                      name="priceBeforeDiscount"
                      onChange={(e) => e.preventDefault()}
                    />
                  </Form.Group>
                </div>
                <div className='col-4'>
                  <Form.Group>
                    <label className='fw-bold fs-6'> GST (%)</label>
                    <Form.Control
                      type="text"
                      className='rounded-1'
                      style={{ padding: "8px" }}
                      placeholder="Enter quantity"
                      value={productSlideData.gst}
                      name="gst"
                      onChange={handleProductInputChange}
                      onInput={(e) => {
                        // Remove any characters that are not digits, decimal points, or #
                        e.target.value = e.target.value.replace(/[^\d.]/g, '');
                      }}
                    />
                  </Form.Group>
                </div>
                <div className='col-4'>
                  <Form.Group>
                    <label className='fw-bold fs-6'>  Net Value</label>
                    <Form.Control
                      className='rounded-1'
                      style={{ padding: "8px" }}
                      // type="number"
                      placeholder="Net Value"
                      value={netValue}
                      name="netValue"
                      onChange={(e) => setNetValue(e.target.value)}
                    // name="qty"
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-center align-items-center gap-5 mt-5'>
              <button
                className="btn border btn-light border-secondary border-1 col-3 text-black rounded-1 "
                onClick={() => {
                  setIsModalOpen(false);
                  setSelectedProduct('')
                  setSelectedCategory('')
                  setSelectedSubcategory('')
                  setTotalQuanity('')
                  setProductSlideData({
                    qty: '',
                    kg: '',
                    totalQuantity: '',
                    gst: '18',
                    discount: '0'
                  })
                  setBasePrice('')
                  setTaxAmount('')
                  // setPriceBeforeDiscount('')
                  // setPriceAfterDiscount('')
                  setNetValue(0)
                  setMeasurementType('')
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-success col-3 border border-success border-1 rounded-1"
                onClick={() => handleProductSubmit(editId)}
              >
                Save
              </button>
            </div>
          </Modal>}
        <Constant>
          <Header
            onpage_link="Sales Order"
            heading="Sales Order"
          />
          <div id="kt_app_content" className="app-content flex-column-fluid">

            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <h2 className='fs-2 mb-5'>Sales Order</h2>
              <div className="col-xxl-12 mb-5 ">
                <div className="card card-flush h-xl-100">
                  <div className='' style={{ alignSelf: 'center', width: "100%" }}>
                    {/* <div className="row"> */}
                    <div
                      className="card card-flush "
                      style={{
                        boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
                      }}
                    >

                      <div>
                        <table className="table m-0" >
                          <thead
                            style={{
                              backgroundColor: 'white',
                              zIndex: 1,
                              fontWeight: 100,
                              borderBottom: "1px solid #e9ecef"
                            }}
                          >
                            <tr className='mb-3'>
                              <th className='fw-bold text-center px-3'>Sr no.</th>
                              <th className='fw-bold '>Product Name</th>
                              <th className='fw-semibold fs-5 '>Brand</th>
                              <th className='fw-bold text-center'>Category</th>
                              <th className='fw-bold text-center'>SubCategory</th>
                              <th className='fw-bold text-center'>Totol quanity</th>
                              <th className='fw-bold text-center'>Price /pc </th>
                              <th className='fw-bold text-center'>Discount</th>
                              <th className='fw-bold text-center'>Net-price</th>
                              <th className='fw-bold text-center'>Taxable Amount</th>
                              <th className='fw-bold text-center'>GST</th>
                              <th className='fw-bold text-center'>Net-Value </th>
                              <th className='fw-bold text-center px-3'>Action</th>
                            </tr>
                          </thead>
                          <tbody className='py-3 '
                          >

                            {
                              loading ? (
                                <tr>
                                  <td colSpan="10">
                                    <div
                                      className="spinner-container w-100"
                                      style={{ alignSelf: 'center' }}
                                    >
                                      <ClipLoader
                                        color={'#50CD89'}
                                        loading={loading}
                                        css={override}
                                        size={60}
                                      />
                                    </div>
                                  </td>
                                </tr>

                              ) : (
                                productsData.length === 0 ? (
                                  <tr className="text-center">
                                    <td
                                      colSpan="9"
                                      style={{ fontSize: 18, marginTop: '10px' }}
                                    >
                                      No Data found
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {productsData.map((e, index) => {
                                      if (Object.keys(e).length === 0) {
                                        return null; // Skip rendering the row
                                      }
                                      return (
                                        <tr key={index}>
                                          <td className='text-center px-3 fw-semibold'>{index + 1}</td>
                                          <td className='fw-semibold'>
                                            {e.product_name
                                              ? e.product_name
                                              : '------'}
                                          </td>
                                          <td className='text-center'>
                                            {e.brand_name
                                              ? e.brand_name
                                              : '------'}
                                          </td>
                                          <td className='text-center fw-semibold'>
                                            {e.category_name
                                              ? e.category_name
                                              : '------'}
                                          </td>
                                          <td className='text-center fw-semibold'>
                                            {e.sub_category_name
                                              ? e.sub_category_name
                                              : '------'}
                                          </td>
                                          <td className='text-center fw-semibold'>
                                            {e.total_quantity
                                              ? e.total_quantity
                                              : '------'}
                                          </td>
                                          {/* <td>120</td> */}
                                          <td className='text-center fw-semibold'>
                                            {e.product_price
                                              ? e.product_price
                                              : '------'}
                                          </td>
                                          {/* <td>24000</td> */}
                                          <td className='text-center fw-semibold'>{e.discount}%</td>
                                          <td className='text-center'>{e.net_price}</td>
                                          <td className='text-center'>{e.taxable_amount}</td>
                                          <td className='text-center fw-semibold'>{e.product_gst}%</td>
                                          <td className='text-center fw-semibold'>
                                            {e.total_price ? e.total_price : '------'}
                                          </td>
                                          <td
                                            className="d-flex justify-content-between px-3"
                                          >
                                            <i
                                              onClick={() => {
                                                setIsModalOpenOne(true);
                                                setEditId(e.product_id);
                                              }}
                                              className="fa-solid fa-trash fs-4 text-danger cursor-pointer px-1"
                                            ></i>
                                            <i
                                              onClick={() => {
                                                setEdit(true);
                                                setIsModalOpen(true);
                                                setEditId(e.product_id);
                                                setSelectedProduct(productOptionss.find(product => product.value == e.product_id));
                                                handleSetState(e.product_no, e.product_id, e.brand_id, e.category_id, e.sub_category_id, e.product_qty, e.measurement, e.product_kg, e.product_price, e.discount, e.product_gst, e.total_price, e.net_price, e.taxable_amount, e.total_quantity)
                                                // console.log(e.product_id, e.category_id, e.sub_category_id, e.product_qty, e.measurement, e.product_kg, e.product_price, e.discount, e.product_gst, e.total_price, e.net_price, e.taxable_amount)

                                              }}

                                              className="fa-solid fa-pen fs-4 text-primary cursor-pointer px-1"
                                            ></i>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </>
                                )
                              )
                            }
                          </tbody>

                        </table>
                      </div>
                    </div>
                    {/* </div> */}

                  </div>

                </div>
              </div>
              <div className="col-xxl-12 mb-5 ">
                <div className="card card-flush h-xl-100">
                  <div className='' style={{ alignSelf: 'center', width: "100%" }}>
                    {/* <div className="row"> */}
                    <div
                      className="card card-flush "
                      style={{
                        boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
                      }}
                    >

                      <div className='row flex-wrap py-5 px-5 row-gap-3'>
                        <div className='col-4'>
                          <Form.Group>
                            <label className='fw-bold fs-6'>PO Number</label>
                            <Form.Control
                              type='text'
                              className='rounded-1'
                              style={{ padding: "8px" }}
                              placeholder="Po Number"
                              value={extraData.po_no}
                              name="po_no"
                              onChange={handleExtraDataChange}
                            // onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}

                            />
                          </Form.Group>
                        </div>
                        <div className='col-4 d-flex flex-column'>

                          <label className='fw-bold fs-6 col-12'>PO Date</label>
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Select a date"
                            isClearable={selectedDate ? true : false} // Display clear icon in the field
                            style={{ padding: "8px" }}
                            className='m-0 w-100 rounded-1 p-2 col-12 border border-secondary form-control'
                            yearDropdown
                            scrollableYearDropdown
                            showYearDropdown={true}
                            onInput={(e) => e.preventDefault()}

                          />

                        </div>
                        <div className='col-4'>
                          <Form.Group>
                            <label className='fw-bold fs-6'>Shipping Address</label>
                            <Form.Control
                              type="text"
                              className='rounded-1'
                              style={{ padding: "8px" }}
                              placeholder="Shipping Address"
                              value={extraData.sales_station}
                              name="sales_station"
                              onChange={handleExtraDataChange}
                            />
                          </Form.Group>
                        </div>
                        <div className='col-4'>
                          <Form.Group>
                            <label className='fw-bold fs-6'>Contact Person Name</label>
                            <Form.Control
                              type="text"
                              className='rounded-1'
                              style={{ padding: "8px" }}
                              placeholder="Contact Person Name"
                              value={extraData.contact_person_name}
                              name="contact_person_name"
                              onChange={handleExtraDataChange}
                              // onInput={(e) => {
                              //   e.target.value = e.target.value.replace(/[^A-Za-z\s]/ig, '');
                              // }}
                            />
                          </Form.Group>
                        </div>
                        <div className='col-4'>
                          <Form.Group>
                            <label className='fw-bold fs-6'>Contact Person Number</label>
                            <Form.Control
                              type="text"
                              className='rounded-1'
                              style={{ padding: "8px" }}
                              placeholder="Contact Person Number"
                              value={extraData.contact_person_number}
                              name="contact_person_number"
                              onChange={handleExtraDataChange}
                              maxLength={10}
                              onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                            />
                          </Form.Group>
                        </div>

                        <div className='col-4'>
                          <Form.Group>
                            <label className='fw-bold fs-6'>Loading</label>
                            <Form.Control
                              type="text"
                              className='rounded-1'
                              style={{ padding: "8px" }}
                              placeholder="Loading"
                              value={extraData.loading_data}
                              name="loading_data"
                              onChange={handleExtraDataChange}
                            />
                          </Form.Group>
                        </div>
                        <div className='col-4'>
                          <Form.Group>
                            <label className='fw-bold fs-6'>Unloading</label>
                            <Form.Control
                              type="text"
                              className='rounded-1'
                              style={{ padding: "8px" }}
                              placeholder="Unoading"
                              value={extraData.unLoading_data}
                              name="unLoading_data"
                              onChange={handleExtraDataChange}
                            />
                          </Form.Group>
                        </div>
                        <div className='col-4'>
                          <Form.Group>
                            <label className='fw-bold fs-6'>Freight</label>
                            <Form.Control
                              type="text"
                              className='rounded-1'
                              style={{ padding: "8px" }}
                              placeholder="Freight Data"
                              value={extraData.freight_data}
                              name="freight_data"
                              onChange={handleExtraDataChange}
                            />
                          </Form.Group>
                        </div>
                        <div className='col-4'>
                          <Form.Group>
                            <label className='fw-bold fs-6'>Transport</label>
                            <Form.Control
                              type="text"
                              className='rounded-1'
                              style={{ padding: "8px" }}
                              placeholder="Transport"
                              value={extraData.sales_transport}
                              name="sales_transport"
                              onChange={handleExtraDataChange}
                            />
                          </Form.Group>
                        </div>
                        <div className='col-4'>
                          <Form.Group>
                            <label className='fw-bold fs-6'>GR Number</label>
                            <Form.Control
                              type="text"
                              className='rounded-1'
                              style={{ padding: "8px" }}
                              placeholder="GR Number"
                              value={extraData.sales_gr_no}
                              name="sales_gr_no"
                              onChange={handleExtraDataChange}
                            // onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                            />
                          </Form.Group>
                        </div>
                        <div className='col-4'>
                          <Form.Group>
                            <label className='fw-bold fs-6'>Vehicle Number</label>
                            <Form.Control
                              type="text"
                              className='rounded-1'
                              style={{ padding: "8px" }}
                              placeholder="Vehicle Number"
                              value={extraData.sales_vehicle_no}
                              name="sales_vehicle_no"
                              onChange={handleExtraDataChange}
                            // onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                            />
                          </Form.Group>
                        </div>
                        <div className='col-4'>
                          <Form.Group>
                            <label className='fw-bold fs-6'>Driver's Name</label>
                            <Form.Control
                              type="text"
                              className='rounded-1'
                              style={{ padding: "8px" }}
                              placeholder="Driver's Name"
                              value={extraData.sales_driver_name}
                              name="sales_driver_name"
                              onChange={handleExtraDataChange}
                            // onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                            />
                          </Form.Group>
                        </div>
                        <div className='col-4'>
                          <Form.Group>
                            <label className='fw-bold fs-6'>Driver's Number</label>
                            <Form.Control
                              type="text"
                              className='rounded-1'
                              style={{ padding: "8px" }}
                              placeholder="Driver's Number"
                              value={extraData.sales_driver_number}
                              name="sales_driver_number"
                              onChange={handleExtraDataChange}
                              maxLength={10}
                              onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                            />
                          </Form.Group>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-start mb-5'>
                <button
                  type="button"
                  className="btn btn-success col-2 rounded-1"
                  onClick={() => setIsModalOpenTwo(true)}
                >
                  {status === 0 ? "Genrate Sales Order" : "Update Sales Order"}

                </button>
              </div>
            </div>
          </div>
        </Constant>
      </div>
    </>
  )
}

export default Intent