// import React, { useState, useEffect } from 'react';
// import Header from '../../Constant/Header';
// import Constant from '../../Constant/UserLayout';
// import DatePicker from 'react-datepicker';
// import { format } from 'date-fns';
// import 'react-datepicker/dist/react-datepicker.css';
// import { useSelector } from 'react-redux';
// import axios from 'axios';
// import Url from '../../Elements/Api/Api';
// import ReactPaginate from 'react-paginate';
// import { ClipLoader } from 'react-spinners';
// import { css } from '@emotion/react';

// const TeamStatusReport = () => {
//   //extratcting token
//   const token = useSelector((state) => state.token);

//   const [searchStatus, setSearchStatus] = useState('');
//   const [searchClientByDescription, setSearchClientByDescription] =
//     useState('');
//   const [itemOffset, setItemOffset] = useState(1);
//   const [response, setResponse] = useState([]);
//   const [deleteRow, setDeleteRow] = useState([]);

//   const [statusReport, setStatusReport] = useState([]);
//   const [pageCount, setPageCount] = useState(1);

//   //stage id & staffId
//   const [stage_Id, setStage_Id] = useState('');
//   const [staff_Id, setStaff_Id] = useState('');

//   // state for date manage
//   const [selectedDate, setSelectedDate] = useState('');
//   const [selectedDate2, setSelectedDate2] = useState('');

//   //state for stage manage and staff manage
//   const [stage, setStage] = useState([]);
//   const [staffList, setStaffList] = useState([]);

//   //function for date picker
//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };
//   const handleDateChange2 = (date) => {
//     setSelectedDate2(date);
//   };
//   const [loading, setLoading] = useState(false);
//   const override = css`
//     display: block;
//     margin: 0 auto;
//   `;
//   //sorting arrow
//   const [isArrowObj, setIsArrowObj] = useState({
//     column: 'contact_name',
//     orderby: 'asc'
//   });
//   console.log('*********************************', isArrowObj);

//   //function for stage change
//   const StageChangeHandler = (event) => {
//     setStage_Id(event.target.value);
//   };

//   //function for staff change
//   const StaffChangeHandler = (event) => {
//     setStaff_Id(event.target.value);
//   };

//   // function for sorting data
//   const handleArrowClick = (param, orderby) => {
//     if (isArrowObj && isArrowObj.column == param) {
//       orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
//     } else {
//       orderby = orderby == 'asc' ? 'desc' : 'asc';
//     }
//     const isArrowobjData = {
//       column: param,
//       orderby: orderby
//     };
//     setIsArrowObj(isArrowobjData);
//   };

//   //api for getting lead stage
//   useEffect(() => {
//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`
//       }
//     };
//     axios
//       .get(Url.BASEURL + 'leadstages', config)
//       .then((value) => setStage(value.data.response))
//       .catch((err) => console.log(err));
//   }, []);

//   //api for getting staff
//   useEffect(() => {
//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`
//       }
//     };
//     axios
//       .get(Url.BASEURL + 'staffnameunderteammanager', config)
//       .then((value) => setStaffList(value.data.response))
//       .catch((err) => console.log(err));
//   }, []);

//   //date formater
//   const formattedDate = selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '';
//   const formattedDate2 = selectedDate2
//     ? format(selectedDate2, 'dd/MM/yyyy')
//     : '';
//   //api for getting statusreport
//   useEffect(() => {
//     getStatusReport();
//   }, [
//     searchStatus,
//     itemOffset,
//     deleteRow,
//     formattedDate,
//     formattedDate2,
//     stage_Id,
//     staff_Id,
//     isArrowObj
//   ]);
//   const getStatusReport = () => {
//     setLoading(true);
//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`
//       }
//     };
//     axios
//       .get(
//         Url.BASEURL +
//           'getdailystatus?' +
//           'search=' +
//           searchStatus +
//           '&page=' +
//           itemOffset +
//           '&from=' +
//           formattedDate +
//           '&to=' +
//           formattedDate2 +
//           '&stage_id=' +
//           stage_Id +
//           '&staff_id=' +
//           staff_Id +
//           '&orderby=' +
//           isArrowObj.column +
//           '&sortby=' +
//           isArrowObj.orderby,
//         config
//       )
//       .then(
//         (value) =>
//           setStatusReport(value.data.response.data) &
//           setPageCount(value.data.response.last_page) &
//           setLoading(false)
//       )
//       .catch((err) => console.log(err));
//   };
//   useEffect(() => {
//     if (statusReport) {
//       setResponse(statusReport);
//       console.log('clientdata', statusReport);
//     }
//   }, [statusReport, searchStatus, deleteRow]);

//   // function for search
//   const handleKeyPress = () => {
//     setItemOffset(0);
//     setSearchClientByDescription(searchClientByDescription);
//   };

//   // function for pagination
//   const handlePageClick = (event) => {
//     const newOffset = event.selected + 1;
//     console.log('newOffset', newOffset);

//     setItemOffset(newOffset);
//   };

//   return (
//     <div>
//       <Constant>
//         <Header
//           onpage_link="Team's Status Reports"
//           heading="Team's Status Reports"
//           btn_path="team_status_reports"
//         />
//         {/* this is the body section */}
//         <div id="kt_app_content" className="app-content flex-column-fluid">
//           <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
//             {/* this code is for search bar */}
//             <div className="searchbar_border" style={{ marginTop: '20px' }}>
//               <div
//                 className="search-field d-none d-md-block"
//                 style={{ marginBottom: '13px' }}
//               >
//                 <div
//                   className="d-flex align-items-center h-100 searchbar_parent"
//                   action="#"
//                 >
//                   <div
//                     className="input-group merchant_search_bar border_radius searchbar_size"
//                     style={{
//                       borderWidth: 1,
//                       borderRadius: 5
//                     }}
//                   >
//                     <div className="input-group-prepend bg-transparent"></div>
//                     <input
//                       style={{
//                         height: 30
//                       }}
//                       onChange={(e) =>
//                         setSearchStatus(e.target.value) & handleKeyPress()
//                       }
//                       type="text"
//                       className="form-control bg-transparent border-0"
//                       placeholder="Search"
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* this  code is for date paicker */}
//             <div style={{ display: 'flex', flexDirection: 'column' }}>
//               <label>From</label>
//               <DatePicker
//                 selected={selectedDate}
//                 onChange={handleDateChange}
//                 dateFormat="dd-MM-yyyy"
//                 placeholderText="Select a date"
//                 // isClearable={true}
//                 yearDropdown
//                 scrollableYearDropdown
//                 showYearDropdown={true}
//               />
//             </div>
//             <div style={{ display: 'flex', flexDirection: 'column' }}>
//               <label>To</label>
//               <DatePicker
//                 selected={selectedDate2}
//                 onChange={handleDateChange2}
//                 dateFormat="dd-MM-yyyy"
//                 placeholderText="Select a date"
//                 // isClearable={true}
//                 yearDropdown
//                 scrollableYearDropdown
//                 showYearDropdown={true}
//               />
//             </div>

//             {/* this code is for stage selection */}
//             <select
//               onChange={StageChangeHandler}
//               id="select1"
//               class="ct_listing border_radius ct_listing_float ct_listing_margin "
//               style={{
//                 borderWidth: '1px',
//                 borderRadius: '8px',
//                 width: '190px',
//                 marginBottom: '13px',
//                 borderColor: '0.5px solid black',
//                 marginTop: '20px'
//               }}
//             >
//               {stage.map((e) => {
//                 return <option value={e.id}>{e.stage}</option>;
//               })}
//               <option value="">Select Stage</option>
//               {/* {console.log('id', stage_Id)} */}
//             </select>

//             {/* this code is for staff selection */}
//             <select
//               onChange={StaffChangeHandler}
//               id="select1"
//               class="ct_listing border_radius ct_listing_float ct_listing_margin "
//               style={{
//                 borderWidth: '1px',
//                 borderRadius: '8px',
//                 width: '190px',
//                 marginBottom: '13px',
//                 borderColor: '0.5px solid black',
//                 marginTop: '20px'
//               }}
//             >
//               {staffList.map((e) => {
//                 return <option value={e.id}>{e.name}</option>;
//               })}
//               <option value="">Select Staff</option>
//               {/* {console.log('id.......', staff_Id)} */}
//             </select>
//           </div>

//           {/* here start the sorting  */}
//           <div
//             id="kt_app_content_container"
//             className="app-container container-fluid"
//           >
//             <div className="col-xxl-12 mb-5 mb-xl-10">
//               <div className="card card-flush h-xl-100">
//                 {loading ? (
//                   <div
//                     className="spinner-container"
//                     style={{ alignSelf: 'center' }}
//                   >
//                     <ClipLoader
//                       color={'#50CD89'}
//                       loading={loading}
//                       css={override}
//                       size={60}
//                     />
//                   </div>
//                 ) : (
//                   <div className="">
//                     {/*begin::Tab content*/}
//                     <div className="tab-content">
//                       <div className=" mb-5 mb-xl-8">
//                         {/*begin::Body*/}
//                         <div className="card-body py-3">
//                           {/*begin::Table container*/}
//                           <div className="table-responsive">
//                             {/* table starts here */}
//                             <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
//                               <thead
//                                 style={{ fontSize: '15px' }}
//                                 className="text-center"
//                               >
//                                 <tr className="fw-bold text-muted">
//                                   {/* sort according to date */}
//                                   <th className="min-w-150px">
//                                     <span style={{ marginRight: '3px' }}>
//                                       <i
//                                         onClick={() =>
//                                           handleArrowClick('owner_name', 'asc')
//                                         }
//                                         class={
//                                           isArrowObj &&
//                                           isArrowObj.column == 'owner_name' &&
//                                           isArrowObj.orderby == 'desc'
//                                             ? 'fa-solid fa-arrow-down'
//                                             : 'fa-solid fa-arrow-up'
//                                         }
//                                         style={{}}
//                                       ></i>
//                                     </span>
//                                     Date
//                                   </th>

//                                   {/* sort according to description */}
//                                   <th className="min-w-150px">
//                                     <span style={{ marginRight: '3px' }}>
//                                       <i
//                                         onClick={() =>
//                                           handleArrowClick('owner_email', 'asc')
//                                         }
//                                         class={
//                                           isArrowObj &&
//                                           isArrowObj.column == 'owner_email' &&
//                                           isArrowObj.orderby == 'desc'
//                                             ? 'fa-solid fa-arrow-down'
//                                             : 'fa-solid fa-arrow-up'
//                                         }
//                                         style={{}}
//                                       ></i>
//                                     </span>
//                                     Description
//                                   </th>
//                                   {/* sort according client name */}
//                                   <th className="min-w-120px">
//                                     <span style={{ marginRight: '3px' }}>
//                                       <i
//                                         onClick={() =>
//                                           handleArrowClick(
//                                             'organisation',
//                                             'asc'
//                                           )
//                                         }
//                                         class={
//                                           isArrowObj &&
//                                           isArrowObj.column == 'organisation' &&
//                                           isArrowObj.orderby == 'desc'
//                                             ? 'fa-solid fa-arrow-down'
//                                             : 'fa-solid fa-arrow-up'
//                                         }
//                                       ></i>
//                                     </span>
//                                     Client Name
//                                   </th>
//                                   {/* sort according staff name */}
//                                   <th className="min-w-120px">
//                                     <span style={{ marginRight: '3px' }}>
//                                       <i
//                                         onClick={() =>
//                                           handleArrowClick(
//                                             'owner_contact_no',
//                                             'asc'
//                                           )
//                                         }
//                                         class={
//                                           isArrowObj &&
//                                           isArrowObj.column ==
//                                             'owner_contact_no' &&
//                                           isArrowObj.orderby == 'desc'
//                                             ? 'fa-solid fa-arrow-down'
//                                             : 'fa-solid fa-arrow-up'
//                                         }
//                                       ></i>
//                                     </span>
//                                     Staff Name
//                                   </th>
//                                   {/* status */}
//                                   <th className="min-w-150px">Status</th>
//                                 </tr>
//                               </thead>

//                               {/* ......................tbody starts here ............. */}
//                               <tbody
//                                 style={{ fontSize: '14px', fontWeight: '400' }}
//                               >
//                                 {statusReport?.length == 0 ? (
//                                   <tr>
//                                     <td colSpan="5" className="text-center">
//                                       No Record found
//                                     </td>
//                                   </tr>
//                                 ) : (
//                                   <>
//                                     {statusReport?.map((e, index) => {
//                                       return (
//                                         <tr key={index} className="text-center">
//                                           <td>{e.created_at}</td>
//                                           <td>{e.task}</td>
//                                           <td>{e.client_name}</td>
//                                           <td>
//                                             {e.staff && e.staff.name
//                                               ? e.staff.name
//                                               : ''}
//                                           </td>
//                                           <td>{e.remarks}</td>
//                                         </tr>
//                                       );
//                                     })}
//                                   </>
//                                 )}
//                               </tbody>
//                             </table>

//                             {/* pagination */}
//                             <ReactPaginate
//                               previousLabel="Previous"
//                               nextLabel="Next"
//                               pageClassName="page-item"
//                               pageLinkClassName="page-link"
//                               previousClassName="page-item"
//                               previousLinkClassName="page-link"
//                               nextClassName="page-item"
//                               nextLinkClassName="page-link"
//                               breakLabel="..."
//                               breakClassName="page-item"
//                               breakLinkClassName="page-link"
//                               pageCount={pageCount}
//                               marginPagesDisplayed={2}
//                               pageRangeDisplayed={5}
//                               containerClassName="pagination"
//                               activeClassName="active"
//                               onPageChange={handlePageClick}
//                             />
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </Constant>
//     </div>
//   );
// };

// export default TeamStatusReport;
import React, { useEffect, useState } from 'react';
// import Constant from '../Admin/AdminLayout';
// import BreadScrum from '../Admin/BreadScrum';
import axios from 'axios';
// import Url from '../Element/Api';
import Url from '../../Elements/Api/Api';

import { Link } from 'react-router-dom';
import Constant from '../../Constant/UserLayout';
import { useSelector } from 'react-redux';
import Header from '../../Constant/Header';
const TeamStatusReport = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // January is 0
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [data, setData] = useState([]);
  const currentYear = currentDate.getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const token = useSelector((state) => state.token);
  const [clientId, setClientId] = useState('');
  const [clientLists, setClientLists] = useState([]);
  const [selectedStage, setSelectedStage] = useState('');
  const [stage, setStage] = useState([]);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
          'list-new-month-dailyStatusReportStaff?' +
          'month=' +
          selectedMonth +
          '&' +
          'year=2024',
        config
      )
      .then((value) => setData(value.data))
      .catch((err) => console.log(err));
  }, [selectedMonth]);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'staffownclients', config)
      .then(
        (value) =>
          setClientLists(value.data.response) 
          // setPageCount(value.data.response.last_page)
      )
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'leadstages', config)
      .then((value) => setStage(value.data.response))
      .catch((err) => console.log(err));
  }, []);
  // Example: Get the total number of days in February 2024
  // const getDaysInMonth = (year, month) => {
  //   return new Date(year, month + 1, 0).getDate();
  // };
  const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  const year = 2024;
  const month = 1; // Note: Months in JavaScript are zero-indexed, so 1 represents February
  // const totalDays = getDaysInMonth(year, month);

  // // Generate an array of table headings
  // const tableHeadings = Array.from(
  //   { length: totalDays },
  //   (_, index) => index + 1
  // );
  const totalDays = getDaysInMonth(selectedYear, selectedMonth);
  const tableHeadings = Array.from(
    { length: totalDays },
    (_, index) => index + 1
  );
  // array for mnonths of the year
  const months = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' }
  ];


  // Now you can use the `day` variable globally

  // Event handler for month change
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  const ClientChangeHandler = (event) => {
    setClientId(event.target.value);
  };
  const handlestageChange = (e) => {
    setSelectedStage(e.target.value);
  };
  return (
    <div>
      <Constant>
        {/* <BreadScrum
          btn_name="Add Staff"
          // routepath={'addstaff'}
          heading={'Manage Attendance'}
          //   btn={'Add Staff'}
          // bgColor={'#1E336A'}
          text_color={'#FFFFFF'}
          disabled={true}
        />{' '} */}
             <Header
          // btnName="Add Client"
          // onpage_link="Team's Clients"
          heading="Team's Status Report"
          // btn_path="addclient"
        />
         <div id="kt_app_content" className="app-content flex-column-fluid">
          <div id="kt_app_content_container" className="app-container container-fluid" style={{ marginBottom:'20px'}}>
        <div className="">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body ">
                <div className="row">
                  <div className="col-md-6">
                    <p className="card-title mb-0 text-left mx-2 mt-1">
                      Manage Daily Status report
                    </p>
                  </div>
                  <div className="col-md-6 text-right d-flex justify-content-end">
        
          
                    <select
                      className="col-md-4 filter form-control form-control-sm actdeact_prod mx-2"
                      id="months"
                      style={{ width: '25%' }}
                      value={selectedMonth}
                      onChange={handleMonthChange}
                    >
                      <option value="">Select Month</option>
                      {months.map((month) => (
                        <option key={month.id} value={month.id}>
                          {month.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <form action="#" method="get">
                        <div className="table-responsive">
                          <table style={{ width: '100%' }}>
                            <thead>
                              <tr>
                                <th className="text-left">Employee Name</th>

                                {tableHeadings.map((day) => (
                                  <th key={day}>{day}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {data.length == 0 ? (
                                <tr className="text-center" >
                                  <td
                                    colSpan="30"
                                    style={{
                                      fontSize: 16,
                                      marginTop: '15px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    No Record found
                                  </td>
                                </tr>
                              ) : (
                                <>
                                  {data.map((employee) => (
                                    <tr key={employee.id}>
                                      <td>{employee.name}</td>
                                      {tableHeadings.map((day) => (
                                        <td>
                                          <Link
                                            to={`/get_report/${employee.id}?date=${day}&month=${selectedMonth}&year=2024`}
                                            // to={'/get_report/' + employee.id}
                                            key={`${employee.id}-${day}`}
                                            className={
                                              employee.status[day] === 'Yes'
                                                ? 'green'
                                                : 'red'
                                            }
                                          >
                                            {employee.status[day]}
                                          </Link>
                                        </td>
                                      ))}
                                    </tr>
                                  ))}
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 grid-margin stretch-card">
                            <div className="template-demo"></div>
                          </div>
                        </div>
                        <div id="butns" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
     
      </Constant>
    </div>
  );
};

export default TeamStatusReport;
