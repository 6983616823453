import React, { useEffect, useState, useRef } from 'react';
import Constant from '../../Constant/UserLayout';
// import Header from '../../Constant/Header';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Url from '../../Elements/Api/Api';
import ReactPaginate from 'react-paginate';
import { Puff } from 'react-loader-spinner';
import axios from 'axios';
import Swal from 'sweetalert2'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { default as ReactSelect } from 'react-select';
import AsyncSelect from 'react-select/async';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaCircle, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Modal from './Modal';
import { ClipLoader } from 'react-spinners';
import Header from '../../Constant/Header';
import { checkBoxSchema, newFeedbackSchemaInput, salesSchemaOne, feedbackSchema, additionalDataSchema, newLeadSchema, newSchemaInput } from '../../Elements/ValidationSchema';
// import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
// import Modal from '../Screens/ManageLeads/Modal';

const override = css`
display: block;
margin: 0 auto;
`;
const AddLead = () => {
  const [product_no, setProduct_no] = useState('')
  const [edit, setEdit] = useState(false)
  const [brandList, setBrandList] = useState([])
  const [selectedBrand, setSelectedBrand] = useState('')
  const [taxAmount, setTaxAmount] = useState('')
  const [loading1, setLoading1] = useState(false)
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [formData, setFormData] = useState({});
  const [activeOption, setActiveOption] = useState('Lead Details');
  const [type] = useState(['Existing', 'New']);
  const [purpose] = useState(['Sales', 'Feedback']);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [dummyData, setDummyData] = useState([]);
  const [dummyData2, setDummyData2] = useState([]);
  const [stage, setStage] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedStage, setSelectedStage] = useState('');
  const [industry, setIndustry] = useState([]);
  const token = useSelector((state) => state.token);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [inputFields, setInputFields] = useState([
    { value1: '', value2: '', value3: '', value4: '' }
  ]);
  const [leadDate, setLeadDate] = useState('');
  const [ProductsInfo, setProductsInfo] = useState([]);
  const [searchClientByName, setSearchClientByName] = useState([]);
  const [searchclient, setSearchClient] = useState('');
  const [searchedClient, setSearchedClient] = useState([]);
  const [error, setError] = useState({});
  const [industryId, setIndustryId] = useState({});
  const [clientData, setClientData] = useState([]);
  const [inputValue, setInputValue] = useState('a');
  const [inputValue2, setInputValue2] = useState('a');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchInputValue2, setSearchInputValue2] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [query, setQuery] = useState('');
  const [clientOptions, setClientOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [labelText, setLabelText] = useState('New');
  const [businessType, setBusinessType] = useState('');
  const [purposeType, setPurposeType] = useState("sales");
  const [ownerName, setOwnerName] = useState('');
  const [selectedOption, setSelectedOption] = useState({
    label: '',
    value: ''
  });
  const [ClientError, setClientError] = useState([])
  const [isInputEnabled, setInputEnabled] = useState(false);
  const [measurement, setMeasurement] = useState('');
  const [leadtype, setLeadtype] = useState([]);
  const [check, setcheck] = useState('');
  const [isChecked, setIsChecked] = useState(0);
  const [formCount, setFormCount] = useState(1);
  const [productSubCategory, setProductSubCategory] = useState([])

  //reamrks add field 
  const [additionalData, setAdditionalData] = useState({
    remarks: '',
    delivery: '',
    gst: '',
    freight: '',
    payment: '',
    make: '',
    note: '',
    // terms: ''
  });

  const [communicationData, setCommunicationData] = useState([
    {
      communicationDesc: '',
      reminderDate: '',
      isReminder: ''
    }
  ]);
  const [clientSaveData, setClientSaveData] = useState({
    organisation: '',
    gst_no: '',
    address: '',
    industry_type: ''
  });
  const [products, setProducts] = useState([]);
  const [productNameId, setProductNameId] = useState('');
  const [productCategory, setProductCategory] = useState([]);


  const [subCategoryId, setSubCategoryId] = useState([]);
  const [productSlideData, setProductSlideData] = useState({
    qty: '',
    kg: '',
    discount: '0',
    gst: '18',
  });
  const [slides, setSlides] = useState([]);
  const [freightCharges, setFreightCharges] = useState('');
  const [loadingUnloadingCharges, setLoadingUnloadingCharges] = useState('');
  // const [slides, setSlides] = useState([
  //   { qty: '' },
  //   { kg: '' },
  //   { discount: '' },
  //   { gst: '18' }
  // ]);
  // const [slides, setSlides] = useState([
  //   { totalQuantity: '', productSlideData: { qty: 0, kg: 0 } }
  // ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [measurementType, setMeasurementType] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedProductName, setSelectedProductName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [basePrice, setBasePrice] = useState('');
  const [totalQuanity, setTotalQuanity] = useState('');
  const [priceBeforeDiscount, setPriceBeforeDiscount] = useState('');
  const [priceAfterDiscount, setPriceAfterDiscount] = useState('');
  const [netValue, setNetValue] = useState('');
  const closeModal = () => setIsModalOpen(false);
  const [productIndex, setProductIndex] = useState('');
  const [isNestedModalOpen, setIsNestedModalOpen] = useState(false);
  const [isNestedUpdateModalOpen, setIsNestedUpdateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subtotalValue, setSubtotalValue] = useState('');
  const closeNestedModal = () => setIsNestedModalOpen(false);
  const closeNestedUpdateModal = () => setIsNestedUpdateModalOpen(false);
  const openNestedModal = () => setIsNestedModalOpen(true);
  const closeUpdateModal = () => setIsUpdateModalOpen(false);
  const [clientLists, setClientLists] = useState([]);

  // const handleTotalQuantityChange = (index, event) => {
  //   const newFormData = [...slides];
  //   newFormData[index].qty = event.target.value;
  //   setSlides(newFormData);
  // };
  const handleClientDataInputChange = (e) => {

    const { name, value } = e.target;
    // Clear the error associated with the input field
    setError((prevErrors) => ({
      ...prevErrors,
      [name]: ""
    }));
    setClientSaveData({
      ...clientSaveData,
      [name]: value
    });
  };

  //error handler


  const handleProductInputChange = (e) => {
    const { name, value } = e.target;
    // if (/^\d*$/.test(value)) {
    // if ((name === 'gst' || name === 'discount') && (parseFloat(value) > 100 || isNaN(parseFloat(value)))) {
    if ((name === 'gst' || name === 'discount') && parseInt(value) > 100) {
      // If greater than 100, set the value to 100
      e.target.value = '100';
    } else {
      var keyToRemove = e.target.name;
      setProductSlideData({
        ...productSlideData,
        [name]: value
      });
    }
    // }
    setError({ ...error, [e.target.name]: '' });
  };

  const handleInputChange3 = (e) => {
    setError(prevErrors => ({ ...prevErrors, [name]: '' }));
    const { name, value } = e.target;
    setCommunicationData(prevData => ({
      ...prevData,
      [name]: value
    }));
    // setError({ ...error, [e.target.name]: '' });
  };
  const [selectedMeasurements, setSelectedMeasurements] = useState([
    { id: '', qty: '', measurement_type: '' }
  ]);
  // State to control input field

  //additinal field
  const handleAdditionalDataChange = (e) => {
    setError(prevErrors => ({ ...prevErrors, [name]: '' }));
    const { name, value } = e.target;
    setAdditionalData({ ...additionalData, [name]: value })
  }

  //function and api for client dropdown
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'staffownclients', config)
      .then((value) => setClientLists(value.data.response))
      .catch((err) => console.log(err));
  }, []);

  // function for change label
  useEffect(() => {
    // Log selectedOption here, it will have the updated value
    if (selectedOption) {
      setcheck(selectedOption);
    }
  }, [selectedOption]);
  // Function of add clientlisting
  // useEffect(() => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };
  //   axios
  //     .get(
  //       Url.BASEURL +
  //         'searchstaffclients?' +
  //         'search=' +
  //         searchInputValue +
  //         '&page=',
  //       config
  //     )
  //     .then((value) => {
  //       setDummyData(value.data.response);
  //       setSearchedClient(value.data.response);
  //       setClientOptions(
  //         value.data.response.map((e) => ({
  //           id: e.id,
  //           value: e.id, // You can use e.id here if you prefer
  //           label: e.client
  //         }))
  //       );
  //     })
  //     .catch((err) => console.log(err));
  // }, [searchInputValue]);
  // function for product listing
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'productdropdown?' + 'search=' + '&page=', config)
      .then((value) => setDummyData2(value.data.response.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'create-new-lead?', config)
      .then((value) => {
        setProducts(value.data.response.product_list);
        setProductCategory(value.data.response.category);
        setProductSubCategory(value.data.response.sub_category);
        setLeadtype(value.data.response.measurement_type);
        setClientOptions(value.data.response.client);
      })
      .catch((err) => console.log(err));
  }, []);
  // state to add new lead
  const [bookRoomData, setBookRoomData] = useState('');
  // state to add product
  const [productData, setProductData] = useState([
    {
      product_id: '2',
      product_name: 'some',
      product_price: 0,
      product_qty: '1',
      price_qty: 2
    }
  ]);
  const handleIndustryChange = (e) => {
    setSelectedIndustry(e.target.value);
  };
  const handlestageChange = (e) => {
    setSelectedStage(e.target.value);
    setError(prevErrors => ({ ...prevErrors, stage: '' }));
  };
  const [formData2, setFormData2] = useState([
    { product_id: '', name: '', price: 0, qty: 0, netValue: 0 }
  ]);
  const [addMoreCheck, setAddMoreCheck] = useState(false);
  const [currentSlides, setCurrentSlides] = useState(null);
  const leadSubmit = () => {
    setLoading1(true);
    let params;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const allSlides = slides.length === 0 ? [] : [...slides];

    if (purposeType === 'feedback') {
      console.log('feedback');
      params = {
        title: title,
        description: description,
        stage: selectedStage,
        purpose_of_visit: purposeType,
        organisation:
          labelText === 'New'
            ? bookRoomData.organisation
            : clientSaveData.organisation,
        industry_id: labelText === 'New' ? bookRoomData.industry_id : industryId.value,
        address:
          labelText === 'New' ? bookRoomData.address : clientSaveData.address,
        gst_no: labelText === 'New' ? bookRoomData.gst_no : clientSaveData.gst_no,
        clientInfo: labelText === 'New' ? searchClientByName : inputParam,
        communication: communicationData.communicationDesc,
        communication_date: communicationData.reminderDate,
        is_reminder: isChecked,
      }
      axios
        .post(Url.BASEURL + 'addlead', params, config)
        .then((value) => {
          if (value.data.status == 200) {
            setProductsInfo(value.data);
            // setIsNestedUpdateModalOpen(true);
            setLoading1(false);
            Swal.fire({
              title: "Lead Addition Successful",
              text: "The lead has been successfully added.",
              icon: "success",
              confirmButtonColor: 'green',
              allowOutsideClick: false,
            });

            navigate('/manageleads');
          } else {
            setError(value.data.errors);
            setLoading1(false);
          }
        })
        .catch((err) => {
          setLoading1(false);
        });

    } else {
      if (allSlides.length > 0) {
        params = {
          title: title,
          description: description,
          lead_value: calculateTotalNetValue,
          // business_type: businessType,
          expt_close_date: leadDate,
          stage: selectedStage,
          lead_gst: productSlideData.gst,
          purpose_of_visit: purposeType,
          // business_type: businessType,
          organisation:
            labelText === 'New'
              ? bookRoomData.organisation
              : clientSaveData.organisation,
          industry_id: labelText === 'New' ? bookRoomData.industry_id : industryId.value,
          address:
            labelText === 'New' ? bookRoomData.address : clientSaveData.address,
          gst_no: labelText === 'New' ? bookRoomData.gst_no : clientSaveData.gst_no,
          clientInfo: labelText === 'New' ? searchClientByName : inputParam,
          products: allSlides,
          freight: freightCharges,
          loadingUnloading: loadingUnloadingCharges,
          // products: slides,
          //  latestSlides,
          // addMoreCheck ? slides : newSlide,
          // newSlide,
          communication: communicationData.communicationDesc,
          communication_date: communicationData.reminderDate,
          is_reminder: isChecked,
          // client_info: bookRoomData

          //additional data
          remark_lead: additionalData.remarks,
          gst_lead: additionalData.gst,
          freight_lead: additionalData.freight,
          payment_lead: additionalData.payment,
          make_lead: additionalData.make,
          delivery_lead: additionalData.delivery,
          additionalNotes_lead: additionalData.note,
          // termsCondition_lead: additionalData.terms
        }

        axios
          .post(Url.BASEURL + 'addlead', params, config)
          .then((value) => {
            if (value.data.status == 200) {
              setProductsInfo(value.data);
              // setIsNestedUpdateModalOpen(true);
              setLoading1(false);
              Swal.fire({
                title: "Lead Addition Successful",
                text: "The lead has been successfully added.",
                icon: "success",
                confirmButtonColor: 'green',
                allowOutsideClick: false,
              });

              navigate('/manageleads');
            } else {
              setError(value.data.errors);
              setLoading1(false);
            }
          })
          .catch((err) => {
            setLoading1(false);
          });
      } else {
        Swal.fire("Products cannot be empty. Please add products.");
        setLoading1(false);
      }

    }
    // });
  };
  const [productError, setProductError] = useState('');

  const addSlider = () => {
    console.log(measurementType.value);
    if (measurementType.value === '1'
      &&
      (!selectedProduct ||
        !selectedCategory ||
        !productSlideData.qty ||
        !measurementType ||
        !basePrice ||
        !productSlideData.discount ||
        !productSlideData.gst ||
        !productSlideData.kg)
    ) {
      // Code block
      console.log('kilo');
      setProductError('All Fields are mandatory*');
    }
    else if (
      measurementType.value !== '1'
      &&
      (!selectedProduct ||
        !selectedCategory ||
        !productSlideData.qty ||
        !measurementType ||
        !basePrice ||
        !productSlideData.discount ||
        !productSlideData.gst)
    ) {
      // Code block
      console.log("not kilo");
      setProductError('All Fields are mandatory*');
    }
    else {
      const newSlide = {
        product_no: !edit ? slides.length + 1 : product_no,
        product_id: selectedProduct.value,
        product_name: selectedProduct.label,
        brand_id: selectedBrand.value,
        brand_name: selectedBrand.label,
        category_id: selectedCategory.value,
        category_name: selectedCategory.label,
        sub_category_id: selectedSubcategory.value,
        sub_category_name: selectedSubcategory.label,
        measurement: measurementType.value,
        total_quantity: totalQuanity,
        product_price: basePrice,
        product_qty: productSlideData.qty,
        discount: productSlideData.discount,
        product_gst: productSlideData.gst,
        product_kg:
          measurementType.label === 'KILOGRAMS'
            ? productSlideData.kg
            : measurementType.label === 'METER'
              ? 6
              : measurementType.label === 'FEET'
                ? 20
                : '1',
        net_price: productSlideData.discount
          ? priceAfterDiscount
          : priceBeforeDiscount,
        taxable_amount: taxAmount,

        total_price: netValue,
        sub_total: subtotalValue
      };
      console.log('>>>>>>>>', newSlide);
      if (edit === true) {
        const index = slides.findIndex(product =>
          product.product_no != newSlide.product_no &&
          product.product_id == newSlide.product_id &&
          product.brand_id == newSlide.brand_id &&
          product.category_id == newSlide.category_id &&
          product.sub_category_id == newSlide.sub_category_id
        )
        if (index !== -1) {
          // If product exists, update it
          setProductError('Product Already Exists Kindly Go for Update*');
        } else {
          const prod = slides.findIndex(product => product.product_no == newSlide.product_no)
          if (prod !== -1) {
            // If product exists, update it
            const updatedProductsData = [...slides];
            updatedProductsData[prod] = newSlide;
            setSlides(updatedProductsData)
            setProductError('');
            setProductNameId('');
            setSubCategoryId([]);
            setMeasurementType('');
            setSelectedProduct('');
            setSelectedCategory('');
            setSelectedSubcategory('');
            setBasePrice('');
            setTaxAmount('')
            setTotalQuanity('');
            setPriceBeforeDiscount('');
            setPriceAfterDiscount('');
            setNetValue('');
            setSelectedBrand('')
            setProductSlideData({
              qty: '',
              kg: '0',
              totalQuantity: '',
              // price: '',
              taxableAmount: '',
              discount: '0',
              priceAfterDiscount: '',
              gst: '18',
              netValue: '',
              // loadingUnloading: '0',
              freight: '0'
            });
            setEdit(false)

          }
        }
      } else {
        const index = slides.findIndex(product =>
          product.product_id == newSlide.product_id &&
          product.brand_id == newSlide.brand_id &&
          product.category_id == newSlide.category_id &&
          product.sub_category_id == newSlide.sub_category_id
        )
        console.log(index);
        if (index !== -1) {
          // If product exists, update it
          setProductError('Product Already Exists Kindly Go for Update*');
        } else {
          setSlides((prevSlides) => [...prevSlides, newSlide]);
          setProductError('');
          setProductNameId('');
          setSubCategoryId([]);
          setMeasurementType('');
          setSelectedProduct('');
          setSelectedCategory('');
          setSelectedSubcategory('');
          setBasePrice('');
          setTaxAmount('')
          setTotalQuanity('');
          setPriceBeforeDiscount('');
          setPriceAfterDiscount('');
          setNetValue('');
          setSelectedBrand('')
          setProductSlideData({
            qty: '',
            kg: '0',
            totalQuantity: '',
            // price: '',
            taxableAmount: '',
            discount: '0',
            priceAfterDiscount: '',
            gst: '18',
            netValue: '',
            // loadingUnloading: '0',
            freight: '0'
          });
          setEdit(false)

        }
      }
      // setSlides((prevSlides) => [newSlide, ...prevSlides]);
      setFormCount((prevFormCount) => prevFormCount + 1);
      // setProductError('');

    }
  };


  // api for searcg product
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL + 'productdropdown?' + 'search=' + searchInputValue2,
        config
      )
      .then((value) => {
        if (value && value.data.status === 200) {
          setProductOptions(
            value.data.response.map((e) => ({
              id: e.id,
              value: e.price, // You can use e.id here if you prefer
              label: e.item_name,
              gst: e.gst
            }))
          );
        }
      })
      .catch((err) => console.log(err));
  }, [searchInputValue, token, selectedOption]);
  // useEffect(() => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };
  //   axios
  //     .get(
  //       Url.BASEURL + 'searchstaffclients?' + 'search=' + searchInputValue,
  //       config
  //     )
  //     .then((value) => {
  //       if (value && value.data.status === 200) {
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }, [searchInputValue, token]);
  // function to add net values
  const calculateTotalNetValue = productData
    .reduce(
      (total, row) => total + parseFloat(selectedMeasurements.netValue),
      0
    )
    .toFixed(2);
  // api for list stages
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'leadstages', config)
      .then((value) => setStage(value.data.response))
      .catch((err) => console.log(err));
  }, []);
  // industry listing
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'allindustry', config)
      .then((value) => setIndustry(value.data.response))
      .catch((err) => console.log(err));
  }, []);
  const BusinessTypeChangeHandler = (event) => {
    setBusinessType(event.target.value);
  };
  // function for handlechange of purpose of visit
  const PurposeChangeHandler = (event) => {
    setPurposeType(event.target.value);
  };
  const fetchData = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'find-client/' + id, config)
      .then(
        (value) =>
          setBookRoomData(value.data.response) &
          setSearchClientByName(value.data.response.clientInfo) &
          setSelectedIndustry(value.data.response[0].industry_id) &
          // setBookRoomData(value.data.response) &
          setOwnerName(value.data.response[0].owner_name.split('-')[0].trim())
      )
      .catch((err) => console.log(err));
  };
  useEffect((e) => {
    if (searchclient) {
    }
  }, []);
  const checkData = (e) => { };
  const toggleLabel = () => {
    // setBookRoomData('');
    // setBookRoomData([
    //   {
    //     id: '',
    //     industry_id: '',
    //     organisation: '',
    //     address: '',
    //     gst_no: '',
    //     extraEmails: [],
    //     extraContacts: []
    //   }
    // ]);
    if (labelText === 'New') {
      setLabelText('Existing');
    } else {
      setLabelText('New');
    }
    setInputValue('');
    setSelectedIndustry('');
  };
  const handleInputChange = (newValue) => {
    setSearchInputValue(newValue);
  };
  const handleInputChange2 = (newValue) => {
    setSearchInputValue2(newValue);
  };
  // const fetchProductData = (id, index) => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };
  //   axios
  //     .get(Url.BASEURL + 'fetchproductdropdown/' + id, config)
  //     .then((value) => {
  //       let productDataById = value.data.response[0];
  //       handleMeasurementChange('price', productDataById.price, index);
  //       const memberData2 = {
  //         product_id:
  //           productDataById && productDataById.id ? productDataById.id : '',
  //         product_name:
  //           productDataById && productDataById.item_name
  //             ? productDataById.item_name
  //             : '',
  //         product_price:
  //           productDataById && productDataById.price
  //             ? productDataById.price
  //             : '',
  //         product_gst:
  //           productDataById && productDataById.gst ? productDataById.gst : '',
  //         product_length:
  //           productDataById && productDataById.length
  //             ? productDataById.length
  //             : '',
  //         product_kg:
  //           productDataById && productDataById.kg ? productDataById.kg : '',
  //         product_measurement: selectedMeasurements,
  //         product_qty: 0,
  //         price_qty: 0
  //       };
  //
  //       const values = [...productData];
  //       values[index] = {
  //         ...memberData2
  //       };
  //       setProductData(values);
  //       setInputValue2([{ index: index, id: value.data.response[0].id }]);
  //     })
  //     .catch((err) => console.log(err));
  // };
  // const handleMeasurementChange = (type, event, index) => {
  //   setSelectedMeasurements((prevMeasurements) => {
  //     const updatedMeasurements = [...prevMeasurements];
  //     const item = { ...updatedMeasurements[index] };
  //     if (type === 'productid') {
  //       item.id = event;
  //     }
  //     if (type === 'measurement') {
  //       item.measurement_type = event.target.value;
  //     }
  //     if (type === 'quantity') {
  //       item.qty = event.target.value;
  //       if (item.price !== '') {
  //         item.netValue = item.price * item.qty;
  //       }
  //     }
  //     if (type === 'price') {
  //       item.price = event;
  //       if (item.qty !== '') {
  //         item.netValue = item.price * item.qty;
  //       }
  //       item.netValue = item.price * item.qty;
  //     }
  //     if (type === 'newprice') {
  //       item.price = event.target.price;
  //       if (item.qty !== '') {
  //         item.netValue = item.price * item.qty;
  //       }
  //       item.netValue = item.price * item.qty;
  //     }
  //     updatedMeasurements[index] = item;
  //     return updatedMeasurements;
  //   });
  // };
  const handleCheckboxChange = (event) => {
    // event.preventDefault();
    // console.log(event.target.checked);
    setIsChecked(event.target.checked ? 1 : 0);
  };
  // console.log(isChecked);
  // const totalQty = selectedMeasurements.qty * selectedIndustry.price;
  // react slider function
  const sliderRef = useRef(null); // Initialize useRef with null
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentSlide(current)
  };
  // const addSlider = () => {
  //   setFormCount(formCount + 1);
  // };
  const deleteSlide = (index) => {
    const updatedSlides = [...slides];
    updatedSlides.splice(index, 1);
    setSlides(updatedSlides);
  };
  const transformIndustryDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, industry_type }) => ({
      value: id.toString(),
      label: `${industry_type}`
    }));
    return [selectOption, ...dataOptions];
  };
  const IndustryOptions = transformIndustryDataToOptions(industry);

  const clientDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(
      ({ id, contact_person_name_0, organisation }) => ({
        value: id.toString(),
        label: `${organisation}`
      })
    );
    return [selectOption, ...dataOptions];
  };
  const clientDropdownOptions = clientDataToOptions(clientLists);

  const handleInputChangeForSlides = (e, field, index) => {
    setSlides((prevSlides) => {
      const newSlides = [...prevSlides];
      newSlides[index] = {
        ...newSlides[index],
        [field]: e.target.value
      };
      return newSlides;
    });
  };
  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
      // setCurrentSlideIndex((prevIndex) => prevIndex + 1);
    }
  };
  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
      // setCurrentSlideIndex((prevIndex) => prevIndex - 1);
    }
  };
  //function for category and subCategory
  const productOptionss = products.map(
    ({ id, product_name, category_related_product }) => ({
      value: id.toString(),
      label: `${product_name}`,
      categoryRelatedProduct: category_related_product
    })
  );
  const categoryOptions = productCategory.map((id) => ({
    value: id.id.toString(),
    label: id.name,
    sub_category: id.sub_category
  }));

  const subcategoryOptions = productSubCategory.map((id, name) => ({
    value: id.id.toString(),
    label: id.name
  }))
  const transformClientToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(
      ({ id, contact_person_name_0, contact_person_email_0 }) => ({
        value: id.toString(),
        label: `${contact_person_name_0} (${contact_person_email_0})`
      })
    );
    return [selectOption, ...dataOptions];
  };
  const clientDataOption = transformClientToOptions(clientOptions);

  const getBrandList = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
      const response = await axios.get(Url.BASEURL + 'lead-brand-list', config)
      if (response.data.status === 200) {
        setBrandList(response.data.response)
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getBrandList();
  }, [])


  const transformBrandToOptions = (data) => {
    const selectOption = { value: '', label: 'select...' };
    const dataOptions = data.map(({ id, bname }) => ({
      value: id.toString(),
      label: `${bname}`
    }));
    return [selectOption, ...dataOptions];
  };
  const BrandOptions = transformBrandToOptions(brandList);

  // function for add and delete multiple
  const handleInputChange1 = (index, fieldName, value) => {
    const newInputFields = [...inputFields];
    newInputFields[index][fieldName] = value;
    setInputFields(newInputFields);

  };
  const handleAddInputFields = () => {
    // Check the total number of fields added
    if (inputFields.length < 3) {
      setInputFields([
        ...inputFields,
        { value1: '', value2: '', value3: '', value4: '' }
      ]);
    }
  };
  // const handleRemoveInputFields = (index) => {
  //   const updatedData = [...productData];
  //   updatedData.splice(index, 1);
  //   setProductData(updatedData);
  // };

  const handleRemoveInputFields = (index) => {
    const updatedData = [...productData];
    updatedData.splice(index, 1);
    setProductData(updatedData);
  };
  const inputParam = inputFields.map((field, key) => ({
    [`contact_person_name_${key}`]: field.value1,
    [`contact_person_email_${key}`]: field.value2,
    [`contact_person_mobile_${key}`]: field.value3,
    [`contact_person_designation_${key}`]: field.value4
  }));


  const handleRemoveFields = (index) => {
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
  };
  const deleteForm = (index) => {
    const updatedForms = [...Array(formCount)];
    updatedForms.splice(index, 1);
    setFormCount(updatedForms.length);
    if (currentSlide >= updatedForms.length) {
      setCurrentSlide(updatedForms.length - 1);
    }
  };
  const DeleteProduct = (productId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: productId
    };
    /** */
    axios
      .delete(Url.BASEURL + 'deleteleadproduct/' + productId, config)
      .then((value) => {
        // setDeleteRow(value.data.response);
        // getData();
      })
      .catch((err) => console.log(err));
  };
  // useEffect(() => {
  //   if (
  //     selectedProduct &&
  //     selectedSubcategory &&
  //     selectedCategory &&
  //     measurementType
  //   ) {
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${token}`
  //       }
  //     };
  //     //https://apirest.symang.in/api/base-price?category_id=5&sub_category_id=6&product_id=1
  //     axios
  //       .get(
  //         Url.BASEURL +
  //         'base-price-lead?' +
  //         'category_id=' +
  //         selectedCategory.value +
  //         '&sub_category_id=' +
  //         selectedSubcategory.value +
  //         // (id !== '' ? '&client_id=' + id.value : '') +
  //         '&product_id=' +
  //         selectedProduct.value +
  //         '&measurement_type=' +
  //         measurementType.value,
  //         config
  //       )
  //       .then((value) => {
  //         if (value.data.response.base_price !== null) {
  //           setBasePrice(value.data.response.base_price.base_price);
  //         } else {
  //           setBasePrice('');
  //         }
  //         // }
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // }, [selectedCategory, selectedSubcategory, selectedProduct, measurementType]);
  const transformMeasurementToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name }) => ({
      value: id.toString(),
      label: `${name}`
    }));
    return [selectOption, ...dataOptions];
  };
  const measurementDataOption = transformMeasurementToOptions(leadtype);
  // const handleBasePriceInputChange = (e) => {
  //   setBasePrice(e.target.value);
  // };
  useEffect(() => {
    // handleSubTotal();
    if (measurementType) {
      handleMultiplications();
    }
    handlePrice();
    if (priceBeforeDiscount && productSlideData.discount) {
      handlePriceDiscount();
    }
    // if (priceAfterDiscount) {
    handleNetAmount();
    // }
  }, [
    measurementType,
    productSlideData,
    totalQuanity,
    basePrice,
    priceBeforeDiscount,
    productSlideData,
    priceAfterDiscount
  ]);
  const handleMultiplications = () => {
    // measurementType.label == 'Kilogram '
    if (measurementType.label == 'KILOGRAMS') {
      // alert('hello')
      let totalQty = productSlideData.qty * productSlideData.kg;
      // setTotalQuanity(totalQty);
    } else if (measurementType.label == 'METRIC TONS') {
      // setTotalQuanity('');
      // let totalQty = productSlideData.qty * 6;
      // setTotalQuanity(totalQty);
    } else if (measurementType.label == 'METER') {
      // setTotalQuanity('');
      let totalQty = productSlideData.qty * 6;
      // setTotalQuanity(totalQty);
    } else if (measurementType.label == 'FEET') {
      // setTotalQuanity('');
      let totalQty = productSlideData.qty * 20;
      // setTotalQuanity(totalQty);
    } else if (measurementType.label == 'PIECES') {
      setTotalQuanity('');
      let totalQty = productSlideData.qty * 1;
      console.log(productSlideData);
      setTotalQuanity(totalQty);
    }
  };
  const handlePrice = () => {
    if (totalQuanity && basePrice) {
      let totalPriceBeforeDiscount = basePrice;

      setPriceBeforeDiscount(totalPriceBeforeDiscount);
    }
  };

  const handlePriceDiscount = () => {
    if (priceBeforeDiscount) {
      let discountPrice =
        (priceBeforeDiscount * productSlideData.discount) / 100;
      let newAmount = priceBeforeDiscount - discountPrice;
      setPriceAfterDiscount(newAmount);
      setTaxAmount(newAmount * totalQuanity)
    }
  };
  const handleNetAmount = () => {
    // if (priceAfterDiscount) {

    let gstAmount = (taxAmount * parseInt(productSlideData.gst)) / 100;
    console.log(gstAmount);
    let netAmount = taxAmount + gstAmount;
    console.log(netAmount);
    // if (productSlideData.discount === 100) {
    //   netAmount = 0;
    // }
    setNetValue(netAmount);
    // }
  };

  // const handleSubTotal = () => {
  //   let gstAmount = (priceAfterDiscount * productSlideData.gst) / 100;

  //   let subTotalValue = parseInt(priceAfterDiscount) + parseInt(gstAmount);
  //   setSubtotalValue(subTotalValue);
  // };
  const handleRadioChange = (event) => {
    setPurposeType(event.target.value);
    setError({})
  };
  const deleteProduct = () => {
    const updatedProducts = [...slides];
    updatedProducts.splice(productIndex, 1);
    setSlides(updatedProducts);
    setIsModalOpen(false);
  };
  const closeAllModals = () => {
    closeNestedModal();
    closeModal();
  };
  const closeAllUpdateModals = () => {
    closeNestedUpdateModal();
    closeUpdateModal();
  };

  function restrictToRange(inputElement) {
    // Get the current value of the input field
    let value = parseInt(inputElement.value);

    // If the value is less than 0, set it to 0
    if (value < 0) {
      inputElement.value = 0;
    }
    // If the value is greater than 100, set it to 100
    else if (value > 100) {
      inputElement.value = 100;
    }
  }

  const validationCheckHandler = async ({ option }) => {
    try {
      setError({})
      let errors = {}; // Initialize an empty object to store errors

      if (isChecked === 1) {
        // Validate using salesSchemaOne
        try {
          await salesSchemaOne.validate(
            {
              organisation: option.organisation,
              title: option.title,
              expt_close_date: option.leadDate,
              stage: option.selectedStage,
              description: option.description,
              communicationDesc: option.communicationDesc,
            },
            {
              abortEarly: false
            }
          );
        } catch (salesError) {
          if (salesError.name === 'ValidationError') {
            salesError.inner.forEach(err => {
              errors[err.path] = err.message;
            });
          }
        }

        // Validate using checkBoxSchema
        try {
          await checkBoxSchema.validate({
            reminderDate: option.reminderDate
          }, {
            abortEarly: false
          });
        } catch (checkBoxError) {
          if (checkBoxError.name === 'ValidationError') {
            checkBoxError.inner.forEach(err => {
              errors[err.path] = err.message;
            });
          }
        }
      } else {
        // If isChecked is not equal to 1, only validate using salesSchemaOne
        try {
          await salesSchemaOne.validate(
            {
              organisation: option.organisation,
              title: option.title,
              expt_close_date: option.leadDate,
              stage: option.selectedStage,
              description: option.description,
              communicationDesc: option.communicationDesc,
            },
            {
              abortEarly: false
            }
          );
        } catch (salesError) {
          if (salesError.name === 'ValidationError') {
            salesError.inner.forEach(err => {
              errors[err.path] = err.message;
            });
          }
        }
      }

      // If errors exist, set the combined errors
      if (Object.keys(errors).length > 0) {
        setError(errors);
      } else {
        setActiveOption('product');
      }
    } catch (error) {
      // Handle other errors
    }
  };


  const feedBackValidationHandler = async ({ option }) => {
    try {
      setError({})
      let errors = {}; // Initialize an empty object to store errors

      if (isChecked === 1) {
        // Validate using salesSchemaOne
        try {
          await feedbackSchema.validate(
            {
              organisation: option.organisation,
              title: option.title,
              // expt_close_date: option.leadDate,
              stage: option.selectedStage,
              description: option.description,
              communicationDesc: option.communicationDesc,
              // ...(isChecked === '1' && { reminderDate: option.reminderDate })
            },
            {
              abortEarly: false
            }
          );
        } catch (salesError) {
          if (salesError.name === 'ValidationError') {
            salesError.inner.forEach(err => {
              errors[err.path] = err.message;
            });
          }
        }

        // Validate using checkBoxSchema
        try {
          await checkBoxSchema.validate({
            reminderDate: option.reminderDate
          }, {
            abortEarly: false
          });
        } catch (checkBoxError) {
          if (checkBoxError.name === 'ValidationError') {
            checkBoxError.inner.forEach(err => {
              errors[err.path] = err.message;
            });
          }
        }
      } else {
        // If isChecked is not equal to 1, only validate using salesSchemaOne
        try {
          await feedbackSchema.validate(
            {
              organisation: option.organisation,
              title: option.title,
              // expt_close_date: option.leadDate,
              stage: option.selectedStage,
              description: option.description,
              communicationDesc: option.communicationDesc,
            },
            {
              abortEarly: false
            }
          );
        } catch (salesError) {
          if (salesError.name === 'ValidationError') {
            salesError.inner.forEach(err => {
              errors[err.path] = err.message;
            });
          }
        }
      }

      // If errors exist, set the combined errors
      if (Object.keys(errors).length > 0) {
        setError(errors);
      } else {
        leadSubmit();
      }
    } catch (error) {
      // Handle other errors
    }
  }

  const additionalValidationHandler = async () => {
    let errors = {};
    try {
      const additional = await additionalDataSchema.validate(
        {
          // remarks: additionalData.remarks,
          delivery: additionalData.delivery,
          gst: additionalData.gst,
          freight: additionalData.freight,
          payment: additionalData.payment,
          make: additionalData.make,
          // note: additionalData.note
        },
        {
          abortEarly: false
        }
      );
      if (additional) {
        setIsUpdateModalOpen(true)
      }
    } catch (validationError) {
      if (validationError.name === 'ValidationError') {
        validationError.inner.forEach(err => {
          errors[err.path] = err.message;
        });
      }
    }

    // Check if errors object has any properties (i.e., if there are any validation errors)
    if (Object.keys(errors).length > 0) {
      setError(errors)
    }
  };
  const newFeedBackValidationCheckHandler = async ({ option }) => {
    try {
      setError({})
      let errors = [];

      for (let i = 0; i < inputParam.length; i++) {
        try {
          const schema = newLeadSchema(i);
          await schema.validate(inputParam[i], { abortEarly: false });
        } catch (error) {
          // Process the errors
          if (error.inner) {
            const errorObj = {};
            error.inner.forEach(err => {
              errorObj[err.path] = err.message;
            });
            errors.push(errorObj);
          }
        }
      }
      if (errors.length > 0) {
        setClientError(errors);
      } else {
        console.log("Validation successful");
      }
    } catch (err) {
      console.log(err);
    }

    try {
      let error = {}; // Define an empty object to store errors
      console.log(option.industry);
      if (isChecked === 1) {
        // Validate using salesSchemaOne
        try {
          await newFeedbackSchemaInput.validate(
            {
              // gst_no: option.gst,
              address: option.address,
              industry_id: option.industry,
              organisation: option.organisation,
              title: option.title,
              // expt_close_date: option.leadDate,
              stage: option.selectedStage,
              description: option.description,
              communicationDesc: option.communicationDesc
            },
            {
              abortEarly: false
            }
          );
        } catch (salesError) {
          if (salesError.name === 'ValidationError') {
            salesError.inner.forEach(err => {
              error[err.path] = err.message;
            });
          }
        }

        // Validate using checkBoxSchema
        try {
          await checkBoxSchema.validate(
            {
              reminderDate: option.reminderDate
            },
            {
              abortEarly: false
            }
          );
        } catch (checkBoxError) {
          if (checkBoxError.name === 'ValidationError') {
            checkBoxError.inner.forEach(err => {
              error[err.path] = err.message;
            });
          }
        }
      } else {
        // If isChecked is not equal to 1, only validate using salesSchemaOne
        try {
          await newFeedbackSchemaInput.validate(
            {
              gst_no: option.gst,
              address: option.address,
              industry_id: option.industry,
              organisation: option.organisation,
              title: option.title,
              // expt_close_date: option.leadDate,
              stage: option.selectedStage,
              description: option.description,
              communicationDesc: option.communicationDesc
            },
            {
              abortEarly: false
            }
          );
        } catch (salesError) {
          if (salesError.name === 'ValidationError') {
            salesError.inner.forEach(err => {
              error[err.path] = err.message;
            });
          }
        }
      }

      // If errors exist, set the combined errors
      if (Object.keys(error).length > 0) {
        console.log(error);
        setError(error);
      } else {
        leadSubmit();
      }
    } catch (error) {
      // Handle other errors
    }
  };

  const newValidationCheckHandler = async ({ option }) => {
    try {
      setError({})
      let errors = [];

      for (let i = 0; i < inputParam.length; i++) {
        try {
          const schema = newLeadSchema(i);
          await schema.validate(inputParam[i], { abortEarly: false });
        } catch (error) {
          // Process the errors
          if (error.inner) {
            const errorObj = {};
            error.inner.forEach(err => {
              errorObj[err.path] = err.message;
            });
            errors.push(errorObj);
          }
        }
      }
      if (errors.length > 0) {
        setClientError(errors);
      } else {
        console.log("Validation successful");
      }
    } catch (err) {
      console.log(err);
    }

    try {
      let error = {}; // Define an empty object to store errors
      console.log(option.industry);
      if (isChecked === 1) {
        // Validate using salesSchemaOne
        try {
          await newSchemaInput.validate(
            {
              // gst_no: option.gst,
              address: option.address,
              industry_id: option.industry,
              organisation: option.organisation,
              title: option.title,
              expt_close_date: option.leadDate,
              stage: option.selectedStage,
              description: option.description,
              communicationDesc: option.communicationDesc
            },
            {
              abortEarly: false
            }
          );
        } catch (salesError) {
          if (salesError.name === 'ValidationError') {
            salesError.inner.forEach(err => {
              error[err.path] = err.message;
            });
          }
        }

        // Validate using checkBoxSchema
        try {
          await checkBoxSchema.validate(
            {
              reminderDate: option.reminderDate
            },
            {
              abortEarly: false
            }
          );
        } catch (checkBoxError) {
          if (checkBoxError.name === 'ValidationError') {
            checkBoxError.inner.forEach(err => {
              error[err.path] = err.message;
            });
          }
        }
      } else {
        // If isChecked is not equal to 1, only validate using salesSchemaOne
        try {
          await newSchemaInput.validate(
            {
              gst_no: option.gst,
              address: option.address,
              industry_id: option.industry,
              organisation: option.organisation,
              title: option.title,
              expt_close_date: option.leadDate,
              stage: option.selectedStage,
              description: option.description,
              communicationDesc: option.communicationDesc
            },
            {
              abortEarly: false
            }
          );
        } catch (salesError) {
          if (salesError.name === 'ValidationError') {
            salesError.inner.forEach(err => {
              error[err.path] = err.message;
            });
          }
        }
      }

      // If errors exist, set the combined errors
      if (Object.keys(error).length > 0) {
        setError(error);
      } else {
        setActiveOption('product');
      }
    } catch (error) {
      // Handle other errors
    }
  };

  const handleEditProduct = async (product) => {
    try {
      setProduct_no(product.product_no)
      setSelectedBrand(BrandOptions.find(brand => brand.value == product.brand_id))
      setSelectedProduct(productOptionss.find(prod => prod.value == product.product_id))
      setSelectedCategory(categoryOptions.find(category => category.value == product.category_id))
      setSelectedSubcategory(subcategoryOptions.find(subCategory => subCategory.value == product.sub_category_id))
      setProductSlideData({
        qty: product.product_qty,
        kg: product.product_kg,
        discount: product.discount,
        gst: product.product_gst
      })
      setMeasurementType(measurementDataOption.find(measurements => measurements.value == product.measurement))
      setBasePrice(product.product_price)
      setTotalQuanity(product.total_quantity)

    } catch (error) {
      console.log(error);
    }
  }


  return (
    <>
      {loading1 &&
        <div className="loader-backdrop">
          <div className="spinner-container">
            <ClipLoader color={'#50CD89'} loading={loading1} css={override} size={60} />
          </div>
        </div>
      }
      <div>
        <Constant>
          <Header
            // btnName="Add Client"
            onpage_link="My Clients"
            heading="My Clients"
          // btn_path="addclient"
          // btnName2="Export Clientdata"
          // onClick={exporData}
          />
          <div
            id="kt_app_content"
            className="app-content flex-column-fluid "
          // style={{backgroundColor:'red'}}
          >
            <div
              id="kt_app_content_container"
              className={
                loading
                  ? 'myclassName app-container container-fluid'
                  : 'app-container container-fluid'
              }
            >
              {loading ? (
                <>
                  {/* <div className="overlay" /> */}
                  <div className="loader-container">
                    <ClipLoader color={'#50CD89'} loading={loading} size={60} />
                  </div>
                </>
              ) : // <div
                //   className="overlay"
                //   style={{ alignSelf: 'center' }}
                // >
                //   <ClipLoader
                //     color={'#50CD89'}
                //     loading={loading}
                //     css={override}
                //     size={60}
                //   />
                // </div>
                null}

              {/* my row */}
              {/* <div className="row mb-3">
                <div className="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-6 d-flex justify-content-around rounded border border-1 border-dark-subtle p-2 mx-3"
                  style={{ backgroundColor: '#F8F2EC' }}>

                  <div className={`fs-4 fw-semibold px-5 py-2
                ${activeOption === 'Lead Details' ? 'activ' : null}`}>
                    Lead Details
                  </div>


                  {purposeType === 'sales' ? (
                    <div className={`fs-4 fw-semibold px-5 py-2  ${activeOption === 'product' ? 'activ' : null}`}>
                      Products
                    </div>
                  ) : null}
                </div>
              </div> */}

              <div className="col-12 d-flex justify-content-between">
                {/* col-12 col-xl-3 mb-3 col-lg-4 col-md-4 col-sm-6 */}
                <div className=" d-flex justify-content-between rounded border border-1 border-dark-subtle p-2 mb-3 "
                  style={{ backgroundColor: '#F8F2EC' }}>

                  <div className={`fs-4 fw-semibold px-5 py-2
                ${activeOption === 'Lead Details' ? 'activ' : null}`}>
                    Lead Details
                  </div>


                  {purposeType === 'sales' ? (
                    <div className={`fs-4 fw-semibold px-5 py-2  ${activeOption === 'product' ? 'activ' : null}`}>
                      Products
                    </div>
                  ) : null}
                </div>
                {productError && productError ? (
                  <div className='col-4 d-flex justify-content-end align-items-end fs-4 fw-semibold text-danger'

                  >
                    {productError}
                  </div>
                ) : null}
              </div>



              {/* <div className="row">
              <div className="col-8">
                <div
                  className="row"
                  style={{
                    fontSize: '20px',
                    fontWeight: '600',
                    textAlign: 'center'
                  }}
                >
                  <div
                    onClick={() => setActiveOption('Lead Details')}
                    className={
                      activeOption === 'Lead Details'
                        ? 'active col-3 py-5'
                        : 'non_active col-3 py-5'
                    }
                    style={{
                      color: error && error.clientInfo ? 'red' : 'black',
                      fontSize: activeOption === 'Lead Details' ? 25 : 20,
                      fontWeight:
                        activeOption === 'Lead Details' ? '900' : '600'
                    }}
                  >
                    Lead Details
                  </div>
                  
                    ---------------------------------------
                  <div
                    onClick={() => setActiveOption('detail')}
                    className={
                      activeOption === 'detail'
                        ? 'active col-3   py-5'
                        : 'non_active col-3  py-5'
                    }
                    style={{
                      color:
                        error && error.title && error.description
                          ? 'red'
                          : 'black',
                      fontSize: activeOption === 'detail' ? 22 : 20,
                      fontWeight: activeOption === 'detail' ? '900' : '600'
                    }}
                  >
                    Details
                  </div>
                  <div
                    onClick={() => setActiveOption('communication')}
                    className={
                      activeOption === 'communication'
                        ? 'active col-3  py-5'
                        : 'non_active col-3   py-5'
                    }
                    style={{
                      color:
                        error && error.communication && error.communication
                          ? 'red'
                          : 'black',
                      fontSize: activeOption === 'communication' ? 22 : 20,
                      fontWeight:
                        activeOption === 'communication' ? '900' : '600'
                    }}
                  >
                    Communication
                  </div>
                  ----------------------------
                  {purposeType === 'sales' ? (
                    <div
                      onClick={() => setActiveOption('product')}
                      className={
                        activeOption === 'product'
                          ? 'active col-1 py-5'
                          : 'non_active col-1 py-5'
                      }
                      style={{
                        cursor: 'pointer',
                        fontSize: activeOption === 'product' ? 25 : 20,
                        fontWeight: activeOption === 'product' ? '900' : '600'
                      }}
                    >
                      |
                    </div>
                  ) : null}
                  {purposeType === 'sales' ? (
                    <div
                      onClick={() => setActiveOption('product')}
                      className={
                        activeOption === 'product'
                          ? 'active col-3  py-5'
                          : 'non_active col-3   py-5'
                      }
                      style={{
                        cursor: 'pointer',
                        fontSize: activeOption === 'product' ? 25 : 20,
                        fontWeight: activeOption === 'product' ? '900' : '600'
                      }}
                    >
                      Products
                    </div>
                  ) : null}
                </div>
              </div>
            </div> */}


              <div className="col-xxl-12 mb-5 mb-xl-10">
                <div className="">
                  {activeOption === 'Lead Details' ? (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <p className="h5 m-0 px-1 py-2">Client Details:</p>
                        <label
                          style={{
                            float: 'right',
                            cursor: 'pointer',
                            color: 'green',
                            fontWeight: '800',
                            borderBottom: '1px solid black'
                          }}
                          onClick={toggleLabel}
                        >
                          {labelText}
                        </label>
                      </div>
                      <div className="clientinfo-box">
                        <div className="dynamic-input ">
                          {/* {searchClientByName.map((field, index) => ( */}
                          {labelText === 'New' ? (
                            <div className="row align-items-center justify-content-between">
                              <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">
                                <div
                                  className="col-5"
                                  style={{ marginBottom: 20 }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between'
                                    }}
                                  >
                                    <label
                                      // htmlFor={`contactPersonEmail${index}`}
                                      className="d-flex align-items-center fs-6 fw-semibold mb-2"
                                    >
                                      <span className="required">
                                        Organisation
                                      </span>
                                    </label>
                                  </div>

                                  {/* {labelText === 'New' ? ( */}
                                  <ReactSelect
                                    options={clientDropdownOptions}
                                    value={selectedOption}
                                    placeholder="Select an option..."
                                    onChange={(newSelectedOption) => {
                                      setError(prevErrors => ({ ...prevErrors, organisation: '' }));
                                      if (newSelectedOption) {
                                        const uppercaseLabel =
                                          newSelectedOption.label
                                            .split('-')[0]
                                            .trim();
                                        const updatedOption = {
                                          ...newSelectedOption,
                                          label: uppercaseLabel
                                        };
                                        setSelectedOption(updatedOption);
                                        fetchData(newSelectedOption.value);
                                      } else {
                                        setSelectedOption(null);
                                      }
                                    }}
                                  />
                                  {error && error.organisation ? (
                                    <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                      {error.organisation}
                                    </div>
                                  ) : null}
                                  {/* ) : (
                                  <Form.Control
                                    disabled={
                                      labelText === 'New' ? true : false
                                    }
                                    // options={clientOptions}
                                    // value={data.owner_name}
                                    name="owner_name"
                                    // onChange={(event) => handleChange(index, event)}
                                    inputValue={inputValue}
                                    placeholder="Enter Name"
                                  />
                                )} */}
                                </div>

                              </div>

                              {searchClientByName.map((item, index) => {
                                let key = index + 1;
                                return (
                                  <></>
                                  // <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">
                                  //   <div className="col-5">
                                  //     <label
                                  //       // htmlFor={`contactPersonDesignation${index}`}
                                  //       className="d-flex align-items-center fs-6 fw-semibold mb-2"
                                  //     >
                                  //       <span className="required">
                                  //         Contact Person Name {key}
                                  //       </span>
                                  //     </label>
                                  //     <input
                                  //       value={
                                  //         item[
                                  //           'contact_person_name_' +
                                  //             index.toString()
                                  //         ]
                                  //         // searchClientByName[0]?.contact_person_name_0
                                  //       }
                                  //       name="designation_name"
                                  //       type="text"
                                  //       className="form-control"
                                  //       // id={`contactPersonDesignation${index}`}
                                  //       // placeholder={`Enter Contact Person Designation`}
                                  //     />
                                  //     {/* {error &&
                                  //   error.clientInfo &&
                                  //   error.clientInfo[index] && (
                                  //     <div>
                                  //       {error.clientInfo[index][
                                  //         `contact_person_designation_${index}`
                                  //       ] && (
                                  //         <div style={{ color: 'red' }}>
                                  //           {
                                  //             error.clientInfo[index][
                                  //               `contact_person_designation_${index}`
                                  //             ]
                                  //           }
                                  //         </div>
                                  //       )}
                                  //     </div>
                                  //   )} */}
                                  //   </div>
                                  //   <div className="col-5">
                                  //     <label
                                  //       // htmlFor={`contactPersonEmail${index}`}
                                  //       className="d-flex align-items-center fs-6 fw-semibold mb-2"
                                  //     >
                                  //       <span className="required">
                                  //         Contact Person's Email address
                                  //       </span>
                                  //     </label>
                                  //     <input
                                  //       // onChange={(e) =>
                                  //       //   handleInputChange1(
                                  //       //     // index,
                                  //       //     'value2',
                                  //       //     e.target.value
                                  //       //   )
                                  //       // }
                                  //       value={
                                  //         item[
                                  //           'contact_person_email_' +
                                  //             index.toString()
                                  //         ]
                                  //         // searchClientByName[0]?.contact_person_email_0
                                  //       }
                                  //       name="owner_email"
                                  //       type="email"
                                  //       className="form-control"
                                  //       // id={`contactPersonEmail${index}`}
                                  //       // placeholder={`Enter email ${index + 1}`}
                                  //     />
                                  //   </div>
                                  //   <div className="col-5">
                                  //     <label
                                  //       // htmlFor={`contactPersonMobile${index}`}
                                  //       className="d-flex align-items-center fs-6 fw-semibold mb-2"
                                  //     >
                                  //       <span className="required">
                                  //         Contact Person's Mobile Number
                                  //       </span>
                                  //     </label>
                                  //     <input
                                  //       maxLength={10}
                                  //       onInput={(e) =>
                                  //         (e.target.value =
                                  //           e.target.value.replace(/[^0-9]/g, ''))
                                  //       }
                                  //       // onChange={(e) =>
                                  //       //   handleInputChange1(
                                  //       //     // index,
                                  //       //     'value3',
                                  //       //     e.target.value
                                  //       //   )
                                  //       // }
                                  //       value={
                                  //         item[
                                  //           'contact_person_mobile_' +
                                  //             index.toString()
                                  //         ]
                                  //         // searchClientByName[0]?.contact_person_mobile_0
                                  //       }
                                  //       name="owner_contact_no"
                                  //       type="text"
                                  //       className="form-control"
                                  //       // id={`contactPersonMobile${index}`}
                                  //       // placeholder={`Enter Mobile Number ${
                                  //       //   index + 1
                                  //       // }`}
                                  //     />
                                  //   </div>
                                  //   <div className="col-5">
                                  //     <label
                                  //       // htmlFor={`contactPersonDesignation${index}`}
                                  //       className="d-flex align-items-center fs-6 fw-semibold mb-2"
                                  //     >
                                  //       <span className="required">
                                  //         Contact Person Designation
                                  //       </span>
                                  //     </label>
                                  //     <input
                                  //       value={
                                  //         item[
                                  //           'contact_person_designation_' +
                                  //             index.toString()
                                  //         ]
                                  //         // searchClientByName[0]
                                  //         //   ?.contact_person_designation_0
                                  //       }
                                  //       name="designation_name"
                                  //       type="text"
                                  //       className="form-control"
                                  //       // id={`contactPersonDesignation${index}`}
                                  //       // placeholder={`Enter Contact Person Designation`}
                                  //     />
                                  //   </div>
                                  // </div>
                                );
                              })}
                            </div>
                          ) : (
                            <div className="dynamic-input ">
                              {inputFields.map((field, index) => (
                                <div className="row align-items-center justify-content-between flex-column">
                                  <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">
                                    <div className="col-6 p-2">
                                      <label
                                        htmlFor={`contactPersonName${index}`}
                                        className="d-flex align-items-center fs-6 fw-semibold mb-2"
                                      >
                                        <span className="required">
                                          Contact Person {index + 1}
                                        </span>
                                      </label>
                                      <input

                                        onInput={(e) =>
                                        (e.target.value =
                                          e.target.value.replace(
                                            /[^a-zA-Z ]/g,
                                            ''
                                          ))
                                        }
                                        onChange={(e) =>
                                          handleInputChange1(
                                            index,
                                            'value1',
                                            e.target.value
                                          )
                                        }
                                        style={{ padding: "8px" }}
                                        value={field.value1}
                                        name="owner_name"
                                        type="text"
                                        className="form-control rounded-1"
                                        id={`contactPersonName${index}`}
                                        placeholder={`Enter Name ${index + 1}`}
                                      />
                                      {ClientError && ClientError[index] && (
                                        <div>
                                          {ClientError[index][`contact_person_name_${index}`] && (
                                            <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                              {ClientError[index][`contact_person_name_${index}`]}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-6 p-2">
                                      <label
                                        htmlFor={`contactPersonEmail${index}`}
                                        className="d-flex align-items-center fs-6 fw-semibold mb-2"
                                      >
                                        <span>
                                          Contact Person's Email address
                                        </span>
                                      </label>
                                      <input
                                        style={{ padding: "8px" }}
                                        onChange={(e) =>
                                          handleInputChange1(
                                            index,
                                            'value2',
                                            e.target.value
                                          )
                                        }
                                        value={field.value2}
                                        name="owner_email"
                                        type="email"
                                        className="form-control rounded-1"
                                        id={`contactPersonEmail${index}`}
                                        placeholder={`Enter email ${index + 1}`}
                                      />
                                      {ClientError && ClientError[index] && (
                                        <div>
                                          {ClientError[index][`contact_person_email_${index}`] && (
                                            <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                              {ClientError[index][`contact_person_email_${index}`]}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-6 p-2">
                                      <label
                                        htmlFor={`contactPersonMobile${index}`}
                                        className="d-flex align-items-center fs-6 fw-semibold mb-2"
                                      >
                                        <span className="required">
                                          Contact Person's Mobile Number
                                        </span>
                                      </label>
                                      <input
                                        style={{ padding: "8px" }}
                                        maxLength={10}
                                        onInput={(e) =>
                                        (e.target.value =
                                          e.target.value.replace(/[^0-9]/g, ''))
                                        }
                                        onChange={(e) =>
                                          handleInputChange1(
                                            index,
                                            'value3',
                                            e.target.value
                                          )
                                        }
                                        value={field.value3}
                                        name="owner_contact_no"
                                        type="text"
                                        className="form-control rounded-1"
                                        id={`contactPersonMobile${index}`}
                                        placeholder={`Enter Mobile Number ${index + 1
                                          }`}
                                      />
                                      {ClientError && ClientError[index] && (
                                        <div>
                                          {ClientError[index][`contact_person_mobile_${index}`] && (
                                            <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                              {ClientError[index][`contact_person_mobile_${index}`]}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-6 p-2">
                                      <label
                                        htmlFor={`contactPersonDesignation${index}`}
                                        className="d-flex align-items-center fs-6 fw-semibold mb-2"
                                      >
                                        <span className="required">
                                          Contact Person Designation
                                        </span>
                                      </label>
                                      <input
                                        onChange={(e) =>
                                          handleInputChange1(
                                            index,
                                            'value4',
                                            e.target.value
                                          )
                                        }
                                        style={{ padding: "8px" }}
                                        value={field.value4}
                                        name="designation_name"
                                        type="text"
                                        className="form-control rounded-1"
                                        id={`contactPersonDesignation${index}`}
                                        placeholder={`Enter Contact Person Designation`}
                                      />
                                      {ClientError && ClientError[index] && (
                                        <div>
                                          {ClientError[index][`contact_person_designation_${index}`] && (
                                            <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                              {ClientError[index][`contact_person_designation_${index}`]}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {index > 0 && (
                                    <div className="add text-center">
                                      <i
                                        className="fa-solid fa-circle-xmark"
                                        onClick={() => handleRemoveFields(index)}
                                      ></i>
                                    </div>
                                  )}
                                  {index === 0 && (
                                    <div className="add text-center">
                                      <i
                                        className="fa-solid fa-circle-plus"
                                        onClick={handleAddInputFields}
                                      ></i>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <p className="h5 m-0 px-1 py-2">Organisation Details:</p>
                      <div className="clientinfo-box">
                        <div className="dynamic-input ">
                          <div className="row align-items-center justify-content-between">
                            <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">
                              <div className="col-6 p-2">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                  <span>GSTIN Number</span>
                                </label>
                                {labelText === 'New' ? (
                                  <input
                                    style={{ padding: "8px" }}
                                    maxLength={15}
                                    disabled
                                    name="gst_no"
                                    value={bookRoomData.gst_no}
                                    type="text"
                                    className="form-control rounded-1"
                                    placeholder="Enter GSTIN Number"
                                  />
                                ) : (
                                  <input
                                    style={{ padding: "8px" }}
                                    maxLength={15}
                                    onChange={handleClientDataInputChange}
                                    name="gst_no"
                                    value={clientSaveData.gst_no}
                                    type="text"
                                    className="form-control rounded-1"
                                    placeholder="Enter GSTIN Number"
                                  />
                                )}
                                {error && error.gst_no ? (
                                  <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                    {error.gst_no}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-6 p-2">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                  {labelText === 'New' ? (
                                    <span >Client Name</span>
                                  ) : (
                                    <span className="required">Organisation</span>
                                  )}
                                </label>
                                {labelText === 'New' ? (
                                  <input
                                    style={{ padding: "8px" }}
                                    value={bookRoomData.client_name}
                                    name="client_name"
                                    disabled
                                    type="text"
                                    className="form-control rounded-1"
                                    placeholder="Enter Client Name "
                                  />
                                ) : (
                                  <input
                                    style={{ padding: "8px" }}
                                    onChange={handleClientDataInputChange}
                                    value={clientSaveData.organisation}
                                    name="organisation"
                                    type="text"
                                    className="form-control rounded-1"
                                    placeholder="Enter Organisation"
                                  />

                                )}
                                {labelText !== 'New' && error && error.organisation && (
                                  <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                    {error.organisation}
                                  </div>
                                )}
                              </div>
                              <div className="col-6 p-2">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                  <span className={labelText === 'New' ? null : "required"}>Address</span>
                                </label>
                                {labelText === 'New' ? (
                                  <input
                                    // onChange={handleInputChange}
                                    style={{ padding: "8px" }}
                                    value={bookRoomData.address}
                                    disabled
                                    name="address"
                                    type="text"
                                    className="form-control rounded-1"
                                    placeholder="Enter Address"
                                  />
                                ) : (
                                  <input
                                    style={{ padding: "8px" }}
                                    onChange={handleClientDataInputChange}
                                    value={clientSaveData.address}
                                    name="address"
                                    type="text"
                                    className="form-control rounded-1"
                                    placeholder="Enter Address"
                                  />
                                )}
                                {error && error.address ? (
                                  <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                    {error.address}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-6 p-2">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                  <span className={labelText === 'New' ? null : "required"}>Industry Type</span>
                                </label>

                                {labelText === 'New' ? (

                                  <input
                                    value={IndustryOptions.find(
                                      (e) => e.value == bookRoomData.industry_id)?.label}
                                    name="industry"
                                    placeholder='Select Industry'
                                    type="text"
                                    className='form-control rounded-1'
                                    disabled

                                  />

                                ) : (
                                  <ReactSelect
                                    // options={clientOptions}
                                    options={IndustryOptions}
                                    value={industryId}
                                    // name="industry_id"
                                    onChange={(newSelectedOption) => {
                                      setError((prevErrors) => ({
                                        ...prevErrors,
                                        industry_id: ""
                                      }));
                                      setIndustryId(newSelectedOption);
                                    }}

                                  />
                                )}

                                {error && error.industry_id ? (
                                  <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                    {error.industry_id}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="clientinfo-box mt-10">
                        {/* <div className="modal-content rounded card"> */}
                        <div className=" col-12 modal-body px-0 pt-0 pb-5 mt-6">
                          {/*begin:Form*/}
                          <form
                            id="kt_modal_new_target_form"
                            className="form fv-plugins-bootstrap5 fv-plugins-framework"
                            action="#"
                          >
                            {/*begin::Input group*/}
                            <div className="d-flex flex-column mb-0 fv-row fv-plugins-icon-container">
                              {/*begin::Label*/}
                              <div
                                className="row"
                                style={{
                                  flexDirection: 'row',
                                  display: 'flex',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <div
                                  className="row  col-5"
                                  style={{
                                    flexDirection: 'row'
                                    // backgroundColor: 'green'
                                    // backgroundColor: 'red',
                                    // display: 'flex'
                                  }}
                                >
                                  <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                                    <span className="required">
                                      Purpose of visit
                                    </span>
                                  </label>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row'
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginLeft: '20px'
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        id="sales"
                                        name="fav_language"
                                        value="sales"
                                        checked={purposeType === 'sales'}
                                        onChange={handleRadioChange}
                                      />
                                      <label
                                        htmlFor="Sales"
                                        style={{
                                          marginLeft: '8px',
                                          fontSize: 'initial'
                                        }}
                                      >
                                        Sales
                                      </label>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginLeft: '20px'
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        id="feedback"
                                        name="fav_language"
                                        value="feedback"
                                        checked={purposeType === 'feedback'}
                                        onChange={handleRadioChange}
                                      />
                                      <label
                                        htmlFor="Feedback"
                                        style={{
                                          marginLeft: '8px',
                                          // font-size: initial;
                                          fontSize: 'initial'
                                        }}
                                      >
                                        Feedback
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {/* {purposeType == 'sales' ? (
                                <div className="row mt-5 col-7">
                                  <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">
                                      Expected Close Date
                                    </span>
                                  </label>
                                  <input
                                    min={new Date().toISOString().split('T')[0]}
                                    type="date"
                                    value={leadDate}
                                    onChange={(e) =>
                                      setLeadDate(e.target.value)
                                    }
                                    name="leadDate"
                                    className="form-control"
                                    placeholder="Enter Expected Close Date"
                                  />
                                  {error && error.expt_close_date ? (
                                    <div style={{ color: 'red' }}>
                                      {error.expt_close_date}
                                    </div>
                                  ) : null}
                                </div>
                              ) : null} */}
                              </div>
                              <div
                                className="row"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  marginTop: '10px',
                                  alignItems: 'center'
                                }}
                              >
                                <div
                                  className={purposeType == 'sales' ? "col-4" : "col-6"}
                                // style={{
                                //   marginTop: '2px',
                                //   marginLeft: '0px'
                                // }}
                                >
                                  <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">Title</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={title}
                                    onChange={(e) => {
                                      setError(prevErrors => ({ ...prevErrors, title: '' }));
                                      setTitle(e.target.value);
                                    }}
                                    // onInput={(e) =>
                                    // (e.target.value =
                                    //   e.target.value.replace(
                                    //     /[^a-zA-Z@#.$!~^&* ]/g,
                                    //     ''
                                    //   ))
                                    // }

                                    name="title"
                                    className="form-control rounded-1"
                                    style={{
                                      backgroundColor: 'transparent',
                                      border: '1px solid #ced4da',
                                      padding: "8px"
                                    }}
                                    placeholder="Enter Title"
                                  />
                                  {error && error.title ? (
                                    <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                      {error.title}
                                    </div>
                                  ) : null}
                                </div>
                                {/* <div className="row mt-5 col-4">
                                  <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">
                                      Business Type
                                    </span>
                                  </label>
                                  <select
                                    style={{ height: '45px' }}
                                    className="form-control"
                                    value={businessType}
                                    onChange={BusinessTypeChangeHandler}
                                  >
                                    <option value="">Select</option>
                                    {type.map((type, index) => (
                                      <option key={index} value={type}>
                                        {type}
                                      </option>
                                    ))}
                                  </select>
                                </div> */}

                                {purposeType == 'sales' ? (
                                  <div className="col-4">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                      <span className="required">
                                        Expected Close Date
                                      </span>
                                    </label>
                                    <input
                                      style={{ padding: "8px" }}
                                      min={new Date().toISOString().split('T')[0]}
                                      type="date"
                                      value={leadDate}
                                      onChange={(e) => {
                                        setLeadDate(e.target.value)
                                        setError(prevErrors => ({ ...prevErrors, expt_close_date: '' }));
                                      }
                                      }
                                      name="leadDate"
                                      className="form-control rounded-1"
                                      placeholder="Enter Expected Close Date"
                                    />
                                    {error && error.expt_close_date ? (
                                      <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                        {error.expt_close_date}
                                      </div>
                                    ) : null}
                                  </div>
                                ) : null}
                                <div className={purposeType == 'sales' ? "col-4" : "col-6"}>
                                  <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">Stage</span>
                                  </label>
                                  <select
                                    style={{ padding: "8px" }}
                                    className="form-control rounded-1"
                                    value={selectedStage}
                                    onChange={handlestageChange}
                                  >
                                    <option value="">Select</option>
                                    {stage.map((stage, index) => (
                                      <option key={stage.id} value={stage.id}>
                                        {stage.stage}
                                      </option>
                                    ))}
                                  </select>
                                  {error && error.stage ? (
                                    <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                      {error.stage}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="row mt-5">
                                <div className="col-md-12">
                                  <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">Description</span>
                                  </label>
                                  <textarea
                                    type="text"
                                    value={description}
                                    onChange={(e) => {
                                      setError(prevErrors => ({ ...prevErrors, description: '' }));
                                      setDescription(e.target.value)
                                    }}
                                    name="descriptipn"
                                    className="form-control rounded-1"
                                    placeholder="Enter Description"
                                    style={{
                                      backgroundColor: 'transparent',
                                      border: '1px solid #ced4da',
                                      padding: "8px"
                                    }}
                                  />
                                  {error && error.description ? (
                                    <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                      {error.description}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              {/*end::Label*/}
                              <div className='row mt-5'>
                                <div className="col-12">

                                  <Col
                                    xs={12}
                                    className='p-0 mb-3'
                                    style={{
                                      paddingLeft: '29px',
                                      paddingLeft: '29px'
                                    }}
                                  >
                                    <Form.Group>
                                      <Form.Label>
                                        Lead Communication
                                      </Form.Label>
                                      <textarea
                                        style={{ padding: "8px" }}
                                        onChange={handleInputChange3}
                                        value={communicationData.communicationDesc}
                                        className="form-control rounded-1"
                                        name="communicationDesc"
                                        type="text"
                                        placeholder=""
                                      />
                                    </Form.Group>
                                    {/* {error && error.communicationDesc ? (
                                      <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                        {error.communicationDesc}
                                      </div>
                                    ) : null} */}
                                  </Col>


                                  <div className='d-flex justify-content-between mt-5' style={{ height: '15vh' }}>
                                    <div className='col-4 ps-0 d-flex align-items-center'>
                                      <Form.Group
                                        className=" col-12 d-flex"
                                      >
                                        <input

                                          style={{ width: '11%' }}
                                          checked={isChecked === 1}
                                          onChange={handleCheckboxChange}
                                          type="checkbox"
                                          placeholder=""
                                          name="communication"
                                        />
                                        <Form.Label className='mb-0 p-2'>
                                          Is Reminder
                                        </Form.Label>
                                      </Form.Group>
                                    </div>
                                    {isChecked ? (
                                      <div className='col-4'>
                                        <div className="col-12">
                                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                            <span className="required">
                                              Reminder Date{' '}
                                            </span>
                                          </label>
                                          <input
                                            style={{ padding: "8px" }}
                                            min={new Date().toISOString().split('T')[0]}
                                            type="datetime-local"
                                            onChange={handleInputChange3}
                                            value={communicationData.reminderDate}
                                            name="reminderDate"
                                            className="form-control rounded-1"
                                            placeholder="Enter Expected Close Date"
                                          />
                                          {error && error.reminderDate ? (
                                            <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                              {error.reminderDate}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                            </div>
                          </form>
                        </div>
                      </div>
                      {
                        purposeType == 'sales' ?
                          <Button
                            className="mt-5 rounded-1"
                            variant="success"
                            onClick={() => {
                              labelText === "New" ? (
                                validationCheckHandler({
                                  option: {
                                    organisation: selectedOption.value,
                                    title,
                                    leadDate,
                                    selectedStage,
                                    description,
                                    // communicationDesc: communicationData.communicationDesc,
                                    reminderDate: communicationData.reminderDate
                                  }
                                })
                              ) : (
                                newValidationCheckHandler({
                                  option: {
                                    // gst: clientSaveData.gst_no,
                                    organisation: clientSaveData.organisation,
                                    address: clientSaveData.address,
                                    industry: industryId.value,
                                    title,
                                    leadDate,
                                    selectedStage,
                                    description,
                                    // communicationDesc: communicationData.communicationDesc,
                                    reminderDate: communicationData.reminderDate
                                  }
                                })
                              )
                            }}


                            style={{ width: '11%' }}
                          >
                            Next
                          </Button>
                          :
                          <Button
                            className="mt-5"
                            variant="success rounded-1"
                            onClick={
                              () => labelText === "New" ? (
                                feedBackValidationHandler(
                                  {
                                    option: {
                                      organisation: selectedOption.value,
                                      title,
                                      // leadDate,
                                      selectedStage,
                                      description,
                                      communicationDesc: communicationData.communicationDesc,
                                      reminderDate: communicationData.reminderDate
                                    }
                                  }
                                )
                              ) : (
                                newFeedBackValidationCheckHandler({
                                  option: {
                                    // gst: clientSaveData.gst_no,
                                    organisation: clientSaveData.organisation,
                                    address: clientSaveData.address,
                                    industry: industryId.value,
                                    title,
                                    leadDate,
                                    selectedStage,
                                    description,
                                    communicationDesc: communicationData.communicationDesc,
                                    reminderDate: communicationData.reminderDate
                                  }
                                })
                              )

                            }
                            style={{ width: '11%' }}
                          >
                            Submit
                          </Button>
                      }
                    </div>
                  ) : null
                  }

                  {/* <Button
                  className="mt-5"
                  variant="success"
                  onClick={
                    () => setActiveOption('product')
                  }
                  style={{ width: '11%' }}
                >
                  Submit
                </Button> */}
                  {activeOption === 'product' && (
                    <Form>
                      {productData.map((data, index) => (
                        <>
                          <div className='clientinfo-box'
                            key={index}
                          >

                            {/* <div
                            className="card card-flush h-xl-100"
                          > */}
                            {/* {productError && productError ? (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '20px',
                                  alignSelf: 'center'
                                }}
                              >
                                {productError}
                              </div>
                            ) : null} */}
                            <div className="row">
                              <Col
                                style={{
                                  marginBottom: '16px',
                                  marginTop: '12px'
                                }}
                                xs={3}
                              >
                                <Form.Group controlId={`formName${index}`}>
                                  <div
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                    className="required"
                                  >
                                    Item Name
                                  </div>
                                  {/* <Form.Label style={{fontSize:16,fontWeight:'900'}}>Item Name</Form.Label> */}
                                  <ReactSelect
                                    options={productOptionss}
                                    value={selectedProduct}
                                    isDisabled={edit === true ? true : null}
                                    onChange={(value) => {

                                      setSelectedProduct(value);
                                      setSelectedProductName(value.label);
                                      setSelectedCategory('');
                                      setSelectedSubcategory('');
                                    }}
                                    maxMenuHeight={'200px'}
                                  />
                                </Form.Group>
                              </Col>
                              <Col
                                style={{
                                  marginBottom: '16px',
                                  marginTop: '12px'
                                }}
                                xs={3}
                              >
                                <Form.Group controlId={`formName${index}`}>
                                  <div
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                    className="required"
                                  >
                                    Brand Name
                                  </div>
                                  {/* <Form.Label style={{fontSize:16,fontWeight:'900'}}>Item Name</Form.Label> */}
                                  <ReactSelect
                                    options={BrandOptions}
                                    value={selectedBrand}
                                    // isDisabled={edit === true ? true : null}
                                    onChange={(value) => {

                                      setSelectedBrand(value);
                                      // setSelectedProductName(value.label);
                                      setSelectedCategory('');
                                      setSelectedSubcategory('');
                                    }}
                                    maxMenuHeight={'200px'}
                                  />
                                </Form.Group>
                              </Col>
                              <Col
                                style={{
                                  marginBottom: '16px',
                                  marginTop: '12px'
                                }}
                                xs={3}
                              >
                                <Form.Group controlId={`formName${index}`}>
                                  <div
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                    className="required"
                                  >
                                    Size
                                  </div>
                                  {/* <Form.Label>Size</Form.Label> */}
                                  <ReactSelect
                                    options={categoryOptions}
                                    value={selectedCategory}
                                    onChange={(value) => {
                                      setSelectedCategory(value);
                                      setSelectedSubcategory('');
                                    }}
                                    maxMenuHeight={'200px'}
                                    isDisabled={!selectedProduct}
                                  />
                                </Form.Group>
                              </Col>
                              <Col
                                style={{
                                  marginBottom: '16px',
                                  marginTop: '12px'
                                }}
                                xs={3}
                              >
                                <Form.Group controlId={`formName${index}`}>
                                  <div
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Thickness
                                  </div>
                                  {/* <Form.Label>className Thickness</Form.Label> */}
                                  <ReactSelect
                                    options={subcategoryOptions}
                                    value={selectedSubcategory}
                                    onChange={setSelectedSubcategory}
                                    isDisabled={!selectedCategory}
                                  />
                                </Form.Group>
                              </Col>
                              <Col
                                xs={1}
                                className="d-flex align-items-center"
                              ></Col>
                            </div>
                            <div className="row">
                              <Col
                                style={{
                                  marginBottom: '16px',
                                  marginTop: '12px'
                                }}
                              // xs={3}
                              >
                                <Form.Group controlId={`formQuantity${index}`}>
                                  <div
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                      // fontWeight: 'bolder'
                                    }}
                                    className="required"
                                  >
                                    Quantity /PCS
                                  </div>

                                  <Form.Control
                                    className='rounded-1'
                                    style={{ padding: "8px" }}
                                    type="text"
                                    placeholder="Enter quantity"
                                    value={productSlideData.qty}
                                    name="qty"
                                    onChange={handleProductInputChange}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col
                                style={{
                                  marginBottom: '16px',
                                  marginTop: '12px'
                                }}
                              // xs={3}
                              >
                                <div key={index}>
                                  <label className="d-flex align-items-center fs-6 fw-semibold ">
                                    {/* <span className="required">
                                          Measurement
                                        </span> */}
                                    <div
                                      style={{
                                        fontSize: 16,
                                        fontWeight: 'bold'
                                      }}
                                      className="required"
                                    >
                                      Measurement
                                    </div>
                                  </label>
                                  <ReactSelect
                                    options={measurementDataOption}
                                    value={measurementType}
                                    onChange={(value) => {
                                      setMeasurementType(value);
                                      // setBasePrice('');
                                      setProductSlideData({ ...productSlideData, kg: '' })
                                    }}
                                  />
                                </div>
                              </Col>

                              <Col
                                style={{
                                  marginBottom: '16px',
                                  marginTop: '12px'
                                }}
                              // xs={3}
                              >
                                <Form.Group controlId={`formQuantity${index}`}>
                                  <div
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                    className='required'
                                  >
                                    Enter kg
                                  </div>
                                  {/* <Form.Label>Enter Kg</Form.Label> */}
                                  <Form.Control
                                    className='rounded-1'
                                    style={{ padding: "8px" }}
                                    type="text"
                                    placeholder="Enter quantity"
                                    value={productSlideData.kg}
                                    name="kg"
                                    onChange={handleProductInputChange}
                                    disabled={
                                      measurementType.label !== 'KILOGRAMS'
                                    }
                                    onInput={(e) => {
                                      // Remove any characters that are not digits, decimal points, or #
                                      e.target.value = e.target.value.replace(/[^\d.]/g, '');
                                    }}
                                  />

                                </Form.Group>
                              </Col>
                              {/* // ) : null} */}
                              <Col
                                style={{
                                  marginBottom: '16px',
                                  marginTop: '12px'
                                }}
                              // xs={3}
                              >
                                <Form.Group controlId={`formQuantity${index}`}>
                                  <div
                                    style={{
                                      flexDirection: 'row',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: 17,
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      Total Qty.
                                    </div>
                                    <div style={{ marginLeft: 6, fontSize: 12 }}>
                                      (after measure.)
                                    </div>
                                  </div>
                                  {/* <Form.Label>Total Quantity</Form.Label> */}
                                  <Form.Control
                                    className='rounded-1'
                                    style={{ padding: "8px" }}
                                    placeholder="Total Quantity"
                                    value={totalQuanity}
                                    name="totalQuantity"
                                    type='text'
                                    onChange={(e) => setTotalQuanity(e.target.value)}
                                    onInput={(e) => {
                                      // Remove any characters that are not digits, decimal points, or #
                                      e.target.value = e.target.value.replace(/[^\d.]/g, '');
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col
                                style={{
                                  marginBottom: '16px',
                                  marginTop: '12px'
                                }}
                              // xs={3}
                              >
                                <Form.Group controlId={`formQuantity${index}`}>
                                  <div
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                    className="required"
                                  >
                                    Price
                                  </div>
                                  {/* <Form.Label>Price</Form.Label> */}
                                  <Form.Control
                                    className='rounded-1'
                                    style={{ padding: "8px" }}
                                    type="text"
                                    placeholder="Enter Price"
                                    value={basePrice}
                                    onInput={(e) => {
                                      // Remove any characters that are not digits, decimal points, or #
                                      e.target.value = e.target.value.replace(/[^\d.]/g, '');
                                    }}
                                    name="price"
                                    onChange={(e) => setBasePrice(e.target.value)}
                                  />
                                </Form.Group>
                              </Col>
                            </div>
                            <div className="row">

                              <Col
                                style={{
                                  marginBottom: '16px',
                                  marginTop: '12px'
                                }}
                              // xs={4}
                              >
                                <Form.Group controlId={`formQuantity${index}`}>
                                  <div
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Discount (%)
                                  </div>

                                  <Form.Control
                                    className='rounded-1'
                                    style={{ padding: "8px" }}
                                    placeholder="Enter Discount"
                                    value={productSlideData.discount}
                                    name="discount"
                                    onChange={handleProductInputChange}
                                    type='text'
                                    onInput={(e) => {
                                      // Remove any characters that are not digits, decimal points, or #
                                      e.target.value = e.target.value.replace(/[^\d.]/g, '');
                                    }}

                                  />
                                </Form.Group>
                              </Col>
                              <Col
                                style={{
                                  marginBottom: '16px',
                                  marginTop: '12px'
                                }}
                              // xs={4}
                              >
                                <Form.Group controlId={`formQuantity${index}`}>
                                  <div
                                    style={{
                                      flexDirection: 'row',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: 17,
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      Net Price
                                    </div>
                                    {/* <div style={{ marginLeft: 6, fontSize: 12 }}>
                                      (Taxable Amount)
                                    </div> */}
                                  </div>

                                  <Form.Control
                                    className='rounded-1'
                                    style={{ padding: "8px" }}
                                    type='text'
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                    }}
                                    placeholder="Price After Discount"
                                    value={
                                      productSlideData.discount
                                        ? priceAfterDiscount
                                        : priceBeforeDiscount
                                    }
                                    name="priceAfterDiscount"
                                    onChange={(e) => setPriceAfterDiscount(e.target.value)}
                                    readOnly
                                  />
                                </Form.Group>
                              </Col>
                              <Col
                                style={{
                                  marginBottom: '16px',
                                  marginTop: '12px'
                                }}
                              // xs={4}
                              >
                                <div key={index}>
                                  <Form.Group controlId={`formQuantity${index}`}>
                                    <div
                                      style={{
                                        fontSize: 16,
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      Taxable Amount
                                    </div>

                                    <Form.Control
                                      className='rounded-1'
                                      style={{ padding: "8px" }}
                                      type='text'
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                      }}
                                      placeholder="Taxable Amount"
                                      value={taxAmount}
                                      name="priceBeforeDiscount"
                                      onChange={(e) => setTaxAmount(e.target.value)}
                                      readOnly
                                    />
                                  </Form.Group>
                                </div>
                              </Col>
                              <Col
                                style={{
                                  marginBottom: '16px',
                                  marginTop: '12px'
                                }}
                              // xs={4}
                              >
                                <div key={index}>
                                  <Form.Group controlId={`formQuantity${index}`}>
                                    <div
                                      style={{
                                        fontSize: 16,
                                        fontWeight: 'bold'
                                      }}
                                      className="required"
                                    >
                                      GST (%)
                                    </div>
                                    {/* <Form.Label> GST (%)</Form.Label> */}
                                    <Form.Control
                                      className='rounded-1'
                                      style={{ padding: "8px" }}
                                      type="text"
                                      placeholder="Enter quantity"
                                      value={productSlideData.gst}
                                      name="gst"
                                      onChange={handleProductInputChange}
                                      onInput={(e) => {
                                        // Remove any characters that are not digits, decimal points, or #
                                        e.target.value = e.target.value.replace(/[^\d.]/g, '');
                                      }}
                                      oninput="restrictToRange(this)"
                                    />
                                  </Form.Group>
                                </div>
                              </Col>
                              <Col
                                style={{
                                  marginBottom: '16px',
                                  marginTop: '12px'
                                }}
                              // xs={4}
                              >
                                <Form.Group controlId={`formQuantity${index}`}>
                                  <div
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 'bold'
                                    }}
                                    className="required"
                                  >
                                    Net Value
                                  </div>
                                  {/* <Form.Label>Net Value</Form.Label> */}
                                  <Form.Control
                                    className='rounded-1'
                                    style={{ padding: "8px" }}
                                    // type="number"
                                    placeholder="Net Value"
                                    value={netValue}
                                    name="netValue"
                                    onChange={(e) => setNetValue(e.target.value)}
                                  // name="qty"
                                  />
                                </Form.Group>
                              </Col>
                            </div>
                            {index !== 0 && (
                              <Col xs={1} className="d-flex align-items-center">
                                <span>
                                  <i
                                    style={{
                                      fontSize: '21px',
                                      marginTop: '10px'
                                    }}
                                    className="fa-solid text-danger fa-circle-minus fa-2xl"
                                    onClick={() => handleRemoveInputFields(index)}
                                  ></i>
                                </span>
                              </Col>
                            )}
                          </div>

                          <div className='col-12 py-4 align-items-center d-flex gap-3'>
                            {/* <Button onClick={addSlider} className='col-2 py-5'>Add More</Button> */}
                            <Button className="col-2 pt-3 rounded-1" onClick={addSlider}>
                              {edit === true ? "Update Product" : "Add Product"}
                            </Button>
                            {edit === true ? <div className="btn btn-secondary col-2 pt-3 rounded-1 "
                              onClick={() => {
                                setEdit(false)
                                setSelectedProduct('')
                                setSelectedCategory('')
                                setSelectedSubcategory('')
                                setProductSlideData({
                                  qty: '',
                                  kg: '',
                                  discount: '',
                                  gst: '18'
                                })
                                setMeasurementType('')
                                setBasePrice('')
                                setPriceAfterDiscount('')
                                setPriceBeforeDiscount('')
                                setTotalQuanity('')
                                setTaxAmount('')
                                setNetValue('')
                                setSelectedBrand('')
                              }}>
                              Cancel
                            </div> : null}
                          </div>
                          <div
                            className="clientinfo-box d-flex flex-wrap rounded-1 py-5 row-gap-2"
                          >
                            <div className='col-12'>
                              <Form.Group>
                                <div
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 'bold'
                                  }}
                                >
                                  Remarks
                                </div>
                                <textarea
                                  style={{ padding: "8px" }}
                                  onChange={handleAdditionalDataChange}
                                  value={additionalData.remarks}
                                  className="form-control rounded-1"
                                  name="remarks"
                                  type="text"
                                />
                                {error && error.remarks ? (
                                  <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                    {error.remarks}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </div>
                            <div className='col-6 pe-2'>
                              <Form.Group >
                                <div
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 'bold'
                                  }}
                                // className="required"
                                >
                                  Delivery
                                </div>
                                {/* <Form.Label>Net Value</Form.Label> */}
                                <Form.Control
                                  style={{ padding: "8px" }}
                                  className="rounded-1"
                                  type="text"
                                  placeholder="Delivery"
                                  value={additionalData.delivery}
                                  name="delivery"
                                  onChange={handleAdditionalDataChange}
                                />
                                {error && error.delivery ? (
                                  <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                    {error.delivery}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </div>
                            <div className='col-6 ps-2'>
                              <Form.Group >
                                <div
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 'bold'
                                  }}
                                // className="required"
                                >
                                  GST
                                </div>
                                {/* <Form.Label>Net Value</Form.Label> */}
                                <Form.Control
                                  style={{ padding: "8px" }}
                                  className="rounded-1"
                                  type="text"
                                  placeholder="Gst"
                                  value={additionalData.gst}
                                  name="gst"
                                  onChange={handleAdditionalDataChange}
                                />
                                {error && error.gst ? (
                                  <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                    {error.gst}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </div>
                            <div className='col-6 pe-2'>
                              <Form.Group >
                                <div
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 'bold'
                                  }}
                                // className="required"
                                >
                                  Payment
                                </div>

                                <Form.Control
                                  style={{ padding: "8px" }}
                                  className="rounded-1"
                                  type="text"
                                  placeholder="Payment"
                                  value={additionalData.payment}
                                  name="payment"
                                  onChange={handleAdditionalDataChange}
                                />
                                {error && error.payment ? (
                                  <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                    {error.payment}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </div>
                            <div className='col-6 ps-2'>
                              <Form.Group>
                                <div
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 'bold'
                                  }}
                                // className="required"
                                >
                                  Freight
                                </div>
                                {/* <Form.Label>Net Value</Form.Label> */}
                                <Form.Control
                                  style={{ padding: "8px" }}
                                  className="rounded-1"
                                  type="text"
                                  placeholder="Freight"
                                  value={additionalData.freight}
                                  name="freight"
                                  onChange={handleAdditionalDataChange}
                                />
                              </Form.Group>
                              {error && error.freight ? (
                                <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                  {error.freight}
                                </div>
                              ) : null}
                            </div>
                            <div className='col-6 pe-2'>
                              <Form.Group controlId={`formQuantity${index}`}>
                                <div
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 'bold'
                                  }}
                                // className="required"
                                >
                                  Make
                                </div>
                                {/* <Form.Label>Net Value</Form.Label> */}
                                <Form.Control
                                  style={{ padding: "8px" }}
                                  className="rounded-1"
                                  type="text"
                                  placeholder="Make"
                                  value={additionalData.make}
                                  name="make"
                                  onChange={handleAdditionalDataChange}
                                />
                                {error && error.make ? (
                                  <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                    {error.make}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </div>


                            <div className='col-6 ps-2'>
                              <Form.Group>
                                <div
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 'bold'
                                  }}
                                >
                                  Additional Note
                                </div>
                                <input
                                  style={{ padding: "8px" }}
                                  type="text"
                                  placeholder="Additional Note"
                                  value={additionalData.note}
                                  name="note"
                                  onChange={handleAdditionalDataChange}
                                  className="form-control rounded-1"
                                />
                                {error && error.note ? (
                                  <div style={{ color: 'red' }} className='mt-1 ms-2 fw-semibold'>
                                    {error.note}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </div>
                            {/* <div className='col-6 p-3'>
                            <Form.Group>
                              <div
                                style={{
                                  fontSize: 16,
                                  fontWeight: 'bold'
                                }}
                              >
                                Terms And Conditions
                              </div>
                              <textarea
                                type="text"
                                placeholder="Terms and Conditions"
                                value={additionalData.terms}
                                name="terms"
                                onChange={handleAdditionalDataChange}
                                className="form-control"
                              />
                              
                            </Form.Group>
                          </div> */}
                          </div>
                          {activeOption == 'product' ? (
                            <div className="clientinfo-box mt-5">
                              <div className='fw-bold fs-3'>
                                Product Details
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  maxHeight: '200px',
                                  overflowY: 'auto'
                                }}
                              >
                                <table className="table-responsive w-100">
                                  <thead
                                    style={{
                                      position: 'sticky',
                                      top: 0,
                                      backgroundColor: 'white',
                                      zIndex: 1
                                    }}
                                  >
                                    <tr>
                                      <th className='fw-semibold fs-5 text-center'>Sr no.</th>
                                      <th className='fw-semibold fs-5 '>Product Name</th>
                                      <th className='fw-semibold fs-5 '>Brand</th>
                                      <th className='fw-semibold fs-5 text-center'>Category</th>
                                      <th className='fw-semibold fs-5 text-center'>SubCategory</th>
                                      <th className='fw-semibold fs-5 text-center'>Total Quantity</th>
                                      <th className='fw-semibold fs-5 text-center'>Price /pc </th>
                                      <th className='fw-semibold fs-5 text-center'>Discount</th>
                                      <th className='fw-semibold fs-5 text-center'>Net-price</th>
                                      <th className='fw-semibold fs-5 text-center'>Taxable Amount</th>
                                      <th className='fw-semibold fs-5 text-center'>GST</th>
                                      <th className='fw-semibold fs-5 text-center'>Net-Value </th>
                                      <th className='fw-semibold fs-5 text-center'>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {slides.length == 0 ? (
                                      <tr className="text-center">
                                        <td
                                          colSpan="13"
                                          style={{ fontSize: 18, marginTop: '10px' }}
                                        >
                                          No Data found
                                        </td>
                                      </tr>
                                    ) : (
                                      <>
                                        {slides.map((e, index) => {
                                          return (
                                            <tr key={index} >
                                              <td className='text-center'>{index + 1}</td>
                                              <td>
                                                {e.product_name
                                                  ? e.product_name
                                                  : '------'}
                                              </td>
                                              <td className='text-center'>
                                                {e.brand_name
                                                  ? e.brand_name
                                                  : '------'}
                                              </td>
                                              <td className='text-center'>
                                                {e.category_name
                                                  ? e.category_name
                                                  : '------'}
                                              </td>
                                              <td className='text-center'>
                                                {e.sub_category_name
                                                  ? e.sub_category_name
                                                  : '------'}
                                              </td>
                                              <td className='text-center'>
                                                {e.total_quantity
                                                  ? e.total_quantity
                                                  : '------'}
                                              </td>
                                              {/* <td>120</td> */}
                                              <td className='text-center'>
                                                {e.product_price
                                                  ? e.product_price
                                                  : '------'}
                                              </td>
                                              {/* <td>24000</td> */}
                                              <td className='text-center'>{e.discount}%</td>
                                              <td className='text-center'>{e.net_price ? parseFloat(e.net_price).toFixed(2) : '------'}</td>
                                              <td className='text-center'>{e.taxable_amount ? parseFloat(e.taxable_amount).toFixed(2) : '------'}</td>
                                              <td className='text-center'>{e.product_gst}%</td>
                                              <td className='text-center'>
                                                {e.total_price ? parseFloat(e.total_price).toFixed(2) : '------'}
                                              </td>
                                              <td className="d-flex justify-content-between px-3">
                                                <i
                                                  onClick={
                                                    () => {
                                                      setProductIndex(index);
                                                      setIsModalOpen(true);
                                                    }

                                                  }
                                                  // onClick={() => DeleteProduct()}
                                                  style={{
                                                    // marginRight: '20px',
                                                    color: 'red',
                                                    fontSize: '20px',
                                                    cursor: 'pointer'
                                                  }}
                                                  className="fa-solid fa-trash fs-5"
                                                ></i>
                                                <i
                                                  onClick={() => {
                                                    window.scrollTo({
                                                      top: 0,
                                                      behavior: 'smooth' // Smooth scroll behavior
                                                    });
                                                    handleEditProduct({
                                                      product_no: e.product_no,
                                                      brand_id: e.brand_id,
                                                      product_id: e.product_id,
                                                      category_id: e.category_id,
                                                      sub_category_id: e.sub_category_id,
                                                      product_qty: e.product_qty,
                                                      measurement: e.measurement,
                                                      product_kg: e.product_kg,
                                                      product_price: e.product_price,
                                                      discount: e.discount,
                                                      product_gst: e.product_gst,
                                                      total_price: e.total_price,
                                                      net_price: e.net_price,
                                                      taxable_amount: e.taxable_amount,
                                                      total_quantity: e.total_quantity
                                                    });
                                                    setEdit(true);
                                                  }}

                                                  className="fa-solid fa-pen fs-4 text-primary cursor-pointer px-1 fs-5"
                                                ></i>
                                              </td>


                                            </tr>
                                          );
                                        })}
                                      </>
                                    )}

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : null}

                        </>
                      ))}
                    </Form>
                  )}
                  {activeOption === 'product' ? (
                    <>



                      {activeOption == 'product' ? (
                        <div className='my-5 d-flex justify-content-start gap-3'>
                          <Button
                            className="rounded-1"
                            variant="tertiary"
                            // variant="primary"
                            onClick={() => setActiveOption("Lead Details")}
                            // onClick={openModal}
                            style={{ width: '11%', border: "1px solid #ced4da" }}
                          >
                            Back
                          </Button>
                          <Button
                            className="rounded-1"
                            variant="success"
                            // variant="primary"
                            onClick={() => {
                              additionalValidationHandler({
                                option: {
                                  // remarks: additionalData.remarks,
                                  delivery: additionalData.delivery,
                                  gst: additionalData.gst,
                                  freight: additionalData.freight,
                                  payment: additionalData.payment,
                                  make: additionalData.make,
                                  // note: additionalData.note,
                                }
                              })

                            }}
                            // onClick={leadSubmit}
                            style={{ width: '11%' }}
                          >
                            Submit
                          </Button>
                        </div>

                      ) : null}
                      {/* {formCount > 1 ? (
                              <Button
                                style={{
                                  padding: '0.5rem 0.8rem',
                                  fontSize: '0.9rem'
                                }}
                                onClick={nextSlide}
                              >
                                <FaArrowRight />
                              </Button>
                            ) : null} */}
                      {/* </div> */}
                    </>
                  ) : null}
                  {/* </div> */}
                  {/* //   ))}
                // </Slider> */}
                </div>
              </div>
            </div>
          </div>
          {/* {activeOption == 'product' ? (
          <Button
            className="mt-5"
            variant="success"
            // variant="primary"
            onClick={leadSubmit}
            style={{ width: '11%' }}
          >
            Submit
          </Button>
        ) : null} */}

          {
            isModalOpen && (
              <Modal
                style={{ display: 'block' }}
                isOpen={isModalOpen}
                onClose={closeModal}
              >
                <div style={{ alignSelf: 'center' }}>
                  <h2> Are You sure</h2>
                  <h4>You want to delete this product</h4>
                  <button
                    style={{ marginTop: '30px' }}
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setIsModalOpen(false)}
                  // disabled={isButtonDisabled}
                  >
                    Cancel
                  </button>
                  <button
                    style={{ marginTop: '30px', marginLeft: '20px' }}
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      deleteProduct();
                      Swal.fire({
                        title: "Product Delation",
                        text: "Your Product has been successfully deleted.",
                        icon: "success",
                        confirmButtonColor: 'green',
                        allowOutsideClick: false,

                      });
                      // setIsNestedModalOpen(true);
                    }}
                  // disabled={isButtonDisabled}
                  >
                    Delete
                  </button>
                </div>
              </Modal>
            )
          }

          {
            isNestedModalOpen && (
              <Modal isOpen={isNestedModalOpen} onClose={closeAllModals}>
                <h2>Product deleted successfully </h2>

                <button
                  style={{ marginTop: '10px' }}
                  type="button"
                  className="btn btn-primary"
                  onClick={closeAllModals}
                >
                  OK
                </button>
              </Modal>
            )
          }

          {
            isUpdateModalOpen && (
              <Modal
                style={{ display: 'block' }}
                isOpen={isUpdateModalOpen}
                onClose={closeUpdateModal}
              >
                <div style={{ alignSelf: 'center' }}>
                  {/* <h1 style={{alignItems:'center'}}>Are You sure</h1> */}
                  <h2>Add Extra Charges</h2>
                  <h6>Do you want to add freight or loading/unloading charges?</h6>

                  <Col
                    style={{
                      marginBottom: '16px',
                      marginTop: '18px'
                    }}
                  // xs={4}
                  >
                    {/* <div> */}
                    <Form.Group>
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: '600',
                          marginTop: 10
                        }}
                      >
                        Freight
                      </div>
                      {/* <Form.Control
                      type="number"
                      placeholder="Enter Charges"
                      value={freightCharges}
                      name="freight"
                      onChange={(value) => setFreightCharges(value)}
                    /> */}
                      <input
                        type="text"
                        value={freightCharges}
                        onChange={(e) => setFreightCharges(e.target.value)}
                        name="freightCharges"
                        className="form-control"
                        style={{
                          backgroundColor: 'transparent',
                          border: '1px solid #ced4da'
                        }}
                        placeholder="Enter Freight Charges"
                        onInput={(e) =>
                          (e.target.value = e.target.value.replace(/[^0-9]/g, ''))
                        }
                      />
                    </Form.Group>
                    {/* </div> */}
                  </Col>
                  <Col
                    style={{
                      marginBottom: '16px',
                      marginTop: '12px'
                    }}
                  // xs={4}
                  >
                    <Form.Group>
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: '600'
                        }}
                      // className='required'
                      >
                        Loading / Unloading
                      </div>
                      {/* <Form.Control
                    type="number"
                    placeholder="Enter Charges"
                    value={loadingUnloadingCharges}
                    name="loadingUnloadingCharges"
                    onChange={(value) => setLoadingUnloadingCharges(value)}
                    // name="qty"
                  /> */}
                      <input
                        type="text"
                        value={loadingUnloadingCharges}
                        onChange={(e) => setLoadingUnloadingCharges(e.target.value)}
                        name="loadingUnloadingCharges"
                        className="form-control"
                        style={{
                          backgroundColor: 'transparent',
                          border: '1px solid #ced4da'
                        }}
                        placeholder="Enter Loading / Unloading Charges"
                        onInput={(e) =>
                          (e.target.value = e.target.value.replace(/[^0-9]/g, ''))
                        }
                      />
                    </Form.Group>
                  </Col>

                  <button
                    style={{ marginTop: '30px' }}
                    type="button"
                    className="btn btn-secondary rounded-1"
                    onClick={() => closeAllUpdateModals()}
                  // disabled={isButtonDisabled}
                  >
                    Cancel
                  </button>
                  <button
                    style={{ marginTop: '30px', marginLeft: '20px' }}
                    type="button"
                    className="btn btn-success rounded-1"
                    onClick={() => {
                      leadSubmit();
                      setIsUpdateModalOpen(false);
                      // setIsNestedModalOpen(true);
                    }}
                  // disabled={isButtonDisabled}
                  >
                    Submit
                  </button>
                </div>
              </Modal>
            )
          }

          {/* //  <Modal
            style={{ display: 'block' }}
            isOpen={isUpdateModalOpen}
            onClose={closeUpdateModal}
          >
            <div style={{ alignSelf: 'center' }}>
              <h2> Are You sure</h2>
              <h4>You want to Add this lead</h4>
              <button
                style={{ marginTop: '30px' }}
                type="button"
                className="btn btn-secondary"
                onClick={() => setIsUpdateModalOpen(false)}
                // disabled={isButtonDisabled}
              >
                Cancel
              </button>
              <button
                style={{ marginTop: '30px', marginLeft: '20px' }}
                type="button"
                className="btn btn-success"
                onClick={() => {
                  // DeleteProduct()
                  // alert('frontend');
                  leadSubmit();
                  setIsUpdateModalOpen(false);
                }}
                // }}
                // disabled={isButtonDisabled}
              >
                Add
              </button>
            </div>
          </Modal> */}

          {
            isNestedUpdateModalOpen && (
              <Modal
                isOpen={isNestedUpdateModalOpen}
                onClose={closeAllUpdateModals}
              >
                <h2>Lead Added successfully </h2>

                <button
                  style={{ marginTop: '10px' }}
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    closeAllUpdateModals();
                    navigate('/manageleads');
                  }}
                >
                  OK
                </button>
              </Modal>
            )
          }

          {/*end product section*/}
        </Constant>
      </div>
    </>

  );
};
export default AddLead;
