import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Url from '../Elements/Api/Api';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { setStoreEmail } from '../Elements/Redux/Action/Authaction';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import './Auth.css'

const override = css`
display: block;
margin: 0 auto;
`;

const ForgotPassword = () => {

  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  const submitHandle = () => {
    setLoading(true)
    dispatch(setStoreEmail(email));
    const params = {
      email: email
    };
    axios
      .post(Url.BASEURL + 'forgot-password', params)
      .then((response) => {
        if (response.status === 200) {

          setLoading(false)
          navigate('/otp_verify');
        } else {

          setLoading(false)
          setError('Please enter valid credentials');
        }
      })
      .catch((error) => {
        setLoading(false)
        setError(error.response.data.message);
      });
  };

  return (
    <>
      {loading &&
        <div className="loader-backdrop">
          <div className="spinner-container">
            <ClipLoader color={'#50CD89'} loading={loading} css={override} size={60} />
          </div>
        </div>
      }
      <div>
        <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>

          <div className="d-flex flex-column flex-lg-row flex-column-fluid justify-content-center">

            <div
              className="d-flex flex-column flex-lg-row-fluid py-10"
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >


              <div className="card">
                <div className="d-flex flex-center flex-column flex-column-fluid">

                  <div className="p-10 p-lg-15 mx-auto">

                    <form
                      className="form w-100"
                      noValidate="novalidate"
                      id="kt_sign_in_form"
                      data-kt-redirect-url="../dist/index.html"
                      action="#"
                    >
                      <div className='d-flex justify-content-center mb-5'>
                        <div className='image-box col-12'>
                          <img
                            style={{
                              height: '200px',
                              width: "80%",
                              margin: "auto",
                              display: "block",
                            }}
                            alt="Logo"
                            src="dist/assets/media/logos/logo.jpeg"
                            className="theme-light-show logo_filter"
                          />
                        </div>

                      </div>
                      <div className="text-center mb-10">

                        <p className="text-dark mb-3 fs-1 fw-bold" style={{ letterSpacing: "1px" }}>
                          Enter your registered email
                        </p>


                      </div>
                      <div className="fv-row mb-10">

                        <label
                          style={{ float: 'left' }}
                          className="form-label fs-6 fw-bold text-dark"
                        >
                          Email
                        </label>

                        <input
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setError('');
                          }}
                          className="form-control form-control rounded-1"
                          type="text"
                          name="email"
                          placeholder='Enter Your Registered Email'
                        />


                        {error ? (
                          <div

                            className='mt-1 ms-2 fw-semibold text-danger fs-6'
                          >
                            {error}
                          </div>
                        ) : null}
                      </div>
                      <div className="row align-items-center flex-column">
                        <div className="col-12 d-flex justify-content-center">
                          <div
                            onClick={() => submitHandle()}
                            className="btn btn-primary rounded-1 px-20"
                          >
                            Get OTP
                          </div>
                        </div>
                        <div className="col-12 d-flex justify-content-center align-items-center mt-3 gap-2">
                          <i className="fa-solid fa-angle-left fs-5 text-dark"></i>
                          <div>
                            <Link style={{ float: 'inline-end' }} to="/" className='fs-5 fw-semibold text-dark'>
                              Back to login
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};
export default ForgotPassword;
