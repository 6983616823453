export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const setToken = (token) => ({
  type: 'SET_TOKEN',
  payload: {
    token
  }
});
export const delToken = (token) => ({
  type: 'DEL_TOKEN'
});
export const setStatus = (status) => ({
  type: 'SET_STATUS',
  payload: {
    status
  }
});
export const attendanceStatus = (attendStatus) => ({
  type: 'SET_ATTENDSTATUS',
  payload: {
    attendStatus
  }
});
// export const setAttendAlreadyMarked = (alreadyMarkedAttend) => ({
//   type: 'SET_ATTENDSTATUS',
//   payload: {
//     alreadyMarkedAttend
//   }
// });
export const attendencemarkedStatus = (alreadyMarkedAttend) => ({
  type: 'SET_ALREADTATTENDMARKED',
  payload: {
    alreadyMarkedAttend
  }
});
export const delPassword = (password) => ({
  type: 'DEL_PASSWORD'
});
export const setName = (name) => ({
  type: 'SET_NAME',
  payload: {
    name
  }
});
export const setProfileImage = (profile_pic) => ({
  type: 'SET_PROFILE_IMAGE',
  payload: {
    profile_pic
  }
});
export const setStoreEmail = (email) => ({
  type: 'SET_EMAIL',
  payload: {
    email
  }
});
export const setpasswordChangeToken = (passwordtoken) => ({
  type: 'SET_PASSWORD_TOKEN',
  payload: {
    passwordtoken
  }
});
export const setStaffId = (staffId) => ({
  type: 'SET_STAFF_ID',
  payload: {
    staffId
  }
});
export const setRequestStatus = (requestStatus) => ({
  type: 'SET_REQUEST_STATUS',
  payload: {
    requestStatus
  }
});
