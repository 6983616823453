import React, { useState, useEffect } from 'react';
import Constant from '../../Constant/UserLayout';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Url from '../../Elements/Api/Api';
import ReactPaginate from 'react-paginate';
import { Puff } from 'react-loader-spinner';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import Header from '../../Constant/Header';
const ManageProducts = () => {
  const [searchStaffByName, setSearhStaffByName] = useState('');
  const [searchClient, setSearchClient] = useState('');
  const [searchClientByName, setSearchClientByName] = useState('');
  const [itemOffset, setItemOffset] = useState(1);
  const [pageNumber, setPageNumber] = useState(0)
  const [clientData, setClientData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [deleteRow, setDeleteRow] = useState([]);
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState('');
  const [clientLists, setClientLists] = useState([]);
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  const [id, setId] = useState('');
  const [isArrowObj, setIsArrowObj] = useState({
    column: 'owner_name',
    orderby: 'asc'
  });

  const token = useSelector((state) => state.token);
  // const name = useSelector((state) => state.name);
  // console.log('nameeeeeee', name);

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate2, setSelectedDate2] = useState('');

  useEffect(() => {
    getBrandList();
  }, [searchClient, itemOffset, deleteRow, pageCount]);
  
  const getBrandList = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
          'staff-product-list?' +
          'search=' +
          searchClient +
          '&page=' +
          itemOffset,

        config
      )
      .then(
        (value) =>
          setClientData(value.data.response.data) &
          setPageCount(value.data.response.last_page) &
          setLoading(false)
      )
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (clientData) {
      setResponse(clientData);
    }
  }, [clientData, searchClient]);

  const handleDateChange = (date) => {
    // If selectedDate2 is set and is before the new selected date, reset both dates
    if (selectedDate2 && date >= selectedDate2) {
      setSelectedDate(null);
      setSelectedDate2(null);
    } else {
      setSelectedDate(date);
    }
  };

  const handleDateChange2 = (date) => {
    // If selectedDate is set and is after the new selected date, reset both dates
    if (selectedDate && date <= selectedDate) {
      setSelectedDate(null);
      setSelectedDate2(null);
    } else {
      setSelectedDate2(date);
    }
  };

  const StaffChangeHandler = (event) => {
    setId(event.target.value);
  };
  // function for search
  const handleKeyPress = () => {
    setItemOffset(0);
    setSearchClientByName(searchClientByName);
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    setPageNumber(event.selected )
    setItemOffset(newOffset);
  };
  return (
    <div>
      <div>
        <Constant>
          <Header onpage_link="My Products" heading="My Products" />

          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div style={{ display: 'flex', paddingLeft: '10px' }}>
                <div className="searchbar_border" style={{ marginTop: '20px' }}>
                  <div
                    className="search-field d-none d-md-block"
                    style={{ marginBottom: '13px' }}
                  >
                    <div
                      className="d-flex align-items-center h-100 searchbar_parent"
                      action="#"
                    >
                      <div
                        className="input-group merchant_search_bar border_radius searchbar_size"
                        style={{
                          borderWidth: 1,
                          borderRadius: 5
                        }}
                      >
                        <div className="input-group-prepend bg-transparent"></div>
                        <input
                          style={{
                            height: 30
                          }}
                          onChange={(e) =>
                            setSearchClient(e.target.value) & handleKeyPress()
                          }
                          type="text"
                          className="form-control bg-transparent border-0"
                          placeholder="Search"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label>From</label>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Select a date"
                  onKeyDown={(e) => e.key === 'Backspace' && e.preventDefault()}
                  maxDate={new Date()}
                  isClearable={true}
                  yearDropdown
                  scrollableYearDropdown
                  showYearDropdown={true}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label>To</label>
                <DatePicker
                  selected={selectedDate2}
                  onChange={handleDateChange2}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Select a date"
                  onKeyDown={(e) => e.key === 'Backspace' && e.preventDefault()}
                  maxDate={new Date()}
                  isClearable={true}
                  yearDropdown
                  scrollableYearDropdown
                  showYearDropdown={true}
                />
              </div> */}
                {/* <select
                onChange={StaffChangeHandler}
                id="select1"
                class="ct_listing border_radius ct_listing_float ct_listing_margin "
                style={{
                  borderWidth: '1px',
                  borderRadius: '8px',
                  width: '190px',
                  marginBottom: '13px',
                  borderColor: '0.5px solid black',
                  marginTop: '20px'
                }}
              >
                {clientLists.map((e) => {
                  return <option value={e.id}>{e.contact_person_name_0}({e.contact_person_email_0})</option>
                })}
                <option value="">Select Client</option>
              </select> */}
              </div>

              <div className="col-xxl-12 mb-5 mb-xl-10">
                <div className="card card-flush h-xl-100">
                  {loading ? (
                    <div
                      className="spinner-container"
                      style={{ alignSelf: 'center' }}
                    >
                      <ClipLoader
                        color={'#50CD89'}
                        loading={loading}
                        css={override}
                        size={60}
                      />
                    </div>
                  ) : (
                    <div className="">
                      {/*begin::Tab content*/}
                      <div className="tab-content">
                        <div className=" mb-5 mb-xl-8">
                          {/*begin::Header*/}

                          {/*end::Header*/}
                          {/*begin::Body*/}
                          <div className="card-body py-3">
                            {/*begin::Table container*/}
                            <div className="table-responsive">
                              {/*begin::Table*/}
                              <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                                {/*begin::Table head*/}
                                <thead>
                                  <tr className="fw-bold text-muted">
                                    <th className="min-w-150px">Brand Name</th>
                                    <th className="min-w-140px">
                                      Product Name
                                    </th>
                                    <th className="min-w-120px">Category</th>
                                    <th className="min-w-120px">
                                      Sub Category
                                    </th>
                                  </tr>
                                </thead>
                                {/*end::Table head*/}
                                {/*begin::Table body*/}

                                <tbody>
                                  {clientData.map((e) => {
                                    return (
                                      <tr>
                                        <td>{e.product_brand.bname}</td>
                                        <td>
                                          {e.product_name && e.product_name
                                            ? e.product_name
                                            : ''}
                                        </td>
                                        <td>
                                          {e?.product_category?.name &&
                                          e?.product_category?.name
                                            ? e?.product_category?.name
                                            : ''}
                                        </td>
                                        <td>
                                          {e?.product_sub_category?.name
                                            ? e?.product_sub_category?.name
                                            : ''}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                                {/*end::Table body*/}
                              </table>
                              <ReactPaginate
                                previousLabel="Previous"
                                nextLabel="Next"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                containerClassName="pagination"
                                activeClassName="active"
                                onPageChange={handlePageClick}
                                forcePage={pageNumber}
                              />

                              {/*end::Table*/}
                            </div>
                            {/*end::Table container*/}
                          </div>
                          {/*begin::Body*/}
                        </div>

                        {/*begin::Tab pane*/}

                        {/*end::Tab pane*/}
                        {/*begin::Tab pane*/}

                        {/*end::Tab pane*/}
                      </div>
                      {/*end::Tab content*/}
                    </div>
                  )}

                  {/*end::Body*/}
                </div>
                {/*end::Chart widget 8*/}
              </div>
            </div>
          </div>
        </Constant>
      </div>
    </div>
  );
};

export default ManageProducts;
