import React, { useState, useEffect } from "react";
import Constant from "../../Constant/UserLayout";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import Url from "../../Elements/Api/Api";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import Modal from "../ManageLeads/Modal";
import Header from "../../Constant/Header";

const AddDailyStatusReport = () => {
  const [dailyData, setDailyData] = useState([
    {
      
      task: "",
      report_time: "",
      remarks: "",
      status: "",
      client_name: "", 
    },
  ]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [addStatusReport, setAddStatusReport] = useState([]);
  const [statusReport, setStatusReport] = useState(['']);
  const [description, setDescription] = useState('');
  const [error, setError] = useState([]);
  const [selectedStage, setSelectedStage] = useState('');

  const [stage, setStage] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [contactPerson, setContactPerson] = useState("");
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isNestedUpdateModalOpen, setIsNestedUpdateModalOpen] = useState(false);
  
  const [emptyerror, setemptyError] = useState('');
  const closeNestedUpdateModal = () => setIsNestedUpdateModalOpen(false);

  const closeUpdateModal = () => setIsUpdateModalOpen(false);
  const addReport = () => {
    const newData = dailyData.map((entry) => ({
      client_name: entry?.contactPerson?.label,
      task: entry.task,
      report_time: entry.report_time,
      remarks: entry.remarks,
      status: entry.status,
      report_date: currentDate,
    }));

    // newData now contains an array of objects combining contactPerson and dailyData.task
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const params = {
      daily: newData,
    };
    axios
      .post(Url.BASEURL + "addreport", params, config)
      .then((value) => {
        if (value.data.status === 200) {
          setAddStatusReport(value.data);
          setIsNestedUpdateModalOpen(true);

          // navigate('/daily_task_report');
        } else {
          setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  };

  // api for stage data
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(Url.BASEURL + "leadstages", config)
      .then((value) => setStage(value.data.response))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(Url.BASEURL + "staffownclients?", config)
      .then((value) => {
        setClientOptions(value.data.response);

      })
      .catch((err) => console.log(err));
  }, []);


  // const handleAddFields = () => {
  //   const updatedData = [...dailyData];
  //   updatedData.push({
  //     description: '',
  //     contact_name: '',
  //     status: ''
  //   });
  //   setContactPerson('');

  //   setDailyData(updatedData);
  // };



  // const handleAddFields = () => {

  //   const updatedData = [...dailyData];

  //   // Check if all fields in the last entry are empty
  //   const lastEntry = updatedData[updatedData.length - 1];
  //   if (
  //     lastEntry.task.trim() === '' ||
  //     lastEntry.report_time.trim() === '' ||
  //     lastEntry.remarks.trim() === ''
  //     // lastEntry.client_name == ''
  //   ) {
  //     // If any field is empty, show an error or handle the error as needed
  //     setemptyError('Please fill all fields');
  //     return false;
  //   }

  //   // All fields are filled, proceed to add a new entry
  //   updatedData.push({
  //     task: '',
  //     report_time: '',
  //     remarks: '',
  //     status: '',
  //     client_name: '' // Include the contactPerson property
  //   });
  //   setDailyData(updatedData);
  //   // Reset the contactPerson state to an empty value for the new entry
  //   setContactPerson('');
  //   return true;
  // };




  //*(j)
  const handleAddFields = () => {
    const updatedData = [...dailyData];

    // Check if all fields in the last entry are empty
    const lastEntry = updatedData[updatedData.length - 1];

    // Object to hold error messages for each field
    const errorMessages = {
      task: '',
      report_time: '',
      remarks: '',
      contactPerson: ''
    };



    if (lastEntry.task.trim() === '') {
      errorMessages.task = 'Task field is required';
    }

    if (lastEntry.report_time.trim() === '') {
      errorMessages.report_time = 'Report time field is required';
    }

    if (lastEntry.remarks.trim() === '') {
      errorMessages.remarks = 'Remarks field is required';
    }

    if (!lastEntry.contactPerson) {
      errorMessages.contactPerson = 'Name field is required';
    }

    // Check if any field has an error message
    if (
      errorMessages.task !== '' ||
      errorMessages.report_time !== '' ||
      errorMessages.remarks !== '' ||
      errorMessages.contactPerson !== ''

    ) {
      // If any field is empty, set the error messages
      setemptyError(errorMessages);
      return false;
    }

    // Clear any existing errors
    setemptyError({});
    return true;
  };



  const handleRemoveFields = (index) => {
    if (dailyData.length === 1) {
      return; // Don't remove the last input field
    }

    const updatedData = [...dailyData];
    updatedData.splice(index, 1);
    setDailyData(updatedData);
  };

  const handleInputChange = (e, index, fieldName) => {
    const updatedData = [...dailyData];
    updatedData[index][fieldName] = e.target.value;
    setDailyData(updatedData);
  };

  //  function for stage selection
  const handlestageChange = (e, index) => {
    const updatedStages = [...selectedStage];
    updatedStages[index] = e.target.value;
    setSelectedStage(updatedStages);
  };

  const transformClientToOptions = (data) => {
    const selectOption = { value: "", label: "Select" };
    const dataOptions = data.map(({ id, organisation }) => ({
      value: id.toString(),
      label: `${organisation}`,
    }));
    return [selectOption, ...dataOptions];
  };
  const clientDataOption = transformClientToOptions(clientOptions);
  const closeAllUpdateModals = () => {
    closeNestedUpdateModal();
    closeUpdateModal();
  };

  // function for current date format
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  var currentDate = `${day}-${month}-${year}`;

  const currentDayDate = () => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    currentDate = `${day}-${month}-${year}`;
  };

  

  // console.log("_____________________________", error);


  const submittaskhandler = () => {
    const success = handleAddFields(); // Check if handleAddFields was successful
    if (success) { // If successful, open the modal
      setIsUpdateModalOpen(true);
    }
  };



  return (
    <div>
      <Header />
      <Constant>
        <Header
          onpage_link="Status Report"
          heading="Daily Status Report"
          btn_path="addlead"
        />
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >

            <form>
              {dailyData.map((entry, index) => (
                <div key={index} className="mt-3">
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* <div className="col-3">
                      <label>Contact Name</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter contact Name"
                        value={entry.client_name}
                        onChange={(e) =>
                          handleInputChange(e, index, 'client_name')
                        }
                      />
                    </div> */}
                    {/* <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap"> */}
                    <div className="col-3" style={{ marginBottom: 5 }}>
                      <label
                        // htmlFor={`contactPersonEmail${index}`}
                        className="d-flex align-items-center fs-6 fw-semibold mb-2"
                      >
                        <span className="required">Name</span>
                      </label>

                      {/* {labelText === 'New' ? ( */}
                      {/* <ReactSelect
                        // options={clientOptions}
                        options={clientDataOption}
                        value={contactPerson}
                        onChange={(newSelectedOption) => {
                          setContactPerson(newSelectedOption);
                        }}
                      /> */}
                      <ReactSelect
                        options={clientDataOption}
                        value={entry.contactPerson}
                        onChange={(newSelectedOption) => {
                          const updatedData = [...dailyData];
                          updatedData[index].contactPerson = newSelectedOption;
                          setDailyData(updatedData);
                          if (!newSelectedOption) {


                            setemptyError('Name field is required');
                          } else {
                            setemptyError('');
                          }
                        }}
                      />

                      {emptyerror.contactPerson && <div style={{ color: 'red' }}>{emptyerror.contactPerson}</div>}


                      {error && Object.keys(error).length > 0 && (
                        <div style={{ color: "red" }}>
                          {error["daily.0.client_name"] && (
                            <p>{error["daily.0.client_name"]}</p>
                          )}
                        </div>
                      )}

                      {/* ) : (
                                  <Form.Control
                                    disabled={
                                      labelText === 'New' ? true : false
                                    }
                                    // options={clientOptions}
                                    // value={data.owner_name}
                                    name="owner_name"
                                    // onChange={(event) => handleChange(index, event)}
                                    inputValue={inputValue}
                                    placeholder="Enter Name"
                                  />
                                )} */}
                    </div>
                    {/* </div> */}
                    <div className="col-3">
                      <label className="required">Task</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Task"
                        value={entry.task}
                        onChange={(e) => handleInputChange(e, index, "task")}
                      />

                      {emptyerror.task && <div style={{ color: 'red' }}>{emptyerror.task}</div>}

                      {error && Object.keys(error).length > 0 && (
                        <div style={{ color: "red" }}>
                          {error["daily.0.task"] && (
                            <p>{error["daily.0.task"]}</p>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="col-3">
                      <label className="required">Enter Spent Time</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Spent Time"
                        value={entry.report_time}
                        onChange={(e) =>
                          handleInputChange(e, index, "report_time")
                        }
                      />

                      {emptyerror.report_time && <div style={{ color: 'red' }}>{emptyerror.report_time}</div>}

                      {error && Object.keys(error).length > 0 && (
                        <div style={{ color: "red" }}>
                          {error["daily.0.report_time"] && (
                            <p>{error["daily.0.report_time"]}</p>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="col-3">
                      <label className="required">Remarks</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Remarks"
                        value={entry.remarks}
                        onChange={(e) => handleInputChange(e, index, "remarks")}
                      />

                      {emptyerror.remarks && <div style={{ color: 'red' }}>{emptyerror.remarks}</div>}

                      {error && Object.keys(error).length > 0 && (
                        <div style={{ color: "red" }}>
                          {error["daily.0.remarks"] && (
                            <p>{error["daily.0.remarks"]}</p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {dailyData.length > 1 && (
                    <i
                      style={{
                        fontSize: "21px",
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                      className="fa-solid text-danger fa-circle-minus fa-2xl"
                      onClick={() => handleRemoveFields(index)}
                    ></i>
                  )}
                </div>
              ))}
              <i
                style={{ fontSize: "21px", marginTop: "10px" }}
                class="fa-solid text-success fa-circle-plus fa-2xl"
                onClick={handleAddFields}
              ></i>
            </form>
            <Button
              className="mt-5"
              variant="primary"
              onClick={submittaskhandler}
              // onClick={addReport}
              style={{ width: '11%', marginBottom: 10 }}

            >
              Submit
            </Button>
          </div>

          {isUpdateModalOpen && (
            <Modal
              style={{ display: "block" }}
              isOpen={isUpdateModalOpen}
              onClose={closeUpdateModal}
            >
              <div style={{ alignSelf: "center" }}>
                {/* <h1 style={{alignItems:'center'}}>Are You sure</h1> */}
                <h2> {!dailyData ? "nop data" : "Are You sure"} </h2>
                <h4>You want to Add this report</h4>
                <button
                  style={{ marginTop: "30px" }}
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setIsUpdateModalOpen(false)}
                // disabled={isButtonDisabled}
                >
                  Cancel
                </button>
                <button
                  style={{ marginTop: "30px", marginLeft: "20px" }}
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    // DeleteProduct()
                    // alert('frontend');
                    // leadSubmit();
                    addReport();
                    setIsUpdateModalOpen(false);
                  }}
                // }}
                // disabled={isButtonDisabled}
                >
                  Add
                </button>
              </div>
            </Modal>
          )}

          {isNestedUpdateModalOpen && (
            <Modal
              isOpen={isNestedUpdateModalOpen}
              onClose={closeAllUpdateModals}
              >
               <h2>Report Added successfully </h2>

              <button
                style={{ marginTop: "10px" }}
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  closeAllUpdateModals();
                  navigate("/daily_task_report");
                }}
              >
                OK
              </button>
            </Modal>
          )}
        </div>
      </Constant>
    </div>
  );
};

export default AddDailyStatusReport;