// import React, { useState, useEffect } from 'react';
// import Constant from '../../Constant/UserLayout';
// import Header from '../../Constant/Header';
// import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import Url from '../../Elements/Api/Api';
// import ReactPaginate from 'react-paginate';
// import { Puff } from 'react-loader-spinner';
// import axios from 'axios';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { format } from 'date-fns';
// import { ClipLoader } from 'react-spinners';
// import { css } from '@emotion/react';

// const TeamAttendance = () => {
//   const [searchStaffByName, setSearhStaffByName] = useState('');
//   const [searchClient, setSearchClient] = useState('');
//   const [searchClientByName, setSearchClientByName] = useState('');
//   const [itemOffset, setItemOffset] = useState(1);
//   const [clientData, setClientData] = useState([]);
//   const [pageCount, setPageCount] = useState(1);
//   const [deleteRow, setDeleteRow] = useState([]);
//   const [response, setResponse] = useState([]);
//   // const [isLoading, setIsLoading] = useState(false);

//   const [isArrowObj, setIsArrowObj] = useState({
//     column: 'staff_name',
//     orderby: 'asc'
//   });
//   const [loading, setLoading] = useState(false);
//   const override = css`
//     display: block;
//     margin: 0 auto;
//   `;

//   // State for the date selection
//   const [id, setId] = useState('');
//   const [stafLists, setStafLists] = useState([]);
//   const [attendanceList, setAttendanceList] = useState([])
//   const [selectedDate, setSelectedDate] = useState();
//   const [selectedDate2, setSelectedDate2] = useState();
//   const [isMarked, setIsMarked] = useState('');

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };
//   const handleDateChange2 = (date) => {
//     setSelectedDate2(date);
//   };

//   const formattedDate = selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '';
//   const formattedDate2 = selectedDate2
//     ? format(selectedDate2, 'dd/MM/yyyy')
//     : '';

//   const StaffChangeHandler = (event) => {
//     setId(event.target.value);
//   };
//   //GET Attendance
//   const handleGetAttendance = () => {
//     // Your logic for getting attendance based on searchInput and selectedMonth
//     // Add your code here
//   };

//   // Function to toggle between marked and unmarked states
//   const handleChangeMark = (event) => {
//     setIsMarked(event.target.value);
//   };

//   // function to get listing of staff
//   useEffect(() => {
//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`
//       }
//     };
//     axios
//       .get(Url.BASEURL + 'staffnameunderteammanager', config)
//       .then(
//         (value) => {
//           console.log('API RESPONSE', value.data.response);
//           setStafLists(value.data.response)
//         }

//         // setPageCount(value.data.response.last_page)
//       )
//       .catch((err) => console.log(err));
//   }, []);

//   const token = useSelector((state) => state.token);
//   const name = useSelector((state) => state.name);
//   // console.log(token, '?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');

//   //get all attendance
//   useEffect(() => {
//     getAllAttendance();
//   }, [
//     itemOffset,
//     deleteRow,
//     id,
//     formattedDate,
//     formattedDate2,
//     isMarked,
//     isArrowObj
//   ]);
//   const getAllAttendance = () => {
//     setLoading(true)
//     const config = {
//       headers: {
//         Authorization: `Bearer ${token}`
//       }
//     };
//     axios
//       .get(Url.BASEURL + `getStaffAttendence?`
//         + '&page='
//         + itemOffset
//         + '&staff_id='
//         + id
//         + '&from='
//         + formattedDate
//         + '&to='
//         + formattedDate2
//         + '&ismarked='
//         + isMarked
//         + '&orderby=' +
//         isArrowObj.column +
//         '&sortby=' +
//         isArrowObj.orderby
//         , config)
//       .then((value) =>
//         setAttendanceList(value.data.response.data) &
//         setPageCount(value.data.response.last_page)&
//         setLoading(false)
//       )
//       .catch((err) => console.log(err));
//   }
//   console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> attendancelist', attendanceList);
//   useEffect(() => {
//     if (attendanceList) {
//       setResponse(attendanceList);
//       console.log('attenfance', attendanceList);
//     }
//   }, [attendanceList, id, formattedDate, formattedDate2, deleteRow, isMarked]);

//   // function for pagination
//   const handlePageClick = (event) => {
//     const newOffset = event.selected + 1;
//     console.log('newOffset', newOffset);

//     setItemOffset(newOffset);
//   };

//   const handleArrowClick = (param, orderby) => {
//     if (isArrowObj && isArrowObj.column == param) {
//       orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
//     } else {
//       orderby = orderby == 'asc' ? 'desc' : 'asc';
//     }
//     const isArrowobjData = {
//       column: param,
//       orderby: orderby
//     };
//     setIsArrowObj(isArrowobjData);
//   };
//   return (
//     <div>
//       <Constant>
//         <Header
//           onpage_link="Team's Attendance"
//           heading="Team's Attendance"
//           btn_path=""
//         />
//         <div id="kt_app_content" className="app-content flex-column-fluid">
//           <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
//             <select
//               onChange={StaffChangeHandler}
//               id="select1"
//               class="ct_listing border_radius ct_listing_float ct_listing_margin "
//               style={{
//                 borderWidth: '1px',
//                 borderRadius: '8px',
//                 width: '190px',
//                 marginBottom: '13px',
//                 borderColor: '0.5px solid black',
//                 marginTop: '20px'
//               }}
//             >
//               {stafLists.map((e) => {
//                 return <option value={e.id}>{e.name}</option>;
//               })}
//               <option value="">Select Staff</option>
//               {console.log('id', id)}
//             </select>
//             <div style={{ display: 'flex', flexDirection: 'column' }}>
//               <label>From</label>
//               <DatePicker
//                 selected={selectedDate}
//                 onChange={handleDateChange}
//                 dateFormat="dd-MM-yyyy"
//                 placeholderText="Select a date"
//                 onKeyDown={(e) => e.key === 'Backspace' && e.preventDefault()}
//                 maxDate={new Date()}
//                 isClearable={true}
//                 yearDropdown
//                 scrollableYearDropdown
//                 showYearDropdown={true}
//               />
//             </div>
//             <div style={{ display: 'flex', flexDirection: 'column' }}>
//               <label>To</label>
//               <DatePicker
//                 selected={selectedDate2}
//                 onChange={handleDateChange2}
//                 dateFormat="dd-MM-yyyy"
//                 placeholderText="Select a date"
//                 isClearable={true}
//                 onKeyDown={(e) => e.key === 'Backspace' && e.preventDefault()}
//                 maxDate={new Date()}
//                 yearDropdown
//                 scrollableYearDropdown
//                 showYearDropdown={true}
//               />
//             </div>
//             <select
//               onChange={handleChangeMark}
//               id="select1"
//               class="ct_listing border_radius ct_listing_float ct_listing_margin "
//               style={{
//                 borderWidth: '1px',
//                 borderRadius: '8px',
//                 width: '190px',
//                 marginBottom: '13px',
//                 borderColor: '0.5px solid black',
//                 marginTop: '20px'
//               }}

//             >
//               <option value="">Marked/Unmarked</option>
//               <option value="marked">Marked</option>
//               <option value="unmarked">Unmarked</option>
//               {console.log('id', id)}
//             </select>

//           </div>
//           <div
//             id="kt_app_content_container"
//             className="app-container container-fluid"
//           >
//             <div className="col-xxl-12 mb-5 mb-xl-10">
//               <div className="card card-flush h-xl-100">
//               {loading ? (
//                   <div
//                     className="spinner-container"
//                     style={{ alignSelf: 'center' }}
//                   >
//                     <ClipLoader
//                       color={'#50CD89'}
//                       loading={loading}
//                       css={override}
//                       size={60}
//                     />
//                   </div>
//                 ):
//                 <div className="">
//                   {/*begin::Tab content*/}
//                   <div className="tab-content">
//                     <div className=" mb-5 mb-xl-8">
//                       {/*begin::Body*/}
//                       <div className="card-body py-3">
//                         {/*begin::Table container*/}
//                         <div className="table-responsive">
//                           {/*begin::Table*/}
//                           <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
//                             {/*begin::Table head*/}
//                             <thead>
//                               <tr className="fw-bold text-muted">
//                                 <th className="min-w-150px text-center">
//                                   {' '}
//                                   <span style={{ marginRight: '3px' }}>
//                                     <i
//                                       onClick={() =>
//                                         handleArrowClick('name', 'asc')
//                                       }
//                                       class={
//                                         isArrowObj &&
//                                           isArrowObj.column == 'name' &&
//                                           isArrowObj.orderby == 'desc'
//                                           ? 'fa-solid fa-arrow-down'
//                                           : 'fa-solid fa-arrow-up'
//                                       }
//                                       style={{}}
//                                     ></i>
//                                   </span>
//                                   Team Member Name
//                                 </th>
//                                 <th className="min-w-120px text-center">
//                                   {' '}
//                                   <span style={{ marginRight: '3px' }}>
//                                    email
//                                   </span>

//                                 </th>
//                                 <th className="min-w-150px text-center">
//                                   {' '}
//                                   <span style={{ marginRight: '3px' }}>
//                                     <i
//                                       onClick={() =>
//                                         handleArrowClick('created_at', 'asc')
//                                       }
//                                       class={
//                                         isArrowObj &&
//                                           isArrowObj.column == 'created_at' &&
//                                           isArrowObj.orderby == 'desc'
//                                           ? 'fa-solid fa-arrow-down'
//                                           : 'fa-solid fa-arrow-up'
//                                       }
//                                       style={{}}
//                                     ></i>
//                                   </span>
//                                   Date
//                                 </th>

//                                 <th className="min-w-140px text-center">
//                                   {' '}
//                                   <span style={{ marginRight: '3px' }}>
//                                   </span>
//                                   Location
//                                 </th>
//                                 <th className="min-w-120px text-center">
//                                   {' '}
//                                   <span style={{ marginRight: '3px' }}>
//                                     {isMarked ? isMarked : 'Marked/Unmarked'}
//                                   </span>

//                                 </th>
//                               </tr>
//                             </thead>
//                             {/*end::Table head*/}
//                             {/*begin::Table body*/}

//                             <tbody>
//                               {attendanceList.length == 0?
//                                  <tr>
//                                  <td colSpan="5" className="text-center">
//                                    No Record found
//                                  </td>
//                                </tr>:
//                                 attendanceList.map((staff, index) => (
//                                   <tr key={index} className="text-center">
//                                     <td>{staff.staff_name}</td>
//                                     <td>{staff.email}</td>
//                                     <td>{staff.date}</td>
//                                     <td>{staff.city_name}</td>
//                                     <td>
//                                       {staff.status === 'Marked' ? 'Marked' : 'Unmarked'}
//                                     </td>
//                                   </tr>
//                                 ))}

//                             </tbody>
//                             {/*end::Table body*/}
//                           </table>
//                           <ReactPaginate
//                             previousLabel="Previous"
//                             nextLabel="Next"
//                             pageClassName="page-item"
//                             pageLinkClassName="page-link"
//                             previousClassName="page-item"
//                             previousLinkClassName="page-link"
//                             nextClassName="page-item"
//                             nextLinkClassName="page-link"
//                             breakLabel="..."
//                             breakClassName="page-item"
//                             breakLinkClassName="page-link"
//                             pageCount={pageCount}
//                             marginPagesDisplayed={2}
//                             pageRangeDisplayed={5}
//                             containerClassName="pagination"
//                             activeClassName="active"
//                             onPageChange={handlePageClick}
//                           />
//                           {/*end::Table*/}
//                         </div>
//                         {/*end::Table container*/}
//                       </div>
//                       {/*begin::Body*/}
//                     </div>

//                     {/*begin::Tab pane*/}

//                     {/*end::Tab pane*/}
//                     {/*begin::Tab pane*/}

//                     {/*end::Tab pane*/}
//                   </div>
//                   {/*end::Tab content*/}
//                 </div>}
//                 {/*end::Body*/}
//               </div>
//               {/*end::Chart widget 8*/}
//             </div>
//           </div>
//         </div>

//       </Constant>
//     </div>
//   );
// };

// export default TeamAttendance;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Url from '../../Elements/Api/Api';
import { Link } from 'react-router-dom';
import Constant from '../../Constant/UserLayout';
import { useSelector } from 'react-redux';
import Header from '../../Constant/Header';

const TeamAttendance = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // January is 0
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const currentYear = currentDate.getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [data, setData] = useState([]);
  const token = useSelector((state) => state.token);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
          'list-attendance-TeamManagerStaff?' +
          'month=' +
          selectedMonth +
          '&' +
          'year=2024',
        config
      )
      .then((value) => setData(value.data))
      .catch((err) => console.log(err));
  }, [selectedMonth]);

  // Example: Get the total number of days in February 2024
  // const getDaysInMonth = (year, month) => {
  //   return new Date(year, month + 1, 0).getDate();
  // };
  const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };
  const year = 2024;
  const month = 1; // Note: Months in JavaScript are zero-indexed, so 1 represents February
  // const totalDays = getDaysInMonth(year, month);

  // // Generate an array of table headings
  // const tableHeadings = Array.from(
  //   { length: totalDays },
  //   (_, index) => index + 1
  // );
  const totalDays = getDaysInMonth(selectedYear, selectedMonth);
  const tableHeadings = Array.from(
    { length: totalDays },
    (_, index) => index + 1
  );
  // array for mnonths of the year
  const months = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' }
  ];


  // Now you can use the `day` variable globally

  // Event handler for month change
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  return (
    <div>
      <Constant>
        {/* <BreadScrum
          btn_name="Add Staff"
          routepath={'addstaff'}
          heading={'Manage Attendance'}
          //   btn={'Add Staff'}
          // bgColor={'#1E336A'}
          text_color={'#FFFFFF'}
          disabled={true}
        />{' '} */}
        <Header
          // btnName="Add Client"
          // onpage_link="Team's Clients"
          heading="Team's Attendance"
          // btn_path="addclient"
        />
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div id="kt_app_content_container" className="app-container container-fluid"  style={{marginBottom:'20px'}}>
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body ">
                  <div className="row">
                    <div className="col-md-6">
                      <p className="card-title mb-0 text-left mx-2 mt-1">
                        Team's Attendance
                      </p>
                    </div>
                    <div className="col-md-6 text-right d-flex justify-content-end">
                      <div className="card-title mx-2 mt-1"></div>
                      <select
                        className="col-md-4 filter form-control form-control-sm actdeact_prod mx-2"
                        id="months"
                        style={{ width: '25%' }}
                        value={selectedMonth}
                        onChange={handleMonthChange}
                      >
                        <option value="">Select Month</option>
                        {months?.map((month) => (
                          <option key={month.id} value={month.id}>
                            {month.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <form action="#" method="get">
                          <div className="table-responsive">
                            <table style={{ width: '100%' }}>
                              <thead>
                                <tr>
                                  <th className="text-left">Employee Name</th>
                                  <th className="text-left">Employee Email</th>

                                  {tableHeadings?.map((day) => (
                                    <th key={day}>{day}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {data?.length == 0 ? (
                                  <tr>
                                    <td
                                      colSpan="30"
                                      style={{
                                        fontSize: 16,
                                        marginTop: '10px',
                                        textAlign: 'center'
                                      }}
                                    >
                                      No Record found
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {data?.map((employee, index) => (
                                      <tr key={employee?.id}>
                                        <td>{employee?.name}</td>
                                        <td>{employee?.email}</td>

                                        {tableHeadings?.map((day, index) => (
                                          <td
                                            key={`${employee.id}-${day}`}
                                            className={
                                              employee?.status[day] === 'P'
                                                ? 'green'
                                                : 'red'
                                            }
                                          >
                                            {employee.status[day] === 'P' ? (
                                              <Link
                                                to={`/get_attendance_report/${
                                                  employee.id
                                                }?date=${
                                                  index + 1
                                                }&month=${selectedMonth}&year=${year}`}
                                                // to={`/get_attendance_report/${employee.id}&day=${
                                                //   index + 1
                                                // }&month=${selectedMonth}&year=${year}`}
                                                key={`${employee.id}-${day}`}
                                                className="green"
                                              >
                                                {employee.status[day]}
                                              </Link>
                                            ) : (
                                              employee.status[day]
                                            )}
                                          </td>
                                        ))}
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 grid-margin stretch-card">
                              <div className="template-demo"></div>
                            </div>
                          </div>
                          <div id="butns" />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Constant>
    </div>
  );
};

export default TeamAttendance;
