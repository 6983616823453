import React from 'react';
import Constant from '../../Constant/UserLayout';

const PageNotfound = () => {
  return (
    <div>
      <Constant>
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <img src="../dist/assets/media/logos/404Error.png"></img>
          </div>
        </div>
      </Constant>
    </div>
  );
};

export default PageNotfound;
