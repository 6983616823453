// import ReactDOM from "react-dom/client";
// import React from "react";
// import "./index.css";
// import App from "./App";
// import { store, persistor } from "./Elements/Redux/Store/Store";
// import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
// const root = ReactDOM.createRoot(document.getElementById("root"));

// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <App />
//       </PersistGate>
//     </Provider>
//   </React.StrictMode>
// );


//previous code fllowing react v-17


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store, persistor } from './Elements/Redux/Store/Store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <PersistGate  children={() => <App />} /> */}
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
