import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

import DataTable from 'react-data-table-component';
import Constant from '../../Constant/UserLayout';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Url from '../../Elements/Api/Api';
import ReactPaginate from 'react-paginate';
import { Puff } from 'react-loader-spinner';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BounceLoader, ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { fetchData } from '../../Constant/CommonApi';

import { format } from 'date-fns';
import Header from '../../Constant/Header';
const ManageClients = (props) => {
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  const [searchStaffByName, setSearhStaffByName] = useState('');
  const [searchClient, setSearchClient] = useState('');
  const [searchClientByName, setSearchClientByName] = useState('');
  const [itemOffset, setItemOffset] = useState(1);
  const [clientData, setClientData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [deleteRow, setDeleteRow] = useState([]);
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [otherData, setOtherData] = useState([]);
  const [clientLists, setClientLists] = useState([]);
  const [id, setId] = useState('');
  const [clientInfo, setIClientInfo] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [excelExport, setExportData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const [isArrowObj, setIsArrowObj] = useState({
    column: 'client_name',
    orderby: 'asc'
  });

  const token = useSelector((state) => state.token);
  const name = useSelector((state) => state.name);

  // State to manage whether the link should be disabled
  const [isLinkDisabled, setIsLinkDisabled] = useState(false);

  // Check the time difference in a useEffect hook

  const [selectedDate, setSelectedDate] = useState();
  const [selectedDate2, setSelectedDate2] = useState();

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [brandIdToDelete, setBrandIdToDelete] = useState(null);

  const handleDateChange = (date) => {
    // If selectedDate2 is set and is before the new selected date, reset both dates
    if (selectedDate2 && date >= selectedDate2) {
      setSelectedDate(null);
      setSelectedDate2(null);
    } else {
      setSelectedDate(date);
    }
  };

  const handleDateChange2 = (date) => {
    // If selectedDate is set and is after the new selected date, reset both dates
    if (selectedDate && date <= selectedDate) {
      setSelectedDate(null);
      setSelectedDate2(null);
    } else {
      setSelectedDate2(date);
    }
  };
  const formattedDate = selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '';
  const formattedDate2 = selectedDate2
    ? format(selectedDate2, 'dd/MM/yyyy')
    : '';

  useEffect(() => {
    getBrandList();
  }, [
    searchClient,
    itemOffset,
    deleteRow,
    formattedDate,
    formattedDate2,
    isArrowObj,
    id,
    pageCount
  ]);

  const getBrandList = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
        'manageclients?' +
        'search=' +
        searchClient +
        '&page=' +
        itemOffset +
        '&client_id=' +
        id +
        '&from=' +
        formattedDate +
        '&to=' +
        formattedDate2 +
        '&orderby=' +
        isArrowObj.column +
        '&sortby=' +
        isArrowObj.orderby,
        // '&orderby=' +
        // isArrowObj.column +
        // '&sortby=' +
        // isArrowObj.orderby,
        config
      )
      .then(
        (value) =>
          setClientData(value.data.response.data) &
          setPageCount(value.data.response.last_page) &
          setLoading(false)
      )
      .catch((err) => console.log(err));
  };
  // function for pagination
  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    setPageNumber(event.selected);
    setItemOffset(newOffset);
  };

  const formatDate = (dateString) => {
    const parsedDate = new Date(dateString);
    return format(parsedDate, 'dd MMM yyyy');
  };

  // function for search
  const handleKeyPress = () => {
    setItemOffset(0);
    setSearchClientByName(searchClientByName);
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'staffownclients?', config)
      .then(
        (value) =>
          setClientLists(value.data.response) &
          setPageCount(value.data.response.last_page)
      )
      .catch((err) => console.log(err));
  }, []);

  const StaffChangeHandler = (event) => {
    setId(event.target.value);
  };

  useEffect(() => {
  }, [formattedDate2]);

  // functio for current time
  useEffect(() => {
    // const interval = setInterval(() => {
    const time = new Date().toLocaleTimeString([], {
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit'
    });
    setCurrentTime(time);
    // }, 1000); // Update every 1 second (1000 milliseconds)

    // Clean up the interval to avoid memory leaks
    // const formattedTime = new Intl.DateTimeFormat('en-US', {
    //   hour: 'numeric',
    //   minute: 'numeric',
    //   second: 'numeric',
    //   hour12: true
    // }).format(currentTime);
    // return () => clearInterval(interval);
  }, []);
  // function for export data
  const exporData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL +
        'exportlead?' +
        'search=' +
        searchClient +
        '&page=' +
        itemOffset +
        '&client_id=' +
        id +
        '&from=' +
        formattedDate +
        '&to=' +
        formattedDate2,
        config,
        {
          method: 'GET',
          responseType: 'json', // important
          headers: {
            Accept: 'application/json'
          }
        }
      )
      .then((response) => {
        setExportData(response.data);
        window.open(response.data, '_self');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleArrowClick = (param, orderby) => {
    if (isArrowObj && isArrowObj.column == param) {
      orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
    } else {
      orderby = orderby == 'asc' ? 'desc' : 'asc';
    }
    const isArrowobjData = {
      column: param,
      orderby: orderby
    };
    setIsArrowObj(isArrowobjData);
  };

  return (
    <>
      <div>
        <Constant>
          <Header
            btnName="Add Client"
            onpage_link="My Clients"
            heading="My Clients"
            btn_path="addclient"
          // btnName2="Export Clientdata"
          // onClick={exporData}
          />
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div className='mx-10 p-0 mb-2' >
                <div className='d-flex justify-content-between'>
                  <div className="rounded-1" style={{ marginTop: '20px' }}>
                    <div
                      className="search-field d-none d-md-block"
                      style={{ marginBottom: '13px' }}
                    >
                      <div
                        className="d-flex align-items-center h-100 searchbar_parent"
                        action="#"
                      >
                        <div
                          className="input-group merchant_search_bar border_radius searchbar_size"
                          style={{
                            borderWidth: 1,
                            borderRadius: 5
                          }}
                        >
                          {/* <div className="input-group-prepend bg-transparent"></div> */}
                          <input
                            style={{
                              // height: 30,
                              padding: "8px"
                            }}
                            onChange={(e) =>
                              setSearchClient(e.target.value) & handleKeyPress()
                            }
                            type="text"
                            className="form-control bg-transparent border border-1 rounded-1 border-dark"
                            placeholder="Search"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>From</label>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Select a date"
                      onKeyDown={(e) => e.key === 'Backspace' && e.preventDefault()}
                      maxDate={new Date()}
                      isClearable={true}
                      yearDropdown
                      scrollableYearDropdown
                      showYearDropdown={true}
                      className='rounded-1'
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>To</label>
                    <DatePicker
                      selected={selectedDate2}
                      onChange={handleDateChange2}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Select a date"
                      onKeyDown={(e) => e.key === 'Backspace' && e.preventDefault()}
                      maxDate={new Date()}
                      isClearable={true}
                      yearDropdown
                      scrollableYearDropdown
                      showYearDropdown={true}
                      className='rounded-1'
                    />
                  </div>
                  <select
                    onChange={StaffChangeHandler}
                    id="select1"
                    class="ct_listing border_radius ct_listing_float ct_listing_margin rounded-1 "
                    style={{
                      borderWidth: '1px',
                      borderRadius: '8px',
                      width: '190px',
                      marginBottom: '13px',
                      borderColor: '0.5px solid black',
                      marginTop: '20px'
                    }}
                  >
                    {clientLists.map((e) => {
                      return (
                        <option value={e.id}>
                          {e.contact_person_name_0}({e.contact_person_email_0})
                        </option>
                      );
                    })}
                    <option value="">Select Client</option>
                  </select>
                </div>
              </div>
              <div className="col-xxl-12 mb-5 mb-xl-10">
                <div className="card clientinfo-box card-flush h-xl-100 mx-10 ">
                  {loading ? (
                    <div
                      className="spinner-container"
                      style={{ alignSelf: 'center' }}
                    >
                      <ClipLoader
                        color={'#50CD89'}
                        loading={loading}
                        css={override}
                        size={60}
                      />
                    </div>
                  ) : (

                    <div className="tab-content">
                      {/*begin::Table container*/}
                      <div className="table-responsive">
                        {/*begin::Table*/}
                        <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                          {/*begin::Table head*/}
                          <thead style={{ fontSize: '15px' }}>
                            <tr className="fw-bold text-muted text-start">
                              <th className="min-w-120px">
                                <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick(
                                        'organisation',
                                        'asc'
                                      )
                                    }
                                    class={
                                      isArrowObj &&
                                        isArrowObj.column ==
                                        'organisation' &&
                                        isArrowObj.orderby == 'desc'
                                        ? 'fa-solid fa-arrow-down'
                                        : 'fa-solid fa-arrow-up'
                                    }
                                  ></i>
                                </span>
                                Organisation
                              </th>

                              <th className="min-w-150px text-center">
                                <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick(
                                        'client_email',
                                        'asc'
                                      )
                                    }
                                    class={
                                      isArrowObj &&
                                        isArrowObj.column ==
                                        'client_email' &&
                                        isArrowObj.orderby == 'desc'
                                        ? 'fa-solid fa-arrow-down'
                                        : 'fa-solid fa-arrow-up'
                                    }
                                    style={{}}
                                  ></i>
                                </span>
                                Email
                              </th>

                              <th className="min-w-150px text-center">
                                <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick(
                                        'client_phone',
                                        'asc'
                                      )
                                    }
                                    class={
                                      isArrowObj &&
                                        isArrowObj.column ==
                                        'client_phone' &&
                                        isArrowObj.orderby == 'desc'
                                        ? 'fa-solid fa-arrow-down'
                                        : 'fa-solid fa-arrow-up'
                                    }
                                    style={{}}
                                  ></i>
                                </span>
                                Contact Number
                              </th>
                              <th className="min-w-150px text-center">
                                <span style={{ marginRight: '3px' }}>
                                  <i
                                    onClick={() =>
                                      handleArrowClick('', 'asc')
                                    }
                                    class={
                                      isArrowObj &&
                                        isArrowObj.column == '' &&
                                        isArrowObj.orderby == 'desc'
                                        ? 'fa-solid fa-arrow-down'
                                        : 'fa-solid fa-arrow-up'
                                    }
                                    style={{}}
                                  ></i>
                                </span>
                                Client Name
                              </th>

                              <th className="min-w-120px text-center">
                                <span style={{ marginRight: '3px' }}>
                                  Active/Not-Active
                                </span>
                              </th>
                              <th className="min-w-120px text-end">
                                <span style={{ marginRight: '3px' }}>
                                  Actions
                                </span>
                              </th>
                            </tr>
                          </thead>
                          {/*end::Table head*/}
                          {/*begin::Table body*/}
                          <tbody
                            style={{
                              fontSize: '14px',
                              fontWeight: '400'
                            }}
                          >
                            {clientData.length == 0 ? (
                              <tr className="text-center">
                                <td
                                  colSpan="9"
                                  style={{
                                    fontSize: 16,
                                    marginTop: '10px'
                                  }}
                                >
                                  No Record found
                                </td>
                              </tr>
                            ) : (
                              clientData.map((e, index) => {
                                // const joinTime = e.time;
                                const currentTimeStr = currentTime;
                                const joinTimeStr = e.time;

                                // Extract hours, minutes, and seconds from the time strings
                                const [
                                  currentHours,
                                  currentMinutes,
                                  currentSeconds
                                ] = currentTimeStr.split(':').map(Number);
                                const [
                                  joinHours,
                                  joinMinutes,
                                  joinSeconds
                                ] = joinTimeStr.split(':').map(Number);

                                // Calculate the time in seconds for both current time and join time
                                const currentTimeInSeconds =
                                  currentHours * 3600 +
                                  currentMinutes * 60 +
                                  currentSeconds;
                                const joinTimeInSeconds =
                                  joinHours * 3600 +
                                  joinMinutes * 60 +
                                  joinSeconds;

                                // Calculate the time difference in seconds
                                const timeDifferenceInSeconds =
                                  joinTimeInSeconds -
                                  currentTimeInSeconds;

                                // Convert the time difference to hours
                                const hoursDifference =
                                  timeDifferenceInSeconds / 3600;

                                // console.log(
                                //   'hoursDifference',
                                //   hoursDifference
                                // );

                                // Check if the time difference is more than 1 hour
                                if (Math.abs(hoursDifference) > 1) {

                                } else {

                                }
                                return (
                                  <tr key={index}>
                                    <td className='text-start'>{e?.organisation}</td>
                                    <td className='text-center'>
                                      {
                                        e.clientInfo
                                          ?.contact_person_email_0
                                      }
                                    </td>
                                    <td className='text-center'>
                                      {
                                        e.clientInfo
                                          ?.contact_person_mobile_0
                                      }
                                    </td>
                                    <td className='text-center'>
                                      {
                                        e.clientInfo
                                          ?.contact_person_name_0
                                      }
                                    </td>
                                    <td className='text-center'>
                                      {e?.enabled === 1
                                        ? 'Active'
                                        : 'Not-active'}
                                    </td>
                                    <td className='text-center'>
                                      <div className='d-flex align-items-center justify-content-center gap-3'>
                                        <Link
                                          to={'/clientinfo/' + e.id}
                                        >
                                          <i
                                            class="fa fa-eye fs-4"
                                            aria-hidden="true"
                                          ></i>
                                        </Link>
                                        {/* {e.created_by === 0 ||
                                          Math.abs(hoursDifference) >
                                          1 ? null : ( */}
                                          <Link
                                            to={'/updateclient/' + e.id}
                                          // className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                          >
                                            <i className="ki-duotone ki-pencil fs-2">
                                              <span className="path1" />
                                              <span className="path2" />
                                            </i>{' '}
                                          </Link>
                                        {/* )} */}
                                      </div>

                                    </td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>


                        {/*end::Table*/}
                      </div>
                      {/*end::Table container*/}
                    </div>
                  )}
                  {/*end::Body*/}
                </div>
                <div className='mx-10 p-1 mt-2'>
                  <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    containerClassName="pagination"
                    activeClassName="active"
                    onPageChange={handlePageClick}
                    forcePage={pageNumber}
                  />
                </div>

              </div>

            </div>

          </div>
        </Constant>
      </div>
    </>
  );
};

export default ManageClients;
