import DataTable from 'react-data-table-component';
import Constant from '../../Constant/UserLayout';
import { Link } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Url from '../../Elements/Api/Api';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { BounceLoader, ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import Header from '../../Constant/Header';

const ListDailyStatusReport = (props) => {
  const maxLength = 50;

  const override = css`
    display: block;
    margin: 0 auto;
  `;
  const [searchClient, setSearchClient] = useState('');
  const [itemOffset, setItemOffset] = useState(1);
  const [clientData, setClientData] = useState([]);
  const [searchClientByName, setSearchClientByName] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [deleteRow, setDeleteRow] = useState([]);
  const [response, setResponse] = useState([]);
  const [id, setId] = useState('');
  const [clientLists, setClientLists] = useState([]);
  const [quotation, setQuotation] = useState([]);
  const token = useSelector((state) => state.token);
  const [dailyStatusReport, setDailyStatusReport] = useState([]);
  const [stage, setStage] = useState([]);
  // start code for datepicker
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate2, setSelectedDate2] = useState('');
  const [isArrowObj, setIsArrowObj] = useState({
    column: '',
    orderby: 'asc'
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
  };
  const formattedDate = selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '';
  const formattedDate2 = selectedDate2
    ? format(selectedDate2, 'dd/MM/yyyy')
    : '';
  // end code for datepicker
  useEffect(() => {
    getBrandList();
  }, [
    searchClient,
    itemOffset,
    deleteRow,
    formattedDate,
    formattedDate2,
    id,
    isArrowObj,
    pageCount
  ]);
  const getBrandList = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
          'listreport?' +
          'search=' +
          searchClient +
          '&page=' +
          itemOffset +
          '&stage_id=' +
          id +
          '&from=' +
          formattedDate +
          '&to=' +
          formattedDate2 +
          '&orderby=' +
          isArrowObj.column +
          '&sortby=' +
          isArrowObj.orderby,
        config
      )
      .then(
        (value) =>
          setDailyStatusReport(value.data.response.data) &
          setPageCount(value.data.response.last_page) &
          setLoading(false)
      )
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (clientData) {
      setResponse(clientData);
    }
  }, [clientData, searchClient, deleteRow]);
  // function for pagination
  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    setPageNumber(event.selected);

    setItemOffset(newOffset);
  };

  // function for search
  const handleKeyPress = () => {
    setItemOffset(0);
    setSearchClientByName(searchClientByName);
  };
  const StaffChangeHandler = (event) => {
    setId(event.target.value);
  };

  // function for sorting data

  const handleArrowClick = (param, orderby) => {
    if (isArrowObj && isArrowObj.column == param) {
      orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
    } else {
      orderby = orderby == 'asc' ? 'desc' : 'asc';
    }
    const isArrowobjData = {
      column: param,
      orderby: orderby
    };
    setIsArrowObj(isArrowobjData);
  };
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'leadstages', config)
      .then((value) => setStage(value.data.response))
      .catch((err) => console.log(err));
  }, []);
  return (
    <div>
      <Constant>
        <Header
          btnName="Add Daily Status Report"
          onpage_link="Daily Status Report"
          heading="Daily Status Report"
          btn_path="add_daily_task_report"
        />
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div className="searchbar_border" style={{ marginTop: '20px' }}>
              <div
                className="search-field d-none d-md-block"
                style={{ marginBottom: '13px' }}
              >
                <div
                  className="d-flex align-items-center h-100 searchbar_parent"
                  action="#"
                >
                  <div
                    className="input-group merchant_search_bar border_radius searchbar_size"
                    style={{
                      borderWidth: 1,
                      borderRadius: 5
                    }}
                  >
                    <div className="input-group-prepend bg-transparent"></div>
                    <input
                      style={{
                        height: 30
                      }}
                      onChange={(e) =>
                        setSearchClient(e.target.value) & handleKeyPress()
                      }
                      type="text"
                      className="form-control bg-transparent border-0"
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label>From</label>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="dd-MM-yyyy"
                placeholderText="Select a date"
                // isClearable={true}
                yearDropdown
                scrollableYearDropdown
                showYearDropdown={true}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label>To</label>
              <DatePicker
                selected={selectedDate2}
                onChange={handleDateChange2}
                dateFormat="dd-MM-yyyy"
                placeholderText="Select a date"
                // isClearable={true}
                yearDropdown
                scrollableYearDropdown
                showYearDropdown={true}
              />
            </div>
          </div>
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="col-xxl-12 mb-5 mb-xl-10">
              <div className="card card-flush h-xl-100">
                {loading ? (
                  <div
                    className="spinner-container"
                    style={{ alignSelf: 'center' }}
                  >
                    <ClipLoader
                      color={'#50CD89'}
                      loading={loading}
                      css={override}
                      size={60}
                    />
                  </div>
                ) : (
                  <div className="">
                    {/*begin::Tab content*/}
                    <div className="tab-content">
                      <div className=" mb-5 mb-xl-8">
                        {/*begin::Body*/}
                        <div className="card-body py-3">
                          {/*begin::Table container*/}
                          <div className="table-responsive">
                            {/*begin::Table*/}
                            <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                              {/*begin::Table head*/}
                              <div></div>
                              <thead
                                style={{ fontSize: '15px' }}
                                className="text-center"
                              >
                                <tr className="fw-bold text-muted">
                                  <th className="min-w-150px">
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('created_at', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'created_at' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i>
                                    </span>
                                    Date
                                  </th>
                                  <th className="min-w-150px">
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('report_time', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'report_time' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i>
                                    </span>
                                    Time
                                  </th>
                                  <th className="min-w-150px">
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('task', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'task' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i>
                                    </span>
                                    Description
                                  </th>
                                  <th className="min-w-120px">
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick(
                                            'client_name',
                                            'asc'
                                          )
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'client_name' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                      ></i>
                                    </span>
                                    Organisation{' '}
                                  </th>
                                  <th className="min-w-150px">
                                    <span style={{ marginRight: '3px' }}>
                                      {/* <i
                                      onClick={() =>
                                        handleArrowClick(
                                          'owner_contact_no',
                                          'asc'
                                        )
                                      }
                                      class={
                                        isArrowObj &&
                                        isArrowObj.column ==
                                          'owner_contact_no' &&
                                        isArrowObj.orderby == 'desc'
                                          ? 'fa-solid fa-arrow-down'
                                          : 'fa-solid fa-arrow-up'
                                      }
                                      style={{}}
                                    ></i> */}
                                    </span>
                                    Remarks
                                  </th>

                                  <div style={{ display: 'flex' }}></div>
                                </tr>
                              </thead>
                              {/*end::Table head*/}
                              {/*begin::Table body*/}
                              <tbody
                                style={{ fontSize: '14px', fontWeight: '400' }}
                              >
                                {dailyStatusReport.length === 0 ? (
                                  <tr>
                                    <td
                                      colSpan="6"
                                      style={{ textAlign: 'center' }}
                                      className="text-center"
                                    >
                                      No Record found
                                    </td>
                                  </tr>
                                ) : (
                                  dailyStatusReport.map((e, index) => {
                                    return (
                                      <tr key={index} className="text-center">
                                        <td>{e.created_at}</td>
                                        <td>{e.report_time}</td>
                                        <td>{e.task}</td>
                                        <td>{e.client_name}</td>
                                        <td>{e.remarks}</td>
                                      </tr>
                                    );
                                  })
                                )}
                              </tbody>
                              {/*end::Table body*/}
                            </table>

                            <ReactPaginate
                              previousLabel="Previous"
                              nextLabel="Next"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              pageCount={pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              containerClassName="pagination"
                              activeClassName="active"
                              onPageChange={handlePageClick}
                              forcePage={pageNumber}
                            />
                            {/*end::Table*/}
                          </div>
                          {/*end::Table container*/}
                        </div>
                        {/*begin::Body*/}
                      </div>

                      {/*begin::Tab pane*/}

                      {/*end::Tab pane*/}
                      {/*begin::Tab pane*/}

                      {/*end::Tab pane*/}
                    </div>
                    {/*end::Tab content*/}
                  </div>
                )}
                {/*end::Body*/}
              </div>
              {/*end::Chart widget 8*/}
            </div>
          </div>
        </div>
      </Constant>
    </div>
  );
};

export default ListDailyStatusReport;
