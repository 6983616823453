import DataTable from 'react-data-table-component';
import Constant from '../../Constant/UserLayout';
import { Link } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Url from '../../Elements/Api/Api';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { BounceLoader, ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import Header from '../../Constant/Header';

const SalesReport = (props) => {
  const maxLength = 50;

  const override = css`
    display: block;
    margin: 0 auto;
  `;
  const [searchClient, setSearchClient] = useState('');
  const [itemOffset, setItemOffset] = useState(1);
  const [clientData, setClientData] = useState([]);
  const [searchClientByName, setSearchClientByName] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [deleteRow, setDeleteRow] = useState([]);
  const [response, setResponse] = useState([]);
  const [id, setId] = useState('');
  const [clientLists, setClientLists] = useState([]);
  const [quotation, setQuotation] = useState([]);
  const token = useSelector((state) => state.token);
  const [dailyStatusReport, setDailyStatusReport] = useState([]);
  const [stage, setStage] = useState([]);
  const [productData, setProductData] = useState([]);
  const [clientslist, setclientsList] = useState([]);
  const [clientId, setClientId] = useState('');
  const [totolValue, setTotalValue] = useState([]);
  // start code for datepicker
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate2, setSelectedDate2] = useState('');
  const [isArrowObj, setIsArrowObj] = useState({
    column: '',
    orderby: 'desc'
  });
  const [monthId, setMonthId] = useState('');
  const [yearId, setYearId] = useState('');
  const staffId = useSelector((item) => item.staffId);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
  };
  const formattedDate = selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '';
  const formattedDate2 = selectedDate2
    ? format(selectedDate2, 'dd/MM/yyyy')
    : '';
  // end code for datepicker
  useEffect(() => {
    getBrandList();
  }, [
    searchClient,
    itemOffset,
    deleteRow,
    formattedDate,
    formattedDate2,
    id,
    clientId,
    isArrowObj,
    yearId,
    monthId,
    pageCount
  ]);
  const getBrandList = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
        'product-sale-report-global_staff/' +
        staffId +
        '?search=' +
        searchClient +
        '&page=' +
        itemOffset +
        '&stage=' +
        id +
        '&client_id=' +
        clientId +
        (monthId !== '' ? '&month=' + monthId : '') +
        (yearId !== '' ? '&year=' + yearId : '') +
        (id !== '' ? '&stage=' + id : '') +
        '&orderby=' +
        isArrowObj.column +
        '&sortby=' +
        isArrowObj.orderby,

        config
      )
      .then((value) => {
        // if (value.data.status == 200) {
        setTotalValue(value.data.response.leads.data);
        setLoading(false);
        // } else {
        //   setTotalValue(value.data.response);
        // }

        setDailyStatusReport(
          value.data.response.leads.data[0].client_data.clientInfo
        );
        setPageCount(value.data.response.leads.last_page);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (clientData) {
      setResponse(clientData);
    }
  }, [clientData, searchClient, deleteRow]);
  // function for pagination
  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    setPageNumber(event.selected);


    setItemOffset(newOffset);
  };

  // function for search
  const handleKeyPress = () => {
    setItemOffset(0);
    setSearchClientByName(searchClientByName);
  };
  const StaffChangeHandler = (event) => {
    setId(event.target.value);
  };
  const MonthChangeHandler = (event) => {
    setMonthId(event.target.value);
  };

  const YearChangeHandler = (event) => {
    setYearId(event.target.value);
  };
  const clientChangeHandler = (event) => {
    setClientId(event.target.value);
  };

  // function for sorting data

  const handleArrowClick = (param, orderby) => {
    if (isArrowObj && isArrowObj.column == param) {
      orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
    } else {
      orderby = orderby == 'asc' ? 'desc' : 'asc';
    }
    const isArrowobjData = {
      column: param,
      orderby: orderby
    };
    setIsArrowObj(isArrowobjData);
  };
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'leadstages', config)
      .then((value) => setStage(value.data.response))
      .catch((err) => console.log(err));
  }, []);

  useEffect((e) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL + 'leadstages?',

        config
      )
      .then((value) => setProductData(value.data.response))
      .catch((err) => console.log(err));
  }, []);

  const MonthsOptions = [
    { value: '0', label: 'Select Month' },
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '12', label: 'November' },
    { value: '12', label: 'December' }
  ];

  const startYear = 2000;
  const endYear = 2040;

  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push({ value: year.toString(), label: year.toString() });
  }

  // function  to get clients listing
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'staffownclients', config)
      .then((value) => setClientLists(value.data.response))
      .catch((err) => console.log(err));
  }, []);
  return (
    <div>
      <Constant>
        <Header
          onpage_link="Manage Sales Report"
          heading="Manage Sales Report"
        />
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div className="searchbar_border" style={{ marginTop: '20px' }}>
              <div
                className="search-field d-none d-md-block"
                style={{ marginBottom: '13px' }}
              >
                <div
                  className="d-flex align-items-center h-100 searchbar_parent"
                  action="#"
                >
                  <div
                    className="input-group merchant_search_bar border_radius searchbar_size"
                    style={{
                      borderWidth: 1,
                      borderRadius: 5
                    }}
                  >
                    <div className="input-group-prepend bg-transparent"></div>
                    <input
                      style={{
                        height: 30
                      }}
                      onChange={(e) =>
                        setSearchClient(e.target.value) & handleKeyPress()
                      }
                      type="text"
                      className="form-control bg-transparent border-0"
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label>From</label>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="dd-MM-yyyy"
                placeholderText="Select a date"
                // isClearable={true}
                yearDropdown
                scrollableYearDropdown
                showYearDropdown={true}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label>To</label>
              <DatePicker
                selected={selectedDate2}
                onChange={handleDateChange2}
                dateFormat="dd-MM-yyyy"
                placeholderText="Select a date"
                // isClearable={true}
                yearDropdown
                scrollableYearDropdown
                showYearDropdown={true}
              />
            </div> */}
            <select
              onChange={clientChangeHandler}
              id="select1"
              class="ct_listing border_radius ct_listing_float ct_listing_margin "
              style={{
                borderWidth: '1px',
                borderRadius: '8px',
                width: '190px',
                marginBottom: '13px',
                borderColor: '0.5px solid black',
                marginTop: '20px'
              }}
            >
              {clientLists.map((e) => {
                return (
                  <option value={e.id}>
                    {e.contact_person_name_0}({e.contact_person_email_0})
                  </option>
                );
              })}
              <option value="">Select Client</option>
            </select>
            <select
              onChange={MonthChangeHandler}
              id="select1"
              class="ct_listing border_radius ct_listing_float ct_listing_margin "
              style={{
                borderWidth: '1px',
                borderRadius: '8px',
                width: '190px',
                marginBottom: '13px',
                borderColor: '0.5px solid black',
                marginTop: '20px'
              }}
            >
              {MonthsOptions.map((e) => {
                return <option value={e.value}>{e.label}</option>;
              })}
              <option value="">Select Month</option>
            </select>

            <select
              onChange={YearChangeHandler}
              id="select1"
              class="ct_listing border_radius ct_listing_float ct_listing_margin "
              style={{
                borderWidth: '1px',
                borderRadius: '8px',
                width: '190px',
                marginBottom: '13px',
                borderColor: '0.5px solid black',
                marginTop: '20px'
              }}
            >
              <option value="">Select Year</option>{' '}
              {/* Move this option to the beginning */}
              {years.map((e) => {
                return <option value={e.value}>{e.label}</option>;
              })}
              {/* <option value="">Select Year</option> */}
            </select>

            <select
              onChange={StaffChangeHandler}
              id="select1"
              class="ct_listing border_radius ct_listing_float ct_listing_margin "
              style={{
                borderWidth: '1px',
                borderRadius: '8px',
                width: '190px',
                marginBottom: '13px',
                borderColor: '0.5px solid black',
                marginTop: '20px'
              }}
            >
              {productData.map((e) => {
                return <option value={e.id}>{e.stage}</option>;
              })}
              <option value="">Select Stage</option>
            </select>
          </div>
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="col-xxl-12 mb-5 mb-xl-10">
              <div className="card card-flush h-xl-100">
                {loading ? (
                  <div
                    className="spinner-container"
                    style={{ alignSelf: 'center' }}
                  >
                    <ClipLoader
                      color={'#50CD89'}
                      loading={loading}
                      css={override}
                      size={60}
                    />
                  </div>
                ) : (
                  <div className="">
                    {/*begin::Tab content*/}
                    <div className="tab-content">
                      <div className=" mb-5 mb-xl-8">
                        {/*begin::Body*/}
                        <div
                          className="card-body py-3 "
                        // style={{ padding: '0rem 0.25rem' }}
                        >
                          {/*begin::Table container*/}
                          <div
                            className="table-responsive"
                          // style={{ overflow: 'hidden' }}
                          >
                            {/*begin::Table*/}
                            <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                              {/*begin::Table head*/}

                              {/* <div></div> */}
                              <thead style={{ fontSize: '15px' }}>
                                <tr
                                  className="fw-bold text-muted"
                                  style={{ textAlign: 'center' }}
                                >
                                  <th className="min-w-120px">
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('date', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'date' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i>
                                    </span>
                                    Date
                                  </th>
                                  <th className="min-w-120px">
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('title', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                            isArrowObj.column == 'title' &&
                                            isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i>
                                    </span>
                                    Lead Name
                                  </th>
                                  <th className="min-w-120px">
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('title', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                            isArrowObj.column == 'title' &&
                                            isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i>
                                    </span>
                                    Organisation
                                  </th>
                                  <th className="min-w-120px">
                                    <span style={{ marginRight: '3px' }}>
                                      {/* <i
                                        onClick={() =>
                                          handleArrowClick(
                                            'owner_contact_no',
                                            'asc'
                                          )
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column ==
                                            'owner_contact_no' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i> */}
                                    </span>
                                    Client Name
                                  </th>
                                  <th className="min-w-120px">
                                    <span style={{ marginRight: '3px' }}>
                                      {/* <i
                                        onClick={() =>
                                          handleArrowClick(
                                            'organisation',
                                            'asc'
                                          )
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'organisation' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                      ></i> */}
                                    </span>
                                    Client Email
                                  </th>
                                  <th className="min-w-120px">
                                    <span style={{ marginRight: '3px' }}>
                                      {/* <i
                                        onClick={() =>
                                          handleArrowClick(
                                            'organisation',
                                            'asc'
                                          )
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'organisation' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                      ></i> */}
                                    </span>
                                    Net Value
                                  </th>
                                  <th className="min-w-120px">
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick(
                                            'stage',
                                            'asc'
                                          )
                                        }
                                        class={
                                          isArrowObj &&
                                            isArrowObj.column == 'stage' &&
                                            isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                      ></i>
                                    </span>
                                    Stage
                                  </th>

                                  <th className="min-w-120px">
                                    <span style={{ marginRight: '3px' }}>
                                      {/* <i
                                        onClick={() =>
                                          handleArrowClick(
                                            'organisation',
                                            'asc'
                                          )
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'organisation' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                      ></i> */}
                                    </span>
                                    Action
                                  </th>
                                  <th className="min-w-120px">
                                    <span style={{}}>
                                      {/* <i
                                        onClick={() =>
                                          handleArrowClick(
                                            'organisation',
                                            'asc'
                                          )
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'organisation' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                      ></i> */}
                                    </span>
                                    Pdf
                                  </th>
                                </tr>
                              </thead>
                              {/*end::Table head*/}
                              {/*begin::Table body*/}
                              {totolValue.length == 0 ? (
                                <tr className="text-center">
                                  <td
                                    colSpan="9"
                                    style={{ fontSize: 16, marginTop: '10px' }}
                                  >
                                    No Record found
                                  </td>
                                </tr>
                              ) : (
                                <tbody
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: '400'
                                  }}
                                >
                                  {totolValue.map((e, index) => {
                                    return (
                                      <tr
                                        key={index}
                                        style={{ textAlign: 'center' }}
                                      >
                                        <td>{e.created_at}</td>
                                        <td>{e.title}</td>
                                        <td>
                                          {e.lead_organisation}
                                        </td>
                                        <td>
                                          {
                                            e.client_data.clientInfo[0]
                                              .contact_person_name_0
                                          }
                                        </td>
                                        <td>
                                          {
                                            e.client_data.clientInfo[0]
                                              .contact_person_email_0
                                          }
                                        </td>
                                        <td>
                                          {e.leadproducts_sum_total_price}
                                        </td>
                                        <td>{e.leadstage.stage}</td>

                                        <td>
                                          <Link
                                            to={'/leadinfo/' + e.id}
                                            className="nav-icon fa-solid fa-eye pointer_cursor d-block p-1"
                                            style={{ color: 'black' }}
                                          ></Link>
                                        </td>
                                        <td className='d-flex col-12 p-2'>
                                          <div className="dropdown col-12 text-center">
                                            <a className="dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                              <i className="fas fa-ellipsis-v fs-3 p-2" />
                                            </a>

                                            <ul className="dropdown-menu py-3 px-3">
                                              <li className='py-1'>
                                                {e.quotationpdflatest !== null ? (

                                                  <Link
                                                    target="_blank"
                                                    to={e?.quotationpdflatest?.url}
                                                    style={{ color: "gray" }}
                                                    className="d-flex align-items-center justify-content-between fs-5 fw-semibold "
                                                  >

                                                    Quotation <i className="fa-solid fa-file-pdf fs-3"></i>

                                                  </Link>

                                                ) : null}
                                              </li>

                                              {e.quotationpdflatest !== null && e.intend_pdf !== 0 ? (
                                                <li className='py-1'>
                                                  <Link
                                                    target="_blank"
                                                    to={e?.intendpdflatest?.url}
                                                    style={{ color: "gray" }}
                                                    className="d-flex align-items-center justify-content-between fs-5 fw-semibold "
                                                  >
                                                    Sales Order <i className="fa-solid fa-file-pdf fs-3"></i>
                                                  </Link>
                                                </li>
                                              ) : null}


                                            </ul>
                                          </div>
                                        </td>
                                        {/* {e.quotationpdflatest !== null ? (
                                          <td className="">
                                            <Link
                                              target="_blank"
                                              to={e.quotationpdflatest.url}
                                            >
                                              <div className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                                <i class="fa-solid fa-file"></i>
                                              </div>
                                            </Link>
                                          </td>
                                        ) : null} */}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              )}
                              {/*end::Table body*/}
                            </table>

                            <ReactPaginate
                              previousLabel="Previous"
                              nextLabel="Next"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              pageCount={pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              containerClassName="pagination"
                              activeClassName="active"
                              onPageChange={handlePageClick}
                              forcePage={pageNumber}
                            />
                            <div className="row justify-content-end">
                              <div className="col-4">
                                <div className="card card-flush h-xl-100">
                                  {/*begin::Body*/}
                                  <div className="card-body pt-2">
                                    <div className="row">
                                      <div className="col-6">Total Revenue</div>
                                      {totolValue.total_revenue}
                                      <div className="col-6">
                                        Total Quantity
                                      </div>
                                      {totolValue.total_qty}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*end::Table*/}
                          </div>
                          {/*end::Table container*/}
                        </div>
                        {/*begin::Body*/}
                      </div>

                      {/*begin::Tab pane*/}

                      {/*end::Tab pane*/}
                      {/*begin::Tab pane*/}

                      {/*end::Tab pane*/}
                    </div>
                    {/*end::Tab content*/}
                  </div>
                )}
                {/*end::Body*/}
              </div>
              {/*end::Chart widget 8*/}
            </div>
          </div>
        </div>
      </Constant>
    </div>
  );
};

export default SalesReport;
