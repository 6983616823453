import React from 'react';
import zxcvbn from 'zxcvbn';
import { passwordStrength } from 'check-password-strength'

const PasswordStrength = ({ password }) => {
    const testResult = zxcvbn(password);
   

    const pswStrength = passwordStrength(password)
    const id = pswStrength.id;

    const num = id * 100 / 3;


    const createPassLabel = () => {
        switch (id) {
            case 0:
                return 'Very weak';
            case 1:
                return 'Weak';
            case 2:
                return 'Good';
            case 3:
                return 'Strong';
            default:
                return '';
        }
    }

    const funcProgressColor = () => {
        switch (id) {
            case 0:
                return '#EA1111';
            case 1:
                return '#EA1111';
            case 2:
                return '#FFAD00';
            case 3:
                return '#00b500';
            default:
                return 'none';
        }
    }

    const changePasswordColor = () => ({
        width: `${num}%`,
        background: funcProgressColor(),
        height: '3px'
    })

    return (
        <>
            <div className="progress" style={{ height: '3px', width:'98%', margin:'auto' }}>
                <div className="progress-bar " style={changePasswordColor()}></div>
            </div>
            <p style={{ color: funcProgressColor() }} className='px-1 fw-semibold'>{createPassLabel()}</p>
        </>
    )
}

export default PasswordStrength;