import React, { useState } from 'react';
import Constant from '../../Constant/UserLayout';
const ManageSales = () => {
  const [selectedOption, setSelectedOption] = useState('Option 1');
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <div>
      <Constant>
        <div>
          <label>
            <input
              type="radio"
              value="Option 1"
              checked={selectedOption === 'Option 1'}
              onChange={handleOptionChange}
            />
            Option 1
          </label>
          <label>
            <input
              type="radio"
              value="Option 2"
              checked={selectedOption === 'Option 2'}
              onChange={handleOptionChange}
            />
            Option 2
          </label>
        </div>
        {selectedOption === 'Option 1' && (
          <div>
            <h2>Content for Option 1</h2>
            <p>This is the content for Option 1.</p>
          </div>
        )}
        {selectedOption === 'Option 2' && (
          <div>
            <h2>Content for Option 2</h2>
            <p>This is the content for Option 2.</p>
          </div>
        )}
      </Constant>
    </div>
  );
};
export default ManageSales;
