import DataTable from 'react-data-table-component';
import Constant from '../../Constant/UserLayout';
import { Link } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Url from '../../Elements/Api/Api';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { BounceLoader, ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import Header from '../../Constant/Header';
import Modal from './Modal';
import Swal from 'sweetalert2'
import Loader from '../../Elements/Loader';
import './Managelead.css'
import { Form, Button, Row, Col } from 'react-bootstrap';
import { default as ReactSelect } from 'react-select';


const ManageLeads = (props) => {
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  // const [edit, setEdit] = useState(false)
  // const [editId, setEditId] = useState('')
  // const [selectedCategory, setSelectedCategory] = useState('');
  // const [selectedSubcategory, setSelectedSubcategory] = useState('');
  // const [selectedProduct, setSelectedProduct] = useState('');
  // const [products, setProducts] = useState([]);
  // const [leadtype, setLeadtype] = useState([]);
  // const [measurementType, setMeasurementType] = useState('');
  // const [basePrice, setBasePrice] = useState('');
  // const [totalQuanity, setTotalQuanity] = useState('');
  // const [priceBeforeDiscount, setPriceBeforeDiscount] = useState('');
  // const [priceAfterDiscount, setPriceAfterDiscount] = useState('');
  // const [netValue, setNetValue] = useState('');
  // const [subtotalValue, setSubtotalValue] = useState('');

  // const [productSlideData, setProductSlideData] = useState({
  //   qty: '',
  //   kg: '',
  //   totalQuantity: '',
  //   price: '',
  //   priceBeforeDiscount: '',
  //   discount: '0',
  //   priceAfterDiscount: '',
  //   gst: '18',
  //   netValue: '',
  //   loadingUnloading: '0',
  //   freight: '0'
  // });


  const [selectedOption, setSelectedOption] = useState('');
  const [searchClient, setSearchClient] = useState('');
  const [itemOffset, setItemOffset] = useState(1);
  const [clientData, setClientData] = useState([]);
  const [searchClientByName, setSearchClientByName] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [pageNumber, setPageNumber] = useState(0);

  const [deleteRow, setDeleteRow] = useState([]);
  const [response, setResponse] = useState([]);
  const [id, setId] = useState('');
  const [clientLists, setClientLists] = useState([]);
  const [quotation, setQuotation] = useState([]);
  const token = useSelector((state) => state.token);
  const [excelExport, setExportData] = useState([]);
  const [selectedStage, setSelectedStage] = useState('');
  const [stage, setStage] = useState([]);
  const [loading, setLoading] = useState('');
  const [loading1, setLoading1] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenOne, setIsModalOpenOne] = useState(false)
  const [isModalOpenTwo, setIsModalOpenTwo] = useState(false)




  const [email, setEmail] = useState('')
  const [quoteId, setQuoteId] = useState('')
  const [intentId, setIntentId] = useState('')
  const closeModal = () => { setIsModalOpen(false) }
  const closeModalOne = () => { setIsModalOpenOne(false) }
  // start code for datepicker
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate2, setSelectedDate2] = useState('');
  const [isArrowObj, setIsArrowObj] = useState({
    column: 'created_at',
    orderby: 'desc'
  });


  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
  };
  const formattedDate = selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '';
  const formattedDate2 = selectedDate2
    ? format(selectedDate2, 'dd/MM/yyyy')
    : '';
  // end code for datepicker
  useEffect(() => {
    getBrandList();
  }, [
    searchClient,
    itemOffset,
    deleteRow,
    formattedDate,
    formattedDate2,
    id,
    isArrowObj,
    selectedStage,
    pageCount
  ]);
  const getBrandList = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
        'manageleads?' +
        'search=' +
        searchClient +
        '&page=' +
        itemOffset +
        '&lead_id=' +
        id +
        '&stage=' +
        selectedStage +
        '&from=' +
        formattedDate +
        '&to=' +
        formattedDate2 +
        '&orderby=' +
        isArrowObj.column +
        '&sortby=' +
        isArrowObj.orderby,
        config
      )
      .then(
        (value) =>
          setClientData(value.data.response.data) &
          setPageCount(value.data.response.last_page) &
          setLoading(false)
      )
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (clientData) {
      setResponse(clientData);
    }
  }, [clientData, searchClient, deleteRow]);
  // function for pagination
  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    setPageNumber(event.selected);
    setItemOffset(newOffset);
  };
  // function for search
  const handleKeyPress = () => {
    setItemOffset(0);
    setSearchClientByName(searchClientByName);
  };
  const StaffChangeHandler = (event) => {
    setId(event.target.value);
  };
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'staffownclients', config)
      .then(
        (value) =>
          setClientLists(value.data.response) &
          setPageCount(value.data.response.last_page)
      )
      .catch((err) => console.log(err));
  }, []);
  // function for sorting data
  const handleArrowClick = (param, orderby) => {
    if (isArrowObj && isArrowObj.column == param) {
      orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
    } else {
      orderby = orderby == 'asc' ? 'desc' : 'asc';
    }
    const isArrowobjData = {
      column: param,
      orderby: orderby
    };
    setIsArrowObj(isArrowobjData);
  };
  // function to set quotation
  const createQuotation = (id, clientId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      clientid: clientId
    };
    axios
      .post(Url.BASEURL + 'setquotation/' + id, params, config)
      .then((value) => setQuotation(value.data.response))
      .catch((err) => console.log(err));
  };
  {
  }
  // function for export data
  const exporData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
        'exportlead?' +
        'search=' +
        searchClient +
        '&page=' +
        itemOffset +
        '&client_id=' +
        id +
        '&from=' +
        formattedDate +
        '&to=' +
        formattedDate2,
        config,
        {
          method: 'GET',
          responseType: 'json', // important
          headers: {
            Accept: 'application/json'
          }
        }
      )
      .then((response) => {
        setExportData(response.data);
        window.open(response.data, '_self');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlestageChange = (e) => {
    setSelectedStage(e.target.value);
  };
  // function for leadstages
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'leadstages', config)
      .then((value) => setStage(value.data.response))
      .catch((err) => console.log(err));
  }, []);

  //function for semding mail
  const sendMailHandler = (email, format, id) => {
    setLoading1(true)
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(Url.BASEURL + 'sendEmailPdf?' + "email=" + email + "&format=" + format + "&id=" + id, config)
      .then((response) => {
        if (response.status === 200) {
          setIsModalOpen(false);
          setLoading1(false)
          if (format === "quotation") {
            Swal.fire({
              title: "Quotation Sent",
              text: "Your quotation has been successfully sent via email.",
              icon: "success",
              confirmButtonColor: 'green',
              allowOutsideClick: false,
            });
          } else {
            Swal.fire({
              title: "Sales Order Sent",
              text: "Your Sales Order has been successfully sent via email.",
              icon: "success",
              confirmButtonColor: 'green',
              allowOutsideClick: false,
            });
          }


        }
      })
      .catch((err) => console.log(err));
  }


  return (
    <div>

      {isModalOpen &&
        <Modal
          style={{ display: 'block' }}
          isOpen={isModalOpen}
          onClose={() => {
            closeModal()
            setSelectedOption('');
          }}
        >
          {loading1 && (
            <div className="loader-backdrop">
              <div className="spinner-container">
                <ClipLoader color={'#50CD89'} loading={loading1} css={override} size={60} />
              </div>
            </div>
          )}
          <div style={{ alignSelf: 'center' }}>
            <h2 className='fs-3'> Are You sure</h2>
            <p className='fw-semibold fs-5'>You want to send the quotation or Sales Order via email</p>
            <div className='d-flex justify-content-start align-items-center '>
              <div className="form-check form-check-inline">
                <input className="form-check-input"
                  type="radio"
                  name="lead_intent"
                  id="inlineRadio1"
                  value="intent"
                  checked={selectedOption === 'intent'}
                  disabled={intentId ? false : true}
                  onChange={handleRadioChange}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">Sales Order</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input"
                  type="radio"
                  name="lead_quotation"
                  id="inlineRadio2"
                  value="quotation"
                  checked={selectedOption === 'quotation'}
                  disabled={quoteId ? false : true}
                  onChange={handleRadioChange}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">Lead Quotation</label>
              </div>
            </div>
            <button
              style={{ marginTop: '30px' }}
              type="button"
              className="btn btn-secondary rounded-1"
              onClick={() => {
                setIsModalOpen(false);
                setSelectedOption('');
              }}
            >
              Cancel
            </button>
            <button
              style={{ marginTop: '30px', marginLeft: '20px' }}
              type="button"
              className="btn btn-success rounded-1"
              onClick={() => {
                sendMailHandler(
                  email,
                  selectedOption,
                  selectedOption === "intent" ? intentId : quoteId
                )
              }}
            // disabled={isButtonDisabled}
            >
              Send
            </button>
          </div>
        </Modal>}

      <Constant>
        <Header
          btnName="Add Lead"
          onpage_link="My Lead"
          heading="My Lead"
          btn_path="addlead"
        // onClick={exporData}
        // btnName2="Export Clientdata"
        />
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className='mx-10 p-0 mb-2'>
              <div className='d-flex justify-content-between flex-wrap gap-2'>
                <div
                  className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12  rounded-1 mb-0"
                  style={{
                    marginTop: '20px',
                    borderWidth: 0,
                    borderRadius: 5
                  }}
                >

                  <input
                    style={{
                      padding: "8px"
                    }}
                    onChange={(e) =>
                      setSearchClient(e.target.value) & handleKeyPress()
                    }
                    type="text"
                    className="form-control bg-transparent rounded-1 border border-1 border-dark"
                    placeholder="Search"
                  />
                </div>

                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12" style={{ display: 'flex', flexDirection: 'column' }}>
                  <label>From</label>

                  <DatePicker
                    className='rounded-1'
                    style={{
                      padding: "5px"
                    }}
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select a date"
                    onKeyDown={(e) => e.key === 'Backspace' && e.preventDefault()}
                    isClearable={selectedDate ? true : false} // Display clear icon in the field
                    yearDropdown
                    scrollableYearDropdown
                    showYearDropdown={true}
                    maxDate={new Date()}
                  />
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12" style={{ display: 'flex', flexDirection: 'column' }}>
                  <label>To</label>
                  <DatePicker
                    className='rounded-1'
                    style={{
                      padding: "5px",
                      width: "100% !important"
                    }}
                    selected={selectedDate2}
                    onChange={handleDateChange2}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select a date"
                    isClearable={selectedDate2 ? true : false} // Display clear icon in the field
                    // isClearable={true}
                    yearDropdown
                    scrollableYearDropdown
                    showYearDropdown={true}
                  />
                </div>
                <div className='col col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12'>
                  <select
                    onChange={StaffChangeHandler}
                    id="select1"
                    className="ct_listing border_radius ct_listing_float ct_listing_margin rounded-1 "
                    style={{
                      padding: "10px",
                      borderWidth: '1px',
                      borderRadius: '8px',
                      width: '100%',
                      // marginBottom: '15px',
                      borderColor: '0.5px solid black',
                      marginTop: '19px'
                    }}
                  >
                    {clientLists.map((e) => {
                      return (
                        <option key={e.id} value={e.id}>
                          {e.contact_person_name_0}({e.contact_person_email_0})
                        </option>
                      );
                    })}
                    <option value="">Select Client</option>
                  </select>
                </div>
                <div className='col col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12'>
                  <select
                    onChange={handlestageChange}
                    id="select1"
                    className="ct_listing border_radius ct_listing_float ct_listing_margin rounded-1"
                    style={{
                      padding: '10px',
                      borderWidth: '1px',
                      borderRadius: '8px',
                      width: '100%',
                      // marginBottom: '15px',
                      borderColor: '0.5px solid black',
                      marginTop: '19px'
                    }}
                  >
                    {stage.map((e) => {
                      return <option key={e.id} value={e.id}>{e.stage}</option>;
                    })}
                    <option value="">Select Stage</option>
                  </select>
                </div>


              </div>
            </div>

            <div className="col-xxl-12 mb-5 mb-xl-10">
              <div className="card clientinfo-box card-flush h-xl-100 mx-10 ">
                {loading ? (
                  <div
                    className="spinner-container"
                    style={{ alignSelf: 'center' }}
                  >
                    <ClipLoader
                      color={'#50CD89'}
                      loading={loading}
                      css={override}
                      size={60}
                    />
                  </div>
                ) : (
                  <div className="">
                    <div className="tab-content">
                      <div className="">
                        
                        <div className="card-body py-0 px-0" >
                          
                          <div className="table-responsive" style={{minHeight:"45vh"}}>
                            {/*begin::Table*/}
                            <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 m-0">
                              {/*begin::Table head*/}
                              <thead style={{ fontSize: '15px' }}>
                                <tr className="fw-bold text-muted">
                                  <th className="min-w-120px">
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick(
                                            'lead_organisation',
                                            'asc'
                                          )
                                        }
                                        className={
                                          isArrowObj &&
                                            isArrowObj.column ==
                                            'lead_organisation' &&
                                            isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i>
                                    </span>
                                    Organisation
                                  </th>
                                  <th className="min-w-120px">
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('title', 'asc')
                                        }
                                        className={
                                          isArrowObj &&
                                            isArrowObj.column == 'title' &&
                                            isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i>
                                    </span>
                                    Title
                                  </th>


                                  <th className="min-w-120px">
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('client_id', 'asc')
                                        }
                                        className={
                                          isArrowObj &&
                                            isArrowObj.column == 'client_id' &&
                                            isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i>
                                    </span>
                                    Client Name
                                  </th>


                                  <th className="min-w-120px">
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick(
                                            'created_at',
                                            'asc'
                                          )
                                        }
                                        className={
                                          isArrowObj &&
                                            isArrowObj.column ==
                                            'created_at' &&
                                            isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i>
                                    </span>
                                    Lead Date
                                  </th>
                                  <th className="min-w-120px">
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('stage', 'asc')
                                        }
                                        className={
                                          isArrowObj &&
                                            isArrowObj.column == 'stage' &&
                                            isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i>
                                    </span>
                                    Status
                                  </th>
                                  {/* <th className="min-w-120px text-end">View</th> */}
                                  <th className="min-w-120px">
                                    <span style={{ marginRight: '3px' }}>
                                      {/* <i
                                        // onClick={() =>
                                        //   handleArrowClick(
                                        //     'expt_close_date',
                                        //     'asc'
                                        //   )
                                        // }
                                        className={
                                          isArrowObj &&
                                          isArrowObj.column ==
                                            'expt_close_date' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i> */}
                                    </span>
                                    Actions
                                  </th>

                                  {/* <th className="min-w-120px text-end">
                                    Actions
                                  </th> */}

                                  <th className="min-w-120px">
                                    <span style={{ marginRight: '3px' }}>
                                      {/* <i
                                        // onClick={() =>
                                        //   handleArrowClick(
                                        //     'expt_close_date',
                                        //     'asc'
                                        //   )
                                        // }
                                        className={
                                          isArrowObj &&
                                          isArrowObj.column ==
                                            'expt_close_date' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i> */}
                                    </span>
                                    Mail
                                  </th>
                                  {/* <th className="min-w-120px text-end">PDF</th> */}
                                </tr>
                              </thead>
                              {/*end::Table head*/}
                              {/*begin::Table body*/}

                              <tbody
                                style={{
                                  fontSize: '14px',
                                  color: 'var(--bs-text-gray-800)'
                                }}
                              >
                                {clientData.length == 0 ? (
                                  <tr>
                                    <td
                                      colSpan="8"
                                      style={{ textAlign: 'center' }}
                                      className="text-center fw-bold"
                                    >
                                      No Record found
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {clientData.map((e, index) => {
                                      {/* console.log(e.intendpdflatest ? e.intendpdflatest : null) */ }
                                      return (
                                        <tr key={index}>
                                          <td>{e.lead_organisation}</td>

                                          <td>{e.title}</td>
                                          <td>
                                            {
                                              e.client_data?.clientInfo[0]
                                                .contact_person_name_0
                                            }
                                            {/* sfbjksabjkasb */}
                                          </td>
                                          <td>{e.created_at}</td>
                                          <td>{e.leadstage.stage}</td>

                                          {/* <td>
                                            <Link
                                              to={'/leadinfo/' + e.id}
                                            >
                                              <i
                                                className="fa fa-eye"
                                                aria-hidden="true"
                                              ></i>
                                            </Link>
                                          </td> */}

                                          {/* {e.leadstage.stage == 'Win' ? ( */}

                                          {/* ) : ( */}
                                          <>
                                            <td className='d-flex col-8 p-2'>
                                              <div className="dropdown col-12 text-center">
                                                <a className="dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                                  <i className="fas fa-ellipsis-v fs-3 p-2" />
                                                </a>

                                                {!e.quotationpdflatest ?(
                                                  <>
                                                  <ul className="dropdown-menu py-3 px-3">
                                                  <li className='py-1'>
                                                        <Link
                                                          to={'/updatelead/' + e.id}
                                                          className="d-flex align-items-center justify-content-between fw-semibold fs-5"
                                                          style={{ color: "gray" }}
                                                        >
                                                          Edit Lead
                                                          <i className="ki-duotone ki-pencil fs-2">
                                                            <span className="path1" />
                                                            <span className="path2" />
                                                          </i>{' '}
                                                        </Link>
                                                      </li>
                                                      <li className='py-1'>
                                                        <Link
                                                          to={'/leadinfo/' + e.id}
                                                          style={{ color: "gray" }}
                                                        >
                                                          <div className="d-flex align-items-center justify-content-between fs-5 fw-semibold">
                                                            View Lead <i className="fa fa-eye fs-4" aria-hidden="true"></i>
                                                          </div>
                                                        </Link>
                                                      </li>
                                                  </ul>
                                                  </>
                                                ) : (
                                                  <>
                                                    <ul className="dropdown-menu py-3 px-3">
                                                      <li className='py-1'>
                                                        <Link
                                                          to={'/updatelead/' + e.id}
                                                          className="d-flex align-items-center justify-content-between fw-semibold fs-5"
                                                          style={{ color: "gray" }}
                                                        >
                                                          Edit Lead
                                                          <i className="ki-duotone ki-pencil fs-2">
                                                            <span className="path1" />
                                                            <span className="path2" />
                                                          </i>{' '}
                                                        </Link>
                                                      </li>
                                                      <li className='py-1'>
                                                        {e.quotationpdflatest !== null ? (
                                                          <Link
                                                            target="_blank"
                                                            to={e.quotationpdflatest.pdf}
                                                            style={{ color: "gray" }}
                                                            className="d-flex align-items-center justify-content-between fs-5 fw-semibold "
                                                          >
                                                            Lead Quot.<i className="fa-solid fa-file-pdf fs-3"></i>
                                                          </Link>
                                                        ) : null}
                                                      </li>
                                                      <li className='py-1'>
                                                        {e.quotationpdflatest !== null && e.intend_pdf !== 0 ? (
                                                          <Link
                                                            target="_blank"
                                                            to={e.intendpdflatest ? e.intendpdflatest.pdf : null}
                                                            style={{ color: "gray" }}
                                                            className="d-flex align-items-center justify-content-between fs-5 fw-semibold "
                                                          >
                                                            Lead SO <i className="fa-solid fa-file-pdf fs-3"></i>
                                                          </Link>
                                                        ) : null}
                                                      </li>
                                                      <li className='py-1'>
                                                        {e.quotationpdflatest !== null && e.intend_pdf == 0 ? (
                                                          <Link
                                                            to={`/genrateIntent/${e.id}/${e.quotationpdflatest.id}`}
                                                            style={{ color: "gray" }}
                                                            className="d-flex align-items-center justify-content-between fs-5 fw-semibold"
                                                          >
                                                            Gen. SO <i className="fa-solid fa-file-circle-plus fs-4"></i>
                                                          </Link>
                                                        ) : (
                                                          <Link
                                                            to={`/editIntent/${e.id}/${e.quotationpdflatest.id}`}
                                                            className="d-flex align-items-center justify-content-between fw-semibold fs-5"
                                                            style={{ color: "gray" }}
                                                          >
                                                            Edit SO
                                                            <i className="ki-duotone ki-pencil fs-2">
                                                              <span className="path1" />
                                                              <span className="path2" />
                                                            </i>{' '}
                                                          </Link>
                                                        )}
                                                      </li>
                                                      <li className='py-1'>
                                                        <Link
                                                          to={'/leadinfo/' + e.id}
                                                          style={{ color: "gray" }}
                                                        >
                                                          <div className="d-flex align-items-center justify-content-between fs-5 fw-semibold">
                                                            View Lead <i className="fa fa-eye fs-4" aria-hidden="true"></i>
                                                          </div>
                                                        </Link>
                                                      </li>
                                                    </ul>
                                                  </>
                                                )}

                                                {/* <ul className="dropdown-menu py-3 px-3">
                                                    <li className='py-1'>
                                                      <Link
                                                        to={'/updatelead/' + e.id}
                                                        className="d-flex align-items-center justify-content-between fw-semibold fs-5"
                                                        style={{ color: "gray" }}
                                                      >Edit Lead
                                                        <i className="ki-duotone ki-pencil fs-2" >
                                                          <span className="path1" />
                                                          <span className="path2" />
                                                        </i>{' '}
                                                      </Link>
                                                    </li>
                                                    <li className='py-1'>
                                                      {e.quotationpdflatest !== null ? (

                                                        <Link
                                                          target="_blank"
                                                          to={e.quotationpdflatest.pdf}
                                                          style={{ color: "gray" }}
                                                          className="d-flex align-items-center justify-content-between fs-5 fw-semibold "
                                                        >

                                                          Lead Quot.<i className="fa-solid fa-file-pdf fs-3"></i>

                                                        </Link>

                                                      ) : null}
                                                    </li>
                                                    <li className='py-1'>
                                                      {e.quotationpdflatest !== null && e.intend_pdf !== 0 ? (
                                                        <Link
                                                          target="_blank"
                                                          to={e.intendpdflatest ? e.intendpdflatest.pdf : null}
                                                          style={{ color: "gray" }}
                                                          className="d-flex align-items-center justify-content-between fs-5 fw-semibold "
                                                        >
                                                          Lead SO <i className="fa-solid fa-file-pdf fs-3"></i>
                                                        </Link>
                                                      ) : null}
                                                    </li>
                                                    <li className='py-1'>

                                                      {e.quotationpdflatest !== null && e.intend_pdf == 0 ? (
                                                        <Link
                                                          to={`/genrateIntent/${e.id}/${e.quotationpdflatest.id}`}
                                                          style={{ color: "gray" }}
                                                          className="d-flex align-items-center justify-content-between fs-5 fw-semibold"
                                                        >
                                                          Gen. SO <i className="fa-solid fa-file-circle-plus fs-4"></i>
                                                        </Link>
                                                      ) : (
                                                        <Link
                                                          to={`/editIntent/${e.id}/${e.quotationpdflatest.id}`}
                                                          className="d-flex align-items-center justify-content-between fw-semibold fs-5"
                                                          style={{ color: "gray" }}
                                                        > Edit SO
                                                          <i className="ki-duotone ki-pencil fs-2" >
                                                            <span className="path1" />
                                                            <span className="path2" />
                                                          </i>{' '}
                                                        </Link>
                                                      )}

                                                    </li>
                                                    <li className='py-1'>
                                                      <Link
                                                        to={'/leadinfo/' + e.id}
                                                        style={{ color: "gray" }}
                                                      >
                                                        <div className="d-flex align-items-center justify-content-between fs-5 fw-semibold">
                                                          View Lead <i
                                                            className="fa fa-eye fs-4"
                                                            aria-hidden="true"
                                                          ></i>
                                                        </div>

                                                      </Link>
                                                    </li>
                                                  </ul> */}
                                              </div>
                                            </td>
                                            {/* <td>
                                                <Link
                                                  style={
                                                    {
                                                      // marginLeft: '80px'
                                                      // marginTop: '10px'
                                                    }
                                                  }
                                                  to={'/updatelead/' + e.id}
                                                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                                >
                                                  <i className="ki-duotone ki-pencil fs-2">
                                                    <span className="path1" />
                                                    <span className="path2" />
                                                  </i>{' '}
                                                </Link>
                                              </td> */}
                                          </>
                                          {/* )} */}
                                          {/* <>
                                            {e.quotationpdflatest !== null ? (
                                              <td>
                                                <Link
                                                  target="_blank"
                                                  to={e.quotationpdflatest.pdf}
                                                >
                                                  <div className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                                    <i className="fa-solid fa-file"></i>
                                                  </div>
                                                </Link>
                                              </td>
                                            ) : null}
                                          </> */}
                                          {/* <>
                                            {e.quotationpdflatest !== null ? (
                                              <td className='ps-5 p-0'>
                                                <Link
                                                  to={`/genrateIntent/${e.id}/${e.quotationpdflatest.id}`}
                                                >
                                                  <div
                                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                                  >
                                                    <i className="fa-solid fa-file-circle-plus fs-4"></i>
                                                  </div>
                                                </Link>
                                              </td>
                                            ) : null}

                                          </> */}
                                          <>
                                            {e.quotationpdflatest !== null ? (
                                              <td>
                                                <div
                                                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                                  onClick={() => {
                                                    setIsModalOpen(true);
                                                    setEmail(e.client_data?.clientInfo[0].contact_person_email_0);
                                                    setQuoteId(e.quotationpdflatest.id);
                                                    setIntentId(e.intendpdflatest ? e.intendpdflatest.id : null)
                                                  }}

                                                >
                                                  <i className="fa-solid fa-envelope-open-text"></i>
                                                </div>
                                              </td>
                                            ) : null}

                                          </>
                                        </tr>
                                      );
                                    })}
                                  </>
                                )}
                              </tbody>


                              {/*end::Table body*/}
                            </table>


                            {/*end::Table*/}
                          </div>
                          {/*end::Table container*/}
                        </div>
                        {/*begin::Body*/}
                      </div>
                      {/*begin::Tab pane*/}
                      {/*end::Tab pane*/}
                      {/*begin::Tab pane*/}
                      {/*end::Tab pane*/}
                    </div>
                    {/*end::Tab content*/}
                  </div>
                )}
                {/*end::Body*/}
              </div>
              <div className='mx-10 p-1 mt-2'>
                <ReactPaginate
                  previousLabel="Previous"
                  nextLabel="Next"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  containerClassName="pagination"
                  activeClassName="active"
                  onPageChange={handlePageClick}
                  forcePage={pageNumber}
                />
              </div>

              {/*end::Chart widget 8*/}
            </div>
          </div>
        </div>
      </Constant>
    </div>
  );
};
export default ManageLeads;
