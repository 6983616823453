import React, { useState } from 'react';

import { Link, useNavigate, Navigate } from 'react-router-dom';
import Url from '../Elements/Api/Api';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import './Auth.css'
import {
  setStatus,
  setpasswordChangeToken
} from '../Elements/Redux/Action/Authaction';

const override = css`
display: block;
margin: 0 auto;
`;

const OtpVerify = () => {
 
  const [error, setError] = useState('');
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const savedEmail = useSelector((state) => state.email);
  const submitHandle = async (e) => {
    try {
      setLoading(true)
      const params = {
        email: savedEmail,
        otp: otp
      };

      const response = await axios.post(
        Url.BASEURL + 'forgot-password/verify-otp',
        params
      );

      if (response.data.status === 200) {
        setLoading(false)
        const responseData = response.data;
        dispatch(setpasswordChangeToken(response.data.data.token));
        navigate('/reset_password');
      } else {
        setError('Please enter valid credentials');
      }
    } catch (error) {
      setLoading(false)
      setError(error.response.data.message);
    }
  };

  return (
    <>
      {loading &&
        <div className="loader-backdrop">
          <div className="spinner-container">
            <ClipLoader color={'#50CD89'} loading={loading} css={override} size={60} />
          </div>
        </div>
      }


      <div>
        <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>
          {/*begin::Authentication - Sign-in */}
          <div className="d-flex flex-column flex-lg-row flex-column-fluid justify-content-center">
            {/*begin::Body*/}
            <div
              className="d-flex flex-column flex-lg-row-fluid py-10"
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >

              {/*begin::Content*/}
              <div className="card">
                <div className="d-flex flex-center flex-column flex-column-fluid">
                  {/*begin::Wrapper*/}
                  <div className="p-10 p-lg-15 mx-auto">
                    {/*begin::Form*/}
                    <form
                      className="form w-100"
                      noValidate="novalidate"
                      id="kt_sign_in_form"
                      data-kt-redirect-url="../dist/index.html"
                      action="#"
                    >
                      <div className='d-flex justify-content-center mb-5'>
                        <div className='image-box col-12'>
                          <img
                            style={{
                              height: '200px',
                              width: "80%",
                              margin: "auto",
                              display: "block",
                            }}
                            alt="Logo"
                            src="dist/assets/media/logos/logo.jpeg"
                            className="theme-light-show logo_filter"
                          />
                        </div>

                      </div>
                      <div className="text-center mb-10">

                        <p className="text-dark mb-3 fs-1 fw-bold px-8" style={{ letterSpacing: "1px" }}>
                          Enter Vrification Code
                        </p>
                        <p className="text-dark-emphasis mb-3 fs-7 fw-semibold " style={{ letterSpacing: "1px" }}>
                          Your code was sent to you via email
                        </p>

                        {/* {error ? (
                          <div
                            style={{
                              color: 'red',
                              textAlign: 'center',
                              // margin: '10px 0px',
                              background: '#FFCCB', fontSize: 20, fontWeight: '600'
                            }}
                          >
                            {error}
                          </div>
                        ) : null} */}
                      </div>
                      <div className="fv-row mb-10">
                        {/*begin::Label*/}
                        <label
                          style={{ float: 'left' }}
                          className="form-label fs-6 fw-bold text-dark"
                        >
                          OTP
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input
                          onChange={(e) => {
                            setOtp(e.target.value);
                            setError('');
                          }}
                          placeholder='Enter Your OTP'
                          className="form-control form-control-lg  rounded-1 text-center"
                          type="text"
                          name="email"
                          maxLength="4"
                          onInput={(e) => {
                            // Remove any characters that are not digits, decimal points, or #
                            e.target.value = e.target.value.replace(/[^\d.]/g, '');
                          }}
                          style={{ letterSpacing: "5px" }}
                        />
                        {error ? (
                          <div
                            style={{
                              color: 'red',

                              margin: '10px 0px',
                              background: '#FFCCB'
                            }}
                            className='mt-1 ms-2 fw-semibold'>
                            {error}
                          </div>
                        ) : null}

                        {/*end::Input*/}
                      </div>
                      <div className="row align-items-center flex-column">
                        <div className="col-12 d-flex justify-content-center">
                          <div
                            onClick={() => submitHandle()}
                            className="btn btn-primary rounded-1 px-20"
                          >
                            Verify OTP
                          </div>
                        </div>
                        <div className="col-12 d-flex justify-content-center align-items-center mt-3 gap-2">
                          <i className="fa-solid fa-angle-left fs-5 text-dark"></i>
                          <div>
                            <Link style={{ float: 'inline-end' }} to="/" className='fs-5 fw-semibold text-dark'>
                              Back to login
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};
export default OtpVerify;
