import React, { useState, useEffect } from 'react';
import Constant from '../../Constant/UserLayout';
import ReactApexChart from 'react-apexcharts';

import Modal from '../ManageLeads/Modal';
import { useSelector } from 'react-redux';
import Url from '../../Elements/Api/Api';
import axios from 'axios';
import Header from '../../Constant/Header';

const Dashboard = () => {
  const token = useSelector((state) => state.token);
  const [reminder, setReminder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tomRem, setTomRem] = useState([]);
  const [staffLeadReport, setStaffLeadReport] = useState('');
  const [totalLeads, setTotalLeads] = useState('');
  const [productRevenue, setProductRevenue] = useState('');
  const [currentProductRev, setCurrentProductRev] = useState('');
  const [graphReport, setGraphReport] = useState([]);
  const [exportLead, setExportLead] = useState([]);
  useEffect((e) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'remindercall?',

        config
      )
      .then(
        (value) =>
          setReminder(value.data.response.today) &
          setTomRem(value.data.response.tomorrow) &
          setLoading(false)
      )
      .catch((err) => console.log(err));
  }, []);

  useEffect((e) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'winleadseverymonth',

        config
      )
      .then((value) => {
        const roundedData = value.data.map(
          (num) => Math.round(num * 100) / 100
        );
        setGraphReport(roundedData);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect((e) => {
  // }, []);

  // STAFF PERFORMANCE REPORT
  useEffect((e) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'staffleadreport?',

        config
      )
      .then(
        (value) => setStaffLeadReport(value.data.response) & setLoading(false)
      )
      .catch((err) => console.log(err));
  }, []);
  // revenue and product report
  useEffect((e) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'revenuetotal?',

        config
      )
      .then(
        (value) =>
          setProductRevenue(value.data.response.total) &
          setCurrentProductRev(value.data.response.current_month) &
          setLoading(false)
      )
      .catch((err) => console.log(err));
  }, []);
  // const closeModal = () => setIsModalOpen(false);

  const series = [
    {
      name: 'Lead Status',
      data: graphReport
    }
  ];
  const options = {
    chart: {
      height: 350,
      type: 'bar'
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top' // top, center, bottom
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + '%';
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#304758']
      }
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      position: 'top',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5
          }
        }
      },
      tooltip: {
        enabled: true
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val + '%';
        }
      }
    },
    title: {
      text: 'Converted leads  in  last 1 year',
      floating: true,
      offsetY: 330,
      align: 'center',
      style: {
        color: '#444'
      }
    }
  };

  const truncatedSeries = series.map((num) => parseFloat(num).toFixed(2));

  return (
    <div>
      <Constant>
        {/* //here i am implementing attendance. */}
        <Header onpage_link="Dashboard" heading="DashBoard" />

        <div id="kt_app_content" className="app-content flex-column-fluid">
          {/*begin::Content container*/}
          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            {/* Nested Modal */}

            {/*begin::Row*/}
            <div className="row gx-5 gx-xl-10 justify-content-center">
              {/*begin::Col*/}

              <div className="row mb-xl-10 cards_filter">
                <div className="col-3 ">
                  <div
                    className="card card-flush border-none shadow-none h-md-60 mb-5 mb-xl-10"
                    style={{ height: '220px' }}
                  >
                    {/*begin::Header*/}
                    <div
                      className="card-header pt-5 px-5"
                      style={{ backgroundColor: 'white' }}
                    >
                      {/*begin::Title*/}
                      <div className="card-title d-flex flex-column">
                        {/*begin::Info*/}
                        <div className="d-flex align-items-center">
                          {/*begin::Currency*/}

                          {/*end::Currency*/}
                          {/*begin::Amount*/}
                          <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                            {staffLeadReport.total_leads}

                          </span>
                          {/*end::Amount*/}
                          {/*begin::Badge*/}

                          {/*end::Badge*/}
                        </div>
                        {/*end::Info*/}
                        {/*begin::Subtitle*/}
                        <span className="text-gray-400 pt-1 fw-semibold fs-6">
                          Total Leads
                        </span>
                        {/*end::Subtitle*/}
                      </div>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Card body*/}
                    <div
                      className="card-body pt-0 pb-4 d-flex px-5 "
                     
                    >
                      {/*begin::Chart*/}
                      {/* <div className="d-flex flex-center me-5 pt-2">
                        <div
                          id="kt_card_widget_4_chart"
                          style={{ minWidth: 70, minHeight: 70 }}
                          data-kt-size={70}
                          data-kt-line={11}
                        />
                      </div> */}
                      {/*end::Chart*/}
                      {/*begin::Labels*/}
                      <div
                        className="d-flex flex-column content-justify-center w-100 px-2 gap-1"
                        
                      >
                        {/*begin::Label*/}
                        <div className="d-flex fs-6 fw-semibold align-items-center">
                          {/*begin::Bullet*/}
                          <div className="bullet w-15px h-6px rounded-2 bg-success me-3" />
                          {/*end::Bullet*/}
                          {/*begin::Label*/}
                          <div className="text-gray-500 flex-grow-1 me-4">
                            Won Leads
                          </div>
                          {/*end::Label*/}
                          {/*begin::Stats*/}
                          <div className="fw-bolder text-gray-700 text-xxl-end">
                            {staffLeadReport.total_win}
                          </div>
                          {/*end::Stats*/}
                        </div>
                        {/*end::Label*/}
                        {/*begin::Label*/}
                        <div className="d-flex fs-6 fw-semibold align-items-center ">
                          {/*begin::Bullet*/}
                          <div className="bullet w-15px h-6px rounded-2 bg-secondary me-3" />
                          {/*end::Bullet*/}
                          {/*begin::Label*/}
                          <div className="text-gray-500 flex-grow-1 me-4">
                            Loss Lead
                          </div>
                          {/*end::Label*/}
                          {/*begin::Stats*/}
                          <div className="fw-bolder text-gray-700 text-xxl-end">
                            {staffLeadReport.total_loss}
                          </div>
                          {/*end::Stats*/}
                        </div>
                        {/*end::Label*/}
                        {/*begin::Label*/}
                        <div className="d-flex fs-6 fw-semibold align-items-center">
                          {/*begin::Bullet*/}
                          <div className="bullet w-15px h-6px rounded-2 bg-primary me-3" />
                          {/*end::Bullet*/}
                          {/*begin::Label*/}
                          <div className="text-gray-500 flex-grow-1 me-4">
                            Cold Leads
                          </div>
                          <div className="fw-bolder text-gray-700 text-xxl-end">
                            {staffLeadReport.total_cold}
                          </div>
                          {/*end::Label*/}
                          {/*begin::Stats*/}

                          {/*end::Stats*/}
                        </div>
                        <div className="d-flex fs-6 fw-semibold align-items-center">
                          {/*begin::Bullet*/}
                          <div className="bullet w-15px h-6px rounded-2 bg-warning me-3" />
                          {/*end::Bullet*/}
                          {/*begin::Label*/}
                          <div className="text-gray-500 flex-grow-1 me-4">
                            Warm Leads
                          </div>
                          <div className="fw-bolder text-gray-700 text-xxl-end">
                            {staffLeadReport.total_warm}
                          </div>
                          {/*end::Label*/}
                          {/*begin::Stats*/}

                          {/*end::Stats*/}
                        </div>
                        <div className="d-flex fs-6 fw-semibold align-items-center">
                          {/*begin::Bullet*/}
                          <div className="bullet w-15px h-6px rounded-2 bg-danger me-3" />
                          {/*end::Bullet*/}
                          {/*begin::Label*/}
                          <div className="text-gray-500 flex-grow-1 me-4">
                            Hot Leads
                          </div>
                          <div className="fw-bolder text-gray-700 text-xxl-end">
                            {staffLeadReport.total_hot}
                          </div>
                          {/*end::Label*/}
                          {/*begin::Stats*/}

                          {/*end::Stats*/}
                        </div>
                        {/*end::Label*/}
                      </div>
                      {/*end::Labels*/}
                    </div>
                    {/*end::Card body*/}
                  </div>
                </div>

                <div className="col-3">
                  <div
                    className="card border-none shadow-none card-flush h-md-60 mb-5 mb-xl-10"
                    style={{ height: '220px' }}
                  >
                    {/*begin::Header*/}
                    <div
                      className="card-header pt-5 px-5"
                      style={{ backgroundColor: 'white' }}
                    >
                      {/*begin::Title*/}
                      <div className="card-title d-flex flex-column">
                        {/*begin::Info*/}
                        <div className="d-flex align-items-center">
                          {/*begin::Currency*/}

                          {/*end::Currency*/}
                          {/*begin::Amount*/}
                          <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                            {staffLeadReport.total_leads}
                          </span>
                          {/*end::Amount*/}
                          {/*begin::Badge*/}

                          {/*end::Badge*/}
                        </div>
                        {/*end::Info*/}
                        {/*begin::Subtitle*/}
                        <span className="text-gray-400 pt-1 fw-semibold fs-6">
                          Total Leads
                        </span>
                        {/*end::Subtitle*/}
                      </div>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Card body*/}
                    <div
                      className="card-body pt-0 pb-4 d-flex px-5"
                      
                    >
                      {/*begin::Chart*/}
                      {/* <div className="d-flex flex-center me-5 pt-2">
                        <div
                          id="kt_card_widget_4_chart"
                          style={{ minWidth: 70, minHeight: 70 }}
                          data-kt-size={70}
                          data-kt-line={11}
                        />
                      </div> */}
                      {/*end::Chart*/}
                      {/*begin::Labels*/}
                      <div
                        className="d-flex flex-column content-justify-center w-100 px-2 gap-1"
                      
                      >
                        {/*begin::Label*/}
                        <div className="d-flex fs-6 fw-semibold align-items-center">
                          {/*begin::Bullet*/}
                          <div className="bullet w-15px h-6px rounded-2 bg-danger me-3" />
                          {/*end::Bullet*/}
                          {/*begin::Label*/}
                          <div className="text-gray-500 flex-grow-1 ">
                            Current Month Leads
                          </div>
                          {/*end::Label*/}
                          {/*begin::Stats*/}
                          <div className="fw-bolder text-gray-700 text-xxl-end">
                            {staffLeadReport.current_month_leads}
                          </div>
                          {/*end::Stats*/}
                        </div>
                        {/*end::Label*/}
                        {/*begin::Label*/}
                      </div>
                      {/*end::Labels*/}
                    </div>
                    {/*end::Card body*/}
                  </div>
                </div>

                <div className="col-3">
                  <div
                    className="card card-flush border-none shadow-none h-md-60 mb-5 mb-xl-10"
                    style={{ height: '220px' }}
                  >
                    {/*begin::Header*/}
                    <div
                      className="card-header pt-5 px-5"
                      style={{ backgroundColor: 'white' }}
                    >
                      {/*begin::Title*/}
                      <div className="card-title d-flex flex-column">
                        {/*begin::Info*/}
                        <div className="d-flex align-items-center">
                          {/*begin::Currency*/}

                          {/*end::Currency*/}
                          {/*begin::Amount*/}
                          <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                            {productRevenue.revenue}
                          </span>
                          {/*end::Amount*/}
                          {/*begin::Badge*/}

                          {/*end::Badge*/}
                        </div>
                        {/*end::Info*/}
                        {/*begin::Subtitle*/}
                        <span className="text-gray-400 pt-1 fw-semibold fs-6">
                          Total Revenue
                        </span>
                        {/*end::Subtitle*/}
                      </div>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Card body*/}
                    <div
                      className="card-body pt-0 pb-4 d-flex px-5"
                      
                    >
                      {/*begin::Chart*/}
                      {/* <div className="d-flex flex-center me-5 pt-2">
                        <div
                          id="kt_card_widget_4_chart"
                          style={{ minWidth: 70, minHeight: 70 }}
                          data-kt-size={70}
                          data-kt-line={11}
                        />
                      </div> */}
                      {/*end::Chart*/}
                      {/*begin::Labels*/}
                      <div
                        className="d-flex flex-column content-justify-center w-100 px-2 gap-1"
                       
                      >
                        {/*begin::Label*/}
                        <div className="d-flex fs-6 fw-semibold align-items-center">
                          {/*begin::Bullet*/}
                          <div className="bullet w-15px h-6px rounded-2 bg-danger me-3" />
                          {/*end::Bullet*/}
                          {/*begin::Label*/}
                          <div className="text-gray-500 flex-grow-1 me-4">
                            Current Month Revenue
                          </div>
                          {/*end::Label*/}
                          {/*begin::Stats*/}
                          <div className="fw-bolder text-gray-700 text-xxl-end">
                            {currentProductRev.revenue}
                          </div>
                          {/*end::Stats*/}
                        </div>
                        {/*end::Label*/}
                        {/*begin::Label*/}
                      </div>
                      {/*end::Labels*/}
                    </div>
                    {/*end::Card body*/}
                  </div>
                </div>

                {/*begin::Card widget 6*/}
                {/*end::Card widget 6*/}
                {/*begin::Card widget 7*/}

                <div className="col-3">
                  <div
                    className="card border-none shadow-none h-md-60 mb-5 mb-xl-10"
                    style={{ height: '220px', '--bs-border-opacity': '10' }}
                  >
                    {/*begin::Header*/}
                    <div
                      className="card-header border-0 shadow-none pt-5 px-5"
                      style={{ backgroundColor: 'white' }}
                    >
                      {/*begin::Title*/}
                      <div className="card-title d-flex flex-column">
                        {/*begin::Info*/}
                        <div className="d-flex align-items-center">
                          {/*begin::Currency*/}

                          {/*end::Currency*/}
                          {/*begin::Amount*/}
                          <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                            {productRevenue.productqty}
                          </span>
                          {/*end::Amount*/}
                          {/*begin::Badge*/}

                          {/*end::Badge*/}
                        </div>
                        {/*end::Info*/}
                        {/*begin::Subtitle*/}
                        <span className="text-gray-400 pt-1 fw-semibold fs-6">
                          Total Products Sold
                        </span>
                        {/*end::Subtitle*/}
                      </div>
                      {/*end::Title*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Card body*/}
                    <div
                      className="card-body pt-0 px-5 pb-4 d-flex  border-0 shadow-none"
                      
                    >
                      {/*begin::Chart*/}
                      {/* <div className="d-flex flex-center me-5 pt-2">
                        <div
                          id="kt_card_widget_4_chart"
                          style={{ minWidth: 70, minHeight: 70 }}
                          data-kt-size={70}
                          data-kt-line={11}
                        />
                      </div> */}
                      {/*end::Chart*/}
                      {/*begin::Labels*/}
                      <div
                        className="d-flex flex-column content-justify-center w-100 px-2"
                        
                      >
                        {/*begin::Label*/}
                        <div className="d-flex fs-6 fw-semibold align-items-start">
                          {/*begin::Bullet*/}
                          <div className="bullet w-15px h-6px rounded-2 bg-danger me-3 mt-2" />
                          {/*end::Bullet*/}
                          {/*begin::Label*/}
                          <div className="text-gray-500 flex-grow-1">
                            Current Month Products Sold
                          </div>
                          {/*end::Label*/}
                          {/*begin::Stats*/}
                          <div className="fw-bolder text-gray-700 text-xxl-end">
                            {currentProductRev.productqty}
                          </div>

                          {/*end::Stats*/}
                        </div>
                        {/*end::Label*/}
                        {/*begin::Label*/}
                      </div>
                      {/*end::Labels*/}
                    </div>
                    {/*end::Card body*/}
                  </div>
                </div>
                {/*end::Card widget 7*/}

                {/*begin::Col*/}

                {/*end::Col*/}
              </div>

              <div className="row pt-5">
                <div className="col-12">
                  <div
                    className="card mb-5 mb-xl-8"
                    id="kt_timeline_widget_2_card"
                  >
                    {/*begin::Header*/}
                    <div className="card-header position-relative py-0 border-bottom-2">
                      {/*begin::Nav*/}
                      <ul
                        className="nav nav-stretch nav-pills nav-pills-custom d-flex mt-3"
                        role="tablist"
                      >
                        {/*begin::Item*/}
                        <li
                          className="nav-item p-0 ms-0 me-8"
                          role="presentation"
                        >
                          {/*begin::Link*/}
                          <a
                            className="nav-link btn btn-color-muted px-0 active"
                            data-bs-toggle="pill"
                            href="#kt_timeline_widget_2_tab_1"
                            aria-selected="true"
                            role="tab"
                          >
                            {/*begin::Subtitle*/}
                            <span className="nav-text fw-semibold fs-4 mb-3">
                              Today's Reminders
                            </span>
                            {/*end::Subtitle*/}
                            {/*begin::Bullet*/}
                            <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded" />
                            {/*end::Bullet*/}
                          </a>
                          {/*end::Link*/}
                        </li>
                        {/*end::Item*/}
                        {/*begin::Item*/}
                        <li
                          className="nav-item p-0 ms-0 me-8"
                          role="presentation"
                        >
                          {/*begin::Link*/}
                          <a
                            className="nav-link btn btn-color-muted px-0"
                            data-bs-toggle="pill"
                            href="#kt_timeline_widget_2_tab_2"
                            aria-selected="false"
                            role="tab"
                            tabIndex={-1}
                          >
                            {/*begin::Subtitle*/}
                            <span className="nav-text fw-semibold fs-4 mb-3">
                              Tommorrow's Reminder
                            </span>
                            {/*end::Subtitle*/}
                            {/*begin::Bullet*/}
                            <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded" />
                            {/*end::Bullet*/}
                          </a>
                          {/*end::Link*/}
                        </li>
                        {/*end::Item*/}
                        {/*begin::Item*/}

                        {/*end::Item*/}
                      </ul>
                      {/*end::Nav*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body">
                      {/*begin::Tab Content*/}
                      <div className="tab-content">
                        {/*begin::Tap pane*/}
                        <div
                          className="tab-pane fade active show"
                          id="kt_timeline_widget_2_tab_1"
                          role="tabpanel"
                        >
                          {/*begin::Table container*/}
                          <div className="table-responsive">
                            {/*begin::Table*/}
                            <table className="table align-middle gs-0 gy-4">
                              {/*begin::Table head*/}
                              <thead>
                                <tr>
                                  <th className="p-0 w-10px" />
                                  <th className="p-0 w-25px" />
                                  <th className="p-0 min-w-400px" />
                                  <th className="p-0 min-w-100px" />
                                  <th className="p-0 min-w-125px" />
                                </tr>
                              </thead>
                              {/*end::Table head*/}
                              {/*begin::Table body*/}
                              <tbody>
                                {reminder.length == 0 ? (
                                  <tr className="">
                                    <td
                                      colSpan="9"
                                      style={{
                                        fontSize: 16,
                                        marginTop: '10px',
                                        color: '#3E97FF',
                                        fontWeight: '800'
                                      }}
                                    >
                                      No Reminder for the day
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {reminder.map((e, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>
                                            <span
                                              data-kt-element="bullet"
                                              className="bullet bullet-vertical d-flex align-items-center h-40px bg-success"
                                            />
                                          </td>
                                          <td className="ps-0">
                                            <div className="form-check form-check-custom form-check-success form-check-solid"></div>
                                          </td>
                                          <td>
                                            <div className="text-gray-800 text-hover-primary fw-bold fs-6">
                                              {e.communication}
                                            </div>
                                            <span className="text-gray-400 fw-bold fs-7 d-block">
                                              {e.communication_date}
                                            </span>
                                          </td>
                                          <td className="text-end">
                                            <span
                                              data-kt-element="status"
                                              className="badge badge-light-success"
                                            >
                                              {`Reminder-${index + 1}`}
                                            </span>
                                          </td>
                                        </tr>

                                      );
                                    })}
                                  </>
                                )}
                              </tbody>

                              {/*end::Table body*/}
                            </table>
                          </div>
                          {/*end::Table*/}
                        </div>
                        {/*end::Tap pane*/}
                        {/*begin::Tap pane*/}
                        <div
                          className="tab-pane fade"
                          id="kt_timeline_widget_2_tab_2"
                          role="tabpanel"
                        >
                          {/*begin::Table container*/}
                          <div className="table-responsive">
                            {/*begin::Table*/}
                            <table className="table align-middle gs-0 gy-4">
                              {/*begin::Table head*/}
                              <thead>
                                <tr>
                                  <th className="p-0 w-10px" />
                                  <th className="p-0 w-25px" />
                                  <th className="p-0 min-w-400px" />
                                  <th className="p-0 min-w-100px" />
                                  <th className="p-0 min-w-125px" />
                                </tr>
                              </thead>
                              <tbody>
                                {tomRem.length == 0 ? (
                                  <tr className="">
                                    <td
                                      colSpan="9"
                                      style={{
                                        fontSize: 16,
                                        marginTop: '10px',
                                        color: '#3E97FF',
                                        fontWeight: '800'
                                      }}
                                    >
                                      No Reminder for the day
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {tomRem.map((e, index) => {
                                      return (

                                        <tr key={index}>
                                          <td>
                                            <span
                                              data-kt-element="bullet"
                                              className="bullet bullet-vertical d-flex align-items-center h-40px bg-success"
                                            />
                                          </td>
                                          <td className="ps-0">
                                            <div className="form-check form-check-custom form-check-success form-check-solid"></div>
                                          </td>
                                          <td>
                                            <a
                                              href="#"
                                              className="text-gray-800 text-hover-primary fw-bold fs-6"
                                            >
                                              {e.communication}
                                            </a>
                                            <span className="text-gray-400 fw-bold fs-7 d-block">
                                              {e.communication_date}
                                            </span>
                                          </td>
                                          <td className="text-end">
                                            <span
                                              data-kt-element="status"
                                              className="badge badge-light-success"
                                            >
                                              {`Reminder-${index + 1}`}
                                            </span>
                                          </td>
                                        </tr>

                                      );
                                    })}
                                  </>
                                )}
                              </tbody>

                              {/*end::Table head*/}
                              {/*begin::Table body*/}
                              {/*end::Table body*/}
                            </table>
                          </div>
                          {/*end::Table*/}
                        </div>
                        {/*end::Tap pane*/}
                        {/*begin::Tap pane*/}
                        <div
                          className="tab-pane fade"
                          id="kt_timeline_widget_2_tab_3"
                          role="tabpanel"
                        >
                          {/*begin::Table container*/}
                          <div className="table-responsive">
                            {/*begin::Table*/}
                            <table className="table align-middle gs-0 gy-4">
                              {/*begin::Table head*/}
                              <thead>
                                <tr>
                                  <th className="p-0 w-10px" />
                                  <th className="p-0 w-25px" />
                                  <th className="p-0 min-w-400px" />
                                  <th className="p-0 min-w-100px" />
                                  <th className="p-0 min-w-125px" />
                                </tr>
                              </thead>
                              {/*end::Table head*/}
                              {/*begin::Table body*/}
                              <tbody>
                                <tr>
                                  <td>
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center h-40px bg-primary"
                                    />
                                  </td>
                                  <td className="ps-0">
                                    <div className="form-check form-check-custom  form-check-solid">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue
                                        data-kt-element="checkbox"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      href="#"
                                      className="text-gray-800 text-hover-primary fw-bold fs-6"
                                    >
                                      Workbook p. 17, tasks 1-6
                                    </a>
                                    <span className="text-gray-400 fw-bold fs-7 d-block">
                                      Mathematics
                                    </span>
                                  </td>
                                  <td className="text-end">
                                    <span
                                      data-kt-element="status"
                                      className="badge badge-light-primary"
                                    >
                                      In Process
                                    </span>
                                  </td>
                                  <td className="text-end">
                                    {/*begin::Icon*/}
                                    <div className="d-flex justify-content-end flex-shrink-0">
                                      {/*begin::Print*/}
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-color-muted btn-bg-light btn-active-color-primary btn-sm me-3"
                                      >
                                        <i className="ki-duotone ki-printer fs-3">
                                          <span className="path1" />
                                          <span className="path2" />
                                          <span className="path3" />
                                          <span className="path4" />
                                          <span className="path5" />
                                        </i>{' '}
                                      </a>
                                      {/*end::Print*/}
                                      {/*begin::Chat*/}
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-color-muted btn-bg-light btn-active-color-primary btn-sm me-3"
                                      >
                                        <i className="ki-duotone ki-sms fs-3">
                                          <span className="path1" />
                                          <span className="path2" />
                                        </i>{' '}
                                      </a>
                                      {/*end::Chat*/}
                                      {/*begin::Attach*/}
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-color-muted btn-bg-light btn-active-color-primary btn-sm"
                                      >
                                        <i className="ki-duotone ki-paper-clip fs-3" />{' '}
                                      </a>
                                      {/*end::Attach*/}
                                    </div>
                                    {/*end::Icon*/}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center h-40px bg-success"
                                    />
                                  </td>
                                  <td className="ps-0">
                                    <div className="form-check form-check-custom form-check-success form-check-solid">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue
                                        defaultChecked
                                        data-kt-element="checkbox"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      href="#"
                                      className="text-gray-800 text-hover-primary fw-bold fs-6"
                                    >
                                      Learn paragraph p. 99, Exercise
                                      1,2,3Scoping &amp; Estimations
                                    </a>
                                    <span className="text-gray-400 fw-bold fs-7 d-block">
                                      Chemistry
                                    </span>
                                  </td>
                                  <td className="text-end">
                                    <span
                                      data-kt-element="status"
                                      className="badge badge-light-success"
                                    >
                                      Done
                                    </span>
                                  </td>
                                  <td className="text-end">
                                    {/*begin::Icon*/}
                                    <div className="d-flex justify-content-end flex-shrink-0">
                                      {/*begin::Print*/}
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-color-muted btn-bg-light btn-active-color-primary btn-sm me-3"
                                      >
                                        <i className="ki-duotone ki-printer fs-3">
                                          <span className="path1" />
                                          <span className="path2" />
                                          <span className="path3" />
                                          <span className="path4" />
                                          <span className="path5" />
                                        </i>{' '}
                                      </a>
                                      {/*end::Print*/}
                                      {/*begin::Chat*/}
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-color-muted btn-bg-light btn-active-color-primary btn-sm me-3"
                                      >
                                        <i className="ki-duotone ki-sms fs-3">
                                          <span className="path1" />
                                          <span className="path2" />
                                        </i>{' '}
                                      </a>
                                      {/*end::Chat*/}
                                      {/*begin::Attach*/}
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-color-muted btn-bg-light btn-active-color-primary btn-sm"
                                      >
                                        <i className="ki-duotone ki-paper-clip fs-3" />{' '}
                                      </a>
                                      {/*end::Attach*/}
                                    </div>
                                    {/*end::Icon*/}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center h-40px bg-primary"
                                    />
                                  </td>
                                  <td className="ps-0">
                                    <div className="form-check form-check-custom  form-check-solid">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue
                                        data-kt-element="checkbox"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      href="#"
                                      className="text-gray-800 text-hover-primary fw-bold fs-6"
                                    >
                                      Write essay 1000 words “WW2 results”
                                    </a>
                                    <span className="text-gray-400 fw-bold fs-7 d-block">
                                      History
                                    </span>
                                  </td>
                                  <td className="text-end">
                                    <span
                                      data-kt-element="status"
                                      className="badge badge-light-primary"
                                    >
                                      In Process
                                    </span>
                                  </td>
                                  <td className="text-end">
                                    {/*begin::Icon*/}
                                    <div className="d-flex justify-content-end flex-shrink-0">
                                      {/*begin::Print*/}
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-color-muted btn-bg-light btn-active-color-primary btn-sm me-3"
                                      >
                                        <i className="ki-duotone ki-printer fs-3">
                                          <span className="path1" />
                                          <span className="path2" />
                                          <span className="path3" />
                                          <span className="path4" />
                                          <span className="path5" />
                                        </i>{' '}
                                      </a>
                                      {/*end::Print*/}
                                      {/*begin::Chat*/}
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-color-muted btn-bg-light btn-active-color-primary btn-sm me-3"
                                      >
                                        <i className="ki-duotone ki-sms fs-3">
                                          <span className="path1" />
                                          <span className="path2" />
                                        </i>{' '}
                                      </a>
                                      {/*end::Chat*/}
                                      {/*begin::Attach*/}
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-color-muted btn-bg-light btn-active-color-primary btn-sm"
                                      >
                                        <i className="ki-duotone ki-paper-clip fs-3" />{' '}
                                      </a>
                                      {/*end::Attach*/}
                                    </div>
                                    {/*end::Icon*/}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center h-40px bg-primary"
                                    />
                                  </td>
                                  <td className="ps-0">
                                    <div className="form-check form-check-custom  form-check-solid">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue
                                        data-kt-element="checkbox"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      href="#"
                                      className="text-gray-800 text-hover-primary fw-bold fs-6"
                                    >
                                      Internal conflicts in Philip Larkin poems,
                                      read p 380-515
                                    </a>
                                    <span className="text-gray-400 fw-bold fs-7 d-block">
                                      English Language
                                    </span>
                                  </td>
                                  <td className="text-end">
                                    <span
                                      data-kt-element="status"
                                      className="badge badge-light-primary"
                                    >
                                      In Process
                                    </span>
                                  </td>
                                  <td className="text-end">
                                    {/*begin::Icon*/}
                                    <div className="d-flex justify-content-end flex-shrink-0">
                                      {/*begin::Print*/}
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-color-muted btn-bg-light btn-active-color-primary btn-sm me-3"
                                      >
                                        <i className="ki-duotone ki-printer fs-3">
                                          <span className="path1" />
                                          <span className="path2" />
                                          <span className="path3" />
                                          <span className="path4" />
                                          <span className="path5" />
                                        </i>{' '}
                                      </a>
                                      {/*end::Print*/}
                                      {/*begin::Chat*/}
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-color-muted btn-bg-light btn-active-color-primary btn-sm me-3"
                                      >
                                        <i className="ki-duotone ki-sms fs-3">
                                          <span className="path1" />
                                          <span className="path2" />
                                        </i>{' '}
                                      </a>
                                      {/*end::Chat*/}
                                      {/*begin::Attach*/}
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-color-muted btn-bg-light btn-active-color-primary btn-sm"
                                      >
                                        <i className="ki-duotone ki-paper-clip fs-3" />{' '}
                                      </a>
                                      {/*end::Attach*/}
                                    </div>
                                    {/*end::Icon*/}
                                  </td>
                                </tr>
                              </tbody>
                              {/*end::Table body*/}
                            </table>
                          </div>
                          {/*end::Table*/}
                        </div>
                        {/*end::Tap pane*/}
                      </div>
                      {/*end::Tab Content*/}
                    </div>
                    {/*end::Body*/}
                  </div>
                </div>
              </div>
              <div className="row pt-5">
                <div className="col-12 card">
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={350}
                  />
                </div>
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row*/}
          </div>
          {/*end::Content container*/}
        </div>
      </Constant>
    </div>
  );
};
export default Dashboard;
