import React, { useEffect, useRef, useState } from 'react';
import Constant from '../../Constant/UserLayout';
// import Header from '../../Constant/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Url from '../../Elements/Api/Api';
import Swal from 'sweetalert2'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { default as ReactSelect } from 'react-select';
import AsyncSelect from 'react-select/async';
import Slider from 'react-slick';
import { FaCircle, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Modal from './Modal';
import { ClipLoader } from 'react-spinners';
import Header from '../../Constant/Header';

import { css } from '@emotion/react';
const maxLength = 70;

const UpdateLead = ({ text }) => {
  // const maxLength = 50;
  const { leadId } = useParams();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [finalCommunication, setFinalCommunication] = useState({});
  const [taxAmount, setTaxAmount] = useState('')
  const [oldProductId, setOldProductId] = useState(0);
  const [newProduct, setNewProduct] = useState([]);
  const [formData, setFormData] = useState({});
  const [activeOption, setActiveOption] = useState('Lead Details');
  const [type] = useState(['Existing', 'New']);
  const [dummyData, setDummyData] = useState([]);
  const [dummyData2, setDummyData2] = useState([]);
  const [stage, setStage] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedStage, setSelectedStage] = useState('');
  const [industry, setIndustry] = useState([]);
  const token = useSelector((state) => state.token);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedStageId, setSelectedStageId] = useState('');
  const [leadDate, setLeadDate] = useState('');
  const [ProductsInfo, setProductsInfo] = useState([]);

  const [selectedProductName, setSelectedProductName] = useState('');
  const [subtotalValue, setSubtotalValue] = useState('');
  const [edit, setEdit] = useState(false)



  const [searchClientByName, setSearchClientByName] = useState('');
  const [searchclient, setSearchClient] = useState('');
  const [searchedClient, setSearchedClient] = useState([]);
  const [error, setError] = useState('');
  const [id, setId] = useState('');
  const [clientData, setClientData] = useState([]);
  const [inputValue, setInputValue] = useState('a');
  const [inputValue2, setInputValue2] = useState('a');

  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchInputValue2, setSearchInputValue2] = useState('');
  const [communicationStates, setCommunicationStates] = useState([]);

  const [clientOptions, setClientOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [labelText, setLabelText] = useState('New');
  const [businessType, setBusinessType] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [leadtype, setLeadtype] = useState([]);
  const [check, setcheck] = useState('');
  const [communicationlead, setCommunicationlead] = useState([]);
  const [communicationError, setCommunicationError] = useState('')

  const [selectedMeasurements, setSelectedMeasurements] = useState([
    { id: '', qty: '', measurement_type: '', price: '', netValue: 0 }
  ]);
  const [leadData, setUpdateleadData] = useState({});

  const [product_no, setProduct_no] = useState('')

  const [industryId, setIndustryId] = useState('');
  const [measurementId, setMeasurementId] = useState('');
  const [productlisting, setProductListing] = useState([]);
  const [deleteRow, setDeleteRow] = useState('');
  const [addLead, setAddLead] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [productId, setProductId] = useState('');
  const [response, setResponse] = useState([]);
  const [isChecked, setIsChecked] = useState(0);
  const [leadCommunication, setLeadCommunation] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [communication, setCommunication] = useState('');
  const [isNestedModalOpen, setIsNestedModalOpen] = useState(false);
  const [isNestedUpdateModalOpen, setIsNestedUpdateModalOpen] = useState(false);

  const [freightCharges, setFreightCharges] = useState('');
  const [loadingUnloadingCharges, setLoadingUnloadingCharges] = useState('');



  // reamrks add field 
  const [additionalData, setAdditionalData] = useState({
    remarks: '',
    delivery: '',
    gst: '',
    fright: '',
    payment: '',
    make: '',
    note: '',
    // terms: ''
  });



  const override = css`
display: block;
margin: 0 auto;
`;

  // Function to toggle between full and truncated communication content
  const toggleReadMore = (index) => {
    const newStates = [...communicationStates];
    newStates[index] = !newStates[index];
    setCommunicationStates(newStates);
  };

  const closeNestedModal = () => setIsNestedModalOpen(false);
  const closeNestedUpdateModal = () => setIsNestedUpdateModalOpen(false);

  const openModal = () => setIsModalOpen(true);
  const openNestedModal = () => setIsNestedModalOpen(true);

  const [communicationData, setCommunicationData] = useState([
    {
      communication: '',
      communication_date: '',
      isReminder: ''
    }
  ]);
  const [clientSaveData, setClientSaveData] = useState({
    organization: '',
    gst_no: '',
    address: '',
    industry_type: ''
  });
  const [inputFields, setInputFields] = useState([
    { value1: '', value2: '', value3: '', value4: '' }
  ]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [productsData, setproductsData] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('')
  const [formCount, setFormCount] = useState(1);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([])
  const [measurementType, setMeasurementType] = useState('');
  const [basePrice, setBasePrice] = useState('');
  const [productCount, setProductCount] = useState('');
  const [productSlideData, setProductSlideData] = useState({
    qty: '',
    kg: '',
    totalQuantity: '',
    price: '',
    priceBeforeDiscount: '',
    discount: '0',
    priceAfterDiscount: '',
    gst: '18',
    netValue: '',
    loadingUnloading: '0',
    freight: '0'
  });
  const [totalQuanity, setTotalQuanity] = useState('');
  const [priceBeforeDiscount, setPriceBeforeDiscount] = useState('');
  const [priceAfterDiscount, setPriceAfterDiscount] = useState('');
  const [netValue, setNetValue] = useState('');
  const [detailStage, setDetailStage] = useState('');
  const [purposeType, setPurposeType] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  //product maping
  // const [productId,setProduct]

  const closeModal = () => setIsModalOpen(false);
  const closeUpdateModal = () => setIsUpdateModalOpen(false);

  const [productIndex, setProductIndex] = useState('');
  const [productIndexForDelete, setProductIndexForDelete] = useState('');
  const [leadIdDelete, setLeadId] = useState('');
  const [loading, setLoading] = useState(false);

  //additinal field
  const handleAdditionalDataChange = (e) => {
    const { name, value } = e.target;
    setAdditionalData({ ...additionalData, [name]: value })
  }

  // console.log('communicationData--->>', leadCommunication,'communicationlead',communicationlead,'finalCommunication',);
  //handler for input change
  const handleProductInputChange = (e) => {
    const { name, value } = e.target;
    // if (/^\d*$/.test(value)) {
    if ((name === 'gst' || name === 'discount') && parseInt(value) > 100) {
      // If greater than 100, set the value to 100
      e.target.value = '100';
    } else {
      // var keyToRemove = e.target.name;
      setProductSlideData({
        ...productSlideData,
        [name]: value
      });
      // }
    }
    setError({ ...error, [e.target.name]: '' });
  };

  const getBrandList = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
      const response = await axios.get(Url.BASEURL + 'lead-brand-list', config)
      if (response.data.status === 200) {
        setBrandList(response.data.response)
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getBrandList();
  }, [])




  useEffect(() => {
    if (
      selectedProduct &&
      selectedSubcategory &&
      selectedCategory &&
      measurementType
    ) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      //https://apirest.symang.in/api/base-price?category_id=5&sub_category_id=6&product_id=1
      axios
        .get(
          Url.BASEURL +
          'base-price-lead?' +
          'category_id=' +
          selectedCategory.value +
          '&sub_category_id=' +
          selectedSubcategory.value +
          // (id !== '' ? '&client_id=' + id.value : '') +
          '&product_id=' +
          selectedProduct.value +
          '&measurement_type=' +
          measurementType.value,
          config
        )
        .then((value) => {
          if (value.data.response.base_price !== null) {
            setBasePrice(value.data.response.base_price.base_price);
          } else {
            // setBasePrice('');
          }
          // }
        })
        .catch((err) => console.log(err));
    }
  }, [selectedCategory, selectedSubcategory, selectedProduct, measurementType]);


  const handleInputChange1 = (index, fieldName, value) => {
    const newInputFields = [...inputFields];
    newInputFields[index][fieldName] = value;
    setInputFields(newInputFields);
  };
  const handleClientDataInputChange = (e) => {
    const { name, value } = e.target;
    setClientSaveData({
      ...clientSaveData,
      [name]: value
    });
  };

  const transformBrandToOptions = (data) => {
    const selectOption = { value: '', label: 'select...' };
    const dataOptions = data.map(({ id, bname }) => ({
      value: id.toString(),
      label: `${bname}`
    }));
    return [selectOption, ...dataOptions];
  };
  const BrandOptions = transformBrandToOptions(brandList);
  // const handleAddInputFields = () => {
  //   // Check the total number of fields added
  //   if (inputFields.length < 3) {
  //     setInputFields([
  //       ...inputFields,
  //       { value1: '', value2: '', value3: '', value4: '' }
  //     ]);
  //   }
  // };

  // const handleRemoveInputFields = (index) => {
  //   const updatedData = [...productData];
  //   updatedData.splice(index, 1);
  //   setProductData(updatedData);
  // };

  const transformIndustryDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, industry_type }) => ({
      value: id.toString(),
      label: `${industry_type}`
    }));
    return [selectOption, ...dataOptions];
  };
  const IndustryOptions = transformIndustryDataToOptions(industry);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked ? 1 : 0);
  };
  useEffect(() => {
    // setFinalCommunication(communicateData);
    const params = {
      communication: communicationData.communication ?? '',
      communication_date: communicationData.communication_date ?? '',
      is_reminder: isChecked,
      lead_id: leadId
    };
    setFinalCommunication(params);
    const initialStates = communicationlead.map(() => false);
    setCommunicationStates(initialStates);
  }, [communicationlead]);
  // function for toggle readmore/readless

  // function for change label

  useEffect(() => {
    // Log selectedOption here, it will have the updated value
    if (selectedOption) {
      setcheck(selectedOption);
    }
  }, [selectedOption]);

  // Function of add clientlisting
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
        'searchstaffclients?' +
        'search=' +
        searchInputValue +
        '&page=',
        config
      )
      .then((value) => {
        setDummyData(value.data.response);
        setSearchedClient(value.data.response.data);
        setClientOptions(
          value.data.response.map((e) => ({
            id: e.id,
            value: e.id, // You can use e.id here if you prefer
            label: e.client
          }))
        );
      })
      .catch((err) => console.log(err));
  }, [searchInputValue]);

  // function for product listing
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'productdropdown?' + 'search=' + '&page=', config)
      .then((value) => setDummyData2(value.data.response))
      .catch((err) => console.log(err));
  }, []);

  // state to add new lead
  const [bookRoomData, setBookRoomData] = useState([{}]);

  // state to add product

  // const [productData, setProductData] = useState([
  //   {
  //     // product_id: '',
  //     product_name: '',
  //     product_price: '',
  //     product_qty: '',
  //     price_qty: ''
  //   }
  // ]);

  const handleIndustryChange = (e) => {
    setSelectedIndustry(e.target.value);
  };

  // const handlestageChange = (e) => {
  //   setSelectedStage(e.target.value);
  // };

  const handleChange = (index, event, selectedOption) => {
    if (selectedOption) {
      // Parse the selected option's client field to extract the desired text
      const clientText = selectedOption.client.split(' - ')[0];
      setInputValue(clientText);
      fetchData(selectedOption.id); // You can call your fetchData function here
    } else {
      setInputValue(''); // Clear the input field if nothing is selected
    }

    if (event && event.target) {
      const { name, value } = event.target;
      var keyToRemove = value;
      const newState = bookRoomData.map((obj) => {
        if (obj) {
          return { ...obj, [name]: value };
        }
      });

      setBookRoomData(newState);
    }
  };

  // const [formData2, setFormData2] = useState([
  //   { product_id: '', name: '', price: 0, qty: 0, netValue: 0 }
  // ]);

  // FUNCTION TO ADD PRODUCT IN THE LEAD
  // const handleProductSearch = (index, event) => {
  //   const item = event.target.value;
  //   const member2 = dummyData2.find((data) => data.item_name === item);
  //   const memberData2 = {
  //     // product_id: member2 && member2.id ? member2.id : '',
  //     product_name: member2 && member2.item_name ? member2.item_name : '',
  //     product_price: member2 && member2.price ? member2.price : '',
  //     product_qty: 0,
  //     price_qty: 0
  //   };
  //   if (member2) {
  //     const values = [...productData];
  //     values[index] = {
  //       ...memberData2,
  //       item
  //     };
  //     setProductData(values);
  //     // setFormData2(memberData2);
  //   } else {
  //     const values = [...productData];
  //     values[index] = {
  //       // product_id: '',
  //       product_name: '',
  //       product_price: '',
  //       product_qty: '',
  //       price_qty: ''
  //     };
  //     setProductData(values);
  //   }
  // };

  // orders section

  // const handleChange2 = (index, event) => {
  //   const updatedData = [...selectedMeasurements];
  //   const { name, value } = event.target;
  //   updatedData[index][name] = value;

  //   if (name === 'product_price' || name === 'qty') {
  //     updatedData[index].price_qty =
  //       updatedData[index].selctedMeasurement[index].qty *
  //       updatedData[index].product_price;
  //   }
  //   setProductData(updatedData);
  // };

  // const handleAddFields = () => {
  //   const updatedData = [...productData];
  //   updatedData.push({
  //     // product_id: '',
  //     product_name: '',
  //     product_price: 0,
  //     product_qty: 0,
  //     price_qty: 0
  //   });
  //   setProductData(updatedData);

  //   const measurement = [...selectedMeasurements];
  //   measurement.push({
  //     id: '',
  //     product_id: '',
  //     qty: '',
  //     measurement_type: '',
  //     price: '',
  //     netValue: 0
  //   });
  //   setSelectedMeasurements(measurement);
  // };

  // const handleRemoveFields = (id, index) => {
  //   handleMeasurementChange('remove', '', index);
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };
  //   const params = {
  //     id: id
  //   };
  //   /** */
  //   axios
  //     .delete(Url.BASEURL + 'deleteleadproduct/' + id, config)
  //     .then((value) => {
  //       setDeleteRow(value.data.response);
  //       getData();
  //     })
  //     .catch((err) => console.log(err));

  //   /** */
  // };

  const leadSubmit = (e) => {
    setLoading(true)
    const allSlides = productsData.length === 0 ? [] : [...productsData];
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    if (purposeType !== 'feedback' && allSlides.length <= 0) {
      Swal.fire("Products cannot be empty. Please add products.");
      setLoading(false);
      return
    }
    const params = {
      title: leadData.title,
      description: leadData.description,
      // lead_value: calculateTotalNetValue,
      business_type: leadData.business_type,
      expt_close_date: leadData.expt_close_date,
      // stage: selectedStage,
      stage: detailStage,
      lead_organisation: leadData.lead_organisation,
      // lead_contact_no: leadData.lead_contact_no,
      // products: selectedMeasurements,
      // products: productsData,
      products: productsData,
      communication: communicationlead,
      remark_lead: additionalData.remarks,
      delivery_lead: additionalData.delivery,
      gst_lead: additionalData.gst,
      freight_lead: additionalData.fright,
      payment_lead: additionalData.payment,
      make_lead: additionalData.make,
      additionalNotes_lead: additionalData.note,
      // termsCondition_lead: additionalData.terms,
      freight: freightCharges,
      loadingUnloading: loadingUnloadingCharges
    };

    axios
      .put(Url.BASEURL + 'updatelead/' + leadId, params, config)
      .then((value) => {
        if (value && value.data.status === 200) {
          // toast('Client update Successfully');
          setAddLead(value.data.response);
          setLoading(false)
          Swal.fire({
            title: "Lead Updation",
            text: "Your Lead has been successfully updated.",
            icon: "success",
            confirmButtonColor: 'green',
            allowOutsideClick: false,

          });
          navigate('/manageleads');
        } else {
          setError(value.data.errors);
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'productdropdown?' + 'search=' + searchInputValue2,
        config
      )
      .then((value) => {
        if (value && value.data.status === 200) {
          setProductOptions(
            value.data.response.map((e) => ({
              id: e.id,
              value: e.id, // You can use e.id here if you prefer
              label: e.item_name
            }))
          );
        }
      })
      .catch((err) => console.log(err));
  }, [searchInputValue, token, selectedOption]);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'searchstaffclients?' + 'search=' + searchInputValue,
        config
      )
      .then((value) => {
        if (value && value.data.status === 200) {
        }
      })
      .catch((err) => console.log(err));
  }, [searchInputValue, token]);

  // function to add net values

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'leadstages', config)
      .then((value) => setStage(value.data.response))
      .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };
  //   axios
  //     .get(Url.BASEURL + 'leadtype', config)
  //     .then((value) => setLeadtype(value.data.response))
  //     .catch((err) => console.log(err));
  // }, []);

  // industry listing

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'allindustry', config)
      .then((value) => setIndustry(value.data.response))
      .catch((err) => console.log(err));
  }, []);
  const BusinessTypeChangeHandler = (event) => {
    setBusinessType(event.target.value);
  };

  const fetchData = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'fetchclientbyid/' + id, config)
      .then(
        (value) =>
          setBookRoomData(value.data.response) &
          setInputValue(value.data.response[0].owner_name) &
          setSelectedIndustry(value.data.response[0].industry_id) &
          setOwnerName(value.data.response[0].owner_name.split('-')[0].trim())
      )
      .catch((err) => console.log(err));
  };

  useEffect((e) => {
    if (searchclient) {
    }
  }, []);

  const handleInputChange2 = (newValue) => {
    setSearchInputValue2(newValue);
  };
  // const fetchProductData = (id, index) => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };
  //   axios
  //     .get(Url.BASEURL + 'fetchproductdropdown/' + id, config)
  //     .then((value) => {
  //       let productDataById = value.data.response[0];

  //       handleMeasurementChange('productid', productDataById.id, index);
  //       handleMeasurementChange('price', productDataById.price, index);
  //       handleMeasurementChange(
  //         'measurement_type',
  //         productDataById.measurement,
  //         index
  //       );

  //       const memberData2 = {
  //         product_id:
  //           productDataById && productDataById.id ? productDataById.id : '',
  //         product_name:
  //           productDataById && productDataById.item_name
  //             ? productDataById.item_name
  //             : '',
  //         product_price:
  //           productDataById && productDataById.price
  //             ? productDataById.price
  //             : '',
  //         product_gst:
  //           productDataById && productDataById.gst ? productDataById.gst : '',
  //         product_length:
  //           productDataById && productDataById.length
  //             ? productDataById.length
  //             : '',
  //         product_kg:
  //           productDataById && productDataById.kg ? productDataById.kg : '',
  //         product_measurement: selectedMeasurements,
  //         product_qty: 0,
  //         price_qty: 0
  //       };

  //       const values = [...productData];
  //       values[index] = {
  //         ...memberData2
  //       };
  //       setProductData(values);
  //       setInputValue2([{ index: index, id: value.data.response[0].id }]);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const handleMeasurementChange = (type, event, index) => {
  //   setSelectedMeasurements((prevMeasurements) => {
  //     const updatedMeasurements = [...prevMeasurements];
  //     const item = { ...updatedMeasurements[index] };
  //     if (type === 'id') {
  //       item.id = event;
  //     }
  //     if (type === 'productid') {
  //       item.product_id = event;
  //     }
  //     if (type === 'measurement') {
  //       item.measurement_type = event.target.value;
  //     }
  //     if (type === 'measurement_type') {
  //       item.measurement_type = event;
  //     }
  //     if (type === 'quantity') {
  //       item.qty = event.target.value;
  //       if (item.price !== '') {
  //         item.netValue = item.price * item.qty;
  //       }
  //     }
  //     if (type === 'qty') {
  //       item.qty = event;
  //       if (item.price !== '') {
  //         item.netValue = item.price * item.qty;
  //       }
  //     }
  //     if (type === 'price') {
  //       item.price = event;
  //       if (item.qty !== '') {
  //         item.netValue = item.price * item.qty;
  //       }
  //     }
  //     if (type === 'remove') {
  //       updatedMeasurements.splice(index, 1);
  //     } else {
  //       updatedMeasurements[index] = item;
  //     }
  //     return updatedMeasurements;
  //   });
  // };
  //
  // useEffect(() => {
  //   getData();
  // }, [deleteRow, updateleadData]);
  useEffect(() => {
    getData();
  }, [deleteRow]);

  const getData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(Url.BASEURL + 'editlead/' + leadId, config)
      .then((value) => {
        console.log(value);
        if (value && value.data.status === 200) {
          setAdditionalData({
            remarks: value.data.response.lead.remark_lead,
            delivery: value.data.response.lead.delivery_lead,
            gst: value.data.response.lead.gst_lead,
            fright: value.data.response.lead.freight_lead,
            payment: value.data.response.lead.payment_lead,
            make: value.data.response.lead.make_lead,
            note: value.data.response.lead.additionalNotes_lead,
            // terms: value.data.response.lead.termsCondition_lead,
          });
          setLoadingUnloadingCharges(value.data.response.lead.loadingUnloading);
          setFreightCharges(value.data.response.lead.freight);

          setUpdateleadData(value.data.response.lead);
          setPurposeType(value.data.response.lead.purpose_of_visit);
          setDetailStage(value.data.response.lead.stage);
          setproductsData(value.data.response.lead.leadproducts);
          setProducts(value.data.response.product_list);
          setLeadtype(value.data.response.measurement_type);
          setProductCount(value.data.response.lead.leadproducts_count);
          //for category and sub category
          setCategoryList(value.data.response.category);
          setSubCategoryList(value.data.response.sub_category);
          // setIsChecked()
          setClientData(value.data.response.lead.client_data.clientInfo);
          setIndustryId(value.data.response.client.industry_id);
          setMeasurementId(value.data.response.leadproducts.measurement);
          setSelectedStage(value.data.response.lead.stage);
          setSelectedStageId(value.data.response.lead.stage);
          setTitle(value.data.response.title);
          setLeadCommunation(value.data.response.leadcommunication);
          const ldata = value.data.response.leadproducts;


          if (ldata.length > 0) {
            setOldProductId(ldata.length - 1);
            // for (var i = 0; i < ldata.length; i++) {
            //   handleMeasurementChange('id', ldata[i].id, i);
            //   handleMeasurementChange('productid', ldata[i].product_id, i);
            //   handleMeasurementChange('qty', ldata[i].product_qty, i);
            //   handleMeasurementChange('price', ldata[i].product_price, i);
            //   handleMeasurementChange(
            //     'measurement_type',
            //     ldata[i].measurement,
            //     i
            //   );
            // }
          }

          // let ledta = value.data.response.leadproducts;
          // if (ledta) {
          //   ledta.push(productData);

          //   setProductListing(ledta);
          // }
        } else {
          navigate('/*')
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (leadData) {
      setResponse(leadData);
    }
  }, [leadData, deleteRow]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdateleadData({ ...leadData, [name]: value });
    // setName(value);
    // setEmail(value);
  };
  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
  };

  const communicationChangeHandler = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      communication: communicationData.communication,
      communication_date: communicationData.communication_date ? communicationData.communication_date : null,
      is_reminder: isChecked
    };
    axios
      .post(Url.BASEURL + 'leadcommunication/' + leadId, params, config)
      .then((value) => {
        setIsChecked(0);
        setCommunicationData({
          communication: '',
          communication_date: '',
          isReminder: 0
        });
        fetchCommunication();
        setCommunicationError(value.data.response.communication)
        // params['lead_id'] = leadId;
        // setLeadCommunation([...leadCommunication, params]);
      })
      .catch((err) => console.log(err));
  };
  const handleInputChange3 = (e) => {
    const { name, value } = e.target;
    setCommunicationData({
      ...communicationData,
      [name]: value
    });
    setError({ ...error, [e.target.name]: '' });
  };


  const fetchCommunication = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'communicationcall/' + leadId, config)
      .then((value) => {
        setCommunicationlead(value.data.response);
        setCommunication(
          isExpanded
            ? value.data.response.communication
            : value.data.response.communication.slice(0, maxLength)
        );
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    fetchCommunication();
  }, [communicationData]);

  const transformStageToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, stage }) => ({
      value: id.toString(),
      label: `${stage}`
    }));
    return [selectOption, ...dataOptions];
  };
  const stageDataOption = transformStageToOptions(stage);

  const handleSelectChange = (selectedOption) => {
    // Assuming selectedOption is an object with a 'value' property
    const selectedStageId = selectedOption ? selectedOption.value : null;
    setDetailStage(selectedStageId);
    // setId(selectedStageId);
  };

  // functions for
  const sliderRef = useRef(null); // Initialize useRef with null
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => {
      setCurrentSlide(current);
    }
  };
  // const settings = {
  //   // Your settings for the Slider component
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   afterChange: (current) => {
  //     setCurrentSlide(current); // Update the current slide index
  //     // Perform actions based on the current slide index
  //     // You can perform any action or logic here based on the current slide index
  //   },
  // };
  // const deleteForm = (index) => {
  //   const updatedForms = [...Array(formCount)];

  //   updatedForms.splice(index, 1);

  //   setFormCount(updatedForms.length);
  //   if (currentSlide >= updatedForms.length) {
  //     setCurrentSlide(updatedForms.length - 1);
  //   }
  // };

  // const handleRemoveInputFields = (index) => {
  //   const updatedData = [...productData];
  //   updatedData.splice(index, 1);
  //   setProductData(updatedData);
  // };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
      // setCurrentSlideIndex((prevIndex) => prevIndex + 1);
    }
  };
  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
      // setCurrentSlideIndex((prevIndex) => prevIndex - 1);
    }
  };

  const productOptionss = products.map(
    ({ id, product_name, category_related_product }) => ({
      value: id.toString(),
      label: `${product_name}`,
      categoryRelatedProduct: category_related_product
    })
  );

  console.log(selectedProduct);
  const categoryOptions = categoryList.map((id, name, sub_category) => ({
    value: id.id.toString(),
    label: id.name,
    sub_category: id.sub_category
  }));

  const subcategoryOptions = subCategoryList.map((id, name) => ({
    value: id.id.toString(),
    label: id.name
  }));

  const transformMeasurementToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name }) => ({
      value: id.toString(),
      label: `${name}`
    }));
    return [selectOption, ...dataOptions];
  };
  const measurementDataOption = transformMeasurementToOptions(leadtype);

  const handleBasePriceInputChange = (e) => {
    setBasePrice(e.target.value);
  };
  useEffect(() => {
    handleSubTotal();
    if (measurementType) {
      handleMultiplications();
    }
    handlePrice();
    if (priceBeforeDiscount && productSlideData.discount) {
      handlePriceDiscount();
    }
    // if (priceAfterDiscount) {
    handleNetAmount();
    // }
  }, [
    measurementType,
    productSlideData,
    totalQuanity,
    basePrice,
    priceBeforeDiscount,
    productSlideData,
    priceAfterDiscount
  ]);

  // const handleMultiplications = () => {
  //   // measurementType.label == 'Kilogram '
  //   if (measurementType.label == 'Kilogram ') {
  //     // alert('hello')
  //     let totalQty = productSlideData.qty * productSlideData.kg;
  //     // setTotalQuanity(totalQty);
  //   } else if (measurementType.label == 'Meter ') {
  //     // setTotalQuanity('');
  //     let totalQty = productSlideData.qty * 6;
  //     // setTotalQuanity(totalQty);
  //   } else if (measurementType.label == 'Feet ') {
  //     // setTotalQuanity('');
  //     let totalQty = productSlideData.qty * 20;
  //     // setTotalQuanity(totalQty);
  //   } else if (measurementType.label == 'Pieces ') {
  //     setTotalQuanity('');
  //     let totalQty = productSlideData.qty * 1;
  //     setTotalQuanity(totalQty);
  //   }
  // };
  const handleMultiplications = () => {
    // measurementType.label == 'Kilogram '
    if (measurementType.label == 'KILOGRAMS') {
      // alert('hello')
      let totalQty = productSlideData.qty * productSlideData.kg;
      // setTotalQuanity(totalQty);
    } else if (measurementType.label == 'METRIC TONS') {
      // setTotalQuanity('');
      // let totalQty = productSlideData.qty * 6;
      // setTotalQuanity(totalQty);
    } else if (measurementType.label == 'METER') {
      // setTotalQuanity('');
      let totalQty = productSlideData.qty * 6;
      // setTotalQuanity(totalQty);
    } else if (measurementType.label == 'FEET') {
      // setTotalQuanity('');
      let totalQty = productSlideData.qty * 20;
      // setTotalQuanity(totalQty);
    } else if (measurementType.label == 'PIECES') {
      setTotalQuanity('');
      let totalQty = productSlideData.qty * 1;
      console.log(productSlideData);
      setTotalQuanity(totalQty);
    }
  };

  const handlePrice = () => {
    if (totalQuanity && basePrice) {
      let totalPriceBeforeDiscount = basePrice;
      setPriceBeforeDiscount(totalPriceBeforeDiscount);
    }
  };

  const handlePriceDiscount = () => {
    if (priceBeforeDiscount) {
      let discountPrice =
        (priceBeforeDiscount * productSlideData.discount) / 100;
      let newAmount = priceBeforeDiscount - discountPrice;
      setPriceAfterDiscount(newAmount);
      setTaxAmount(newAmount * totalQuanity)
    }
  };

  const handleSubTotal = () => {
    let gstAmount = (priceAfterDiscount * productSlideData.gst) / 100;

    let subTotalValue = priceAfterDiscount + gstAmount;
    setNetValue(subTotalValue);
  };
  const handleNetAmount = () => {
    // if (priceAfterDiscount) {

    let gstAmount = (taxAmount * productSlideData.gst) / 100;

    // console.log(
    //   '---------------->>>priceAfterDiscount',
    //   priceAfterDiscount,
    //   'gstAmount',
    //   gstAmount,
    //   '==<<',
    //   productSlideData.discount,
    //   '----....',
    //   priceBeforeDiscount
    // );
    let netAmount = taxAmount + gstAmount;
    if (productSlideData.discount === 100) {
      netAmount = 0;
    }
    setNetValue(netAmount);
    // }
  };
  const handleRadioChange = (event) => {
    setPurposeType(event.target.value);
  };




  function restrictToRange(inputElement) {
    // Get the current value of the input field
    let value = parseInt(inputElement.value);

    // If the value is less than 0, set it to 0
    if (value < 0) {
      inputElement.value = 0;
    }
    // If the value is greater than 100, set it to 100
    else if (value > 100) {
      inputElement.value = 100;
    }
  }


  const transformCategoryToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name }) => ({
      value: id.toString(),
      label: `${name} `
    }));
    return [selectOption, ...dataOptions];
  };
  const categoryDataOption = transformCategoryToOptions(categoryList);
  const businessTypeData = [
    { label: 'Existing', value: 'Existing' },
    { label: 'New', value: 'New' }
  ];

  const transformSubCategoryToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name }) => ({
      value: id.toString(),
      label: `${name} `
    }));
    return [selectOption, ...dataOptions];
  };
  const subCategoryDataOption = transformSubCategoryToOptions(subCategoryList);

  // useEffect(() => {
  //   // Set initial selected product, category, and subcategory based on preloaded data
  //   const initialSelectedProduct = productOptionss.find((e) => e.value === data.product_id);
  //   setSelectedProduct(initialSelectedProduct);

  //   if (  ) {
  //     const initialSelectedCategory = categoryOptions.find((e) => e.value === data.category_id);
  //     setSelectedCategory(initialSelectedCategory);
  //   }

  //   if (initialSelectedCategory) {
  //     const initialSelectedSubcategory = subcategoryOptions.find((e) => e.value === data.subcategory_id);
  //     setSelectedSubcategory(initialSelectedSubcategory);
  //   }
  // }, [data, productOptionss, categoryOptions, subcategoryOptions]);

  const [slides, setSlides] = useState([{}]);
  //function for add more slides
  const [productError, setProductError] = useState('');

  // console.log(productsData);
  const addSlider = () => {
    if (measurementType.value === '1'
      &&
      (!selectedProduct ||
        !selectedCategory ||
        !productSlideData.qty ||
        !measurementType ||
        !basePrice ||
        !productSlideData.discount ||
        !productSlideData.gst ||
        !productSlideData.kg)
    ) {
      // Code block
      // console.log('kilo');
      setProductError('All Fields are mandatory*');
    }
    else if (
      measurementType.value !== '1'
      &&
      (!selectedProduct ||
        !selectedCategory ||
        !productSlideData.qty ||
        !measurementType ||
        !basePrice ||
        !productSlideData.discount ||
        !productSlideData.gst)
    ) {
      // Code block
      console.log("not kilo");
      setProductError('All Fields are mandatory*');
    } else {
      const newSlide = {
        product_no: !edit ? productsData.length + 1 : product_no,
        product_id: selectedProduct.value, //product Id
        product_name: selectedProduct.label,
        brand_id: selectedBrand.value,
        brand_name: selectedBrand.label,
        category_id: selectedCategory.value, // categoryID
        category_name: selectedCategory.label,
        sub_category_id: selectedSubcategory.value, // subcategoryId
        sub_category_name: selectedSubcategory.label, // subcategoryId
        measurement: measurementType.value, // measurement id
        total_quantity: totalQuanity,
        product_price: basePrice !== null ? basePrice : productSlideData.price, // price
        product_qty: productSlideData.qty, // product qty
        discount: productSlideData.discount, // discount in %
        product_gst: productSlideData.gst, // gst
        product_kg:
          measurementType.label === 'KILOGRAMS'
            ? productSlideData.kg
            : measurementType.label === 'METER'
              ? 6
              : measurementType.label === 'FEET'
                ? 20
                : '1',
        // measurement value (kd,meter,feet)
        // price_before_discount: priceBeforeDiscount,
        taxable_amount: taxAmount,
        net_price: productSlideData.discount
          ? priceAfterDiscount
          : priceBeforeDiscount,
        total_price: netValue,
        total_quantity: totalQuanity
        // staff_id:,
        // lead_id:

        // freight: productSlideData.freight,
        // loadingUnloading: productSlideData.loadingUnloading
      };
      console.log(productsData);
      console.log(newSlide);
      if (edit === true) {
        const index = productsData.findIndex(product =>
          product.product_no != newSlide.product_no &&
          product.product_id == newSlide.product_id &&
          product.brand_id == newSlide.brand_id &&
          product.category_id == newSlide.category_id &&
          product.sub_category_id == newSlide.sub_category_id
        )
        if (index !== -1) {
          // If product exists, update it
          setProductError('Product Already Exists Kindly Go for Update*');
        } else {
          const prod = productsData.findIndex(product => product.product_no == newSlide.product_no)
          if (prod !== -1) {
            // If product exists, update it
            const updatedProductsData = [...productsData];
            updatedProductsData[prod] = newSlide;
            setproductsData(updatedProductsData)
            setProductError('');
            setSelectedBrand('')
            setMeasurementType('');
            setSelectedProduct('');
            setSelectedCategory('');
            setSelectedSubcategory('');
            setBasePrice('');
            setTotalQuanity('');
            setPriceBeforeDiscount('');
            setTaxAmount('')
            setPriceAfterDiscount('');
            setNetValue('');
            setProductSlideData({
              qty: '',
              kg: '',
              totalQuantity: '',
              price: '',
              priceBeforeDiscount: '',
              discount: '0',
              // priceAfterDiscount: '',
              gst: '18',
              netValue: '',
              priceBeforeDiscount: '',
              priceAfterDiscount: '',
              netValue: '',
              freight: '0',
              loadingUnloading: '0'
            });
            setEdit(false)


          }
        }

      } else {
        const index = productsData.findIndex(product =>
          product.product_id == newSlide.product_id &&
          product.brand_id == newSlide.brand_id &&
          product.category_id == newSlide.category_id &&
          product.sub_category_id == newSlide.sub_category_id
        )
        if (index !== -1) {
          // If product exists, update it
          setProductError('Product Already Exists Kindly Go for Update*');
        } else {
          setproductsData((prevData) => [...prevData, newSlide]);
          setProductError('');
          setSelectedBrand('')
          setMeasurementType('');
          setSelectedProduct('');
          setSelectedCategory('');
          setSelectedSubcategory('');
          setBasePrice('');
          setTotalQuanity('');
          setPriceBeforeDiscount('');
          setTaxAmount('')
          setPriceAfterDiscount('');
          setNetValue('');
          setProductSlideData({
            qty: '',
            kg: '',
            totalQuantity: '',
            price: '',
            priceBeforeDiscount: '',
            discount: '0',
            // priceAfterDiscount: '',
            gst: '18',
            netValue: '',
            priceBeforeDiscount: '',
            priceAfterDiscount: '',
            netValue: '',
            freight: '0',
            loadingUnloading: '0'
          });
          setEdit(false)

        }
      }

    }
  };

  const handleInputChangeslide = (index, fieldName, value) => {
    setproductsData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [fieldName]: value };
      return newData;
    });
  };

  // const deleteForm = (index) => {
  //   const updatedForms = [...Array(formCount)];

  //   updatedForms.splice(index, 1);

  //   setFormCount(updatedForms.length);
  //   if (currentSlide >= updatedForms.length) {
  //     setCurrentSlide(updatedForms.length - 1);
  //     // let newdata = productsData.length - 1
  //     // setproductsData(newdata);
  //   }
  // };

  const deleteForm = (index) => {
    setFormCount(formCount - 1);
    setproductsData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const DeleteProduct = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: productId
    };
    /** */
    axios
      .delete(
        Url.BASEURL + 'deleteleadproduct/' + productIndex + '/' + leadIdDelete,
        config
      )
      .then((value) => {
        setIsModalOpen(false);
        setDeleteRow(value.data.response);
        setproductsData(productsData);
        // getData();
      })
      .catch((err) => console.log(err));
  };
  // const handleRadioChange = (event) => {
  //   setPurposeType(event.target.value);
  // };

  const deleteProduct = () => {
    const updatedProducts = [...productsData];
    updatedProducts.splice(productIndexForDelete, 1);
    setproductsData(updatedProducts);
    setIsModalOpen(false);
  };

  const handleEditProduct = async (product) => {
    try {
      setProduct_no(product.product_no)
      setSelectedProduct(productOptionss.find(prod => prod.value == product.product_id))
      setSelectedBrand(BrandOptions.find(brand => brand.value == product.brand_id))
      setSelectedCategory(categoryOptions.find(category => category.value == product.category_id))
      setSelectedSubcategory(subcategoryOptions.find(subCategory => subCategory.value == product.sub_category_id))
      setProductSlideData({
        qty: product.product_qty,
        kg: product.product_kg,
        discount: product.discount,
        gst: product.product_gst
      })
      setMeasurementType(measurementDataOption.find(measurements => measurements.value == product.measurement))
      setBasePrice(product.product_price)
      setTotalQuanity(product.total_quantity)

    } catch (error) {
      console.log(error);
    }
  }

  // modal for confirmation
  // {
  //   isModalOpen && (
  //     <Modal
  //       style={{ display: 'block' }}
  //       isOpen={isModalOpen}
  //       onClose={closeModal}
  //     >
  //       <h2>Are you sure you want to update ?</h2>
  //       <button
  //         style={{ marginTop: '30px' }}
  //         type="button"
  //         className="btn btn-success"
  //         onClick={leadSubmit}
  //         // disabled={isButtonDisabled}
  //       >
  //         Present
  //       </button>
  //     </Modal>
  //   );
  // }
  const handlePresentClick = () => {
    openNestedModal();
  };
  const closeAllModals = () => {
    closeNestedModal();
    closeModal();
  };
  const closeAllUpdateModals = () => {
    closeNestedUpdateModal();
    closeUpdateModal();
  };
  return (
    <>
      {loading &&
        <div className="loader-backdrop">
          <div className="spinner-container">
            <ClipLoader color={'#50CD89'} loading={loading} css={override} size={60} />
          </div>
        </div>
      }
      <div>
        <Constant>
          <Header
          // btnName="Add Client"
          // onpage_link="My Clients"
          // heading="My Clients"
          // btn_path="addclient"
          // btnName2="Export Clientdata"
          // onClick={exporData}
          />
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className={
                loading
                  ? 'myclassName app-container container-fluid'
                  : 'app-container container-fluid'
              }
              style={{ marginBottom: '20px' }}
            >
              {loading ? (
                <>
                  {/* <div className="overlay" /> */}
                  <div className="loader-container">
                    <ClipLoader color={'#50CD89'} loading={loading} size={60} />
                  </div>
                </>
              ) : // <div
                //   className="overlay"
                //   style={{ alignSelf: 'center' }}
                // >
                //   <ClipLoader
                //     color={'#50CD89'}
                //     loading={loading}
                //     css={override}
                //     size={60}
                //   />
                // </div>
                null}


              {/* my row (j)*/}
              <div className="col-12 d-flex justify-content-between">
                {/* col-12 col-xl-4  col-lg-4 col-md-4 col-sm-6  */}
                <div className="d-flex justify-content-between rounded border border-1 border-dark-subtle p-2 mb-3"
                  style={{ backgroundColor: '#F8F2EC' }}>

                  <div className={`fs-4 fw-semibold px-5 py-2
                ${activeOption === 'Lead Details' ? 'activ' : null}`}>
                    Lead Details
                  </div>


                  {purposeType === 'sales' ? (
                    <div className={`fs-4 fw-semibold px-5 py-2  ${activeOption === 'product' ? 'activ' : null}`}>
                      Products
                    </div>
                  ) : null}
                </div>
                {productError && productError ? (
                  <div className='col-4 d-flex justify-content-end align-items-end fs-4 fw-semibold text-danger'

                  >
                    {productError}
                  </div>
                ) : null}
              </div>

              {/* <div className="row">
              <div className="col-8">
                <div
                  className="row"
                  style={{
                    fontSize: '17px',
                    fontWeight: '600',
                    display: 'flex',
                    justifyContent: 'space-around'
                  }}
                > */}
              {/* <div
                    onClick={() => setActiveOption('Lead Details')}
                    className={
                      activeOption === 'Lead Details'
                        ? 'active col-3   py-5'
                        : 'non_active col-3  py-5'
                    }
                    style={{
                      color:
                        error && error.title && error.description
                          ? 'red'
                          : 'black',
                      width: 'auto',
                      fontSize: activeOption === 'Lead Details' ? 25 : 20,
                      fontWeight:
                        activeOption === 'Lead Details' ? '900' : '600'
                    }}
                  >
                    Lead Details
                  </div> */}
              {/* <div
                    onClick={() => setActiveOption('contact')}
                    className={
                      activeOption === 'contact'
                        ? 'active col-3 py-5'
                        : 'non_active col-3 py-5'
                    }
                    style={{
                      color: error && error.owner_name ? 'red' : 'black',
                      width: 'auto'
                    }}
                  >
                    Contact Person
                  </div> */}
              {/* <div
                    onClick={() => setActiveOption('product')}
                    className={
                      activeOption === 'product'
                        ? 'active col-3  py-5'
                        : 'non_active col-3   py-5'
                    }
                    style={{
                      cursor: 'pointer',
                      width: 'auto',
                      fontSize: activeOption === 'product' ? 25 : 20,
                      fontWeight: activeOption === 'product' ? '900' : '600'
                    }}
                  >
                    Products
                  </div> */}
              {/* <div
                    onClick={() => setActiveOption('communication')}
                    className={
                      activeOption === 'communication'
                        ? 'active col-3  py-5'
                        : 'non_active col-3   py-5'
                    }
                    style={{ cursor: 'pointer', width: 'auto' }}
                  >
                    Communication
                  </div> */}
              {/* </div>
              </div>
            </div> */}
              {/* {activeOption === 'detail' ? (
              <div className="card-body pt-6">

                <div className="tab-content">
                  <div className="modal-content rounded card">

                    <div className="modal-header pb-0 border-0 justify-content-end">

                      <div
                        className="btn btn-sm btn-icon btn-active-color-primary"
                        data-bs-dismiss="modal"
                      ></div>

                    </div>
                    <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
                      <form
                        id="kt_modal_new_target_form"
                        className="form fv-plugins-bootstrap5 fv-plugins-framework"
                        action="#"
                      >
                        <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                          <div className="row">
                            <div className="col-md-12">
                              <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">Title</span>
                              </label>
                              <input
                                type="text"
                                value={leadData?.title}
                                onChange={handleInputChange}
                                name="title"
                                className="form-control "
                                placeholder="Enter Title"
                              />
                            </div>
                          </div>
                          <div className="row mt-5">
                            <div className="col-md-12">
                              <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">Description</span>
                              </label>
                              <textarea
                                type="text"
                                value={leadData?.description}
                                onChange={handleInputChange}
                                name="description"
                                className="form-control "
                                placeholder="Enter Description"
                              />
                            </div>
                          </div>

                          <div className="row mt-5">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span className="required">Business Type</span>
                            </label>
                            <ReactSelect
                              value={businessTypeData.find(
                                (e) => e.value == leadData.business_type
                              )}
                              isSearchable={false}
                            />
                          </div>

                          <div className="row mt-5">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span className="required">
                                Expected Close Date
                              </span>
                            </label>
                            <input
                              min={new Date().toISOString().split('T')[0]}
                              type="date"
                              value={leadData.expt_close_date}
                              onChange={(e) => setLeadDate(e.target.value)}
                              name="leadDate"
                              className="form-control "
                              placeholder="Enter Expected Close Date"
                            />
                          </div>
                          <div className="row mt-5">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span className="required">Stage</span>
                            </label>
                            <ReactSelect
                              value={stageDataOption.find(
                                (e) => e.value == detailStage
                              )}
                              onChange={(selectedOption) =>
                                handleSelectChange(selectedOption)
                              }
                              options={stageDataOption}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            ) : null} */}
              {activeOption === 'Lead Details' ? (
                <>
                  <div className="clientinfo-box">
                    <div className="dynamic-input ">
                      {clientData.map((field, index) => (
                        <div className="row align-items-center justify-content-between">
                          <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">
                            <div className="dynamic-input ">
                              <div className="row align-items-center justify-content-between">
                                <div className="d-flex align-items-center justify-content-around inputrow flex-wrap">
                                  <div className="col-6 p-2">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                      <span className="required">
                                        GSTIN Number
                                      </span>
                                    </label>
                                    {/* {labelText === 'New' ? ( */}
                                    <input
                                      maxLength={15}
                                      // onChange={handleInputChange}
                                      name="product_gst"
                                      value={leadData?.client?.gst_no}
                                      type="text"
                                      className="form-control rounded-1"
                                      style={{ padding: "8px" }}
                                      placeholder="Enter GSTIN Number"
                                      disabled
                                    />
                                  </div>
                                  <div className="col-6 p-2">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                      <span className="required">
                                        Organisation
                                      </span>
                                    </label>

                                    <input
                                      // onChange={handleInputChange}
                                      value={leadData?.client?.organisation}
                                      name="organisation"
                                      type="text"
                                      className="form-control rounded-1"
                                      style={{ padding: "8px" }}
                                      placeholder="Enter Organisation"
                                      disabled
                                    />
                                  </div>
                                  <div className="col-6 p-2">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                      <span className="required">Address</span>
                                    </label>

                                    <input
                                      // onChange={handleInputChange}
                                      value={leadData?.client?.address}
                                      name="address"
                                      type="text"
                                      className="form-control rounded-1"
                                      style={{ padding: "8px" }}
                                      placeholder="Enter Address"
                                      disabled
                                    />
                                  </div>
                                  {/* <div className="col-5">
                                  <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">
                                      Industry Type
                                    </span>
                                  </label>
                                  {labelText === 'New' ? (
                                    <ReactSelect
                                      // options={clientOptions}
                                      value={IndustryOptions.find(
                                        (e) =>
                                          e.value ==
                                          leadData?.client?.industry_id
                                      )}
                                      options={IndustryOptions}
                                      isDisabled={true}
                                      // value={id}
                                      // onChange={(newSelectedOption) => {
                                      //   setId(newSelectedOption);
                                      // }}
                                    />
                                  ) : (
                                    <ReactSelect
                                      // options={clientOptions}
                                      options={IndustryOptions}
                                      value={id}
                                      onChange={(newSelectedOption) => {
                                        setId(newSelectedOption);
                                      }}
                                    />
                                  )}
                                  {error && error.industry_id ? (
                                    <div style={{ color: 'red' }}>
                                      {error.industry_id}
                                    </div>
                                  ) : null}
                                </div> */}

                                  <div className="col-6 p-2">
                                    <label
                                      htmlFor={`contactPersonName${index}`}
                                      className="d-flex align-items-center fs-6 fw-semibold mb-2"
                                    >
                                      <span className="required">
                                        Contact Person Name
                                      </span>
                                    </label>
                                    <input
                                      onInput={(e) =>
                                      (e.target.value = e.target.value.replace(
                                        /[^a-zA-Z ]/g,
                                        ''
                                      ))
                                      }
                                      onChange={(e) =>
                                        handleInputChange1(
                                          index,
                                          'value1',
                                          e.target.value
                                        )
                                      }
                                      value={
                                        field[
                                        'contact_person_name_' +
                                        index.toString()
                                        ]
                                      }
                                      // value={field.contact_person_name_0}
                                      name="owner_name"
                                      type="text"
                                      className="form-control rounded-1"
                                      style={{ padding: "8px" }}
                                      id={`contactPersonName${index}`}
                                      placeholder={`Enter Name ${index + 1}`}
                                      disabled
                                    />
                                    {error &&
                                      error.clientInfo &&
                                      error.clientInfo[index] && (
                                        <div>
                                          {error.clientInfo[index][
                                            `contact_person_name_${index}`
                                          ] && (
                                              <div style={{ color: 'red' }}>
                                                {
                                                  error.clientInfo[index][
                                                  `contact_person_name_${index}`
                                                  ]
                                                }
                                              </div>
                                            )}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-6 p-2">
                              <label
                                htmlFor={`contactPersonEmail${index}`}
                                className="d-flex align-items-center fs-6 fw-semibold mb-2"
                              >
                                <span className="required">
                                  Contact Person's Email address
                                </span>
                              </label>
                              <input
                                onChange={(e) =>
                                  handleInputChange1(
                                    index,
                                    'value2',
                                    e.target.value
                                  )
                                }
                                // value={field.contact_person_email_0}
                                value={
                                  field[
                                  'contact_person_email_' + index.toString()
                                  ]
                                }
                                name="owner_email"
                                type="email"
                                className="form-control rounded-1"
                                style={{ padding: "8px" }}
                                id={`contactPersonEmail${index}`}
                                placeholder={`Enter email ${index + 1}`}
                                disabled
                              />
                              {error &&
                                error.clientInfo &&
                                error.clientInfo[index] && (
                                  <div>
                                    {error.clientInfo[index][
                                      `contact_person_email_${index}`
                                    ] && (
                                        <div style={{ color: 'red' }}>
                                          {
                                            error.clientInfo[index][
                                            `contact_person_email_${index}`
                                            ]
                                          }
                                        </div>
                                      )}
                                    {/* Repeat similar checks for other fields (email, mobile, designation) */}
                                  </div>
                                )}
                            </div>
                            <div className="col-6 p-2">
                              <label
                                htmlFor={`contactPersonMobile${index}`}
                                className="d-flex align-items-center fs-6 fw-semibold mb-2"
                              >
                                <span className="required">
                                  Contact Person's Mobile Number
                                </span>
                              </label>
                              <input
                                style={{
                                  cursor: 'not-allowed',
                                  pointerEvents: 'none',
                                  padding: "8px"
                                }}
                                readOnly
                                onInput={(e) =>
                                (e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ''
                                ))
                                }
                                maxLength={10}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    `contact_person_mobile_${index}`,
                                    e.target.value
                                  )
                                }
                                value={field[`contact_person_mobile_${index}`]}
                                name="owner_contact_no"
                                type="text"
                                className="form-control rounded-1"
                                id={`contactPersonMobile${index}`}
                                placeholder={`Enter Mobile Number ${index + 1}`}
                                disabled
                              />
                              {error &&
                                error.clientInfo &&
                                error.clientInfo[index] && (
                                  <div>
                                    {error.clientInfo[index][
                                      `contact_person_mobile_${index}`
                                    ] && (
                                        <div style={{ color: 'red' }}>
                                          {
                                            error.clientInfo[index][
                                            `contact_person_mobile_${index}`
                                            ]
                                          }
                                        </div>
                                      )}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="clientinfo-box mt-6">
                    <div className="row">
                      {/*begin:Form*/}
                      <form
                        id="kt_modal_new_target_form"
                        className="form fv-plugins-bootstrap5 fv-plugins-framework"
                        action="#"
                      >
                        {/*begin::Input group*/}
                        <div className="d-flex flex-column mb-0 fv-row fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <div
                            className="row"
                            style={{
                              flexDirection: 'row',
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <div
                              className="row"
                              style={{
                                flexDirection: 'row'
                                // backgroundColor: 'green'
                                // backgroundColor: 'red',
                                // display: 'flex'
                              }}
                            >
                              <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                                <span className="required">Purpose of visit</span>
                              </label>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row'
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '20px'
                                  }}
                                >
                                  <input
                                    type="radio"
                                    id="sales"
                                    name="fav_language"
                                    value="sales"
                                    checked={purposeType === 'sales'}
                                  // onChange={handleRadioChange}
                                  />
                                  <label
                                    htmlFor="Sales"
                                    style={{
                                      marginLeft: '8px',
                                      fontSize: 'initial'
                                    }}
                                  >
                                    Sales
                                  </label>
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '20px'
                                  }}
                                >
                                  <input
                                    type="radio"
                                    id="feedback"
                                    name="fav_language"
                                    value="feedback"
                                    checked={purposeType === 'feedback'}
                                  // onChange={handleRadioChange}
                                  />
                                  <label
                                    htmlFor="Feedback"
                                    style={{
                                      marginLeft: '8px',
                                      // font-size: initial;
                                      fontSize: 'initial'
                                    }}
                                  >
                                    Feedback
                                  </label>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div
                            className="row"
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginTop: '10px',
                              alignItems: 'center'
                            }}
                          >
                            <div
                              className="col-4"

                            >
                              <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span>Title</span>
                              </label>
                              <input
                                type="text"
                                value={leadData?.title}
                                onChange={handleInputChange}
                                // onInput={(e) =>
                                // (e.target.value =
                                //   e.target.value.replace(
                                //     /[^a-zA-Z@#.$!~^&* ]/g,
                                //     ''
                                //   ))
                                // }
                                name="title"
                                className="form-control rounded-1"
                                style={{
                                  backgroundColor: 'transparent',
                                  border: '1px solid #ced4da',
                                  padding: "8px"
                                }}
                                placeholder="Enter Title"
                              />
                              {error && error.title ? (
                                <div style={{ color: 'red' }}>{error.title}</div>
                              ) : null}
                            </div>

                            {purposeType == 'sales' ? (
                              <div className="col-4">
                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                  <span className="required">
                                    Expected Close Date
                                  </span>
                                </label>
                                <input
                                  min={new Date().toISOString().split('T')[0]}
                                  type="date"
                                  value={leadData.expt_close_date}
                                  onChange={handleInputChange}
                                  name="expt_close_date"
                                  className="form-control rounded-1"
                                  style={{ padding: "8px" }}
                                  placeholder="Enter Expected Close Date"
                                />
                                {error && error.expt_close_date ? (
                                  <div style={{ color: 'red' }}>
                                    {error.expt_close_date}
                                  </div>
                                ) : null}
                              </div>
                            ) : null}

                            {/* <div className="row mt-5 col-4">
                                  <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">
                                      Business Type
                                    </span>
                                  </label>
                                  <select
                                    style={{ height: '45px' }}
                                    className="form-control"
                                    value={businessType}
                                    onChange={BusinessTypeChangeHandler}
                                  >
                                    <option value="">Select</option>
                                    {type.map((type, index) => (
                                      <option key={index} value={type}>
                                        {type}
                                      </option>
                                    ))}
                                  </select>
                                </div> */}

                            <div className="col-4">
                              <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">Stage</span>
                              </label>
                              <ReactSelect
                                value={stageDataOption.find(
                                  (e) => e.value == detailStage
                                )}
                                onChange={(selectedOption) =>
                                  handleSelectChange(selectedOption)
                                }
                                options={stageDataOption}
                              />
                              {/* <select
                                  className="form-control"
                                  value={stageDataOption.find(
                                    (e) => e.value == detailStage
                                  )}
                                  // value={selectedStage}
                                  // onChange={handlestageChange}
                                >
                                  <option value="">Stage</option>
                                  {stage.map((stage, index) => (
                                    <option key={stage.id} value={stage.id}>
                                      {stage.stage}
                                    </option>
                                  ))}
                                </select> */}
                              {error && error.stage ? (
                                <div style={{ color: 'red' }}>{error.stage}</div>
                              ) : null}
                            </div>
                          </div>

                          <div className="row mt-5">
                            <div className="col-md-12">
                              <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">Description</span>
                              </label>
                              <textarea
                                type="text"
                                // value={description}
                                value={leadData?.description}
                                onChange={handleInputChange}
                                name="description"
                                className="form-control rounded-1"
                                placeholder="Enter Description"
                                style={{
                                  backgroundColor: 'transparent',
                                  border: '1px solid #ced4da',
                                  padding: "8px"
                                }}
                              />
                              {error && error.description ? (
                                <div style={{ color: 'red' }}>
                                  {error.description}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          {/*end::Label*/}
                        </div>
                      </form>
                    </div>
                    <div className="row pt-3 pb-0 ">
                      <Form>
                        <div
                          id="kt_app_content"
                          className="app-content flex-column-fluid pt-0"
                        >
                          <div
                            id="kt_app_content_container"
                            className=" container-fluid"
                          >
                            <div className="col-xxl-12 mb-5 mb-xl-2 ">
                              <div className="h-xl-100">
                                <Row>
                                  <Col
                                    xs={12}
                                    className='p-0 mb-3'
                                    style={{
                                      paddingLeft: '29px',
                                      paddingLeft: '29px'
                                    }}
                                  >
                                    <Form.Group>
                                      <Form.Label>
                                        Lead Communication
                                      </Form.Label>
                                      <textarea
                                        onChange={handleInputChange3}
                                        value={communicationData.communication}
                                        className="form-control rounded-1"
                                        style={{ padding: '8px' }}
                                        name="communication"
                                        type="text"
                                        placeholder=""
                                      />
                                    </Form.Group>
                                  </Col>
                                  {/* {communicationError ?
                                    <div className='col-12 ps-2 pt-2'
                                      style={{
                                        color: 'red',
                                        fontSize: '14px',
                                        alignSelf: 'center'
                                      }}
                                    >
                                      {communicationError}
                                    </div> : null} */}
                                </Row>
                                <Row className='justify-content-between' style={{ height: '15vh' }}>
                                  <div className='col-3 ps-0 d-flex align-items-center'>
                                    <Form.Group
                                      className=" col-12 d-flex"
                                    >
                                      <input
                                        style={{ width: '20%' }}
                                        checked={isChecked === 1}
                                        onChange={handleCheckboxChange}
                                        type="checkbox"
                                        placeholder=""
                                        name="communication"
                                      />
                                      <Form.Label className='mb-0 p-2'>
                                        Is Reminder
                                      </Form.Label>
                                    </Form.Group>
                                  </div>
                                  {isChecked ? (
                                    <Col xs={4} className='d-flex align-items-center'>
                                      <div className="row">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                          <span className="required">
                                            Reminder Date{' '}
                                          </span>
                                        </label>
                                        <input
                                          min={
                                            new Date().toISOString().split('T')[0]
                                          }

                                          type="datetime-local"
                                          onChange={handleInputChange3}
                                          value={
                                            communicationData.communication_date
                                          }
                                          name="communication_date"
                                          className="form-control rounded-1"
                                          style={{ padding: '8px' }}
                                          placeholder="Enter Expected Close Date"
                                        />
                                      </div>
                                    </Col>
                                  ) : null}
                                  <div className='col-3 d-flex align-items-center justify-content-end pe-0'>
                                    <Button
                                      className="btn  rounded-1"
                                      variant="success"
                                      onClick={communicationChangeHandler}

                                    >
                                      Add Communication
                                    </Button>
                                  </div>
                                </Row>

                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>



                    {/* <Form>
                    <Col
                      xs={11}
                      style={{ padding: '30px', paddingLeft: '40px' }}
                    >
                      <Form.Group>
                        <Form.Label>Lead Communication</Form.Label>
                        <textarea
                          onChange={handleInputChange3}
                          value={communicationData.communicationDesc}
                          className="form-control box_shadow"
                          name="communicationDesc"
                          type="text"
                          placeholder=""
                        />
                        {error && error.communication ? (
                          <div style={{ color: 'red' }}>
                            {error.communication}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Row>
                      <Col xs={4}>
                        <Form.Group
                          className=""
                          style={{
                            display: 'flex',
                            marginTop: '10%',
                            marginLeft: '35px'
                          }}
                        >
                          <input
                            style={{ width: '11%' }}
                            value={isChecked}
                            onChange={handleCheckboxChange}
                            type="checkbox"
                            placeholder=""
                            name="communication"
                          />
                          <Form.Label
                            style={{
                              marginLeft: '6px',
                              marginTop: '7px',
                              marginLeft: '12px'
                            }}
                          >
                            Is Reminder
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col xs={4}>
                        <div className="row mt-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">
                              Expected Close Date
                            </span>
                          </label>
                          <input
                            min={new Date().toISOString().split('T')[0]}
                            type="datetime-local"
                            onChange={handleInputChange3}
                            value={communicationData.reminderDate}
                            name="reminderDate"
                            className="form-control "
                            placeholder="Enter Expected Close Date"
                          />
                          {console.log('date', leadDate)}
                        </div>
                      </Col>
                    </Row>

                  </Form> */}


                    <Form>
                      <div
                        id="kt_app_content"
                        className="app-content flex-column-fluid pt-0 "
                      >
                        <div
                          id="kt_app_content_container"
                          className=" container-fluid"
                        >
                          {/* <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly'
                      }}
                    ></div> */}
                          <div className="col-xxl-12 mb-5 mb-xl-2">
                            <div className="h-xl-100">
                              {/* <Row>
                              <Col
                                xs={12}
                                style={{
                                  paddingLeft: '29px',
                                  paddingLeft: '29px'
                                }}
                              >
                                <Form.Group>
                                  <Form.Label className="required">
                                    Lead Communication
                                  </Form.Label>
                                  <textarea
                                    onChange={handleInputChange3}
                                    value={communicationData.communication}
                                    className="form-control box_shadow"
                                    name="communication"
                                    type="text"
                                    placeholder=""
                                  />
                                </Form.Group>
                                {communicationError ?
                                  <div className='col-12 ps-2 pt-2'
                                    style={{
                                      color: 'red',
                                      fontSize: '14px',
                                      alignSelf: 'center'
                                    }}
                                  >
                                    {communicationError}
                                  </div> : null}
                              </Col>

                            </Row> */}
                              {/* <Row>
                              <Col xs={4}>
                                <Form.Group
                                  className=""
                                  style={{
                                    display: 'flex',
                                    marginTop: '15%',
                                    marginLeft: '35px'
                                  }}
                                >
                                  <input
                                    style={{ width: '11%' }}
                                    value={isChecked}
                                    onChange={handleCheckboxChange}
                                    type="checkbox"
                                    placeholder=""
                                    name="communication"
                                  />
                                  <Form.Label
                                    style={{
                                      marginLeft: '6px',
                                      marginTop: '7px',
                                      marginLeft: '12px'
                                    }}
                                  >
                                    Is Reminder
                                  </Form.Label>
                                </Form.Group>
                              </Col>
                              {isChecked ? (
                                <Col xs={4}>
                                  <div className="row mt-15">
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                      <span className="required">
                                        Reminder Date{' '}
                                      </span>
                                    </label>
                                    <input
                                      min={
                                        new Date().toISOString().split('T')[0]
                                      }
                                      type="datetime-local"
                                      onChange={handleInputChange3}
                                      value={
                                        communicationData.communication_date
                                      }
                                      name="communication_date"
                                      className="form-control "
                                      placeholder="Enter Expected Close Date"
                                    />
                                  </div>
                                </Col>
                              ) : null}
                              <Col xs={4} style={{ marginTop: '25px' }}>
                                <Button
                                  className="mt-15"
                                  variant="success"
                                  onClick={communicationChangeHandler}
                                  style={{
                                    width: '44%',
                                    float: 'right',
                                    marginRight: '25px'
                                  }}
                                >
                                  Add Communication
                                </Button>
                              </Col>
                            </Row> */}
                              {/* <hr></hr> */}
                              {/* <div className="mt-5"> */}
                              {/*begin::Tab content*/}
                              <div className="tab-content">
                                <div className=" mb-5 mb-xl-5">
                                  <div className="row ">
                                    <div className="card-body col-12 py-2">
                                      {/*begin::Table container*/}
                                      <div className="">
                                        {/*begin::Table*/}
                                        {/* <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                                          
                                          <tbody
                                            style={{
                                              fontSize: '14px',
                                              color: 'var(--bs-text-gray-800)'
                                            }}
                                          ></tbody>
                                         
                                        </table> */}

                                        {/* Display data in a col-6 col-6 flex layout */}
                                        <div className="row row-gap-3">
                                          {communicationlead.map((e, index) => (
                                            <div className="col-4" key={index}>
                                              <div
                                                className="card card-flush clientinfo-box"

                                              >
                                                <div className="card-header justify-content-start align-items-center p-0 col-12">
                                                  <h3
                                                    style={{
                                                      color: 'darkslategrey!important',
                                                      textTransform: 'capitalize',
                                                      wordBreak: 'break-word',
                                                      width: '100%',
                                                      lineHeight: '20px'
                                                    }}
                                                    className="fw-semibold fs-6"
                                                  >
                                                    {e.communication.length > maxLength && !communicationStates[index] ? (
                                                      <>
                                                        {e.communication.slice(0, maxLength)}
                                                        <span>
                                                          <a
                                                            style={{ marginLeft: '10px' }}
                                                            href="#"
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              toggleReadMore(index);
                                                            }}
                                                          >
                                                            Read More
                                                          </a>
                                                        </span>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {e.communication}
                                                        {e.communication.length > maxLength && (
                                                          <span>
                                                            <a
                                                              style={{ marginLeft: '10px' }}
                                                              href="#"
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                toggleReadMore(index);
                                                              }}
                                                            >
                                                              Read Less
                                                            </a>
                                                          </span>
                                                        )}
                                                      </>
                                                    )}
                                                  </h3>
                                                </div>
                                                <div className="card-footer p-0">
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      justifyContent:
                                                        'space-between'
                                                    }}
                                                  >
                                                    <div className="indicator-label">
                                                      <p className='m-0 fw-semibold'>communication date</p>
                                                      <div className='m-0'>{e.created_at}</div>
                                                    </div>
                                                    <div className="indicator-label">
                                                      <div className='d-flex align-items-center'

                                                      >
                                                        <i
                                                          style={{
                                                            color: 'goldenrod'
                                                          }}
                                                          className="fa-solid fa-star"
                                                        ></i>
                                                        <p className='m-0 ps-2 fw-semibold'>Reminder date</p>
                                                      </div>
                                                      <div>
                                                        {e.communication_date}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                        {/*end::Table*/}
                                      </div>
                                      {/*end::Table container*/}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </Form>
                  </div>


                  {/* jeet */}
                  {
                    purposeType == 'sales' ?
                      <Button
                        className="btn  rounded-1 mt-5"
                        variant="success"
                        onClick={() => {
                          // labelText === "New" ? (
                          //   validationCheckHandler({
                          //     option: {
                          //       organisation: selectedOption.value,
                          //       title,
                          //       leadDate,
                          //       selectedStage,
                          //       description,
                          //       communicationDesc: communicationData.communicationDesc,
                          //       reminderDate: communicationData.reminderDate
                          //     }
                          //   })
                          // ) : (
                          //   newValidationCheckHandler({
                          //     option: {
                          //       // inputParam,
                          //       title,
                          //       leadDate,
                          //       selectedStage,
                          //       description,
                          //       communicationDesc: communicationData.communicationDesc,
                          //       reminderDate: communicationData.reminderDate
                          //     }
                          //   })
                          //   // alert('')
                          // )

                          setActiveOption('product');
                        }}


                        style={{ width: '11%' }}
                      >
                        Next
                      </Button>
                      :
                      <Button
                        className="mt-5"
                        variant="success"
                        // onClick={
                        //   () =>
                        //     feedBackValidationHandler(
                        //       {
                        //         option: {
                        //           organisation: selectedOption.value,
                        //           title,
                        //           // leadDate,
                        //           selectedStage,
                        //           description,
                        //           communicationDesc: communicationData.communicationDesc,
                        //           reminderDate: communicationData.reminderDate
                        //         }
                        //       }
                        //     )
                        // }
                        style={{ width: '11%' }}
                        onClick={() => leadSubmit()}

                      >
                        Update
                      </Button>
                  }

                  {/* <Button
                  className="mt-5"
                  variant="success"
                  // onClick={openNestedModal}
                  onClick={setIsUpdateModalOpen}
                  // () => setActiveOption('product')

                  style={{ width: '11%' }}
                >
                  Update
                </Button> */}
                </>
              ) : null}
              {/* {activeOption === 'contact' ? (
              <>
                <div className="clientinfo-box">
                  <div className="dynamic-input ">
                    {clientData.map((field, index) => (
                      <div className="row align-items-center justify-content-between">
                        <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">
                          <div className="col-5">
                            <label
                              htmlFor={`contactPersonName${index}`}
                              className="d-flex align-items-center fs-6 fw-semibold mb-2"
                            >
                              <span className="required">
                                Contact Person {index + 1}
                              </span>
                            </label>
                            <input
                              onInput={(e) =>
                                (e.target.value = e.target.value.replace(
                                  /[^a-zA-Z ]/g,
                                  ''
                                ))
                              }
                              onChange={(e) =>
                                handleInputChange1(
                                  index,
                                  'value1',
                                  e.target.value
                                )
                              }
                              value={
                                field['contact_person_name_' + index.toString()]
                              }
                              // value={field.contact_person_name_0}
                              name="owner_name"
                              type="text"
                              className="form-control "
                              id={`contactPersonName${index}`}
                              placeholder={`Enter Name ${index + 1}`}
                            />
                            {error &&
                              error.clientInfo &&
                              error.clientInfo[index] && (
                                <div>
                                  {error.clientInfo[index][
                                    `contact_person_name_${index}`
                                  ] && (
                                    <div style={{ color: 'red' }}>
                                      {
                                        error.clientInfo[index][
                                          `contact_person_name_${index}`
                                        ]
                                      }
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                          <div className="col-5">
                            <label
                              htmlFor={`contactPersonEmail${index}`}
                              className="d-flex align-items-center fs-6 fw-semibold mb-2"
                            >
                              <span className="required">
                                Contact Person's Email address
                              </span>
                            </label>
                            <input
                              onChange={(e) =>
                                handleInputChange1(
                                  index,
                                  'value2',
                                  e.target.value
                                )
                              }
                              // value={field.contact_person_email_0}
                              value={
                                field[
                                  'contact_person_email_' + index.toString()
                                ]
                              }
                              name="owner_email"
                              type="email"
                              className="form-control "
                              id={`contactPersonEmail${index}`}
                              placeholder={`Enter email ${index + 1}`}
                            />
                            {error &&
                              error.clientInfo &&
                              error.clientInfo[index] && (
                                <div>
                                  {error.clientInfo[index][
                                    `contact_person_email_${index}`
                                  ] && (
                                    <div style={{ color: 'red' }}>
                                      {
                                        error.clientInfo[index][
                                          `contact_person_email_${index}`
                                        ]
                                      }
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                          <div className="col-5">
                            <label
                              htmlFor={`contactPersonMobile${index}`}
                              className="d-flex align-items-center fs-6 fw-semibold mb-2"
                            >
                              <span className="required">
                                Contact Person's Mobile Number
                              </span>
                            </label>
                            <input
                              maxLength={10}
                              onInput={(e) =>
                                (e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ''
                                ))
                              }
                              onChange={(e) =>
                                handleInputChange1(
                                  index,
                                  'value3',
                                  e.target.value
                                )
                              }
                              value={
                                field[
                                  'contact_person_mobile_' + index.toString()
                                ]
                              }
                              // value={field.contact_person_mobile_0}
                              name="owner_contact_no"
                              type="text"
                              className="form-control "
                              id={`contactPersonMobile${index}`}
                              placeholder={`Enter Mobile Number ${index + 1}`}
                            />
                            {error &&
                              error.clientInfo &&
                              error.clientInfo[index] && (
                                <div>
                                  {error.clientInfo[index][
                                    `contact_person_mobile_${index}`
                                  ] && (
                                    <div style={{ color: 'red' }}>
                                      {
                                        error.clientInfo[index][
                                          `contact_person_mobile_${index}`
                                        ]
                                      }
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                          <div className="col-5">
                            <label
                              htmlFor={`contactPersonDesignation${index}`}
                              className="d-flex align-items-center fs-6 fw-semibold mb-2"
                            >
                              <span className="required">
                                Contact Person Designation
                              </span>
                            </label>
                            <input
                              onInput={(e) =>
                                (e.target.value = e.target.value.replace(
                                  /[^a-zA-Z]/g,
                                  ''
                                ))
                              }
                              onChange={(e) =>
                                handleInputChange1(
                                  index,
                                  'value4',
                                  e.target.value
                                )
                              }
                              // value={
                              //   field.contact_person_designation_ +
                              //   index.toString()
                              // }
                              value={
                                field[
                                  'contact_person_designation_' +
                                    index.toString()
                                ]
                              }
                              name="designation_name"
                              type="text"
                              className="form-control "
                              id={`contactPersonDesignation${index}`}
                              placeholder={`Enter Contact Person Designation`}
                            />
                            {error &&
                              error.clientInfo &&
                              error.clientInfo[index] && (
                                <div>
                                  {error.clientInfo[index][
                                    `contact_person_designation_${index}`
                                  ] && (
                                    <div style={{ color: 'red' }}>
                                      {
                                        error.clientInfo[index][
                                          `contact_person_designation_${index}`
                                        ]
                                      }
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <p className="h5 m-0 px-1 py-2">Organisation Details:</p>
                <div className="clientinfo-box">
                  <div className="dynamic-input ">
                    <div className="row align-items-center justify-content-between">
                      <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">
                        <div className="col-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">GSTIN Number</span>
                          </label>

                          <input
                            maxLength={15}
                            // onChange={handleInputChange}
                            name="product_gst"
                            value={leadData.client.product_gst}
                            type="text"
                            className="form-control "
                            placeholder="Enter GSTIN Number"
                          />
                        </div>
                        <div className="col-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Organisation</span>
                          </label>

                          <input
                            // onChange={handleInputChange}
                            value={leadData.client.organisation}
                            name="organisation"
                            type="text"
                            className="form-control "
                            placeholder="Enter Organisation"
                          />
                        </div>
                        <div className="col-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Address</span>
                          </label>

                          <input
                            // onChange={handleInputChange}
                            value={leadData.client.address}
                            name="address"
                            type="text"
                            className="form-control "
                            placeholder="Enter Address"
                          />
                        </div>
                        <div className="col-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Industry Type</span>
                          </label>
                          {labelText === 'New' ? (
                            <ReactSelect
                              // options={clientOptions}
                              value={IndustryOptions.find(
                                (e) => e.value == leadData.client.industry_id
                              )}
                              options={IndustryOptions}
                              isDisabled={true}
                              // value={id}
                              // onChange={(newSelectedOption) => {
                              //   setId(newSelectedOption);
                              // }}
                            />
                          ) : (
                            <ReactSelect
                              // options={clientOptions}
                              options={IndustryOptions}
                              value={id}
                              onChange={(newSelectedOption) => {
                                setId(newSelectedOption);
                              }}
                            />
                          )}
                          {error && error.industry_id ? (
                            <div style={{ color: 'red' }}>
                              {error.industry_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="h5 m-0 px-1 py-2">Additional Info:</p>
                <div className="clientinfo-box">
                  <div className="dynamic-input ">
                    <div className="row align-items-center justify-content-between">
                      <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">
                        <div className="col-8">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span>Description</span>
                          </label>
                          <input
                            // onChange={handleInputChange}
                            name="notes"
                            // value={userData.notes}
                            type="text"
                            className="form-control "
                            placeholder="Enter Description"
                          />
                        </div>
                    
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null} */}

              {activeOption === 'product' && (
                <Form className=''>
                  {/* {productData.map((data, index) => ( */}
                  {/* <Row

                  // key={index}
                  > */}
                  <div
                    className="clientinfo-box"
                  >

                    <div className="row">
                      <Col
                        style={{
                          marginBottom: '16px',
                          marginTop: '12px'
                        }}
                        xs={3}
                      >
                        <Form.Group>
                          <div
                            style={{
                              fontSize: 17,
                              fontWeight: 'bold'
                            }}
                            className="required"
                          >
                            Item Name
                          </div>
                          <ReactSelect
                            options={productOptionss}
                            value={selectedProduct}
                            onChange={(value) => {
                              setSelectedProduct(value);
                              // setSelectedProductName(value.label);
                              setSelectedCategory('');
                              setSelectedSubcategory('');
                            }}
                            maxMenuHeight={'200px'}
                            isDisabled={edit === true ? true : null}

                          />
                        </Form.Group>
                      </Col>
                      <Col
                        style={{
                          marginBottom: '16px',
                          marginTop: '12px'
                        }}
                        xs={3}
                      >
                        <Form.Group>
                          <div
                            style={{
                              fontSize: 17,
                              fontWeight: 'bold'
                            }}
                            className="required"
                          >
                            Brand Name
                          </div>
                          <ReactSelect
                            options={BrandOptions}
                            value={selectedBrand}
                            onChange={(value) => {
                              setSelectedBrand(value);
                              // setSelectedProductName(value.label);
                              setSelectedCategory('');
                              setSelectedSubcategory('');
                            }}
                            maxMenuHeight={'200px'}
                          // isDisabled={edit === true ? true : null}

                          />
                        </Form.Group>
                      </Col>
                      <Col
                        style={{
                          marginBottom: '16px',
                          marginTop: '12px'
                        }}
                        xs={3}
                      >
                        <Form.Group>
                          <div
                            style={{
                              fontSize: 17,
                              fontWeight: 'bold'
                            }}
                            className="required"
                          >
                            Size
                          </div>
                          {/* <Form.Label>Size</Form.Label> */}
                          <ReactSelect
                            options={categoryOptions}
                            value={selectedCategory}
                            onChange={(value) => {
                              setSelectedCategory(value);
                              setSelectedSubcategory('');
                            }}
                            maxMenuHeight={'200px'}
                            isDisabled={!selectedProduct}
                          />
                        </Form.Group>
                      </Col>
                      <Col
                        style={{
                          marginBottom: '16px',
                          marginTop: '12px'
                        }}
                        xs={3}
                      >
                        <Form.Group>
                          <div
                            style={{
                              fontSize: 17,
                              fontWeight: 'bold'
                            }}
                          >
                            Thickness
                          </div>
                          {/* <Form.Label>className Thickness</Form.Label> */}
                          <ReactSelect
                            options={subcategoryOptions}
                            value={selectedSubcategory}
                            onChange={setSelectedSubcategory}
                            isDisabled={!selectedCategory}
                          />
                        </Form.Group>
                      </Col>

                    </div>
                    <div className="row">
                      <Col
                        style={{
                          marginBottom: '16px',
                          marginTop: '12px'
                        }}
                      // xs={3}
                      >
                        <Form.Group>
                          <div
                            style={{
                              fontSize: 17,
                              fontWeight: 'bold'
                              // fontWeight: 'bolder'
                            }}
                            className="required"
                          >
                            Quantity /PCS
                          </div>

                          <Form.Control
                            className='rounded-1'
                            style={{ padding: "8px" }}
                            type="text"
                            placeholder="Enter quantity"
                            value={productSlideData.qty}
                            name="qty"
                            qty
                            onChange={handleProductInputChange}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col
                        style={{
                          marginBottom: '16px',
                          marginTop: '12px'
                        }}
                      // xs={3}
                      >
                        <div>
                          <label className="d-flex align-items-center fs-6 fw-semibold">
                            {/* <span className="required">
                                          Measurement
                                        </span> */}
                            <div
                              style={{
                                fontSize: 17,
                                fontWeight: 'bold'
                              }}
                              className="required"
                            >
                              Measurement
                            </div>
                          </label>
                          <ReactSelect
                            options={measurementDataOption}
                            value={measurementType}
                            onChange={(value) => {
                              setMeasurementType(value);
                              // setBasePrice('');
                              setProductSlideData({ ...productSlideData, kg: '' })
                            }}
                          />
                        </div>
                      </Col>
                      {/* {console.log('measurementType',measurementType)} */}
                      {/* {measurementType.label == 'Kilogram ' ? ( */}
                      <Col
                        style={{
                          marginBottom: '16px',
                          marginTop: '12px'
                        }}
                      // xs={3}
                      >
                        <Form.Group>
                          <div
                            style={{
                              fontSize: 17,
                              fontWeight: 'bold',
                            }}
                            className='required'
                          >
                            Enter kg
                          </div>
                          {/* <Form.Label>Enter Kg</Form.Label> */}
                          <Form.Control
                            className='rounded-1'
                            style={{ padding: "8px" }}
                            type="text"
                            placeholder="Enter quantity"
                            value={productSlideData.kg}
                            name="kg"
                            onChange={handleProductInputChange}
                            disabled={
                              measurementType.label !== 'KILOGRAMS'
                            }
                            onInput={(e) => {
                              // Remove any characters that are not digits, decimal points, or #
                              e.target.value = e.target.value.replace(/[^\d.]/g, '');
                            }}


                          />

                        </Form.Group>
                      </Col>
                      {/* // ) : null} */}
                      <Col
                        style={{
                          marginBottom: '16px',
                          marginTop: '12px'
                        }}
                      // xs={3}
                      >
                        <Form.Group>
                          <div
                            style={{
                              flexDirection: 'row',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <div
                              style={{
                                fontSize: 17,
                                fontWeight: 'bold'
                              }}
                            >
                              Total Qty.
                            </div>
                            <div style={{ marginLeft: 6, fontSize: 12 }}>
                              (after measure.)
                            </div>
                          </div>
                          {/* <Form.Label>Total Quantity</Form.Label> */}
                          <Form.Control
                            className='rounded-1'
                            style={{ padding: "8px" }}
                            type="text"
                            placeholder="Total Quantity"
                            value={totalQuanity}
                            name="totalQuantity"
                            onChange={(e) => setTotalQuanity(e.target.value)}
                          // onChange={handleProductInputChange}

                          />
                        </Form.Group>
                      </Col>
                      <Col
                        style={{
                          marginBottom: '16px',
                          marginTop: '12px'
                        }}
                      // xs={3}
                      >
                        <Form.Group>
                          <div
                            style={{
                              fontSize: 17,
                              fontWeight: 'bold'
                            }}
                            className="required"
                          >
                            Price
                          </div>
                          {/* <Form.Label>Price</Form.Label> */}
                          <Form.Control
                            type="text"
                            className='rounded-1'
                            style={{ padding: "8px" }}
                            placeholder="Enter Price"
                            value={
                              basePrice
                              // measurementType.label == 'Meter '
                              //   ? basePrice
                              //   : null
                            }
                            name="price"
                            onChange={handleBasePriceInputChange}
                            onInput={(e) => {
                              // Remove any characters that are not digits, decimal points, or #
                              e.target.value = e.target.value.replace(/[^\d.]/g, '');
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </div>
                    <div className="row">
                      <Col
                        style={{
                          marginBottom: '16px',
                          marginTop: '12px'
                        }}
                      // xs={4}
                      >
                        <Form.Group>
                          <div
                            style={{
                              fontSize: 17,
                              fontWeight: 'bold'
                            }}
                          >
                            Discount (%)
                          </div>
                          {/* <Form.Label>Discount (%)</Form.Label> */}
                          <Form.Control
                            className='rounded-1'
                            style={{ padding: "8px" }}
                            // type="number"
                            placeholder="Enter Discount"
                            value={productSlideData.discount}
                            name="discount"
                            onChange={handleProductInputChange}
                            onInput={(e) => {
                              // Remove any characters that are not digits, decimal points, or #
                              e.target.value = e.target.value.replace(/[^\d.]/g, '');
                            }}
                          // name="qty"
                          />
                        </Form.Group>
                      </Col>
                      <Col
                        style={{
                          marginBottom: '16px',
                          marginTop: '12px'
                        }}
                      // xs={4}
                      >
                        <Form.Group>
                          <div
                            style={{
                              flexDirection: 'row',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <div
                              style={{
                                fontSize: 17,
                                fontWeight: 'bold'
                              }}
                            >
                              Net Price
                            </div>
                            {/* <div style={{ marginLeft: 6, fontSize: 12 }}>
                              (Taxable Amount)
                            </div> */}
                          </div>

                          <Form.Control
                            className='rounded-1'
                            style={{ padding: "8px" }}
                            type='text'
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                            }}
                            placeholder="Price After Discount"
                            value={
                              productSlideData.discount
                                ? priceAfterDiscount
                                : priceBeforeDiscount
                            }
                            name="priceAfterDiscount"
                            onChange={(e) => setPriceAfterDiscount(e.target.value)}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col
                        style={{
                          marginBottom: '16px',
                          marginTop: '12px'
                        }}

                      >
                        <div>
                          <Form.Group>
                            <div
                              style={{
                                fontSize: 17,
                                fontWeight: 'bold'
                              }}
                            >
                              Taxable Amount
                            </div>

                            <Form.Control
                              className='rounded-1'
                              style={{ padding: "8px" }}
                              type='text'
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                              }}
                              placeholder="Taxable Amount"
                              value={taxAmount}
                              name="priceBeforeDiscount"
                              onChange={(e) => setTaxAmount(e.target.value)}
                              readOnly
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      <Col
                        style={{
                          marginBottom: '16px',
                          marginTop: '12px'
                        }}
                      // xs={4}
                      >
                        <div>
                          <Form.Group>
                            <div
                              style={{
                                fontSize: 17,
                                fontWeight: 'bold'
                              }}
                              className="required"
                            >
                              GST (%)
                            </div>
                            {/* <Form.Label> GST (%)</Form.Label> */}
                            <Form.Control
                              className='rounded-1'
                              style={{ padding: "8px" }}
                              type="text"
                              placeholder="Enter quantity"
                              value={productSlideData.gst}
                              name="gst"
                              onChange={handleProductInputChange}
                              onInput={(e) => {
                                // Remove any characters that are not digits, decimal points, or #
                                e.target.value = e.target.value.replace(/[^\d.]/g, '');
                              }}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      <Col
                        style={{
                          marginBottom: '16px',
                          marginTop: '12px'
                        }}
                      // xs={4}
                      >
                        <Form.Group>
                          <div
                            style={{
                              flexDirection: 'row',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <div
                              style={{
                                fontSize: 17,
                                fontWeight: 'bold'
                              }}
                            >
                              Net Value
                            </div>
                            <div style={{ marginLeft: 6, fontSize: 12 }}>
                              (Taxable Amount)
                            </div>
                          </div>

                          <Form.Control
                            className='rounded-1'
                            style={{ padding: "8px" }}
                            // type="number"
                            placeholder="Net Value"
                            value={netValue}
                            name="netValue"
                          // onChange={handleProductInputChange}
                          // name="qty"
                          />
                        </Form.Group>
                      </Col>
                    </div>

                    <div className="row">
                      {/* <Col
                        style={{
                          marginBottom: '16px',
                          marginTop: '12px'
                        }}
                        // xs={4}
                      >
                        <Form.Group>
                          <div
                            style={{
                              fontSize: 17,
                              fontWeight: 'bold'
                            }}
                          >
                            Freight
                          </div>
                          <Form.Control
                            type="number"
                            placeholder="Enter Charges"
                            value={productSlideData.freight}
                            name="freight"
                            onChange={handleProductInputChange}
                            // name="qty"
                          />
                        </Form.Group>
                      </Col>
                      <Col
                        style={{
                          marginBottom: '16px',
                          marginTop: '12px'
                        }}
                        // xs={4}
                      >
                        <Form.Group>
                          <div
                            style={{
                              flexDirection: 'row',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <div
                              style={{
                                fontSize: 17,
                                fontWeight: 'bold'
                              }}
                            >
                              Loading / Unloading
                            </div>
                          </div>
                        
                          <Form.Control
                            type="number"
                            placeholder="Enter Charges"
                            value={productSlideData.loadingUnloading}
                            name="loadingUnloading"
                            onChange={handleProductInputChange}
                          />
                        </Form.Group>
                      </Col> */}
                    </div>
                  </div>
                  <div className='col-12 py-4 align-items-center d-flex gap-3'>
                    <Button className="col-2 pt-3 rounded-1" onClick={addSlider}>
                      {edit === true ? "Update Product" : "Add Product"}
                    </Button>
                    {edit === true ? <div className="btn btn-secondary col-2 pt-3 rounded-1 "
                      onClick={() => {
                        setEdit(false)
                        setSelectedProduct('')
                        setSelectedCategory('')
                        setSelectedSubcategory('')
                        setProductSlideData({
                          qty: '',
                          kg: '',
                          discount: '',
                          gst: '18'
                        })
                        setMeasurementType('')
                        setBasePrice('')
                        setPriceAfterDiscount('')
                        setPriceBeforeDiscount('')
                        setTotalQuanity('')
                        setTaxAmount('')
                        setNetValue('')
                        setSelectedBrand('')
                      }}>
                      Cancel
                    </div> : null}
                  </div>

                  <div
                    style={{
                      // marginLeft: '-17px',
                      // marginTop: 15,
                      // padding: 10,
                      // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                      // paddingLeft: 20,
                      // paddingRight: 20
                    }}
                    className="clientinfo-box d-flex flex-wrap row-gap-2"
                  >
                    <div className='col-12'>
                      <Form.Group>
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold'
                          }}
                        >
                          Remarks
                        </div>
                        <textarea
                          onChange={handleAdditionalDataChange}
                          value={additionalData.remarks}
                          style={{ padding: "8px" }}
                          className="form-control rounded-1"
                          name="remarks"
                          type="text"
                        />
                        {/* {error && error.communication ? (
                              <div style={{ color: 'red' }}>
                                {error.communication}
                              </div>
                            ) : null} */}
                      </Form.Group>
                    </div>
                    <div className='col-6 pe-2'>
                      <Form.Group >
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold'
                          }}
                        // className="required"
                        >
                          Delivery
                        </div>
                        {/* <Form.Label>Net Value</Form.Label> */}
                        <Form.Control
                          style={{ padding: "8px" }}
                          className="rounded-1"
                          type="text"
                          placeholder="Delivery"
                          value={additionalData.delivery}
                          name="delivery"
                          onChange={handleAdditionalDataChange}
                        />
                      </Form.Group>
                    </div>
                    <div className='col-6 ps-2'>
                      <Form.Group >
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold'
                          }}
                        // className="required"
                        >
                          GST
                        </div>
                        {/* <Form.Label>Net Value</Form.Label> */}
                        <Form.Control
                          style={{ padding: "8px" }}
                          className="rounded-1"
                          type="text"
                          placeholder="Gst"
                          value={additionalData.gst}
                          name="gst"
                          onChange={handleAdditionalDataChange}
                        />
                      </Form.Group>
                    </div>
                    <div className='col-6 pe-2'>
                      <Form.Group >
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold'
                          }}
                        // className="required"
                        >
                          Payment
                        </div>

                        <Form.Control
                          style={{ padding: "8px" }}
                          className="rounded-1"
                          type="text"
                          placeholder="Payment"
                          value={additionalData.payment}
                          name="payment"
                          onChange={handleAdditionalDataChange}
                        />
                      </Form.Group>
                    </div>
                    <div className='col-6 ps-2'>
                      <Form.Group>
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold'
                          }}
                        // className="required"
                        >
                          Fright
                        </div>
                        {/* <Form.Label>Net Value</Form.Label> */}
                        <Form.Control
                          style={{ padding: "8px" }}
                          className="rounded-1"
                          type="text"
                          placeholder="Delivery"
                          value={additionalData.fright}
                          name="fright"
                          onChange={handleAdditionalDataChange}
                        />
                      </Form.Group>
                    </div>
                    <div className='col-6 pe-2'>
                      <Form.Group >
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold'
                          }}
                        // className="required"
                        >
                          Make
                        </div>
                        {/* <Form.Label>Net Value</Form.Label> */}
                        <Form.Control
                          style={{ padding: "8px" }}
                          className="rounded-1"
                          type="text"
                          placeholder="Make"
                          value={additionalData.make}
                          name="make"
                          onChange={handleAdditionalDataChange}
                        />
                      </Form.Group>
                    </div>

                    <div className='col-6 ps-3'>
                      <Form.Group>
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold'
                          }}
                        >
                          Additional Note
                        </div>
                        <input
                          style={{ padding: "8px" }}
                          type="text"
                          placeholder="Additional Note"
                          value={additionalData.note}
                          name="note"
                          onChange={handleAdditionalDataChange}
                          className="form-control rounded-1"
                        />
                        {/* {error && error.communication ? (
                              <div style={{ color: 'red' }}>
                                {error.communication}
                              </div>
                            ) : null} */}
                      </Form.Group>
                    </div>
                    {/* <div className='col-6 p-3'>
                      <Form.Group>
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold'
                          }}
                        >
                          Terms And Conditions
                        </div>
                        <textarea
                          type="text"
                          placeholder="Terms and Conditions"
                          value={additionalData.terms}
                          name="terms"
                          onChange={handleAdditionalDataChange}
                          className="form-control"
                        />
                        {error && error.communication ? (
                              <div style={{ color: 'red' }}>
                                {error.communication}
                              </div>
                            ) : null}
                      </Form.Group>
                    </div> */}
                    <div className='col-6 pe-2'>
                      <Form.Group >
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold'
                          }}
                        // className="required"
                        >
                          Fright Charges
                        </div>
                        {/* <Form.Label>Net Value</Form.Label> */}

                        <Form.Control
                          style={{ padding: "8px" }}
                          className="rounded-1"
                          type="text"
                          placeholder="Terms and Conditions"
                          value={freightCharges}
                          name="terms"
                          onChange={(e) => setFreightCharges(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                    <div className='col-6 ps-2'>
                      <Form.Group >
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold'
                          }}
                        // className="required"
                        >
                          Loading Unloading Charges
                        </div>
                        {/* <Form.Label>Net Value</Form.Label> */}
                        <Form.Control
                          style={{ padding: "8px" }}
                          className="rounded-1"
                          type="text"
                          placeholder="Terms and Conditions"
                          value={loadingUnloadingCharges}
                          name="terms"
                          onChange={(e) => setLoadingUnloadingCharges(e.target.value)}
                        />
                      </Form.Group>
                    </div>

                  </div>
                  {/* <div style={{ fontSize: '22px', marginTop: 10 }}>
                                Quantity Info
                              </div>
                              <div
                                style={{
                                  marginLeft: '-17px',
                                  // borderWidth: 1,
                                  // borderColor: 'black',
                                  marginTop: 5,
                                  padding: 10,
                                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                                  paddingLeft: 20,
                                  paddingRight: 20
                                }}
                                className="card card-flush h-xl-100"
                              >
                                <div className="row">
                                  <Col
                                    style={{
                                      marginBottom: '16px',
                                      marginTop: '12px'
                                    }}
                                    xs={3}
                                  >
                                    <Form.Group
                                      controlId={`formQuantity${index}`}
                                    >
                                      <Form.Label>Quantity</Form.Label>
                                      <Form.Control
                                        type="number"
                                        placeholder="Enter quantity"
                                        value={productSlideData.qty}
                                        name="qty"
                                        qty
                                        onChange={handleProductInputChange}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col
                                    style={{
                                      marginBottom: '16px',
                                      marginTop: '12px'
                                    }}
                                    xs={3}
                                  >
                                    <div key={index}>
                                      <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                        <span className="required">
                                          Measurement
                                        </span>
                                      </label>
                                      <ReactSelect
                                        options={measurementDataOption}
                                        value={measurementType}
                                        onChange={(value) => {
                                          setMeasurementType(value);
                                          setBasePrice('0');
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col
                                    style={{
                                      marginBottom: '16px',
                                      marginTop: '12px'
                                    }}
                                    xs={3}
                                  >
                                    <Form.Group
                                      controlId={`formQuantity${index}`}
                                    >
                                      <Form.Label>Enter Kg</Form.Label>
                                      <Form.Control
                                        type="number"
                                        placeholder="Enter quantity"
                                        value={productSlideData.kg}
                                        name="kg"
                                        onChange={handleProductInputChange}
                                        disabled={
                                          measurementType.label !== 'Kilogram '
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col
                                    style={{
                                      marginBottom: '16px',
                                      marginTop: '12px'
                                    }}
                                    xs={3}
                                  >
                                    <Form.Group
                                      controlId={`formQuantity${index}`}
                                    >
                                      <Form.Label>Total Quantity</Form.Label>
                                      <Form.Control
                                        // type="number"
                                        placeholder="Enter quantity"
                                        value={totalQuanity}
                                        name="totalQuantity"
                                        // onChange={handleProductInputChange}
                                      />
                                    </Form.Group>
                                  </Col>
                                </div>
                              </div>
                              <div style={{ fontSize: '22px', marginTop: 10 }}>
                                Pricing details
                              </div> */}
                  {/* <div
                                style={{
                                  marginLeft: '-17px',
                                  // borderWidth: 1,
                                  // borderColor: 'black',
                                  marginTop: 5,
                                  padding: 10,
                                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                                  paddingLeft: 20,
                                  paddingRight: 20
                                }}
                                className="card card-flush h-xl-100"
                              >
                                <div className="row">
                                  <Col
                                    style={{
                                      marginBottom: '16px',
                                      marginTop: '12px'
                                    }}
                                    xs={4}
                                  >
                                    <Form.Group
                                      controlId={`formQuantity${index}`}
                                    >
                                      <Form.Label>Price</Form.Label>
                                      <Form.Control
                                        type="number"
                                        placeholder="Enter quantity"
                                        value={
                                          basePrice
                                          // measurementType.label == 'Meter '
                                          //   ? basePrice
                                          //   : null
                                        }
                                        name="price"
                                        onChange={handleBasePriceInputChange}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col
                                    style={{
                                      marginBottom: '16px',
                                      marginTop: '12px'
                                    }}
                                    xs={4}
                                  >
                                    <div key={index}>
                                      <Form.Group
                                        controlId={`formQuantity${index}`}
                                      >
                                        <Form.Label>
                                          Price Before Discount
                                        </Form.Label>
                                        <Form.Control
                                          // type="number"
                                          placeholder="Enter quantity"
                                          value={priceBeforeDiscount}
                                          name="priceBeforeDiscount"
                                          // onChange={handleProductInputChange}
                                        />
                                      </Form.Group>
                                    </div>
                                  </Col>
                                  <Col
                                    style={{
                                      marginBottom: '16px',
                                      marginTop: '12px'
                                    }}
                                    xs={4}
                                  >
                                    <Form.Group
                                      controlId={`formQuantity${index}`}
                                    >
                                      <Form.Label>Discount (%)</Form.Label>
                                      <Form.Control
                                        // type="number"
                                        placeholder="Enter quantity"
                                        value={productSlideData.discount}
                                        name="discount"
                                        onChange={handleProductInputChange}
                                        // name="qty"
                                      />
                                    </Form.Group>
                                  </Col>
                                </div>
                                <div className="row">
                                  <Col
                                    style={{
                                      marginBottom: '16px',
                                      marginTop: '12px'
                                    }}
                                    xs={4}
                                  >
                                    <Form.Group
                                      controlId={`formQuantity${index}`}
                                    >
                                      <Form.Label>
                                        Price After Discount(Taxable Amount)
                                      </Form.Label>
                                      <Form.Control
                                        // type="number"
                                        placeholder="Enter quantity"
                                        value={
                                          productSlideData.discount
                                            ? priceAfterDiscount
                                            : priceBeforeDiscount
                                        }
                                        name="priceAfterDiscount"
                                        // onChange={handleProductInputChange}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col
                                    style={{
                                      marginBottom: '16px',
                                      marginTop: '12px'
                                    }}
                                    xs={4}
                                  >
                                    <div key={index}>
                                      <Form.Group
                                        controlId={`formQuantity${index}`}
                                      >
                                        <Form.Label> GST (%)</Form.Label>
                                        <Form.Control
                                          type="number"
                                          placeholder="Enter quantity"
                                          value={productSlideData.gst}
                                          name="gst"
                                          onChange={handleProductInputChange}
                                        />
                                      </Form.Group>
                                    </div>
                                  </Col>
                                  <Col
                                    style={{
                                      marginBottom: '16px',
                                      marginTop: '12px'
                                    }}
                                    xs={4}
                                  >
                                    <Form.Group
                                      controlId={`formQuantity${index}`}
                                    >
                                      <Form.Label>Net Value</Form.Label>
                                      <Form.Control
                                        // type="number"
                                        placeholder="Enter quantity"
                                        value={netValue}
                                        name="netValue"
                                        // onChange={handleProductInputChange}
                                        // name="qty"
                                      />
                                    </Form.Group>
                                  </Col>
                                </div>
                              </div> */}
                  {/* {index !== 0 && (
                          <Col xs={1} className="d-flex align-items-center">
                            <span>
                              <i
                                style={{
                                  fontSize: '21px',
                                  marginTop: '10px'
                                }}
                                className="fa-solid text-danger fa-circle-minus fa-2xl"
                                onClick={() => handleRemoveInputFields(index)}
                              ></i>
                            </span>
                          </Col>
                        )} */}
                  {/* </Row> */}
                  {/* ))} */}
                </Form>
              )}
              {activeOption === 'product' ? (
                <>
                  <div style={{ textAlign: 'center', marginTop: '10px' }}>
                    {/* {[...Array(formCount)].map((_, index) => (
                              <FaCircle
                                key={index}
                                style={{
                                  cursor: 'pointer',
                                  margin: '0 5px',
                                  color:
                                    index === currentSlide ? 'blue' : 'gray'
                                }}
                                onClick={() => setCurrentSlide(index)}
                              />
                            ))} */}
                  </div>
                  <div
                    style={{
                      marginTop: '20px',
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                      // backgroundColor: 'red',
                      marginBottom: '5px',
                      gap: "10px"
                    }}
                  >
                    {/* {formCount > 1 ? (
                              <Button
                                onClick={prevSlide}
                                style={{
                                  padding: '0.5rem 0.8rem',
                                  fontSize: '0.9rem'
                                }}
                              >
                                <FaArrowLeft />
                              </Button>
                            ) : null} */}

                    {/* {activeOption == 'product' ? (
                      <>
                        <Button
                          className="mb-3"
                          variant="tertiary"
                          // variant="primary"
                          onClick={() => setActiveOption("Lead Details")}
                          // onClick={openModal}
                          style={{ width: '11%', border: "1px solid #ced4da" }}
                        >
                          Back
                        </Button>
                        <Button
                          className="mb-3"
                          variant="success"
                          // variant="primary"
                          onClick={setIsUpdateModalOpen}
                          // onClick={openModal}
                          style={{ width: '11%' }}
                        >
                          Update
                        </Button>
                      </>
                    ) : null} */}
                    {/* {formCount > 1 ? (
                              <Button
                                style={{
                                  padding: '0.5rem 0.8rem',
                                  fontSize: '0.9rem'
                                }}
                                onClick={nextSlide}
                              >
                                <FaArrowRight />
                              </Button>
                            ) : null} */}
                  </div>
                </>
              ) : null}

              {/* {activeOption === 'product' ? (
              <Form>
                {selectedMeasurements.map((data, index) => (
                  <Row className="mt-3" key={index}>
                    <Col xs={3}>
                      <Form.Group controlId={`formName${index}`}>
                        <Form.Label>Item Name</Form.Label>
                        <ReactSelect
                          options={productOptions}
                          value={productOptions.filter(function (option) {
                            return option.value === data.product_id;
                          })}
                          onInputChange={handleInputChange2}
                          onChange={(selectedOption) => {
                            handleMeasurementChange(
                              'productid',
                              selectedOption.id,
                              index
                            );
                            fetchProductData(selectedOption.id, index);
                          }}
                          isDisabled={!!data.product_id}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={2}>
                      <Form.Group controlId={`formPrice${index}`}>
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter price"
                          name="product_price"
                          value={data.price}
                          onChange={(event) => handleChange2(index, event)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={2}>
                      <Form.Group controlId={`formQuantity${index}`}>
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter quantity"
                          name="qty"
                          value={
                            selectedMeasurements[index].qty
                              ? selectedMeasurements[index].qty
                              : ''
                          }
                          onChange={(event) =>
                            handleMeasurementChange('quantity', event, index)
                          }
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={2}>
                      <div key={index}>
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                          <span className="required">Measurement</span>
                        </label>
                        <select
                          className="form-control"
                          value={data.measurement_type}
                          onChange={(event) =>
                            handleMeasurementChange('measurement', event, index)
                          }
                        >
                          <option value="">Select</option>
                          {leadtype.map((measurement) => (
                            <option
                              key={measurement.type}
                              value={measurement.type}
                            >
                              {measurement.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col xs={2}>
                      <Form.Group controlId={`formNetValue${index}`}>
                        <Form.Label>Net Value</Form.Label>

                        <Form.Control
                          type="number"
                          placeholder="Net value"
                          name="price_qty"
                          value={selectedMeasurements[index].netValue}
                        />
                      </Form.Group>
                    </Col>

                    {selectedMeasurements.map((data, index) => (
                      <Row className="" key={index}>
                      </Row>
                    ))}

                    <Col xs={1} className="d-flex align-items-center">
                      <span>
                        <i
                          style={{
                            fontSize: '21px',
                            marginTop: '10px',
                            cursor: 'pointer'
                          }}
                          className="fa-solid text-danger fa-circle-minus fa-2xl"
                          onClick={() =>
                            handleRemoveFields(
                              data.id,

                              index
                            )
                          }
                        ></i>
                      </span>
                    </Col>
                  </Row>
                ))}
                <Row className="mt-3">
                  <Col xs={12} className="d-flex align-items-center">
                    <span>
                      <i
                        style={{ fontSize: '21px', cursor: 'pointer' }}
                        className="fa-solid text-success fa-circle-plus fa-2xl"
                        onClick={handleAddFields}
                      ></i>
                    </span>
                  </Col>
                </Row>
              </Form>
            ) : null} */}

              {/* {activeOption === 'communication' ? (
              <Form>
                <div
                  id="kt_app_content"
                  className="app-content flex-column-fluid"
                >
                  <div
                    id="kt_app_content_container"
                    className=" container-fluid"
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly'
                      }}
                    ></div>
                    <div className="col-xxl-12 mb-5 mb-xl-12">
                      <div className="card card-flush h-xl-100">
                        <Row>
                          <Col xs={12} style={{ padding: '39px' }}>
                            <Form.Group>
                              <Form.Label>Lead Communication</Form.Label>
                              <textarea
                                onChange={handleInputChange3}
                                value={communicationData.communication}
                                className="form-control box_shadow"
                                name="communication"
                                type="text"
                                placeholder=""
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={4}>
                            <Form.Group
                              className=""
                              style={{
                                display: 'flex',
                                marginTop: '16%',
                                marginLeft: '35px'
                              }}
                            >
                              <input
                                style={{ width: '11%' }}
                                value={isChecked}
                                onChange={handleCheckboxChange}
                                type="checkbox"
                                placeholder=""
                                name="communication"
                              />
                              <Form.Label
                                style={{
                                  marginLeft: '6px',
                                  marginTop: '7px',
                                  marginLeft: '12px'
                                }}
                              >
                                Is Reminder
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col xs={4}>
                            <div className="row mt-5">
                              <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">
                                  Expected Close Date
                                </span>
                              </label>
                              <input
                                min={new Date().toISOString().split('T')[0]}
                                type="datetime-local"
                                onChange={handleInputChange3}
                                value={communicationData.communication_date}
                                name="communication_date"
                                className="form-control "
                                placeholder="Enter Expected Close Date"
                              />
                            </div>
                          </Col>
                          <Col xs={4} style={{ marginTop: '25px' }}>
                            <Button
                              className="mt-5"
                              variant="success"
                              onClick={communicationChangeHandler}
                              style={{
                                width: '34%',
                                float: 'right',
                                marginRight: '31px'
                              }}
                            >
                              ADD
                            </Button>
                          </Col>
                        </Row>
                        <hr></hr>
                        <div className="">
                          <div className="tab-content">
                            <div className=" mb-5 mb-xl-12">
                              <div className="row">
                                <div className="card-body col-12 py-3">
                                  <div className="">
                                    <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                                     
                                      <tbody
                                        style={{
                                          fontSize: '14px',
                                          color: 'var(--bs-text-gray-800)'
                                        }}
                                      ></tbody>
                                    </table>

                                    <div className="row">
                                      {communicationlead.map((e, index) => (
                                        <div className="col-6" key={index}>
                                          <div
                                            className="card card-flush box_shadow mb-12"
                                            style={{
                                              boxShadow:
                                                ' rgba(0, 0, 0, 0.24) 0px 3px 8px;'
                                            }}
                                          >
                                            <div className="card-header justify-content-start align-items-center pt-4">
                                              <div className="symbol symbol-45px me-5"></div>
                                              <h3
                                                style={{
                                                  color:
                                                    'darkslategrey!important'
                                                }}
                                                className=" fw-semibold fs-6"
                                              >
                                                {isExpanded
                                                  ? e.communication
                                                  : e.communication.slice(
                                                      0,
                                                      maxLength
                                                    )}
                                                {e.communication.length >
                                                  maxLength && (
                                                  <span>
                                                    <a
                                                      style={{
                                                        marginLeft: '10px'
                                                      }}
                                                      href="#"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        toggleReadMore(index);
                                                      }}
                                                    >
                                                      {communicationStates[
                                                        index
                                                      ]
                                                        ? 'Read Less'
                                                        : 'Read More'}
                                                    </a>
                                                  </span>
                                                )}
                                              </h3>
                                            </div>
                                            <div className="card-footer">
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  justifyContent:
                                                    'space-between'
                                                }}
                                              >
                                                <div className="indicator-label">
                                                  <p>communication date</p>
                                                  <div>{e.created_at}</div>
                                                </div>
                                                <div className="indicator-label">
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      justifyContent:
                                                        'space-between'
                                                    }}
                                                  >
                                                    <i
                                                      style={{
                                                        color: 'goldenrod'
                                                      }}
                                                      className="fa-solid fa-star"
                                                    ></i>
                                                    <p>Reminder date</p>
                                                  </div>
                                                  <div>
                                                    {e.communication_date}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            ) : null} */}

              {activeOption == 'product' ? (
                <div className="clientinfo-box">
                  <div className='fw-bold fs-3'>
                    Product Details
                  </div>
                  {/* <div style={{ marginBottom: 10, padding: 24 }}> */}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      maxHeight: '200px',
                      overflowY: 'auto'
                    }}
                  >
                    <table className="table-responsive w-100">
                      <thead
                        style={{
                          position: 'sticky',
                          top: 0,
                          backgroundColor: 'white',
                          zIndex: 1
                        }}
                      >
                        <tr>
                          <th className='fw-semibold fs-5 text-center'>Sr no.</th>
                          <th className='fw-semibold fs-5 '>Product Name</th>
                          <th className='fw-semibold fs-5 '>Brand</th>
                          <th className='fw-semibold fs-5 text-center'>Category</th>
                          <th className='fw-semibold fs-5 text-center'>SubCategory</th>
                          <th className='fw-semibold fs-5 text-center'>Total Quantity</th>
                          <th className='fw-semibold fs-5 text-center'>Price /pc </th>
                          <th className='fw-semibold fs-5 text-center'>Discount</th>
                          <th className='fw-semibold fs-5 text-center'>Net-price</th>
                          <th className='fw-semibold fs-5 text-center'>Taxable Amount</th>
                          <th className='fw-semibold fs-5 text-center'>GST</th>
                          <th className='fw-semibold fs-5 text-center'>Net-Value </th>
                          <th className='fw-semibold fs-5 text-center'>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {productsData.length == 0 ? (
                          <tr className="text-center">
                            <td
                              colSpan="13"
                              style={{ fontSize: 18, marginTop: '10px' }}
                            >
                              No Data found
                            </td>
                          </tr>
                        ) : (
                          <>
                            {productsData.map((e, index) => {
                              if (Object.keys(e).length === 0) {
                                return null; // Skip rendering the row
                              }
                              return (
                                <tr key={index}>
                                  <td className='text-center'>{index + 1}</td>
                                  <td>
                                    {e.product_name
                                      ? e.product_name
                                      : '------'}
                                  </td>
                                  <td className='text-center'>
                                    {e.brand_name
                                      ? e.brand_name
                                      : '------'}
                                  </td>
                                  <td className='text-center'>
                                    {e.category_name
                                      ? e.category_name
                                      : '------'}
                                  </td>
                                  <td className='text-center'>
                                    {e.sub_category_name
                                      ? e.sub_category_name
                                      : '------'}
                                  </td>
                                  <td className='text-center'>
                                    {e.total_quantity
                                      ? e.total_quantity
                                      : '------'}
                                  </td>
                                  {/* <td>120</td> */}
                                  <td className='text-center'>
                                    {e.product_price
                                      ? e.product_price
                                      : '------'}
                                  </td>
                                  {/* <td>24000</td> */}
                                  <td className='text-center'>{e.discount}%</td>
                                  <td className='text-center'>{e.net_price}</td>
                                  <td className='text-center'>{e.taxable_amount}</td>
                                  <td className='text-center'>{e.product_gst}%</td>
                                  <td className='text-center'>
                                    {e.total_price ? parseFloat(e.total_price).toFixed(2) : '------'}
                                  </td>
                                  <td className="d-flex justify-content-between px-3">
                                    <i
                                      onClick={
                                        () => {
                                          setProductIndex(e.product_id);
                                          setProductIndexForDelete(index);
                                          setLeadId(e.lead_id);
                                          setIsModalOpen(true);
                                        }
                                        // deleteProduct(index)
                                      }
                                      // onClick={() => DeleteProduct()}
                                      style={{
                                        // marginRight: '20px',
                                        color: 'red',
                                        fontSize: '20px',
                                        cursor: 'pointer'
                                      }}
                                      className="fa-solid fa-trash fs-5"
                                    ></i>
                                    <i
                                      onClick={() => {
                                        window.scrollTo({
                                          top: 0,
                                          behavior: 'smooth' // Smooth scroll behavior
                                        });
                                        handleEditProduct({
                                          product_no: e.product_no,
                                          product_id: e.product_id,
                                          category_id: e.category_id,
                                          brand_id: e.brand_id,
                                          sub_category_id: e.sub_category_id,
                                          product_qty: e.product_qty,
                                          measurement: e.measurement,
                                          product_kg: e.product_kg,
                                          product_price: e.product_price,
                                          discount: e.discount,
                                          product_gst: e.product_gst,
                                          total_price: e.total_price,
                                          net_price: e.net_price,
                                          taxable_amount: e.taxable_amount,
                                          total_quantity: e.total_quantity
                                        });
                                        setEdit(true);
                                      }}

                                      className="fa-solid fa-pen fs-4 text-primary cursor-pointer px-1 fs-5"
                                    ></i>
                                  </td>

                                </tr>
                              );
                            })}
                          </>
                        )}

                      </tbody>
                    </table>
                  </div>
                  {/* </div> */}
                  {/* </div> */}
                </div>

              ) : null}
              {activeOption == 'product' ? (
                <div className='my-5 d-flex justify-content-start gap-3'>
                  <Button
                    className="rounded-1"
                    variant="tertiary"
                    // variant="primary"
                    onClick={() => setActiveOption("Lead Details")}
                    // onClick={openModal}
                    style={{ width: '11%', border: "1px solid #ced4da" }}
                  >
                    Back
                  </Button>
                  <Button
                    className="rounded-1"
                    variant="success"
                    // variant="primary"
                    onClick={setIsUpdateModalOpen}
                    // onClick={openModal}
                    style={{ width: '11%' }}
                  >
                    Update
                  </Button>
                </div>
              ) : null}

              {isModalOpen && (
                <Modal
                  style={{ display: 'block' }}
                  isOpen={isModalOpen}
                  onClose={closeModal}
                >
                  <div style={{ alignSelf: 'center' }}>
                    {/* <h1 style={{alignItems:'center'}}>Are You sure</h1> */}
                    <h2> Are You sure</h2>
                    <h4>You want to delete this product</h4>
                    <button
                      style={{ marginTop: '30px' }}
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setIsModalOpen(false)}
                    // disabled={isButtonDisabled}
                    >
                      Cancel
                    </button>
                    <button
                      style={{ marginTop: '30px', marginLeft: '20px' }}
                      type="button"
                      className="btn btn-success"
                      onClick={() => {
                        // DeleteProduct()
                        // alert('frontend');
                        deleteProduct();
                        Swal.fire({
                          title: "Product Deletion",
                          text: "Your Product has been successfully Deleted.",
                          icon: "success",
                          confirmButtonColor: 'green',
                          allowOutsideClick: false,

                        });

                        // setIsNestedModalOpen(true);
                      }}
                    // }}
                    // disabled={isButtonDisabled}
                    >
                      Delete
                    </button>
                  </div>
                </Modal>
              )}

              {isNestedModalOpen && (
                <Modal isOpen={isNestedModalOpen} onClose={closeAllModals}>
                  <h2>Product deleted successfully </h2>

                  <button
                    style={{ marginTop: '10px' }}
                    type="button"
                    className="btn btn-primary"
                    onClick={closeAllModals}
                  >
                    OK
                  </button>
                </Modal>
              )}

              {isUpdateModalOpen && (
                <Modal
                  style={{ display: 'block' }}
                  isOpen={isUpdateModalOpen}
                  onClose={closeUpdateModal}
                >
                  <div style={{ alignSelf: 'center' }}>
                    {/* <h1 style={{alignItems:'center'}}>Are You sure</h1> */}
                    <h2> Are You sure</h2>
                    <h4>You want to update this lead</h4>
                    <button
                      style={{ marginTop: '30px' }}
                      type="button"
                      className="btn btn-secondary rounded-1"
                      onClick={() => setIsUpdateModalOpen(false)}
                    // disabled={isButtonDisabled}
                    >
                      Cancel
                    </button>
                    <button
                      style={{ marginTop: '30px', marginLeft: '20px' }}
                      type="button"
                      className="btn btn-success rounded-1"
                      onClick={() => {
                        // DeleteProduct()
                        // alert('frontend');
                        leadSubmit();
                        // setIsNestedUpdateModalOpen(true);
                        setIsUpdateModalOpen(false);
                      }}
                    // }}
                    // disabled={isButtonDisabled}
                    >
                      Update
                    </button>
                  </div>
                </Modal>
              )}

              {isNestedUpdateModalOpen && (
                <Modal
                  isOpen={isNestedUpdateModalOpen}
                  onClose={closeAllUpdateModals}
                >
                  <h2>Lead updated successfully </h2>

                  <button
                    style={{ marginTop: '10px' }}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      closeAllUpdateModals();
                      navigate('/manageleads');
                    }}
                  >
                    OK
                  </button>
                </Modal>
              )}
            </div>
          </div>
          {/* <Button
          className=""
          variant="primary"
          onClick={leadSubmit}
          style={{ width: '11%', marginTop: '21px' }}
        >
          Submit
        </Button> */}

          {/*end product section*/}
        </Constant >
      </div >
    </>

  );
};

export default UpdateLead;
