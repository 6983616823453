import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Url from '../Elements/Api/Api';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import {
  setRequestStatus,
  setStaffId,
  setStatus
} from '../Elements/Redux/Action/Authaction';
import { setToken } from '../Elements/Redux/Action/Authaction';
import { setName } from '../Elements/Redux/Action/Authaction';
import { attendanceStatus } from '../Elements/Redux/Action/Authaction';
import { setProfileImage } from '../Elements/Redux/Action/Authaction';

const UserLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitHandle = async (e) => {
    e.preventDefault();
    try {
      if (email && password) {
        const params = {
          email: email,
          password: password
        };

        const response = await axios.post(Url.BASEURL + 'stafflogin', params);

        if (response.data.status === 200) {
          const responseData = response.data.response;

          // Dispatch Redux actions
          dispatch(setStatus(responseData.manager_status));
          dispatch(setToken(responseData.token));
          dispatch(setName(responseData.name));
          dispatch(attendanceStatus(responseData.today_attendance));
          dispatch(setProfileImage(responseData.profile_pic));
          dispatch(setStaffId(responseData.id));
          dispatch(setRequestStatus(responseData.request_status));
          // Navigate to the dashboard
          navigate('/dashboard');

          // Store relevant data in localStorage
          localStorage.setItem('loginResponse', JSON.stringify(responseData));
          localStorage.setItem('toast', true);

        } else {
          setError(response.data.response);
        }
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  return (
    <div>
      <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>

        <div className="d-flex flex-column flex-lg-row flex-column-fluid justify-content-center">

          <div
            className="d-flex flex-column flex-lg-row-fluid py-10"
            style={{ justifyContent: 'center', alignItems: 'center' }}
          >


            <div className="card">
              <div className="d-flex flex-center flex-column flex-column-fluid">

                <div className="p-10 p-lg-15 mx-auto">

                  <form
                    className="form w-100"
                    noValidate="novalidate"
                    id="kt_sign_in_form"
                    data-kt-redirect-url="../dist/index.html"
                    action="#"
                  >
                    <div className='d-flex justify-content-center mb-5'>
                      <div className='image-box col-12'>
                        <img
                          style={{
                            height: '200px',
                            width: "80%",
                            margin: "auto",
                            display: "block",
                          }}
                          alt="Logo"
                          src="dist/assets/media/logos/logo.jpeg"
                          className="theme-light-show logo_filter"
                        />
                      </div>

                    </div>

                    <div className="text-center mb-10">


                      <p className="text-dark mb-3 fs-1 fw-bold" style={{ letterSpacing: "1px" }}>
                        Sign In to your account
                      </p>
                      <p className="text-dark-emphasis mb-3 fs-7 fw-semibold " style={{ letterSpacing: "1px" }}>
                        Enter your details to proceed further
                      </p>
                      {error ? (
                        <div
                          style={{
                            color: 'red',
                            textAlign: 'center',

                            background: '#FFCCB', fontSize: 20, fontWeight: '600'
                          }}
                        >
                          {error}
                        </div>
                      ) : null}
                    </div>

                    <div className="fv-row mb-5">

                      <label
                        style={{ float: 'left' }}
                        className="form-label fs-6 fw-bold text-dark"
                      >
                        Email Address
                      </label>

                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control form-control-lg rounded-1"
                        type="text"
                        name="email"
                      />

                    </div>

                    <div className="fv-row mb-15">

                      <div className="d-flex flex-stack mb-2">

                        <label className="form-label fw-bold text-dark fs-6 mb-0">
                          Password
                        </label>
                      </div>

                      <input
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control form-control-lg rounded-1"
                        type="password"
                        name="password"
                        autoComplete='off'
                      />
                      <div className='mt-1 me-1 text-primary fw-semibold'>
                        <Link
                          style={{ float: 'inline-end' }}
                          to="forgot_password"
                        >
                          Forgot Password ?
                        </Link>
                      </div>

                    </div>

                    <div className="d-flex justify-content-center">
                      <button
                        onClick={submitHandle}
                        className="col-6 btn btn-primary btn-block rounded-1"
                      >
                        SIGN IN
                      </button>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserLogin;
