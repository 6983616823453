import * as Yup from "yup";

//validation schema for new lead
// export const newLeadSchema = Yup.object().shape({
//     inputParam: Yup.array().of(
//       Yup.object().shape({
//         contact_person_name: Yup.string().required('Contact person name is required'),
//         contact_person_email: Yup.string().email('Invalid email format').required('Contact person email is required'),
//         contact_person_mobile: Yup.string().required('Contact person mobile is required'),
//         contact_person_designation: Yup.string().required('Contact person designation is required')
//       })
//     )
//   });
// 

export const newLeadSchema = (index) => {
    return Yup.object().shape({
        [`contact_person_name_${index}`]: Yup.string().required(`Contact person name is required`),
        // [`contact_person_email_${index}`]: Yup.string().email(`Invalid email format`).required(`Contact person email is required`),
        [`contact_person_mobile_${index}`]: Yup.string().required(`Contact person mobile is required`),
        [`contact_person_designation_${index}`]: Yup.string().required(`Contact person designation is required`)
    });
};
//schema for fillig lead details
export const salesSchemaOne = Yup.object({
    organisation: Yup.string().required(),
    title: Yup.string().required(),
    expt_close_date: Yup.string().required("close date is a required field"),
    stage: Yup.string().required(),
    description: Yup.string().required(),
    // communicationDesc: Yup.string().required(),
});

// schema for fillig lead details
export const newSchemaInput = Yup.object({
    // gst_no:Yup.string().required(),
    organisation:Yup.string().required(),
    address:Yup.string().required(),
    industry_id:Yup.string().required("industry type is a required field"),
    title: Yup.string().required(),
    expt_close_date: Yup.string().required("close date is a required field"),
    stage: Yup.string().required(),
    description: Yup.string().required(),
    // communicationDesc: Yup.string().required(),
});

//new feedback schema
export const newFeedbackSchemaInput = Yup.object({
    // gst_no:Yup.string().required(),
    organisation:Yup.string().required(),
    address:Yup.string().required(),
    industry_id:Yup.string().required("industry type is a required field"),
    title: Yup.string().required(),
    // expt_close_date: Yup.string().required("close date is a required field"),
    stage: Yup.string().required(),
    description: Yup.string().required(),
    // communicationDesc: Yup.string().required(),
});


//schema for checkBox
export const checkBoxSchema = Yup.object({
    reminderDate: Yup.string().required("Reminder Date is a required field"),
});

//feedback validation schema
export const feedbackSchema = Yup.object({
    organisation: Yup.string().required(),
    title: Yup.string().required(),
    // expt_close_date: Yup.string().required("close date is a required field"),
    stage: Yup.string().required(),
    description: Yup.string().required(),
    // communicationDesc: Yup.string().required(),
});

//feedback validation schema
export const additionalDataSchema = Yup.object({
    // remarks: Yup.string().required(),
    delivery: Yup.string().required(),
    gst: Yup.string().required(),
    freight: Yup.string().required(),
    payment: Yup.string().required(),
    make: Yup.string().required(),
    // note: Yup.string().required()
});

//pasword schema
export const passwordSchema = Yup.object({
    newPassword: Yup.string()
        .required('Required')
        .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/,
            'Must have at least 1 uppercase, 1 lowercase, 1 number, 1 special character, and be 8+ characters long'
        ),
    confirmPassword: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});