import React, { useEffect, useState } from 'react';
import Constant from '../../Constant/UserLayout';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Url from '../../Elements/Api/Api';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBProgress,
  MDBProgressBar,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem
} from 'mdb-react-ui-kit';
import Header from '../../Constant/Header';

import Modal from '../ManageLeads/Modal';

const Userprofile = () => {
  const token = useSelector((state) => state.token);
  const toggleShow = () => setBasicModal(!basicModal);
  const [basicModal, setBasicModal] = useState(false);
  const [profile, setProfile] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isRequestedModal, setIsRequestedModal] = useState(false);
  const [isUpdateProfileModal, setIsUpdateProfileModal] = useState(false);
  const [profileUpdatedSuccessModal, setProfileUpdatedSuccessModal] =
    useState(false);
  const closeAllModals = () => {
    setIsRequestedModal(false);
    setIsUpdateModalOpen(false);
    setIsUpdateProfileModal(false);
    setProfileUpdatedSuccessModal(false);
  };
  const closeUpdateModal = () => setIsUpdateModalOpen(false);
  const [descriptionUpdate, setDescriptionUpdate] = useState('');
  const handleModalClose = () => {
    setShowModal(false);
    //setShowOptions(true);
  };
  const todayDate = new Date();
  const year = todayDate.getFullYear();
  const month = String(todayDate.getMonth() + 1).padStart(2, '0'); // Add 1 to month because month is zero-indexed
  const date = String(todayDate.getDate()).padStart(2, '0');

  // Form the formatted date string in 'YYYY-MM-DD' format
  const formattedDate = `${year}-${month}-${date}`;
  const staffId = useSelector((item) => item.staffId);
  
  const [error, setError] = useState('');
  const [requestStatus, setRequestStatus] = useState('');
  const [requestID, setRequestID] = useState('')
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    contact_no: '',
    whatsapp_no: '',
    alternate_no: '',
    dob: '',
    join_date: '',
    team_manager: '',
    designation: '',
    aadhaar_number: '',
    pan_number: ''
  });
 
  useEffect(() => {

    getProfileApi()

  }, [token]);

  const getProfileApi = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(Url.BASEURL + 'profile?', config)
      .then((value) => {
        setUserData(value.data.response[0]);
        setRequestStatus(value.data.response[0].request_status);
        setRequestID(value.data.response[0].request_id)
      })
      .catch((err) => console.log(err));
  }
  const SubmitUpdate = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      staff_id: staffId,
      request_title: 'Profile edit',
      request_desc: descriptionUpdate,
      request_status: '0',
      request_date: formattedDate,
      request_sent_to: 'Admin',
      read_at: '0'
    };
    axios
      .post(Url.BASEURL + 'addEditRequestStaff', params, config)
      .then((value) => {
        closeUpdateModal();
        if (value && value.data.status == true) {
          closeUpdateModal();
          closeUpdateModal();
          setDescriptionUpdate('');
        } else {
          // setIsRequestedModal(true)
          // closeUpdateModal();
          setDescriptionUpdate('');
          // setError(value.data.errors);
          // setLoading(false);
        }
      })
      .catch((err) => {
        setIsRequestedModal(true);

        // setIsRequestedModal(true)
        // closeUpdateModal();
        // setLoading(false);
      });
  };

  const SubmitProfileUpdateWithNewData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      name: userData?.name,
      email: userData.email,
      contact_no: userData.contact_no,
      whatsapp_no: userData.whatsapp_no,
      alternate_no: userData.alternate_no,
      dob: userData.dob,
      join_date: userData.join_date,
      team_manager:
        userData?.manager_status === 0 && userData?.team_manager
          ? userData?.team_manager?.name
          : 'Manager it self',
      // designation: userData?.designationdata?.designation,
      aadhaar_number: userData.aadhaar_number,
      pan_number: userData.pan_number,
      alternate_name: userData.alternate_name,
      request_status: '3',
      request_id: requestID
      // profile_image: imageUrl,
    };

    axios
      .put(Url.BASEURL + 'updateStaffRequest/' + staffId, params, config)
      .then((value) => {
        if (value && value.data.status == 200) {
          setRequestStatus('');
          setProfileUpdatedSuccessModal(true);

        } else {
        }
      })
      .catch((err) => {
        closeAllModals();
      });
  };

  const handleDescriptionInputChnage = (e) => {
    setDescriptionUpdate(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    var keyToRemove = e.target.name;

    if ((name === 'contact_no' || name === 'whatsapp_no' || name === 'alternate_no') && !/^\d{0,10}$/.test(value)) {
      return;
    }
    else if (name === 'aadhaar_number' && !/^\d{0,12}$/.test(value)) {
      return;
    }

    else if (name === 'pan_number' && !/^[a-zA-Z0-9]{0,10}$/.test(value)) {
      return; // Do not update state if PAN number does not match the required format
    }


    setUserData({
      ...userData,
      [name]: value
    });
    setError({ ...error, [e.target.name]: '' });
  };

  return (
    <div>
      <Constant>
        <Header
          onpage_link="Manage Profile"
          heading="Manage Profile"
          profile_pic="profile_pic"
        />

        <section style={{ backgroundColor: '#FFFFFF' }}>
          <MDBContainer className="py-5">
            <MDBRow>
              {/* {profile.map((e) => {
                return ( */}
              <MDBCol lg="12">
                <MDBCard className="mb-4">
                  <MDBCardBody>
                    <div className="row">
                      <div className="col-6">
                        <div className="pt-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">FullName</span>
                          </label>

                          <input
                            name="name"
                            type="text"
                            className="form-control form-control-solid"
                            value={userData?.name}
                            onChange={handleInputChange}
                            disabled={requestStatus == '1' ? false : true}
                          />
                        </div>
                        <div className="pt-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Email</span>
                          </label>
                          <input
                            name="email"
                            type="text"
                            className="form-control form-control-solid"
                            value={userData?.email}
                            onChange={handleInputChange}
                            disabled={requestStatus == '1' ? false : true}
                          />
                        </div>
                        <div className="pt-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Contact Number</span>
                          </label>
                          <input
                            name="contact_no"
                            type="text"
                            className="form-control form-control-solid"
                            value={userData?.contact_no}
                            onChange={handleInputChange}
                            disabled={requestStatus == '1' ? false : true}
                          />
                        </div>
                        <div className="pt-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">WhatsApp Number</span>
                          </label>
                          <input
                            name="whatsapp_no"
                            type="text"
                            className="form-control form-control-solid"
                            value={userData?.whatsapp_no}
                            onChange={handleInputChange}
                            disabled={requestStatus == '1' ? false : true}
                          />
                        </div>
                        <div className="pt-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Alternate Number</span>
                          </label>
                          <input
                            name="alternate_no"
                            type="text"
                            className="form-control form-control-solid"
                            value={userData?.alternate_no}
                            onChange={handleInputChange}
                            disabled={requestStatus == '1' ? false : true}
                          />
                        </div>
                        <div className="pt-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Date Of Birth</span>
                          </label>
                          <input
                            name="dob"
                            type="date"
                            className="form-control form-control-solid"
                            value={userData?.dob}
                            onChange={handleInputChange}
                            disabled={requestStatus == '1' ? false : true}
                          />
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="pt-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Joining Year</span>
                          </label>
                          <input
                            name="join_date"
                            type="text"
                            className="form-control form-control-solid"
                            value={userData?.join_date}
                            disabled={true}

                          // onChange={handleInputChange}
                          />
                        </div>
                        <div className="pt-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Team Manager</span>
                          </label>
                          <input
                            name="team_manager"
                            type="text"
                            className="form-control form-control-solid"
                            value={
                              userData?.manager_status === 0 &&
                                userData?.team_manager
                                ? userData?.team_manager?.name
                                : 'Manager it self'
                            }
                            onChange={handleInputChange}
                            disabled={true}
                          />
                        </div>
                        <div className="pt-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Designation</span>
                          </label>
                          <input
                            name="designation"
                            type="text"
                            className="form-control form-control-solid"
                            value={userData?.designationdata?.designation}
                            onChange={handleInputChange}
                            disabled={true}
                          />
                        </div>
                        <div className="pt-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Aadhar Number</span>
                          </label>
                          <input
                            name="aadhaar_number"
                            type="text"
                            className="form-control form-control-solid"
                            value={userData?.aadhaar_number}
                            onChange={handleInputChange}
                            disabled={requestStatus == '1' ? false : true}
                          />
                        </div>
                        <div className="pt-5">
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Pan Number</span>
                          </label>
                          <input
                            name="pan_number"
                            type="text"
                            className="form-control form-control-solid"
                            value={userData?.pan_number}
                            onChange={handleInputChange}
                            disabled={requestStatus == '1' ? false : true}
                          />
                        </div>
                      </div>
                    </div>
                  </MDBCardBody>
                </MDBCard>

                <button
                  onClick={() => setIsUpdateModalOpen(true)}
                  // onClick={submitHandler}
                  className="btn btn-primary btn-block"
                // disabled={requestStatus=='1' ?false:  requestStatus=='2' ? false :requestStatus=='' ? false:true}
                >
                  Request to Update
                </button>
                <button
                  style={{ marginLeft: '44px' }}
                  disabled={requestStatus == '1' ? false : true}
                  onClick={() => setIsUpdateProfileModal(true)}
                  // onClick={submitHandler}
                  className="btn btn-primary btn-block"
                >
                  Update Profile
                </button>
                <MDBRow></MDBRow>
              </MDBCol>
              {/* //   );
              // })} */}
            </MDBRow>
          </MDBContainer>
          {isUpdateModalOpen && (
            <Modal
              style={{ display: 'block' }}
              isOpen={isUpdateModalOpen}
              onClose={closeUpdateModal}
            >
              <div style={{ alignSelf: 'center' }}>
                <h4 style={{ alignSelf: 'center', fontSize: 20 }}>
                  Profile Edit
                </h4>

                <div className="col-12" style={{ marginTop: 20 }}>
                  <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                    <span className="required">Description</span>
                  </label>
                  <input
                    // maxLength={15}
                    onChange={handleDescriptionInputChnage}
                    // name="gst_no"
                    value={descriptionUpdate}
                    type="text"
                    className="form-control form-control-solid"
                    placeholder="Enter Description"
                    style={{ height: 100 }}
                  />
                  {/* {error && error.gst_no ? (
                              <div style={{ color: 'red' }}>{error.gst_no}</div>
                            ) : null} */}
                </div>
                <button
                  style={{ marginTop: '30px' }}
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setIsUpdateModalOpen(false)}
                // disabled={isButtonDisabled}
                >
                  Cancel
                </button>
                <button
                  style={{ marginTop: '30px', marginLeft: '20px' }}
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    SubmitUpdate();
                    // DeleteProduct()
                    // alert('frontend');
                    // leadSubmit();
                    // submitHandler();
                    // setIsUpdateModalOpen(false);
                  }}
                // }}
                // disabled={isButtonDisabled}
                >
                  Send
                </button>
              </div>
            </Modal>
          )}

          {isRequestedModal && (
            <Modal
              style={{ display: 'block' }}
              isOpen={isRequestedModal}
              onClose={closeAllModals}
            >
              <div style={{ alignSelf: 'center' }}>
                <h4 style={{ alignSelf: 'center', fontSize: 20 }}>
                  Request Already Sent
                </h4>

                <button
                  style={{ marginTop: '30px', marginLeft: '20px' }}
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    closeAllModals();
                    // DeleteProduct()
                    // alert('frontend');
                    // leadSubmit();
                    // submitHandler();
                    // setIsUpdateModalOpen(false);
                  }}
                // }}
                // disabled={isButtonDisabled}
                >
                  Ok
                </button>
              </div>
            </Modal>
          )}

          {isUpdateProfileModal && (
            <Modal
              style={{ display: 'block' }}
              isOpen={isUpdateProfileModal}
              onClose={closeAllModals}
            >
              <div style={{ alignSelf: 'center' }}>
                <h4 style={{ alignSelf: 'center', fontSize: 20 }}>
                  Update Profile
                </h4>
                <h6>Are you sure , you want to update this profile</h6>
                <button
                  style={{ marginTop: '30px' }}
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => closeAllModals()}
                // disabled={isButtonDisabled}
                >
                  No
                </button>
                <button
                  style={{ marginTop: '30px', marginLeft: '20px' }}
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    // closeAllModals();
                    SubmitProfileUpdateWithNewData();
                  }}
                // }}
                // disabled={isButtonDisabled}
                >
                  Yes
                </button>
              </div>
            </Modal>
          )}
          {profileUpdatedSuccessModal && (
            <Modal
              style={{ display: 'block' }}
              isOpen={profileUpdatedSuccessModal}
              onClose={closeAllModals}
            >
              <div style={{ alignSelf: 'center' }}>
                <h4 style={{ alignSelf: 'center', fontSize: 20 }}>
                  Profile Updated Successfully
                </h4>

                <button
                  style={{ marginTop: '30px', marginLeft: '20px' }}
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    closeAllModals();
                    getProfileApi()
                  }}
                >
                  Ok
                </button>
              </div>
            </Modal>
          )}
        </section>
      </Constant>
    </div>
  );
};

export default Userprofile;
