import React from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
const GuestGuard = ({ children }) => {
  // const  token  = "123456"
  const token = useSelector((state) => state.token);
  if (token) {
    return <Navigate to="/dashboard" />;
  } else {
    return <div>{children}</div>;
  }
};
export default GuestGuard;
