import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Url from '../Elements/Api/Api';
import { useSelector } from 'react-redux';
import './constant.css';
import { useLocation } from 'react-router-dom';

const Header = (props) => {
  const location = useLocation();
  const [scrumvalue, setScrumValue] = useState([]);

  const token = useSelector((state) => state.token);
  const profile_pic = useSelector((state) => state.profile_pic);

  const exporData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(Url.BASEURL + 'exportlead', config, {
        method: 'GET',
        responseType: 'blob', // important
        headers: {
          Accept: 'application/json'
        }
      })
      .then((response) => {
        window.open(response.data, '_blank');
        // var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        // var fileLink = document.createElement('a');
        // fileLink.href = fileURL;
        // fileLink.setAttribute('download', 'file.xlsx');
        // document.body.appendChild(fileLink);
        // fileLink.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // breadcrumb api
  useEffect(() => {
    axios
      .get(Url.BASEURL + 'breadcrumbsStaff' + location.pathname)
      .then((value) => {
        setScrumValue(value.data.breadcrumbs);
      })

      .catch((err) => console.log(err));
  }, []);
  const url = new URL('https://staff.symang.in/dashboard');
  const pathname = url.pathname.split('/').pop();
  return (
    <div id="kt_app_toolbar" className="app-toolbar pt-5 above_breadcrums">
      <div
        id="kt_app_toolbar_container"
        className="app-container container-fluid d-flex align-items-stretch"
      >
        <div className="d-flex flex-wrap w-100 gap-4 bottom_border justify-content-center align-items-center justify-content-between">
          <div className="page-title d-flex flex-column gap-1 me-3 mb-2">
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-6">
  {scrumvalue.map((e, index) => (
    <React.Fragment key={index}>
      {e.title === 'Dashboard' ? (
        <Link to={props.path1} key={index}>
          <li className="breadcrumb-item text-gray-700 fw-bold lh-1"></li>
        </Link>
      ) : (
        <Link to={e.url} className="breadcrumb-item" key={index}>
          <i className="ki-duotone ki-right fs-4 text-gray-700 mx-n1" />
        </Link>
      )}
      <Link to={e.url}>
        <li
          className="breadcrumb-item text-gray-700 fw-bold lh-1"
          style={{ cursor: 'pointer' }}
          key={index}
        >
          {e.title}
        </li>
      </Link>
    </React.Fragment>
  ))}
</ul>

          </div>
          <div>
            {props.btnName ? (
              <Link
                to={'/' + props.btn_path}
                className="btn btn-sm btn-success px-4 py-3"
              >
                {props.btnName}
              </Link>
            ) : null}

            {props.btnName2 ? (
              <div
                className="btn btn-sm btn-success ms-3 px-4 py-3"
                onClick={props.onClick}
              >
                {props.btnName2}
              </div>
            ) : null}
          </div>
          {props.profile_pic ? (
            <div className="profile_image">
              {props.profile_pic ? (
                <img
                  src={profile_pic}
                  class="rounded-circle shadow-4"
                  alt="Avatar"
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default Header;
