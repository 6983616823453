import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
// import Header from './Header2';
import Userprofile from '../Screens/UserProfile/Userprofile';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../Screens/ManageLeads/Modal';
import { delToken, setToken } from '../Elements/Redux/Action/Authaction';
import Url from '../Elements/Api/Api';
import axios from 'axios';
import { attendencemarkedStatus } from '../Elements/Redux/Action/Authaction';
import { attendanceStatus } from '../Elements/Redux/Action/Authaction';
import './constant.css';
import { setProfileImage } from '../Elements/Redux/Action/Authaction';

// import { useHistory } from 'react-router-dom';
const Constant = ({ children, children2 }) => {
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const imageStyle = {
    width: '150px'
  };
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [userData, setUserData] = useState([]);
  const [show, setShow] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);
  const [hoverItem, setHoverItem] = useState(false);
  const [profile, setProfile] = useState(false);
  const status = useSelector((state) => state.status);
  const token = useSelector((state) => state.token);
  const name = useSelector((state) => state.name);
  const profile_pic = useSelector((state) => state.profile_pic);
  const attendstatuss = useSelector((state) => state.attendStatus);
  const alreadymarkedAttendance = useSelector(
    (state) => state?.alreadyMarkedAttend
  );
  const [attendance, setAttendance] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(
  //   localStorage.getItem('isModalOpen') === 'true' || false
  // );
  const [isAttendanceMarked, setIsAttendanceMarked] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const [isNestedModalOpen, setIsNestedModalOpen] = useState(false);
  //open modal function
  useEffect(() => {
    if (attendstatuss === 1 || alreadymarkedAttendance === 3) {
      closeModal();
    } else {
      openModal();
    }
  }, [attendstatuss, alreadymarkedAttendance]);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openNestedModal = () => setIsNestedModalOpen(true);
  const closeNestedModal = () => setIsNestedModalOpen(false);
  const [currentDate2, setCurrentDate2] = useState(new Date());
  const [isAttendMarked, setIsAttendMarked] = useState('');
  const [data, setData] = useState(null);

  //handle fuction for hover
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [signoffModalOpen, setSignoffModalOpen] = useState(false);

  const [activeItem, setActiveItem] = useState(null);
  const [dropShow, setDropShow] = useState(false);
  const [dropShow2, setDropShow2] = useState(false);
  const [mapData, setMapData] = useState('');
  const handleToggleMenu = () => {
    setDropShow(!dropShow);
  };
  const closeUpdateModal = () => setLogoutModalOpen(false);
  const closeSignoffModal = () => setSignoffModalOpen(false);



  // useEffect(() => {
  //   const RefreshTokenApi = () => {
  //     RefreshTokenApis();
  //   };
  //   RefreshTokenApi();
  //   // Set up interval to call RefreshTokenApi every 45 minutes
  //   const interval = setInterval(RefreshTokenApi, 45 * 60 * 1000);
  //   return () => clearInterval(interval);
  // }, []);

  const RefreshTokenApis = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .post(Url.BASEURL + 'refresh-token', {}, config)
      .then((value) => {
        if (value && value.status == 200) {
          dispatch(setToken(value.data.token));
        } else {
          setIsSessionExpired(true);
          // dispatch(delToken(), navigate('/'));
        }
      })
      .catch((err) => {
        setIsSessionExpired(true);
        // dispatch(delToken(), navigate('/'));
      });
  };

    useEffect(() => {
      if (latitude !== '' && longitude !== '') {
        axios
          .get(
            'https://maps.googleapis.com/maps/api/geocode/json?' +
              'latlng=' +
              latitude +
              ',' +
              longitude +
              '&result_type=premise' +
              '&key=AIzaSyBPiODyK3IMgrq0805wpHsA4gISlLMPl-w'
          )
          .then((value) => {
            setMapData(value.data.results[0].formatted_address);
          })
          .catch((err) => console.log(err));
      }
    }, [latitude, longitude]);
    const handleToggleMenu2 = () => {
      setDropShow2(!dropShow2);
    };


  useEffect(() => {
    // Use this effect to set the activeItem based on the current route
    const currentPath = location.pathname;

    // Logic to determine the active item based on the current route
    let newActiveItem = '';
    if (currentPath === '/dashboard') {
      newActiveItem = 'dashboard';
    } else if (currentPath === '/manageclients') {
      newActiveItem = 'manageclients';
    } else if (currentPath === '/manageleads') {
      newActiveItem = 'manageleads';
    } else if (currentPath === '/manageproducts') {
      newActiveItem = 'manageproducts';
    } else if (currentPath === '/salesreport') {
      newActiveItem = 'salesreport';
      setDropShow(true);
    } else if (currentPath === '/daily_task_report') {
      newActiveItem = 'daily_task_report';
    } else if (currentPath === '/team_members') {
      setDropShow2(true);
      newActiveItem = 'team_members';
    } else if (currentPath === '/teamleads') {
      setDropShow2(true);
      newActiveItem = 'teamleads';
    } else if (currentPath === '/teamclients') {
      setDropShow2(true);
      newActiveItem = 'teamclients';
    } else if (currentPath === '/teamattendance') {
      setDropShow2(true);
      newActiveItem = 'teamattendance';
    } else if (currentPath === '/teamstatusreports') {
      setDropShow2(true);
      newActiveItem = 'teamstatusreports';
    } else if (currentPath === '/requeststatus') {
      newActiveItem = 'requeststatus';
    }
    // Add more conditions for other routes as needed

    setActiveItem(newActiveItem);
  }, [location]);
  // Function to handle menu item click
  const handleMenuItemClick = (menuItem) => {
    setActiveItem(menuItem);
  };

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        });
      } else {
      }
    };

    // Load the Google Maps API and then get the location
    const loadGoogleMapsApi = async() => {
      const script = await document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY`;
      script.onload = () => {
        getLocation();
      };
      document.head.appendChild(script);
    };

    loadGoogleMapsApi();
  }, []);

  // function for get current lat long

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       setLatitude(position.coords.latitude);
  //       setLongitude(position.coords.longitude);
  //     });
  //   } else {
  //     console.log('Geolocation is not supported by this browser.');
  //   }
  // }, []);
  // funciton to get current date

  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  var currentDate = `${day}-${month}-${year}`;

  const todayDate = new Date();
  const Thisyear = todayDate.getFullYear();
  const Thismonth = String(todayDate.getMonth() + 1).padStart(2, '0'); // Add 1 to month because month is zero-indexed
  const Thisdate = String(todayDate.getDate()).padStart(2, '0');

  // Form the formatted date string in 'YYYY-MM-DD' format
  const formattedDate = `${Thisyear}-${Thismonth}-${Thisdate}`;

  const currentDayDate = () => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    currentDate = `${day}-${month}-${year}`;
  };

  // fumction to store store currentdate on click of attendance button

  const handlePresentClick = () => {
    localStorage.setItem('attendanceDate', currentDate);

    openNestedModal();
    currentDayDate();
  };
  // function to close modal

  const closeAllModals = () => {
    closeNestedModal();
    closeModal();
  };
  // logouthandler function
  const sessionModal = () => {
    dispatch(delToken(), navigate('/'));
  };
  const logouthandler = () => {
    dispatch(delToken(), navigate('/'));
    dispatch(attendencemarkedStatus(0));

    handleLogout();
  };
  // function to clear local storage when logout
  const handleLogout = () => {
    localStorage.clear();
  };

  // // function to send request on attendance mark

  const getAttendance = () => {
    if (latitude && longitude) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const params = {
        latitude: latitude,
        longitude: longitude,
        formatted_address: mapData
      };
      axios
        .post(Url.BASEURL + 'markattendance', params, config)
        .then((value) => {
          if (value.data.stattus == 4) {
          } else {
            setIsAttendanceMarked(1);
          }
          // dispatch(alreadyMarkedAttend(value.data.status));
          dispatch(attendencemarkedStatus(value.data.status));
          setAttendance(value.data.response);
        })
        .catch((err) => console.log(err));
      handlePresentClick();
    } else {
      alert('enable to get your location');
    }
  };

  const HandleSignOff = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      signdate: formattedDate
    };
    axios
      .put(Url.BASEURL + 'staffSignOff', params, config)
      .then((value) => {
        if (value.data.status == 200) {
          dispatch(delToken());
          navigate('/');
        } else {
        }
      })
      .catch((err) => console.log(err));
  };


  const savedDate = localStorage.getItem('attendanceDate');
  // this api hits for the next day mark attendance
  if (
    (savedDate !== null || savedDate !== '') &&
    attendance &&
    currentDate !== savedDate
  ) {
    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${token}`
    //   }
    // };
    // axios
    //   .get(Url.BASEURL + 'checkattendance', config)
    //   .then((value) => {
    //     const todayAttendance = parseInt(value.data.response.today_attendance);
    //     setIsAttendMarked(todayAttendance);
    //     if (todayAttendance === 0) {
    //     } else {
    //     }
    //   })
    //   .catch((err) => console.log(err));
  } else {
  }

  // static image
  const imageSource = profile_pic
    ? profile_pic
    : 'https://www.shutterstock.com/image-vector/default-profile-picture-avatar-photo-600nw-1725917284.jpg';

  //api for user updation
  const fetchUserData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'refreshuserdetails', config)
      .then((value) => {
        dispatch(setProfileImage(value.data.response.profile_image.path));
      })
      .catch((err) => console.log(err));
  };
  // Fetch user data on component mount (initial render)
  useEffect(() => {
    fetchUserData();
  }, [navigate]);
  // function for breadscrum

  // useEffect((e) => {
  //   axios.get(Url.BASEURL + 'breadscrumbs/' + location.path).then(() => {
  //   });
  // });
  // funciton to navigte to add daily status report

  const dailyStatusReportPath = () => {
    navigate('/add_daily_task_report');
  };

  // function to get current lat long
  // useEffect(() => {
  //   const getLocation = () => {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition((position) => {
  //         setLatitude(position.coords.latitude);
  //         setLongitude(position.coords.longitude);
  //       });
  //     } else {
  //     }
  //   };

  //   // Load the Google Maps API and then get the location
  //   const loadGoogleMapsApi = () => {
  //     const script = document.createElement('script');
  //     script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY`;
  //     script.onload = () => {
  //       getLocation();
  //     };
  //     document.head.appendChild(script);
  //   };

  //   loadGoogleMapsApi();
  // }, []);

  // fucntion to get more details from the

  // function for third party api to get other information about current lat long

 

  if (token) {
    return (
      <div
        id="kt_app_body"
        data-kt-app-header-fixed="true"
        data-kt-app-header-fixed-mobile="true"
        data-kt-app-sidebar-enabled="true"
        data-kt-app-sidebar-fixed="true"
        data-kt-app-sidebar-hoverable="true"
        data-kt-app-sidebar-push-toolbar="true"
        data-kt-app-sidebar-push-footer="true"
        data-kt-app-toolbar-enabled="true"
        data-kt-app-aside-enabled="true"
        // data-kt-app-aside-fixed="true"
        data-kt-app-aside-push-toolbar="true"
        data-kt-app-aside-push-footer="true"
        className={profile ? 'app-default modal-open' : 'app-default'}
        // className="app-default"
        data-kt-app-sidebar-minimize={show ? 'on' : null}
        data-kt-drawer={toggle ? 'on' : null}
        style={
          profile
            ? {
                overflow: 'hidden',
                paddingRight: '10px'
              }
            : {
                paddingRight: '10px'
              }
        }
        // style={
        //   profile
        //     ? {
        //         overflow: 'hidden',
        //         paddingRight: '0px'
        //       }
        //     : null
        // }
      >
        {isSessionExpired && (
          <Modal
            style={{ display: 'block' }}
            isOpen={isSessionExpired}
            onClose={() => setIsSessionExpired(false)}
            // onClose={() => sessionModal()}
          >
            <h2>Your Session has been expired</h2>
            <button
              style={{ marginTop: '30px' }}
              type="button"
              className="btn btn-success"
              onClick={() => sessionModal()}
              // disabled={isButtonDisabled}
            >
              OK
            </button>
          </Modal>
        )}
        {isModalOpen && (
          <Modal
            style={{ display: 'block' }}
            isOpen={isModalOpen}
            onClose={closeModal}
          >
            <h2>Mark Your Attendance Here</h2>
            <button
              style={{ marginTop: '30px' }}
              type="button"
              className="btn btn-success"
              onClick={getAttendance}
              // disabled={isButtonDisabled}
            >
              Present
            </button>
          </Modal>
        )}

        {isNestedModalOpen && (
          <Modal isOpen={isNestedModalOpen} onClose={closeAllModals}>
            <h2>Attendance Status</h2>
            <p>Attendance has been marked</p>
            <button
              style={{ marginTop: '10px' }}
              type="button"
              className="btn btn-primary"
              onClick={closeAllModals}
            >
              OK
            </button>
          </Modal>
        )}
        {/*begin::App*/}
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
          {/*begin::Page*/}
          <div
            className="app-page flex-column flex-column-fluid"
            id="kt_app_page"
          >
            {/*begin::Header*/}
            <div
              id="kt_app_header"
              className="app-header d-flex flex-column flex-stack"
            >
              {/*begin::Header main*/}
              <div className="d-flex align-items-center flex-stack flex-grow-1">
                <div
                  className="app-header-logo d-flex align-items-center flex-stack pe-lg-15 ps-lg-6 mb-2"
                  id="kt_app_header_logo"
                >
                  {/*begin::Sidebar mobile toggle*/}
                  <div
                    className={
                      toggle
                        ? 'btn btn-icon btn-active-color-primary w-35px h-35px ms-3 me-2 d-flex d-lg-none active'
                        : 'btn btn-icon btn-active-color-primary w-35px h-35px ms-3 me-2 d-flex d-lg-none'
                    }
                    id="kt_app_sidebar_mobile_toggle"
                  >
                    <i
                      className="ki-duotone ki-abstract-14 fs-2"
                      onClick={() => setToggle(!toggle)}
                    >
                      <span className="path1" />
                      <span className="path2" />
                    </i>
                  </div>
                  {/*end::Sidebar mobile toggle*/}
                  {/*begin::Logo*/}
                  <a className="app-sidebar-logo">
                    <img
                      alt="Logo"
                      // src="assets/media/logos/default.svg"
                      src="dist/assets/media/logos/head-logo.png"
                      width="110px"
                      height="80px"
                      className="theme-light-show"
                    />
                    <img
                      alt="Logo"
                      src="assets/media/logos/default-dark.svg"
                      className="h-30px theme-dark-show"
                    />
                  </a>
                  {/*end::Logo*/}
                  {/*begin::Sidebar toggle*/}
                  <div
                    id="kt_app_sidebar_toggle"
                    data-kt-toggle="true"
                    data-kt-toggle-state="active"
                    data-kt-toggle-target="body"
                    data-kt-toggle-name="app-sidebar-minimize"
                  >
                    <i
                      className="ki-duotone ki-exit-left fs-2x rotate-180"
                      onClick={() => setShow(!show)}
                    >
                      <span className="path1" />
                      <span className="path2" />
                    </i>
                  </div>
                  {/*end::Sidebar toggle*/}
                </div>
                {/*begin::Navbar*/}
                <div
                  className="app-navbar flex-grow-1 m-0"
                  id="kt_app_header_navbar"
                >
                  {/* attendance button */}
                  <button
                    onClick={openModal}
                    // disabled={isAttendMarked === 1 ? true : false}
                    type="button"
                    className={
                      attendstatuss === 1 || alreadymarkedAttendance === 3
                        ? ' display_none'
                        : ' btn btn-success display_block'
                    }
                  >
                    Mark Your Attendance
                  </button>
                  <button
                    style={{ marginRight: '10px', marginLeft: '13px' }}
                    className="btn btn-success"
                    onClick={() => dailyStatusReportPath()}
                  >
                    Add Daily Status Report
                  </button>

                  {/* <div>
                    {latitude && longitude ? (
                      <p>
                        Latitude: {latitude}, Longitude: {longitude}
                      </p>
                    ) : (
                      <p>Fetching location...</p>
                    )}
                  </div> */}
                  <div className="d-flex align-items-center mx-3">
                    <div
                      style={{
                        // marginRight: '95px',
                        fontSize: '16px',
                        fontWeight: '600',
                        paddingRight: '10px'
                      }}
                    >
                      Welcome {name}
                    </div>
                    <div className="profile_image_profile">
                      {profile_pic ? (
                        <img
                          src={profile_pic}
                          className="rounded-circle shadow-4"
                          alt="Avatar"
                        />
                      ) : (
                        <img
                          src={imageSource}
                          className="rounded-circle shadow-4"
                          alt="Avatar"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* if notification it will be placed here */}
                {/*end::Navbar*/}
              </div>
              {/*end::Header main*/}
              {/*begin::Separator*/}

              {/*end::Separator*/}
            </div>
            {/*end::Header*/}
            {/*begin::Wrapper*/}
            <div
              className="app-wrapper flex-column flex-row-fluid"
              id="kt_app_wrapper"
            >
              {/*begin::Sidebar*/}
              <div
                // className={
                //   toggle
                //     ? 'app-sidebar flex-column drawer drawer-start drawer-on'
                //     : 'app-sidebar flex-column drawer drawer-start '
                // }
                style={{ zIndex: '9' }}
                id="kt_app_sidebar"
                className="app-sidebar flex-column"
                data-kt-drawer="true"
                data-kt-drawer-name="app-sidebar"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="250px"
                data-kt-drawer-direction="start"
                data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
              >
                {/*begin::Main*/}
                <div
                  className="d-flex flex-column justify-content-between h-100 hover-scroll-overlay-y my-2 d-flex flex-column"
                  id="kt_app_sidebar_main"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="true"
                  data-kt-scroll-height="auto"
                  data-kt-scroll-dependencies="#kt_app_header"
                  data-kt-scroll-wrappers="#kt_app_main"
                  data-kt-scroll-offset="5px"
                >
                  {/*begin::Sidebar menu*/}
                  <div
                    id="#kt_app_sidebar_menu"
                    data-kt-menu="true"
                    data-kt-menu-expand="false"
                    className="flex-column-fluid menu menu-sub-indention menu-column menu-rounded menu-active-bg mb-7"
                  >
                    {/*begin:Menu item*/}
                    <div
                      data-kt-menu-trigger="click"
                      className="menu-item menu-accordion"
                    >
                      {/*begin:Menu link*/}
                      <span
                        className={`menu-link py-0 pl-3 pe-0 ${
                          activeItem === 'dashboard' ? 'active' : ''
                        }`}
                        onClick={() => handleMenuItemClick('dashboard')}
                      >
                        <span className="menu-icon">
                          <i
                            className="fa-solid fa-gauge"
                            style={{ fontSize: '20px' }}
                          >
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                        <Link to="/dashboard" className="w-100">
                          <span className="menu-title">Dashboard</span>
                        </Link>
                      </span>
                      {/*end:Menu link*/}
                    </div>
                    <div
                      data-kt-menu-trigger="click"
                      className="menu-item menu-accordion"
                    >
                      {/*begin:Menu link*/}
                      <Link
                        to="/manageclients"
                        className={`menu-link py-0 pl-3 pe-0 ${
                          activeItem === 'manageclients' ? 'active' : ''
                        }`}
                        onClick={() => handleMenuItemClick('manageclients')}
                      >
                        <span className="menu-icon">
                          <i
                            className="fa-solid fa-people-group"
                            style={{ fontSize: '20px' }}
                          >
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                        <span className="menu-title">My Clients</span>
                      </Link>

                      {/*end:Menu link*/}
                    </div>
                    {/*end:Menu item*/}
                    {/*begin:Menu item*/}
                    <div
                      data-kt-menu-trigger="click"
                      className="menu-item menu-accordion"
                    >
                      {/*begin:Menu link*/}
                      <Link
                        to="/manageleads"
                        className={`menu-link py-0 pl-3 pe-0 ${
                          activeItem === 'manageleads' ? 'active' : ''
                        }`}
                        onClick={() => handleMenuItemClick('manageleads')}
                      >
                        <span className="menu-icon">
                          <i
                            className="fa-solid fa-shop"
                            style={{ fontSize: '20px' }}
                          >
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                        <span className={`menu-title`}>My Leads</span>
                      </Link>
                      {/*end:Menu link*/}
                    </div>

                    <div
                      data-kt-menu-trigger="click"
                      className="menu-item menu-accordion"
                    >
                      {/*begin:Menu link*/}
                      <Link
                        to="/manageproducts"
                        className={`menu-link py-0 pl-3 pe-0 ${
                          activeItem === 'manageproducts' ? 'active' : ''
                        }`}
                        onClick={() => handleMenuItemClick('manageproducts')}
                      >
                        <span className="menu-icon">
                          <i
                            className="fa-solid fa-boxes-stacked"
                            style={{ fontSize: '20px' }}
                          >
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                        <span className={`menu-title`}>My Products</span>
                      </Link>
                      {/*end:Menu link*/}
                    </div>

                    <div
                      data-kt-menu-trigger="click"
                      className="menu-item menu-accordion"
                    >
                      {/*begin:Menu link*/}
                      <Link
                        to="/daily_task_report"
                        className={`menu-link py-0 pl-3 pe-0 ${
                          activeItem === 'daily_task_report' ? 'active' : ''
                        }`}
                        onClick={() => handleMenuItemClick('daily_task_report')}
                      >
                        <span className="menu-icon">
                          <i
                            className="fa-solid fa-note-sticky"
                            style={{ fontSize: '20px' }}
                          >
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                        <span className={`menu-title`}>Daily Task Report</span>
                      </Link>
                      {/*end:Menu link*/}
                    </div>

                    <div
                      data-kt-menu-trigger="click"
                      className="menu-item menu-accordion"
                    >
                      {/*begin:Menu link*/}
                      <Link
                        to="/salesreport"
                        className={`menu-link py-0 pl-3 pe-0 ${
                          activeItem === 'salesreport' ? 'active' : ''
                        }`}
                        onClick={() => handleMenuItemClick('salesreport')}
                      >
                        <span className="menu-icon">
                          <i
                            className="fa-solid fa-clipboard"
                            style={{ fontSize: '20px' }}
                          >
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                        <span className={`menu-title`}>Sales Report</span>
                      </Link>
                      {/*end:Menu link*/}
                    </div>
                    {status === 1 ? (
                      <div
                        data-kt-menu-trigger="click"
                        className={`menu-item menu-accordion ${
                          dropShow2 ? 'show' : ''
                        }`}
                        onClick={handleToggleMenu2}
                      >
                        <span
                          className={`menu-link ${
                            dropShow2 ? 'menu-item-accordion-show' : ''
                          }`}
                          // onClick={() => setDropShow2(!dropShow2)}
                        >
                          <span className="menu-icon">
                            <i
                              className="fa-solid fa-users-gear"
                              style={{ fontSize: '20px' }}
                            ></i>
                            <span className="path1" />
                            <span className="path2" />
                          </span>
                          <span className="menu-title">Manage Team</span>
                          <span className="menu-arrow" />
                        </span>
                        {status === 1 && (
                          <div className="menu-sub menu-sub-accordion ">
                            {/*begin:Menu item*/}
                            <div
                              className={`menu-item ${
                                activeItem === 'team_members' ? 'active' : ''
                              }`}
                              onClick={() =>
                                handleMenuItemClick('team_members')
                              }
                            >
                              {/*begin:Menu link*/}
                              <Link
                                to="/team_members"
                                className={`menu-link ${
                                  activeItem === 'team_members' ? 'active' : ''
                                }`}
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">
                                  Team's Members
                                </span>
                              </Link>
                              {/*end:Menu link*/}
                            </div>
                            <div
                              className={`menu-item ${
                                activeItem === 'teamleads' ? 'active' : ''
                              }`}
                              onClick={() => handleMenuItemClick('teamleads')}
                            >
                              {/*begin:Menu link*/}
                              <Link
                                to="/teamleads"
                                className={`menu-link ${
                                  activeItem === 'teamleads' ? 'active' : ''
                                }`}
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Team's Leads</span>
                              </Link>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div
                              className={`menu-item ${
                                activeItem === 'teamclients' ? 'active' : ''
                              }`}
                              onClick={() => handleMenuItemClick('teamclients')}
                            >
                              {/*begin:Menu link*/}
                              <Link
                                to="/teamclients"
                                className={`menu-link ${
                                  activeItem === 'teamclients' ? 'active' : ''
                                }`}
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">
                                  Team's Clients
                                </span>
                              </Link>
                              {/*end:Menu link*/}
                            </div>
                            <div
                              className={`menu-item ${
                                activeItem === 'teamattendance' ? 'active' : ''
                              }`}
                              onClick={() =>
                                handleMenuItemClick('teamattendance')
                              }
                            >
                              {/*begin:Menu link*/}
                              <Link
                                to="/teamattendance"
                                className={`menu-link ${
                                  activeItem === 'teamattendance'
                                    ? 'active'
                                    : ''
                                }`}
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">
                                  Team's Attendance
                                </span>
                              </Link>

                              {/*end:Menu link*/}
                            </div>

                            <div
                              className={`menu-item ${
                                activeItem === 'teamstatusreports'
                                  ? 'active'
                                  : ''
                              }`}
                              onClick={() =>
                                handleMenuItemClick('teamstatusreports')
                              }
                            >
                              <Link
                                to="/teamstatusreports"
                                className={`menu-link ${
                                  activeItem === 'teamstatusreports'
                                    ? 'active'
                                    : ''
                                }`}
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">
                                  Team's Status Reports
                                </span>
                              </Link>
                            </div>

                            {/*end:Menu item*/}
                          </div>
                        )}
                      </div>
                    ) : (
                      ''
                    )}

                    <div
                      data-kt-menu-trigger="click"
                      className="menu-item menu-accordion"
                    >
                      {/*begin:Menu link*/}
                      <Link
                        to="/requeststatus"
                        className={`menu-link py-0 pl-3 pe-0 ${
                          activeItem === 'requeststatus' ? 'active' : ''
                        }`}
                        onClick={() => handleMenuItemClick('requeststatus')}
                      >
                        <span className="menu-icon">
                          <i
                            className="fa-solid fa-clipboard"
                            style={{ fontSize: '20px' }}
                          >
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                        <span className={`menu-title`}>Requests Status</span>
                      </Link>
                      {/*end:Menu link*/}
                    </div>
                    {/*end:Menu item*/}
                  </div>
                  {/*end::Sidebar menu*/}
                  {/*begin::Footer*/}
                  {/*end::Footer*/}
                </div>
                <div
                  className="app-sidebar-footer d-flex align-items-center"
                  id="kt_app_sidebar_footer"
                >
                  {/*begin::User*/}
                  <div>
                    {/*begin::User info*/}
                    <div
                      className="d-flex align-items-center"
                      data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                      data-kt-menu-overflow="true"
                      data-kt-menu-placement="top-start"
                    >
                      {/*begin::Name*/}
                      <div className="d-flex flex-column align-items-start justify-content-center">
                        <div>
                          <div
                            id="#kt_app_sidebar_menu"
                            data-kt-menu="true"
                            data-kt-menu-expand="false"
                            className="flex-column-fluid menu menu-sub-indention menu-column menu-rounded menu-active-bg mb-7"
                          >
                            <div
                              data-kt-menu-trigger="click"
                              className="menu-item menu-accordion"
                            >
                              {/*begin:Menu link*/}
                              <span className="menu-link">
                                <span className="menu-icon">
                                  <i
                                    className="fa-solid fa-user"
                                    style={{ fontSize: '20px' }}
                                  >
                                    <span className="path1" />
                                    <span className="path2" />
                                  </i>
                                </span>
                                <Link to="/userprofile">
                                  <span className="menu-title">
                                    User Profile
                                  </span>
                                </Link>
                              </span>
                              {/*end:Menu link*/}
                            </div>
                            <div
                              data-kt-menu-trigger="click"
                              className="menu-item menu-accordion"
                            >
                              <span className="menu-link">
                                <span className="menu-icon">
                                  <i
                                    className="fa-solid fa-user"
                                    style={{ fontSize: '20px' }}
                                  >
                                    <span className="path1" />
                                    <span className="path2" />
                                  </i>
                                </span>
                                <Link onClick={setSignoffModalOpen}>
                                  <span className="menu-title">Sign Off</span>
                                </Link>
                              </span>
                            </div>
                            <div
                              data-kt-menu-trigger="click"
                              className="menu-item menu-accordion"
                            >
                              {/*begin:Menu link*/}
                              <span className="menu-link">
                                <span className="menu-icon">
                                  <i
                                    className="fa-solid fa-right-from-bracket"
                                    style={{ fontSize: '20px' }}
                                  >
                                    <span className="path1" />
                                    <span className="path2" />
                                  </i>
                                </span>
                                <div>
                                  <span
                                    onClick={setLogoutModalOpen}
                                    className="menu-title"
                                  >
                                    Logout
                                  </span>
                                </div>
                              </span>
                              {/*end:Menu link*/}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*end::Name*/}
                    </div>
                    <div
                      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
                      data-kt-menu="true"
                      style={{}}
                    >
                      {/*begin::Menu item*/}
                      <div className="menu-item px-3">
                        <div className="menu-content d-flex align-items-center px-3">
                          {/*begin::Avatar*/}
                          <div className="symbol symbol-50px me-5">
                            <img
                              alt="Logo"
                              src="assets/media/avatars/300-1.jpg"
                            />
                          </div>
                          {/*end::Avatar*/}
                          {/*begin::Username*/}
                          <div className="d-flex flex-column">
                            <div className="fw-bold d-flex align-items-center fs-5">
                              Eugenia
                              <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                                Pro
                              </span>
                            </div>
                            <a
                              href="#"
                              className="fw-semibold text-muted text-hover-primary fs-7"
                            >
                              eugenia@kt.com
                            </a>
                          </div>
                          {/*end::Username*/}
                        </div>
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu separator*/}
                      <div className="separator my-2" />
                      {/*end::Menu separator*/}
                      {/*begin::Menu item*/}
                      <div className="menu-item px-5">
                        <a
                          href="../../demo42/dist/account/overview.html"
                          className="menu-link px-5"
                        >
                          My Profile
                        </a>
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu item*/}
                      <div className="menu-item px-5">
                        <a
                          href="../../demo42/dist/apps/projects/list.html"
                          className="menu-link px-5"
                        >
                          <span className="menu-text">My Projects</span>
                          <span className="menu-badge">
                            <span className="badge badge-light-danger badge-circle fw-bold fs-7">
                              3
                            </span>
                          </span>
                        </a>
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu item*/}
                      <div
                        className="menu-item px-5"
                        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                        data-kt-menu-placement="right-end"
                        data-kt-menu-offset="-15px, 0"
                      >
                        <a href="#" className="menu-link px-5">
                          <span className="menu-title">My Subscription</span>
                          <span className="menu-arrow" />
                        </a>
                        {/*begin::Menu sub*/}
                        <div className="menu-sub menu-sub-dropdown w-175px py-4">
                          {/*begin::Menu item*/}
                          <div className="menu-item px-3">
                            <a
                              href="../../demo42/dist/account/referrals.html"
                              className="menu-link px-5"
                            >
                              Referrals
                            </a>
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div className="menu-item px-3">
                            <a
                              href="../../demo42/dist/account/billing.html"
                              className="menu-link px-5"
                            >
                              Billing
                            </a>
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div className="menu-item px-3">
                            <a
                              href="../../demo42/dist/account/statements.html"
                              className="menu-link px-5"
                            >
                              Payments
                            </a>
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div className="menu-item px-3">
                            <a
                              href="../../demo42/dist/account/statements.html"
                              className="menu-link d-flex flex-stack px-5"
                            >
                              Statements
                              <span
                                className="ms-2"
                                data-bs-toggle="tooltip"
                                aria-label="View your statements"
                                data-bs-original-title="View your statements"
                                data-kt-initialized={1}
                              >
                                <i className="ki-outline ki-information fs-7" />
                              </span>
                            </a>
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu separator*/}
                          <div className="separator my-2" />
                          {/*end::Menu separator*/}
                          {/*begin::Menu item*/}
                          <div className="menu-item px-3">
                            <div className="menu-content px-3">
                              <label className="form-check form-switch form-check-custom form-check-solid">
                                <input
                                  className="form-check-input w-30px h-20px"
                                  type="checkbox"
                                  defaultValue={1}
                                  defaultChecked="checked"
                                  name="notifications"
                                />
                                <span className="form-check-label text-muted fs-7">
                                  Notifications
                                </span>
                              </label>
                            </div>
                          </div>
                          {/*end::Menu item*/}
                        </div>
                        {/*end::Menu sub*/}
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu item*/}
                      <div className="menu-item px-5">
                        <a
                          href="../../demo42/dist/account/statements.html"
                          className="menu-link px-5"
                        >
                          My Statements
                        </a>
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu separator*/}
                      <div className="separator my-2" />
                      {/*end::Menu separator*/}
                      {/*begin::Menu item*/}
                      <div
                        className="menu-item px-5"
                        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                        data-kt-menu-placement="left-start"
                        data-kt-menu-offset="-15px, 0"
                      >
                        <a href="#" className="menu-link px-5">
                          <span className="menu-title position-relative">
                            Mode
                            <span className="ms-5 position-absolute translate-middle-y top-50 end-0">
                              <i className="ki-outline ki-night-day theme-light-show fs-2" />
                              <i className="ki-outline ki-moon theme-dark-show fs-2" />
                            </span>
                          </span>
                        </a>
                        {/*begin::Menu*/}
                        <div
                          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
                          data-kt-menu="true"
                          data-kt-element="theme-mode-menu"
                        >
                          {/*begin::Menu item*/}
                          <div className="menu-item px-3 my-0">
                            <a
                              href="#"
                              className="menu-link px-3 py-2 active"
                              data-kt-element="mode"
                              data-kt-value="light"
                            >
                              <span
                                className="menu-icon"
                                data-kt-element="icon"
                              >
                                <i className="ki-outline ki-night-day fs-2" />
                              </span>
                              <span className="menu-title">Light</span>
                            </a>
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div className="menu-item px-3 my-0">
                            <a
                              href="#"
                              className="menu-link px-3 py-2"
                              data-kt-element="mode"
                              data-kt-value="dark"
                            >
                              <span
                                className="menu-icon"
                                data-kt-element="icon"
                              >
                                <i className="ki-outline ki-moon fs-2" />
                              </span>
                              <span className="menu-title">Dark</span>
                            </a>
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div className="menu-item px-3 my-0">
                            <a
                              href="#"
                              className="menu-link px-3 py-2"
                              data-kt-element="mode"
                              data-kt-value="system"
                            >
                              <span
                                className="menu-icon"
                                data-kt-element="icon"
                              >
                                <i className="ki-outline ki-screen fs-2" />
                              </span>
                              <span className="menu-title">System</span>
                            </a>
                          </div>
                          {/*end::Menu item*/}
                        </div>
                        {/*end::Menu*/}
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu item*/}
                      <div
                        className="menu-item px-5"
                        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                        data-kt-menu-placement="right-end"
                        data-kt-menu-offset="-15px, 0"
                      >
                        <a href="#" className="menu-link px-5">
                          <span className="menu-title position-relative">
                            Language
                            <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
                              English
                              <img
                                className="w-15px h-15px rounded-1 ms-2"
                                src="assets/media/flags/united-states.svg"
                                alt="imges"
                              />
                            </span>
                          </span>
                        </a>
                        {/*begin::Menu sub*/}
                        <div className="menu-sub menu-sub-dropdown w-175px py-4">
                          {/*begin::Menu item*/}
                          <div className="menu-item px-3">
                            <a
                              href="../../demo42/dist/account/settings.html"
                              className="menu-link d-flex px-5 active"
                            >
                              <span className="symbol symbol-20px me-4">
                                <img
                                  className="rounded-1"
                                  src="assets/media/flags/united-states.svg"
                                  alt="image2"
                                />
                              </span>
                              English
                            </a>
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div className="menu-item px-3">
                            <a
                              href="../../demo42/dist/account/settings.html"
                              className="menu-link d-flex px-5"
                            >
                              <span className="symbol symbol-20px me-4">
                                <img
                                  className="rounded-1"
                                  src="assets/media/flags/spain.svg"
                                  alt="image3"
                                />
                              </span>
                              Spanish
                            </a>
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div className="menu-item px-3">
                            <a
                              href="../../demo42/dist/account/settings.html"
                              className="menu-link d-flex px-5"
                            >
                              <span className="symbol symbol-20px me-4">
                                <img
                                  className="rounded-1"
                                  src="assets/media/flags/germany.svg"
                                  alt="image4"
                                />
                              </span>
                              German
                            </a>
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div className="menu-item px-3">
                            <a
                              href="../../demo42/dist/account/settings.html"
                              className="menu-link d-flex px-5"
                            >
                              <span className="symbol symbol-20px me-4">
                                <img
                                  className="rounded-1"
                                  src="assets/media/flags/japan.svg"
                                  alt="image5"
                                />
                              </span>
                              Japanese
                            </a>
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div className="menu-item px-3">
                            <a
                              href="../../demo42/dist/account/settings.html"
                              className="menu-link d-flex px-5"
                            >
                              <span className="symbol symbol-20px me-4">
                                <img
                                  className="rounded-1"
                                  src="assets/media/flags/france.svg"
                                  alt="image6"
                                />
                              </span>
                              French
                            </a>
                          </div>
                          {/*end::Menu item*/}
                        </div>
                        {/*end::Menu sub*/}
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu item*/}
                      <div className="menu-item px-5 my-1">
                        <a
                          href="../../demo42/dist/account/settings.html"
                          className="menu-link px-5"
                        >
                          Account Settings
                        </a>
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu item*/}
                      <div className="menu-item px-5">
                        <a
                          href="../../demo42/dist/authentication/layouts/corporate/sign-in.html"
                          className="menu-link px-5"
                        >
                          Sign Out
                        </a>
                      </div>
                      {/*end::Menu item*/}
                    </div>
                    {/*end::User info*/}
                    {/*begin::User account menu*/}
                    {/*end::User account menu*/}
                  </div>
                  {/*end::User*/}
                </div>

                {/*end::Main*/}
              </div>
              {/*end::Sidebar*/}
              {/*begin::Main*/}
              <div
                className="app-main flex-column flex-row-fluid "
                id="kt_app_main"
              >
                {/*begin::Content wrapper*/}
                <div className="d-flex flex-column flex-column-fluid">
                  {children}

                  {/*end::Content*/}
                </div>
                {/*end::Content wrapper*/}
                {/*begin::Footer*/}
                <div
                  id="kt_app_footer"
                  className="app-footer align-items-center justify-content-center justify-content-md-between flex-column flex-md-row py-3"
                >
                  {/*begin::Copyright*/}
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">2023©</span>
                    <a
                      style={{ marginLeft: '10px' }}
                      // href="https://keenthemes.com"
                      target="_blank"
                      // className="text-gray-800 text-hover-primary"
                    >
                      OM METALS{' '}
                    </a>
                  </div>
                  {/*end::Copyright*/}
                  {/*begin::Menu*/}

                  {/*end::Menu*/}
                </div>
                {/*end::Footer*/}
              </div>
              {/*end:::Main*/}
              {/*begin::aside*/}
              <div
                style={{ zIndex: '9' }}
                id="kt_app_aside"
                className="app-aside flex-column"
                data-kt-drawer="true"
                data-kt-drawer-name="app-aside"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="auto"
                data-kt-drawer-direction="end"
                data-kt-drawer-toggle="#kt_app_aside_mobile_toggle"
              >
                {/*begin::Wrapper*/}

                {/*end::Wrapper*/}
              </div>
              {/*end::aside*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Page*/}
        </div>
        {/*end::App*/}
        {/*begin::Drawers*/}
        {/*begin::Activities drawer*/}
        <div
          id="kt_activities"
          className="bg-body"
          data-kt-drawer="true"
          data-kt-drawer-name="activities"
          data-kt-drawer-activate="true"
          data-kt-drawer-overlay="true"
          data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
          data-kt-drawer-direction="end"
          data-kt-drawer-toggle="#kt_activities_toggle"
          data-kt-drawer-close="#kt_activities_close"
        >
          <div className="card shadow-none border-0 rounded-0">
            {/*begin::Header*/}
            <div className="card-header" id="kt_activities_header">
              <h3 className="card-title fw-bold text-dark">Activity Logs</h3>
              <div className="card-toolbar">
                <button
                  type="button"
                  className="btn btn-sm btn-icon btn-active-light-primary me-n5"
                  id="kt_activities_close"
                >
                  <i className="ki-duotone ki-cross fs-1">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </button>
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div
              className="card-body position-relative"
              id="kt_activities_body"
            >
              {/*begin::Content*/}
              <div
                id="kt_activities_scroll"
                className="position-relative scroll-y me-n5 pe-5"
                data-kt-scroll="true"
                data-kt-scroll-height="auto"
                data-kt-scroll-wrappers="#kt_activities_body"
                data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer"
                data-kt-scroll-offset="5px"
              >
                {/*begin::Timeline items*/}
                <div className="timeline">
                  {/*begin::Timeline item*/}
                  <div className="timeline-item">
                    {/*begin::Timeline line*/}
                    <div className="timeline-line w-40px" />
                    {/*end::Timeline line*/}
                    {/*begin::Timeline icon*/}
                    <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                      <div className="symbol-label bg-light">
                        <i className="ki-duotone ki-message-text-2 fs-2 text-gray-500">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                        </i>
                      </div>
                    </div>
                    {/*end::Timeline icon*/}
                    {/*begin::Timeline content*/}
                    <div className="timeline-content mb-10 mt-n1">
                      {/*begin::Timeline heading*/}
                      <div className="pe-3 mb-5">
                        {/*begin::Title*/}
                        <div className="fs-5 fw-semibold mb-2">
                          There are 2 new tasks for you in “AirPlus Mobile App”
                          project:
                        </div>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="d-flex align-items-center mt-1 fs-6">
                          {/*begin::Info*/}
                          <div className="text-muted me-2 fs-7">
                            Added at 4:23 PM by
                          </div>
                          {/*end::Info*/}
                          {/*begin::User*/}
                          <div
                            className="symbol symbol-circle symbol-25px"
                            data-bs-toggle="tooltip"
                            data-bs-boundary="window"
                            data-bs-placement="top"
                            title="Nina Nilson"
                          >
                            <img
                              src="assets/media/avatars/300-14.jpg"
                              alt="img"
                            />
                          </div>
                          {/*end::User*/}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Timeline heading*/}
                      {/*begin::Timeline details*/}
                      <div className="overflow-auto pb-5">
                        {/*begin::Record*/}
                        <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
                          {/*begin::Title*/}
                          <a
                            href="#"
                            className="fs-5 text-dark text-hover-primary fw-semibold w-375px min-w-200px"
                          >
                            Meeting with customer
                          </a>
                          {/*end::Title*/}
                          {/*begin::Label*/}
                          <div className="min-w-175px pe-2">
                            <span className="badge badge-light text-muted">
                              Application Design
                            </span>
                          </div>
                          {/*end::Label*/}
                          {/*begin::Users*/}
                          <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2">
                            {/*begin::User*/}
                            <div className="symbol symbol-circle symbol-25px">
                              <img
                                src="assets/media/avatars/300-2.jpg"
                                alt="img"
                              />
                            </div>
                            {/*end::User*/}
                            {/*begin::User*/}
                            <div className="symbol symbol-circle symbol-25px">
                              <img
                                src="assets/media/avatars/300-14.jpg"
                                alt="img"
                              />
                            </div>
                            {/*end::User*/}
                            {/*begin::User*/}
                            <div className="symbol symbol-circle symbol-25px">
                              <div className="symbol-label fs-8 fw-semibold bg-primary text-inverse-primary">
                                A
                              </div>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Users*/}
                          {/*begin::Progress*/}
                          <div className="min-w-125px pe-2">
                            <span className="badge badge-light-primary">
                              In Progress
                            </span>
                          </div>
                          {/*end::Progress*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light btn-active-light-primary"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Record*/}
                        {/*begin::Record*/}
                        <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-0">
                          {/*begin::Title*/}
                          <a
                            href="#"
                            className="fs-5 text-dark text-hover-primary fw-semibold w-375px min-w-200px"
                          >
                            Project Delivery Preparation
                          </a>
                          {/*end::Title*/}
                          {/*begin::Label*/}
                          <div className="min-w-175px">
                            <span className="badge badge-light text-muted">
                              CRM System Development
                            </span>
                          </div>
                          {/*end::Label*/}
                          {/*begin::Users*/}
                          <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px">
                            {/*begin::User*/}
                            <div className="symbol symbol-circle symbol-25px">
                              <img
                                src="assets/media/avatars/300-20.jpg"
                                alt="img"
                              />
                            </div>
                            {/*end::User*/}
                            {/*begin::User*/}
                            <div className="symbol symbol-circle symbol-25px">
                              <div className="symbol-label fs-8 fw-semibold bg-success text-inverse-primary">
                                B
                              </div>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Users*/}
                          {/*begin::Progress*/}
                          <div className="min-w-125px">
                            <span className="badge badge-light-success">
                              Completed
                            </span>
                          </div>
                          {/*end::Progress*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light btn-active-light-primary"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Record*/}
                      </div>
                      {/*end::Timeline details*/}
                    </div>
                    {/*end::Timeline content*/}
                  </div>
                  {/*end::Timeline item*/}
                  {/*begin::Timeline item*/}
                  <div className="timeline-item">
                    {/*begin::Timeline line*/}
                    <div className="timeline-line w-40px" />
                    {/*end::Timeline line*/}
                    {/*begin::Timeline icon*/}
                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                      <div className="symbol-label bg-light">
                        <i className="ki-duotone ki-flag fs-2 text-gray-500">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                    </div>
                    {/*end::Timeline icon*/}
                    {/*begin::Timeline content*/}
                    <div className="timeline-content mb-10 mt-n2">
                      {/*begin::Timeline heading*/}
                      <div className="overflow-auto pe-3">
                        {/*begin::Title*/}
                        <div className="fs-5 fw-semibold mb-2">
                          Invitation for crafting engaging designs that speak
                          human workshop
                        </div>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="d-flex align-items-center mt-1 fs-6">
                          {/*begin::Info*/}
                          <div className="text-muted me-2 fs-7">
                            Sent at 4:23 PM by
                          </div>
                          {/*end::Info*/}
                          {/*begin::User*/}
                          <div
                            className="symbol symbol-circle symbol-25px"
                            data-bs-toggle="tooltip"
                            data-bs-boundary="window"
                            data-bs-placement="top"
                            title="Alan Nilson"
                          >
                            <img
                              src="assets/media/avatars/300-1.jpg"
                              alt="img"
                            />
                          </div>
                          {/*end::User*/}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Timeline heading*/}
                    </div>
                    {/*end::Timeline content*/}
                  </div>
                  {/*end::Timeline item*/}
                  {/*begin::Timeline item*/}
                  <div className="timeline-item">
                    {/*begin::Timeline line*/}
                    <div className="timeline-line w-40px" />
                    {/*end::Timeline line*/}
                    {/*begin::Timeline icon*/}
                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                      <div className="symbol-label bg-light">
                        <i className="ki-duotone ki-disconnect fs-2 text-gray-500">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                          <span className="path5" />
                        </i>
                      </div>
                    </div>
                    {/*end::Timeline icon*/}
                    {/*begin::Timeline content*/}
                    <div className="timeline-content mb-10 mt-n1">
                      {/*begin::Timeline heading*/}
                      <div className="mb-5 pe-3">
                        {/*begin::Title*/}
                        <a
                          href="#"
                          className="fs-5 fw-semibold text-gray-800 text-hover-primary mb-2"
                        >
                          3 New Incoming Project Files:
                        </a>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="d-flex align-items-center mt-1 fs-6">
                          {/*begin::Info*/}
                          <div className="text-muted me-2 fs-7">
                            Sent at 10:30 PM by
                          </div>
                          {/*end::Info*/}
                          {/*begin::User*/}
                          <div
                            className="symbol symbol-circle symbol-25px"
                            data-bs-toggle="tooltip"
                            data-bs-boundary="window"
                            data-bs-placement="top"
                            title="Jan Hummer"
                          >
                            <img
                              src="assets/media/avatars/300-23.jpg"
                              alt="img"
                            />
                          </div>
                          {/*end::User*/}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Timeline heading*/}
                      {/*begin::Timeline details*/}
                      <div className="overflow-auto pb-5">
                        <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5">
                          {/*begin::Item*/}
                          <div className="d-flex flex-aligns-center pe-10 pe-lg-20">
                            {/*begin::Icon*/}
                            <img
                              alt="image7"
                              className="w-30px me-3"
                              src="assets/media/svg/files/pdf.svg"
                            />
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="ms-1 fw-semibold">
                              {/*begin::Desc*/}
                              <a
                                href="#"
                                className="fs-6 text-hover-primary fw-bold"
                              >
                                Finance KPI App Guidelines
                              </a>
                              {/*end::Desc*/}
                              {/*begin::Number*/}
                              <div className="text-gray-400">1.9mb</div>
                              {/*end::Number*/}
                            </div>
                            {/*begin::Info*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-aligns-center pe-10 pe-lg-20">
                            {/*begin::Icon*/}
                            <img
                              alt="#"
                              className="w-30px me-3"
                              src="assets/media/svg/files/doc.svg"
                            />
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="ms-1 fw-semibold">
                              {/*begin::Desc*/}
                              <a
                                href="#"
                                className="fs-6 text-hover-primary fw-bold"
                              >
                                Client UAT Testing Results
                              </a>
                              {/*end::Desc*/}
                              {/*begin::Number*/}
                              <div className="text-gray-400">18kb</div>
                              {/*end::Number*/}
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-aligns-center">
                            {/*begin::Icon*/}
                            <img
                              alt="#"
                              className="w-30px me-3"
                              src="assets/media/svg/files/css.svg"
                            />
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="ms-1 fw-semibold">
                              {/*begin::Desc*/}
                              <a
                                href="#"
                                className="fs-6 text-hover-primary fw-bold"
                              >
                                Finance Reports
                              </a>
                              {/*end::Desc*/}
                              {/*begin::Number*/}
                              <div className="text-gray-400">20mb</div>
                              {/*end::Number*/}
                            </div>
                            {/*end::Icon*/}
                          </div>
                          {/*end::Item*/}
                        </div>
                      </div>
                      {/*end::Timeline details*/}
                    </div>
                    {/*end::Timeline content*/}
                  </div>
                  {/*end::Timeline item*/}
                  {/*begin::Timeline item*/}
                  <div className="timeline-item">
                    {/*begin::Timeline line*/}
                    <div className="timeline-line w-40px" />
                    {/*end::Timeline line*/}
                    {/*begin::Timeline icon*/}
                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                      <div className="symbol-label bg-light">
                        <i className="ki-duotone ki-abstract-26 fs-2 text-gray-500">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                    </div>
                    {/*end::Timeline icon*/}
                    {/*begin::Timeline content*/}
                    <div className="timeline-content mb-10 mt-n1">
                      {/*begin::Timeline heading*/}
                      <div className="pe-3 mb-5">
                        {/*begin::Title*/}
                        <div className="fs-5 fw-semibold mb-2">
                          Task
                          <a href="#" className="text-primary fw-bold me-1">
                            #45890
                          </a>
                          merged with
                          <a href="#" className="text-primary fw-bold me-1">
                            #45890
                          </a>
                          in “Ads Pro Admin Dashboard project:
                        </div>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="d-flex align-items-center mt-1 fs-6">
                          {/*begin::Info*/}
                          <div className="text-muted me-2 fs-7">
                            Initiated at 4:23 PM by
                          </div>
                          {/*end::Info*/}
                          {/*begin::User*/}
                          <div
                            className="symbol symbol-circle symbol-25px"
                            data-bs-toggle="tooltip"
                            data-bs-boundary="window"
                            data-bs-placement="top"
                            title="Nina Nilson"
                          >
                            <img
                              src="assets/media/avatars/300-14.jpg"
                              alt="img"
                            />
                          </div>
                          {/*end::User*/}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Timeline heading*/}
                    </div>
                    {/*end::Timeline content*/}
                  </div>
                  {/*end::Timeline item*/}
                  {/*begin::Timeline item*/}
                  <div className="timeline-item">
                    {/*begin::Timeline line*/}
                    <div className="timeline-line w-40px" />
                    {/*end::Timeline line*/}
                    {/*begin::Timeline icon*/}
                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                      <div className="symbol-label bg-light">
                        <i className="ki-duotone ki-pencil fs-2 text-gray-500">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                    </div>
                    {/*end::Timeline icon*/}
                    {/*begin::Timeline content*/}
                    <div className="timeline-content mb-10 mt-n1">
                      {/*begin::Timeline heading*/}
                      <div className="pe-3 mb-5">
                        {/*begin::Title*/}
                        <div className="fs-5 fw-semibold mb-2">
                          3 new application design concepts added:
                        </div>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="d-flex align-items-center mt-1 fs-6">
                          {/*begin::Info*/}
                          <div className="text-muted me-2 fs-7">
                            Created at 4:23 PM by
                          </div>
                          {/*end::Info*/}
                          {/*begin::User*/}
                          <div
                            className="symbol symbol-circle symbol-25px"
                            data-bs-toggle="tooltip"
                            data-bs-boundary="window"
                            data-bs-placement="top"
                            title="Marcus Dotson"
                          >
                            <img
                              src="assets/media/avatars/300-2.jpg"
                              alt="img"
                            />
                          </div>
                          {/*end::User*/}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Timeline heading*/}
                      {/*begin::Timeline details*/}
                      <div className="overflow-auto pb-5">
                        <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">
                          {/*begin::Item*/}
                          <div className="overlay me-10">
                            {/*begin::Image*/}
                            <div className="overlay-wrapper">
                              <img
                                alt="img"
                                className="rounded w-150px"
                                src="assets/media/stock/600x400/img-29.jpg"
                              />
                            </div>
                            {/*end::Image*/}
                            {/*begin::Link*/}
                            <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                              <a
                                href="#"
                                className="btn btn-sm btn-primary btn-shadow"
                              >
                                Explore
                              </a>
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="overlay me-10">
                            {/*begin::Image*/}
                            <div className="overlay-wrapper">
                              <img
                                alt="img"
                                className="rounded w-150px"
                                src="assets/media/stock/600x400/img-31.jpg"
                              />
                            </div>
                            {/*end::Image*/}
                            {/*begin::Link*/}
                            <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                              <a
                                href="#"
                                className="btn btn-sm btn-primary btn-shadow"
                              >
                                Explore
                              </a>
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="overlay">
                            {/*begin::Image*/}
                            <div className="overlay-wrapper">
                              <img
                                alt="img"
                                className="rounded w-150px"
                                src="assets/media/stock/600x400/img-40.jpg"
                              />
                            </div>
                            {/*end::Image*/}
                            {/*begin::Link*/}
                            <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                              <a
                                href="#"
                                className="btn btn-sm btn-primary btn-shadow"
                              >
                                Explore
                              </a>
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Item*/}
                        </div>
                      </div>
                      {/*end::Timeline details*/}
                    </div>
                    {/*end::Timeline content*/}
                  </div>
                  {/*end::Timeline item*/}
                  {/*begin::Timeline item*/}
                  <div className="timeline-item">
                    {/*begin::Timeline line*/}
                    <div className="timeline-line w-40px" />
                    {/*end::Timeline line*/}
                    {/*begin::Timeline icon*/}
                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                      <div className="symbol-label bg-light">
                        <i className="ki-duotone ki-sms fs-2 text-gray-500">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                    </div>
                    {/*end::Timeline icon*/}
                    {/*begin::Timeline content*/}
                    <div className="timeline-content mb-10 mt-n1">
                      {/*begin::Timeline heading*/}
                      <div className="pe-3 mb-5">
                        {/*begin::Title*/}
                        <div className="fs-5 fw-semibold mb-2">
                          New case
                          <a href="#" className="text-primary fw-bold me-1">
                            #67890
                          </a>
                          is assigned to you in Multi-platform Database Design
                          project
                        </div>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="overflow-auto pb-5">
                          {/*begin::Wrapper*/}
                          <div className="d-flex align-items-center mt-1 fs-6">
                            {/*begin::Info*/}
                            <div className="text-muted me-2 fs-7">
                              Added at 4:23 PM by
                            </div>
                            {/*end::Info*/}
                            {/*begin::User*/}
                            <a href="#" className="text-primary fw-bold me-1">
                              Alice Tan
                            </a>
                            {/*end::User*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Timeline heading*/}
                    </div>
                    {/*end::Timeline content*/}
                  </div>
                  {/*end::Timeline item*/}
                  {/*begin::Timeline item*/}
                  <div className="timeline-item">
                    {/*begin::Timeline line*/}
                    <div className="timeline-line w-40px" />
                    {/*end::Timeline line*/}
                    {/*begin::Timeline icon*/}
                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                      <div className="symbol-label bg-light">
                        <i className="ki-duotone ki-pencil fs-2 text-gray-500">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                    </div>
                    {/*end::Timeline icon*/}
                    {/*begin::Timeline content*/}
                    <div className="timeline-content mb-10 mt-n1">
                      {/*begin::Timeline heading*/}
                      <div className="pe-3 mb-5">
                        {/*begin::Title*/}
                        <div className="fs-5 fw-semibold mb-2">
                          You have received a new order:
                        </div>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="d-flex align-items-center mt-1 fs-6">
                          {/*begin::Info*/}
                          <div className="text-muted me-2 fs-7">
                            Placed at 5:05 AM by
                          </div>
                          {/*end::Info*/}
                          {/*begin::User*/}
                          <div
                            className="symbol symbol-circle symbol-25px"
                            data-bs-toggle="tooltip"
                            data-bs-boundary="window"
                            data-bs-placement="top"
                            title="Robert Rich"
                          >
                            <img
                              src="assets/media/avatars/300-4.jpg"
                              alt="img"
                            />
                          </div>
                          {/*end::User*/}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Timeline heading*/}
                      {/*begin::Timeline details*/}
                      <div className="overflow-auto pb-5">
                        {/*begin::Notice*/}
                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6">
                          {/*begin::Icon*/}
                          <i className="ki-duotone ki-devices-2 fs-2tx text-primary me-4">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                          {/*end::Icon*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                            {/*begin::Content*/}
                            <div className="mb-3 mb-md-0 fw-semibold">
                              <h4 className="text-gray-900 fw-bold">
                                Database Backup Process Completed!
                              </h4>
                              <div className="fs-6 text-gray-700 pe-7">
                                Login into Admin Dashboard to make sure the data
                                integrity is OK
                              </div>
                            </div>
                            {/*end::Content*/}
                            {/*begin::Action*/}
                            <a
                              href="#"
                              className="btn btn-primary px-6 align-self-center text-nowrap"
                            >
                              Proceed
                            </a>
                            {/*end::Action*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Notice*/}
                      </div>
                      {/*end::Timeline details*/}
                    </div>
                    {/*end::Timeline content*/}
                  </div>
                  {/*end::Timeline item*/}
                  {/*begin::Timeline item*/}
                  <div className="timeline-item">
                    {/*begin::Timeline line*/}
                    <div className="timeline-line w-40px" />
                    {/*end::Timeline line*/}
                    {/*begin::Timeline icon*/}
                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                      <div className="symbol-label bg-light">
                        <i className="ki-duotone ki-basket fs-2 text-gray-500">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                        </i>
                      </div>
                    </div>
                    {/*end::Timeline icon*/}
                    {/*begin::Timeline content*/}
                    <div className="timeline-content mt-n1">
                      {/*begin::Timeline heading*/}
                      <div className="pe-3 mb-5">
                        {/*begin::Title*/}
                        <div className="fs-5 fw-semibold mb-2">
                          New order
                          <a href="#" className="text-primary fw-bold me-1">
                            #67890
                          </a>
                          is placed for Workshow Planning &amp; Budget
                          Estimation
                        </div>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="d-flex align-items-center mt-1 fs-6">
                          {/*begin::Info*/}
                          <div className="text-muted me-2 fs-7">
                            Placed at 4:23 PM by
                          </div>
                          {/*end::Info*/}
                          {/*begin::User*/}
                          <a href="#" className="text-primary fw-bold me-1">
                            Jimmy Bold
                          </a>
                          {/*end::User*/}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Timeline heading*/}
                    </div>
                    {/*end::Timeline content*/}
                  </div>
                  {/*end::Timeline item*/}
                </div>
                {/*end::Timeline items*/}
              </div>
              {/*end::Content*/}
            </div>
            {/*end::Body*/}
            {/*begin::Footer*/}
            <div
              className="card-footer py-5 text-center"
              id="kt_activities_footer"
            >
              <a href="#" className="btn btn-bg-body text-primary">
                View All Activities
                <i className="ki-duotone ki-arrow-right fs-3 text-primary">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </a>
            </div>
            {/*end::Footer*/}
          </div>
        </div>
        {/*end::Activities drawer*/}
        {/*begin::Chat drawer*/}
        <div
          id="kt_drawer_chat"
          className="bg-body"
          data-kt-drawer="true"
          data-kt-drawer-name="chat"
          data-kt-drawer-activate="true"
          data-kt-drawer-overlay="true"
          data-kt-drawer-width="{default:'300px', 'md': '500px'}"
          data-kt-drawer-direction="end"
          data-kt-drawer-toggle="#kt_drawer_chat_toggle"
          data-kt-drawer-close="#kt_drawer_chat_close"
        >
          {/*begin::Messenger*/}
          <div
            className="card w-100 border-0 rounded-0"
            id="kt_drawer_chat_messenger"
          >
            {/*begin::Card header*/}
            <div
              className="card-header pe-5"
              id="kt_drawer_chat_messenger_header"
            >
              {/*begin::Title*/}
              <div className="card-title">
                {/*begin::User*/}
                <div className="d-flex justify-content-center flex-column me-3">
                  <a
                    href="#"
                    className="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                  >
                    Brian Cox
                  </a>
                  {/*begin::Info*/}
                  <div className="mb-0 lh-1">
                    <span className="badge badge-success badge-circle w-10px h-10px me-1" />
                    <span className="fs-7 fw-semibold text-muted">Active</span>
                  </div>
                  {/*end::Info*/}
                </div>
                {/*end::User*/}
              </div>
              {/*end::Title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Menu*/}
                <div className="me-0">
                  <button
                    className="btn btn-sm btn-icon btn-active-color-primary"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                  >
                    <i className="ki-duotone ki-dots-square fs-2">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                    </i>
                  </button>
                  {/*begin::Menu 3*/}
                  <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                    data-kt-menu="true"
                  >
                    {/*begin::Heading*/}
                    <div className="menu-item px-3">
                      <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                        Contacts
                      </div>
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a
                        href="#"
                        className="menu-link px-3"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_users_search"
                      >
                        Add Contact
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a
                        href="#"
                        className="menu-link flex-stack px-3"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_invite_friends"
                      >
                        Invite Contacts
                        <span
                          className="ms-2"
                          data-bs-toggle="tooltip"
                          title="Specify a contact email to send an invitation"
                        >
                          <i className="ki-duotone ki-information fs-7">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div
                      className="menu-item px-3"
                      data-kt-menu-trigger="hover"
                      data-kt-menu-placement="right-start"
                    >
                      <a href="#" className="menu-link px-3">
                        <span className="menu-title">Groups</span>
                        <span className="menu-arrow" />
                      </a>
                      {/*begin::Menu sub*/}
                      <div className="menu-sub menu-sub-dropdown w-175px py-4">
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-bs-toggle="tooltip"
                            title="Coming soon"
                          >
                            Create Group
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-bs-toggle="tooltip"
                            title="Coming soon"
                          >
                            Invite Members
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="#"
                            className="menu-link px-3"
                            data-bs-toggle="tooltip"
                            title="Coming soon"
                          >
                            Settings
                          </a>
                        </div>
                        {/*end::Menu item*/}
                      </div>
                      {/*end::Menu sub*/}
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3 my-1">
                      <a
                        href="#"
                        className="menu-link px-3"
                        data-bs-toggle="tooltip"
                        title="Coming soon"
                      >
                        Settings
                      </a>
                    </div>
                    {/*end::Menu item*/}
                  </div>
                  {/*end::Menu 3*/}
                </div>
                {/*end::Menu*/}
                {/*begin::Close*/}
                <div
                  className="btn btn-sm btn-icon btn-active-color-primary"
                  id="kt_drawer_chat_close"
                >
                  <i className="ki-duotone ki-cross-square fs-2">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </div>
                {/*end::Close*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body" id="kt_drawer_chat_messenger_body">
              {/*begin::Messages*/}
              <div
                className="scroll-y me-n5 pe-5"
                data-kt-element="messages"
                data-kt-scroll="true"
                data-kt-scroll-activate="true"
                data-kt-scroll-height="auto"
                data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
                data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body"
                data-kt-scroll-offset="0px"
              >
                {/*begin::Message(in)*/}
                <div className="d-flex justify-content-start mb-10">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column align-items-start">
                    {/*begin::User*/}
                    <div className="d-flex align-items-center mb-2">
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-3">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                        >
                          Brian Cox
                        </a>
                        <span className="text-muted fs-7 mb-1">2 mins</span>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Text*/}
                    <div
                      className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start"
                      data-kt-element="message-text"
                    >
                      How likely are you to recommend our company to your
                      friends and family ?
                    </div>
                    {/*end::Text*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Message(in)*/}
                {/*begin::Message(out)*/}
                <div className="d-flex justify-content-end mb-10">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column align-items-end">
                    {/*begin::User*/}
                    <div className="d-flex align-items-center mb-2">
                      {/*begin::Details*/}
                      <div className="me-3">
                        <span className="text-muted fs-7 mb-1">5 mins</span>
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1"
                        >
                          You
                        </a>
                      </div>
                      {/*end::Details*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                      </div>
                      {/*end::Avatar*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Text*/}
                    <div
                      className="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end"
                      data-kt-element="message-text"
                    >
                      Hey there, we’re just writing to let you know that you’ve
                      been subscribed to a repository on GitHub.
                    </div>
                    {/*end::Text*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Message(out)*/}
                {/*begin::Message(in)*/}
                <div className="d-flex justify-content-start mb-10">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column align-items-start">
                    {/*begin::User*/}
                    <div className="d-flex align-items-center mb-2">
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-3">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                        >
                          Brian Cox
                        </a>
                        <span className="text-muted fs-7 mb-1">1 Hour</span>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Text*/}
                    <div
                      className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start"
                      data-kt-element="message-text"
                    >
                      Ok, Understood!
                    </div>
                    {/*end::Text*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Message(in)*/}
                {/*begin::Message(out)*/}
                <div className="d-flex justify-content-end mb-10">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column align-items-end">
                    {/*begin::User*/}
                    <div className="d-flex align-items-center mb-2">
                      {/*begin::Details*/}
                      <div className="me-3">
                        <span className="text-muted fs-7 mb-1">2 Hours</span>
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1"
                        >
                          You
                        </a>
                      </div>
                      {/*end::Details*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                      </div>
                      {/*end::Avatar*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Text*/}
                    <div
                      className="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end"
                      data-kt-element="message-text"
                    >
                      You’ll receive notifications for all issues, pull
                      requests!
                    </div>
                    {/*end::Text*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Message(out)*/}
                {/*begin::Message(in)*/}
                <div className="d-flex justify-content-start mb-10">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column align-items-start">
                    {/*begin::User*/}
                    <div className="d-flex align-items-center mb-2">
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-3">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                        >
                          Brian Cox
                        </a>
                        <span className="text-muted fs-7 mb-1">3 Hours</span>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Text*/}
                    <div
                      className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start"
                      data-kt-element="message-text"
                    >
                      You can unwatch this repository immediately by clicking
                      here:
                      <a href="https://keenthemes.com">Keenthemes.com</a>
                    </div>
                    {/*end::Text*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Message(in)*/}
                {/*begin::Message(out)*/}
                <div className="d-flex justify-content-end mb-10">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column align-items-end">
                    {/*begin::User*/}
                    <div className="d-flex align-items-center mb-2">
                      {/*begin::Details*/}
                      <div className="me-3">
                        <span className="text-muted fs-7 mb-1">4 Hours</span>
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1"
                        >
                          You
                        </a>
                      </div>
                      {/*end::Details*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                      </div>
                      {/*end::Avatar*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Text*/}
                    <div
                      className="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end"
                      data-kt-element="message-text"
                    >
                      Most purchased Business courses during this sale!
                    </div>
                    {/*end::Text*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Message(out)*/}
                {/*begin::Message(in)*/}
                <div className="d-flex justify-content-start mb-10">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column align-items-start">
                    {/*begin::User*/}
                    <div className="d-flex align-items-center mb-2">
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-3">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                        >
                          Brian Cox
                        </a>
                        <span className="text-muted fs-7 mb-1">5 Hours</span>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Text*/}
                    <div
                      className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start"
                      data-kt-element="message-text"
                    >
                      Company BBQ to celebrate the last quater achievements and
                      goals. Food and drinks provided
                    </div>
                    {/*end::Text*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Message(in)*/}
                {/*begin::Message(template for out)*/}
                <div
                  className="d-flex justify-content-end mb-10 d-none"
                  data-kt-element="template-out"
                ></div>
                {/*end::Message(template for out)*/}
                {/*begin::Message(template for in)*/}

                {/*end::Message(template for in)*/}
              </div>
              {/*end::Messages*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Messenger*/}
        </div>
        {/*end::Chat drawer*/}
        {/*begin::Chat drawer*/}
        <div
          id="kt_shopping_cart"
          className="bg-body"
          data-kt-drawer="true"
          data-kt-drawer-name="cart"
          data-kt-drawer-activate="true"
          data-kt-drawer-overlay="true"
          data-kt-drawer-width="{default:'300px', 'md': '500px'}"
          data-kt-drawer-direction="end"
          data-kt-drawer-toggle="#kt_drawer_shopping_cart_toggle"
          data-kt-drawer-close="#kt_drawer_shopping_cart_close"
        >
          {/*begin::Messenger*/}
        </div>
        <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
          <i className="ki-duotone ki-arrow-up">
            <span className="path1" />
            <span className="path2" />
          </i>
        </div>
        {logoutModalOpen && (
          <Modal
            style={{ display: 'block' }}
            isOpen={logoutModalOpen}
            onClose={closeUpdateModal}
          >
            <div style={{ alignSelf: 'center' }}>
              {/* <h1 style={{alignItems:'center'}}>Are You sure</h1> */}
              <h2> Are You sure</h2>
              <h4>You want to Logout?</h4>
              <button
                style={{ marginTop: '30px' }}
                type="button"
                className="btn btn-secondary"
                onClick={() => setLogoutModalOpen(false)}
                // disabled={isButtonDisabled}
              >
                No
              </button>
              <button
                style={{ marginTop: '30px', marginLeft: '20px' }}
                type="button"
                className="btn btn-success"
                onClick={() => {
                  // DeleteProduct()
                  // alert('frontend');
                  // leadSubmit();
                  logouthandler();
                  setLogoutModalOpen(false);
                }}
                // }}
                // disabled={isButtonDisabled}
              >
                Yes
              </button>
            </div>
          </Modal>
        )}

        {signoffModalOpen && (
          <Modal
            style={{ display: 'block' }}
            isOpen={signoffModalOpen}
            onClose={closeSignoffModal}
          >
            <div style={{ alignSelf: 'center' }}>
              {/* <h1 style={{alignItems:'center'}}>Are You sure</h1> */}
              <h2> Are You sure</h2>
              <h4>You want to Sign-Off?</h4>
              <button
                style={{ marginTop: '30px' }}
                type="button"
                className="btn btn-secondary"
                onClick={() => setSignoffModalOpen(false)}
                // disabled={isButtonDisabled}
              >
                No
              </button>
              <button
                style={{ marginTop: '30px', marginLeft: '20px' }}
                type="button"
                className="btn btn-success"
                onClick={() => {
                  // DeleteProduct()
                  // alert('frontend');
                  // leadSubmit();
                  HandleSignOff();
                  setSignoffModalOpen(false);
                }}
                // }}
                // disabled={isButtonDisabled}
              >
                Yes
              </button>
            </div>
          </Modal>
        )}
      </div>
    );
  } else {
    return (
      <>
        <Navigate to="/"></Navigate>
      </>
    );
  }
};
export default Constant;
