import React, { useState } from 'react';
import { css } from '@emotion/react';
import BounceLoader from 'react-spinners/BounceLoader';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  position: absolute;
  top: 60% !important;
  left: 55% !important;
  z-index: 999999;
  text-align: -webkit-center;
`;
function Loader(props) {
  let color = 'rgb(3, 201, 215)';
  return (
    <div className={props.loading ? 'parentDisable' : ''} width="100%">
      <div className="overlay-boxss ">
        <BounceLoader
          color={color}
          loading={props.loading}
          css={override}
          size={50}
        />
      </div>
    </div>
  );
}
export default Loader;
