import logo from './logo.svg';
import './App.css';
import Constant from './Constant/UserLayout';
import { Route, BrowserRouter, Routes, useLocation ,createBrowserRouter} from 'react-router-dom';
import Dashboard from './Screens/Dashboard/Dashboard';
import ManageSales from './Screens/ManageSales/ManageSales';
// import Header from './Constant/Header2';
import UserLogin from './Auth/UserLogin';
import ManageClients from './Screens/Manageclients/ManageClients';
import Test from './Constant/Test';
import AddClients from './Screens/Manageclients/AddClients';
import ManageLeads from './Screens/ManageLeads/ManageLeads';
import AddLead from './Screens/ManageLeads/AddLead';
import ManageProducts from './Screens/ManageProducts/ManageProducts';
import Userprofile from './Screens/UserProfile/Userprofile';
import SalesReport from './Screens/Reporting/SalesReport';
import LeadReport from './Screens/LeadReport/LeadReport';
import TeamClients from './Screens/Manage Teams/TeamClients';
import TeamLeads from './Screens/Manage Teams/TeamLeads';
import PageNotfound from './Screens/404/PageNotfound';
import GuestGuard from './Constant/GuestGuard';
import UpdateLead from './Screens/ManageLeads/UpdateLead';
import ModalComponent from './Screens/ManageLeads/Modal';
import TeamAttendance from './Screens/Manage Teams/TeamAttendance';
import DailyTaskReport from './Screens/Reporting/AddDailyStatusReport';
import UpdateClient from './Screens/Manageclients/UpdateClient';
import ListDailyStatusReport from './Screens/Reporting/Listdailystatusreport';
import AddDailyStatusReport from './Screens/Reporting/AddDailyStatusReport';
import fetchData from './Constant/CommonApi';
import TeamStatusReport from './Screens/Manage Teams/TeamStatusReport';
import TeamMembers from './Screens/Manage Teams/TeamMembers';
import ClientInfo from './Screens/Manageclients/ClientInfo';
import ViewLead from './Screens/Reporting/ViewLead';
import ForgotPassword from './Auth/ForgotPassword';
import OtpVerify from './Auth/OtpVerify';
import ResetPassword from './Auth/ResetPassword';
import LeadInfo from './Screens/ManageLeads/LeadInfo';
import TeamStatusReportDetail from './Screens/Manage Teams/TeamReportDetails';
import TeamAttendanceDetails from './Screens/Manage Teams/TeamAttendanceDetails';
import Header from './Constant/Header';
import RequestsStatus from './Screens/RequestStatus/RequestsStatus';
import Intent from './Screens/ManageLeads/Intent';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <GuestGuard>
                <UserLogin />
              </GuestGuard>
            }
          />
          <Route path="/constant" element={<Constant />}></Route>

          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/managesales" element={<ManageSales />}></Route>
          <Route path="/manageclients" element={<ManageClients />}></Route>
          <Route path="/test" element={<Test />}></Route>
          <Route path="/header" element={<Header />}></Route>
          <Route path="/addclient" element={<AddClients />}></Route>
          <Route path="/manageleads" element={<ManageLeads />}></Route>
          <Route path="/addlead" element={<AddLead />}></Route>
          <Route path="/manageproducts" element={<ManageProducts />}></Route>
          <Route path="/userprofile" element={<Userprofile />}></Route>
          <Route path="/salesreports" element={<SalesReport />}></Route>
          <Route path="/leadreport" element={<LeadReport />}></Route>
          <Route path="/teamleads" element={<TeamLeads />}></Route>
          <Route path="/teamattendance" element={<TeamAttendance />}></Route>
          <Route
            path="/teamstatusreports"
            element={<TeamStatusReport />}
          ></Route>
          <Route path="/teamclients" element={<TeamClients />}></Route>
          <Route path="/updatelead" element={<UpdateLead />}></Route>
          <Route path="/updatelead/:leadId" element={<UpdateLead />}></Route>
          <Route path="/leadinfo" element={<LeadInfo />}></Route>
          <Route path="/genrateIntent/:id/:qutationId" element={<Intent />}></Route>
          <Route path="/editIntent/:id/:qutationId" element={<Intent />}></Route>
          <Route path="/leadinfo/:leadId" element={<LeadInfo />}></Route>
          <Route path="/modal" element={<ModalComponent />}></Route>
          <Route path="/teamattendance" element={<TeamAttendance />}></Route>
          <Route path="/updateclient" element={<UpdateClient />}></Route>
          <Route path="/salesreport" element={<SalesReport />}></Route>
          <Route path="/fetchdata" element={<fetchData />}></Route>
          <Route path="/team_members" element={<TeamMembers />}></Route>

          <Route path="/clientinfo" element={<ClientInfo />}></Route>
          <Route path="/clientinfo/:clientId" element={<ClientInfo />}></Route>

          <Route
            path="/updateclient/:clientId"
            element={<UpdateClient />}
          ></Route>

          <Route
            path="/daily_task_report"
            element={<ListDailyStatusReport />}
          ></Route>
          <Route
            path="/add_daily_task_report"
            element={<AddDailyStatusReport />}
          ></Route>
          <Route path="/get_report" element={<TeamStatusReportDetail />} />
          <Route
            path="/get_report/:getstaffId"
            element={<TeamStatusReportDetail />}
          />
          <Route
            path="/add_daily_task_report"
            element={<AddDailyStatusReport />}
          ></Route>
          <Route
            path="/get_attendance_report"
            element={<TeamAttendanceDetails />}
          />
          <Route
            path="/get_attendance_report/:getstaffId"
            element={<TeamAttendanceDetails />}
          />

          <Route path="/*" element={<PageNotfound />}></Route>
          <Route path="/viewLead" element={<ViewLead />}></Route>
          <Route path="/viewLead/:leadId" element={<ViewLead />}></Route>
          <Route path="/forgot_password" element={<ForgotPassword />}></Route>
          <Route path="/otp_verify" element={<OtpVerify />}></Route>
          <Route path="/reset_password" element={<ResetPassword />}></Route>
          <Route path="/requeststatus" element={<RequestsStatus />}></Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
