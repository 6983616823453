import React, { useEffect, useRef, useState } from 'react';
import Constant from '../../Constant/UserLayout';
// import Header from '../../Constant/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Url from '../../Elements/Api/Api';

import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { default as ReactSelect } from 'react-select';
import AsyncSelect from 'react-select/async';
import Slider from 'react-slick';
import { FaCircle, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Modal from './Modal';
import { ClipLoader } from 'react-spinners';
import Header from '../../Constant/Header';


const LeadInfo = ({ text }) => {
  const maxLength = 50;
  const { leadId } = useParams();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [finalCommunication, setFinalCommunication] = useState({});
  const [oldProductId, setOldProductId] = useState(0);
  const [newProduct, setNewProduct] = useState([]);
  const [formData, setFormData] = useState({});
  const [activeOption, setActiveOption] = useState('Lead Details');
  const [type] = useState(['Existing', 'New']);
  const [dummyData, setDummyData] = useState([]);
  const [dummyData2, setDummyData2] = useState([]);
  const [stage, setStage] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedStage, setSelectedStage] = useState('');
  const [industry, setIndustry] = useState([]);
  const token = useSelector((state) => state.token);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedStageId, setSelectedStageId] = useState('');
  const [leadDate, setLeadDate] = useState('');
  const [ProductsInfo, setProductsInfo] = useState([]);

  const [searchClientByName, setSearchClientByName] = useState('');
  const [searchclient, setSearchClient] = useState('');
  const [searchedClient, setSearchedClient] = useState([]);
  const [error, setError] = useState('');
  const [id, setId] = useState('');
  const [clientData, setClientData] = useState([]);
  const [inputValue, setInputValue] = useState('a');
  const [inputValue2, setInputValue2] = useState('a');

  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchInputValue2, setSearchInputValue2] = useState('');
  const [communicationStates, setCommunicationStates] = useState([]);

  const [clientOptions, setClientOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [labelText, setLabelText] = useState('New');
  const [businessType, setBusinessType] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [leadtype, setLeadtype] = useState([]);
  const [check, setcheck] = useState('');
  const [communicationlead, setCommunicationlead] = useState([]);

  const [selectedMeasurements, setSelectedMeasurements] = useState([
    { id: '', qty: '', measurement_type: '', price: '', netValue: 0 }
  ]);
  const [leadData, setUpdateleadData] = useState('');
  const [industryId, setIndustryId] = useState('');
  const [measurementId, setMeasurementId] = useState('');
  const [productlisting, setProductListing] = useState([]);
  const [deleteRow, setDeleteRow] = useState('');
  const [addLead, setAddLead] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [productId, setProductId] = useState('');
  const [response, setResponse] = useState([]);
  const [isChecked, setIsChecked] = useState(0);
  const [leadCommunication, setLeadCommunation] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [communication, setCommunication] = useState('');
  const [isNestedModalOpen, setIsNestedModalOpen] = useState(false);
  const [isNestedUpdateModalOpen, setIsNestedUpdateModalOpen] = useState(false);

  const closeNestedModal = () => setIsNestedModalOpen(false);
  const closeNestedUpdateModal = () => setIsNestedUpdateModalOpen(false);

  const openModal = () => setIsModalOpen(true);
  const openNestedModal = () => setIsNestedModalOpen(true);

  const [communicationData, setCommunicationData] = useState([
    {
      communication: '',
      communication_date: '',
      isReminder: ''
    }
  ]);
  const [clientSaveData, setClientSaveData] = useState({
    organization: '',
    gst_no: '',
    address: '',
    industry_type: ''
  });
  const [inputFields, setInputFields] = useState([
    { value1: '', value2: '', value3: '', value4: '' }
  ]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [productsData, setproductsData] = useState([]);

  const [formCount, setFormCount] = useState(1);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);

  const [measurementType, setMeasurementType] = useState('');
  const [basePrice, setBasePrice] = useState('');
  const [productCount, setProductCount] = useState('');
  const [productSlideData, setProductSlideData] = useState({
    qty: '',
    kg: '',
    totalQuantity: '',
    price: '',
    priceBeforeDiscount: '',
    discount: '0',
    priceAfterDiscount: '',
    gst: '18',
    netValue: ''
  });
  const [totalQuanity, setTotalQuanity] = useState('');
  const [priceBeforeDiscount, setPriceBeforeDiscount] = useState('');
  const [priceAfterDiscount, setPriceAfterDiscount] = useState('');
  const [netValue, setNetValue] = useState('');
  const [detailStage, setDetailStage] = useState('');
  const [purposeType, setPurposeType] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const closeModal = () => setIsModalOpen(false);
  const closeUpdateModal = () => setIsUpdateModalOpen(false);

  const [productIndex, setProductIndex] = useState('');
  const [productIndexForDelete, setProductIndexForDelete] = useState('');
  const [leadIdDelete, setLeadId] = useState('');
  const [loading, setLoading] = useState(false);

  //handler for input change
  const handleProductInputChange = (e) => {
    const { name, value } = e.target;
    if (/^\d*$/.test(value)) {
      // var keyToRemove = e.target.name;
      setProductSlideData({
        ...productSlideData,
        [name]: value
      });
    }
    setError({ ...error, [e.target.name]: '' });
  };
  const handleBasePriceInputChange = (e) => {
    setBasePrice(e.target.value);
  };
  useEffect(() => {
    if (measurementType) {
      handleMultiplications();
    }
    handlePrice();
    if (priceBeforeDiscount && productSlideData.discount) {
      handlePriceDiscount();
    }
    if (priceAfterDiscount) {
      handleNetAmount();
    }
  }, [
    measurementType,
    productSlideData,
    totalQuanity,
    basePrice,
    priceBeforeDiscount,
    productSlideData,
    priceAfterDiscount
  ]);

  useEffect(() => {
    if (
      selectedProduct &&
      selectedSubcategory &&
      selectedCategory &&
      measurementType
    ) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      //https://apirest.symang.in/api/base-price?category_id=5&sub_category_id=6&product_id=1
      axios
        .get(
          Url.BASEURL +
          'base-price-lead?' +
          'category_id=' +
          selectedCategory.value +
          '&sub_category_id=' +
          selectedSubcategory.value +
          // (id !== '' ? '&client_id=' + id.value : '') +
          '&product_id=' +
          selectedProduct.value +
          '&measurement_type=' +
          measurementType.value,
          config
        )
        .then((value) => {
          if (value.data.response.base_price !== null) {
            setBasePrice(value.data.response.base_price.base_price);
          } else {
            setBasePrice('0');
          }
          // }
        })
        .catch((err) => console.log(err));
    }
  }, [selectedCategory, selectedSubcategory, selectedProduct, measurementType]);
  const handleMultiplications = () => {
    // measurementType.label == 'Kilogram '
    if (measurementType.label == 'Kilogram ') {
      // alert('hello')
      let totalQty = productSlideData.qty * productSlideData.kg;
      setTotalQuanity(totalQty);
    } else if (measurementType.label == 'Meter ') {
      setTotalQuanity('');
      let totalQty = productSlideData.qty * 6;
      setTotalQuanity(totalQty);
    } else if (measurementType.label == 'Feet ') {
      setTotalQuanity('');
      let totalQty = productSlideData.qty * 20;
      setTotalQuanity(totalQty);
    }
  };
  const handlePrice = () => {
    if (totalQuanity && basePrice) {
      let totalPriceBeforeDiscount = totalQuanity * basePrice;
      setPriceBeforeDiscount(totalPriceBeforeDiscount);
    }
  };

  const handlePriceDiscount = () => {
    if (priceBeforeDiscount) {
      let discountPrice =
        (priceBeforeDiscount * productSlideData.discount) / 100;
      let newAmount = priceBeforeDiscount - discountPrice;
      setPriceAfterDiscount(newAmount);
    }
  };

  const handleNetAmount = () => {
    if (priceAfterDiscount) {
      let gstAmount = (priceAfterDiscount * productSlideData.gst) / 100;
      let netAmount = priceAfterDiscount + gstAmount;
      setNetValue(netAmount);
    }
  };

  const handleInputChange1 = (index, fieldName, value) => {
    const newInputFields = [...inputFields];
    newInputFields[index][fieldName] = value;
    setInputFields(newInputFields);
  };
  const handleClientDataInputChange = (e) => {
    const { name, value } = e.target;
    setClientSaveData({
      ...clientSaveData,
      [name]: value
    });
  };
  // const handleAddInputFields = () => {
  //   // Check the total number of fields added
  //   if (inputFields.length < 3) {
  //     setInputFields([
  //       ...inputFields,
  //       { value1: '', value2: '', value3: '', value4: '' }
  //     ]);
  //   }
  // };

  // const handleRemoveInputFields = (index) => {
  //   const updatedData = [...productData];
  //   updatedData.splice(index, 1);
  //   setProductData(updatedData);
  // };

  const transformIndustryDataToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, industry_type }) => ({
      value: id.toString(),
      label: `${industry_type}`
    }));
    return [selectOption, ...dataOptions];
  };
  const IndustryOptions = transformIndustryDataToOptions(industry);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked ? 1 : 0);
  };
  useEffect(() => {
    // setFinalCommunication(communicateData);
    const params = {
      communication: communicationData.communication ?? '',
      communication_date: communicationData.communication_date ?? '',
      is_reminder: isChecked,
      lead_id: leadId
    };
    setFinalCommunication(params);
    const initialStates = communicationlead.map(() => false);
    setCommunicationStates(initialStates);
  }, [communicationlead]);
  // function for toggle readmore/readless
  const toggleReadMore = (index) => {
    const newStates = [...communicationStates];
    newStates[index] = !newStates[index];
    setCommunicationStates(newStates);
  };
  // function for change label

  useEffect(() => {
    // Log selectedOption here, it will have the updated value
    if (selectedOption) {
      setcheck(selectedOption);
    }
  }, [selectedOption]);

  // Function of add clientlisting
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
        'searchstaffclients?' +
        'search=' +
        searchInputValue +
        '&page=',
        config
      )
      .then((value) => {
        setDummyData(value.data.response);
        setSearchedClient(value.data.response.data);
        setClientOptions(
          value.data.response.map((e) => ({
            id: e.id,
            value: e.id, // You can use e.id here if you prefer
            label: e.client
          }))
        );
      })
      .catch((err) => console.log(err));
  }, [searchInputValue]);

  // function for product listing
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'productdropdown?' + 'search=' + '&page=', config)
      .then((value) => setDummyData2(value.data.response.data))
      .catch((err) => console.log(err));
  }, []);

  // state to add new lead
  const [bookRoomData, setBookRoomData] = useState([{}]);

  // state to add product

  // const [productData, setProductData] = useState([
  //   {
  //     // product_id: '',
  //     product_name: '',
  //     product_price: '',
  //     product_qty: '',
  //     price_qty: ''
  //   }
  // ]);

  const handleIndustryChange = (e) => {
    setSelectedIndustry(e.target.value);
  };

  // const handlestageChange = (e) => {
  //   setSelectedStage(e.target.value);
  // };

  const handleChange = (index, event, selectedOption) => {
    if (selectedOption) {
      // Parse the selected option's client field to extract the desired text
      const clientText = selectedOption.client.split(' - ')[0];
      setInputValue(clientText);
      fetchData(selectedOption.id); // You can call your fetchData function here
    } else {
      setInputValue(''); // Clear the input field if nothing is selected
    }

    if (event && event.target) {
      const { name, value } = event.target;
      var keyToRemove = value;
      const newState = bookRoomData.map((obj) => {
        if (obj) {
          return { ...obj, [name]: value };
        }
      });

      setBookRoomData(newState);
    }
  };

  const leadSubmit = (e) => {
    // setLoading(true)
    const currentSlide = {
      product_id: selectedProduct.value,
      // id: selectedProduct.value,
      category_id: selectedCategory.value,
      sub_category_id: selectedSubcategory.value,
      measurement: measurementType.value,
      total_quantity: totalQuanity,
      product_price: basePrice !== null ? basePrice : productSlideData.price,
      product_qty: productSlideData.qty,
      discount: productSlideData.discount,
      product_gst: productSlideData.gst,
      product_kg:
        measurementType.label === 'Kilogram '
          ? productSlideData.kg
          : measurementType.label === 'Meter '
            ? 20
            : measurementType.label === 'Feet '
              ? 6
              : '',
      price_before_discount: priceBeforeDiscount,
      price_after_discount: productSlideData.discount
        ? priceAfterDiscount
        : priceBeforeDiscount,
      total_price: netValue
    };

    // Combine the current slide with previously filled slides
    // const allSlides = [...slides, currentSlide];
    const allSlides =
      productsData.length === 0 ? [] : [currentSlide, ...productsData];
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      title: leadData.title,
      description: leadData.description,
      // lead_value: calculateTotalNetValue,
      business_type: leadData.business_type,
      expt_close_date: leadData.expt_close_date,
      // stage: selectedStage,
      stage: detailStage,
      lead_organisation: leadData.lead_organisation,
      // lead_contact_no: leadData.lead_contact_no,
      // products: selectedMeasurements,
      // products: productsData,
      products: allSlides,
      communication: finalCommunication
    };

    axios
      .put(Url.BASEURL + 'updatelead/' + leadId, params, config)
      .then((value) => {
        if (value && value.data.status === 200) {
          // toast('Client update Successfully');
          setAddLead(value.data.response);
          // navigate('/manageleads');
        } else {
          setError(value.data.errors);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'productdropdown?' + 'search=' + searchInputValue2,
        config
      )
      .then((value) => {
        if (value && value.data.status === 200) {
          setProductOptions(
            value.data.response.map((e) => ({
              id: e.id,
              value: e.id, // You can use e.id here if you prefer
              label: e.item_name
            }))
          );
        }
      })
      .catch((err) => console.log(err));
  }, [searchInputValue, token, selectedOption]);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(
        Url.BASEURL + 'searchstaffclients?' + 'search=' + searchInputValue,
        config
      )
      .then((value) => {
        if (value && value.data.status === 200) {
        }
      })
      .catch((err) => console.log(err));
  }, [searchInputValue, token]);

  // function to add net values

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'leadstages', config)
      .then((value) => setStage(value.data.response))
      .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   };
  //   axios
  //     .get(Url.BASEURL + 'leadtype', config)
  //     .then((value) => setLeadtype(value.data.response))
  //     .catch((err) => console.log(err));
  // }, []);

  // industry listing

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'allindustry', config)
      .then((value) => setIndustry(value.data.response))
      .catch((err) => console.log(err));
  }, []);
  const BusinessTypeChangeHandler = (event) => {
    setBusinessType(event.target.value);
  };

  const fetchData = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'fetchclientbyid/' + id, config)
      .then(
        (value) =>
          setBookRoomData(value.data.response) &
          setInputValue(value.data.response[0].owner_name) &
          setSelectedIndustry(value.data.response[0].industry_id) &
          setOwnerName(value.data.response[0].owner_name.split('-')[0].trim())
      )
      .catch((err) => console.log(err));
  };

  useEffect((e) => {
    if (searchclient) {
    }
  }, []);

  const handleInputChange2 = (newValue) => {
    setSearchInputValue2(newValue);
  };

  useEffect(() => {
    getData();
  }, [deleteRow]);

  const getData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: leadId
    };
    axios
      .get(Url.BASEURL + 'editlead/' + leadId, config)
      .then((value) => {
        if (value && value.data.status === 200) {
          setUpdateleadData(value.data.response.lead);
          setPurposeType(value.data.response.lead.purpose_of_visit);
          setDetailStage(value.data.response.lead.stage);
          setproductsData(value.data.response.lead.leadproducts);
          setProducts(value.data.response.product_list);
          setLeadtype(value.data.response.measurement_type);
          setProductCount(value.data.response.lead.leadproducts_count);
          //for category and sub category
          setCategoryList(value.data.response.category);
          setSubCategoryList(value.data.response.sub_category);

          setClientData(value.data.response.lead.client_data.clientInfo);
          setIndustryId(value.data.response.client.industry_id);
          setMeasurementId(value.data.response.leadproducts.measurement);
          setSelectedStage(value.data.response.lead.stage);
          setSelectedStageId(value.data.response.lead.stage);
          setTitle(value.data.response.title);
          setLeadCommunation(value.data.response.leadcommunication);
          const ldata = value.data.response.leadproducts;
          if (ldata.length > 0) {
            setOldProductId(ldata.length - 1);
          }
        } else {
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (leadData) {
      setResponse(leadData);
    }
  }, [leadData, deleteRow]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdateleadData({ ...leadData, [name]: value });
    // setName(value);
    // setEmail(value);
  };
  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
  };

  const communicationChangeHandler = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      communication: communicationData.communication,
      communication_date: communicationData.communication_date,
      is_reminder: isChecked
    };
    axios
      .post(Url.BASEURL + 'leadcommunication/' + leadId, params, config)
      .then((value) => {
        setIsChecked(0);
        setCommunicationData({
          communication: '',
          communication_date: '',
          isReminder: 0
        });
        params['lead_id'] = leadId;
        setLeadCommunation([...leadCommunication, params]);
      })
      .catch((err) => console.log(err));
  };
  const handleInputChange3 = (e) => {
    const { name, value } = e.target;
    setCommunicationData({
      ...communicationData,
      [name]: value
    });
    setError({ ...error, [e.target.name]: '' });
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'communicationcall/' + leadId, config)
      .then((value) => {
        setCommunicationlead(value.data.response);
        setCommunication(
          isExpanded
            ? value.data.response.communication
            : value.data.response.communication.slice(0, maxLength)
        );
      })
      .catch((err) => console.log(err));
  }, [communicationData]);

  const transformStageToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, stage }) => ({
      value: id.toString(),
      label: `${stage}`
    }));
    return [selectOption, ...dataOptions];
  };
  const stageDataOption = transformStageToOptions(stage);

  const handleSelectChange = (selectedOption) => {
    // Assuming selectedOption is an object with a 'value' property
    const selectedStageId = selectedOption ? selectedOption.value : null;
    setDetailStage(selectedStageId);
    // setId(selectedStageId);
  };

  // functions for
  const sliderRef = useRef(null); // Initialize useRef with null
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => {
      setCurrentSlide(current);
    }
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
      // setCurrentSlideIndex((prevIndex) => prevIndex + 1);
    }
  };
  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
      // setCurrentSlideIndex((prevIndex) => prevIndex - 1);
    }
  };

  const productOptionss = products.map(
    ({ id, product_name, category_related_product }) => ({
      value: id.toString(),
      label: `${product_name}`,
      categoryRelatedProduct: category_related_product
    })
  );
  const categoryOptions = selectedProduct
    ? selectedProduct.categoryRelatedProduct.map((id, name, sub_category) => ({
      value: id.id.toString(),
      label: id.name,
      sub_category: id.sub_category
    }))
    : [];

  const subcategoryOptions = selectedCategory
    ? selectedCategory.sub_category.map((id, name) => ({
      value: id.id.toString(),
      label: id.name
    }))
    : [];

  const transformMeasurementToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name }) => ({
      value: id.toString(),
      label: `${name} `
    }));
    return [selectOption, ...dataOptions];
  };
  const measurementDataOption = transformMeasurementToOptions(leadtype);

  const transformCategoryToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name }) => ({
      value: id.toString(),
      label: `${name} `
    }));
    return [selectOption, ...dataOptions];
  };
  const categoryDataOption = transformCategoryToOptions(categoryList);
  const businessTypeData = [
    { label: 'Existing', value: 'Existing' },
    { label: 'New', value: 'New' }
  ];

  const transformSubCategoryToOptions = (data) => {
    const selectOption = { value: '', label: 'Select' };
    const dataOptions = data.map(({ id, name }) => ({
      value: id.toString(),
      label: `${name} `
    }));
    return [selectOption, ...dataOptions];
  };
  const subCategoryDataOption = transformSubCategoryToOptions(subCategoryList);

  // useEffect(() => {
  //   // Set initial selected product, category, and subcategory based on preloaded data
  //   const initialSelectedProduct = productOptionss.find((e) => e.value === data.product_id);
  //   setSelectedProduct(initialSelectedProduct);

  //   if (  ) {
  //     const initialSelectedCategory = categoryOptions.find((e) => e.value === data.category_id);
  //     setSelectedCategory(initialSelectedCategory);
  //   }

  //   if (initialSelectedCategory) {
  //     const initialSelectedSubcategory = subcategoryOptions.find((e) => e.value === data.subcategory_id);
  //     setSelectedSubcategory(initialSelectedSubcategory);
  //   }
  // }, [data, productOptionss, categoryOptions, subcategoryOptions]);

  const [slides, setSlides] = useState([{}]);

  const [productError, setProductError] = useState('');

  const addSlider = () => {
    if (
      !selectedProduct ||
      !selectedCategory ||
      // !selectedSubcategory ||
      !productSlideData.qty ||
      !measurementType ||
      !basePrice ||
      // !productSlideData.price ||
      !productSlideData.discount ||
      !productSlideData.gst
    ) {
      setProductError('All Fields are mandatory');
      // const newSlide = {};
      // setFormCount((prevFormCount) => prevFormCount + 1);
      // setproductsData((prevData) => [...prevData, newSlide]);
    } else {
      const newSlide = {
        product_id: selectedProduct.value, //product Id
        product_name: selectedProduct.label,
        category_id: selectedCategory.value, // categoryID
        category_name: selectedCategory.label,
        sub_category_id: selectedSubcategory.value, // subcategoryId
        sub_category_name: selectedSubcategory.label, // subcategoryId
        measurement: measurementType.value, // measurement id
        total_quantity: totalQuanity,
        product_price: basePrice !== null ? basePrice : productSlideData.price, // price
        product_qty: productSlideData.qty, // product qty
        discount: productSlideData.discount, // discount in %
        product_gst: productSlideData.gst, // gst
        product_kg:
          measurementType.label === 'Kilogram '
            ? productSlideData.kg
            : measurementType.label === 'Meter '
              ? 20
              : measurementType.label === 'Feet '
                ? 6
                : '',
        // measurement value (kd,meter,feet)
        price_before_discount: priceBeforeDiscount,
        price_after_discount: productSlideData.discount
          ? priceAfterDiscount
          : priceBeforeDiscount,
        total_price: netValue
      };
      setproductsData((prevData) => [...prevData, newSlide]);
      // setSlides((prevSlides) => [newSlide, ...prevSlides]);

      setMeasurementType('');
      setSelectedProduct('');
      setSelectedCategory('');
      setSelectedSubcategory('');
      setBasePrice('');
      setTotalQuanity('');
      setPriceBeforeDiscount('');
      setPriceAfterDiscount('');
      setNetValue('');
      setProductSlideData({
        qty: '',
        kg: '',
        totalQuantity: '',
        price: '',
        priceBeforeDiscount: '',
        discount: '0',
        priceAfterDiscount: '',
        gst: '18',
        netValue: '',
        priceBeforeDiscount: '',
        priceAfterDiscount: '',
        netValue: ''
      });
      // setCurrentSlideIndex(0);
      // if (sliderRef.current) {
      //   sliderRef.current.slickGoTo(0);
      // }
    }
  };

  const handleInputChangeslide = (index, fieldName, value) => {
    setproductsData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [fieldName]: value };
      return newData;
    });
  };

  const deleteForm = (index) => {
    setFormCount(formCount - 1);
    setproductsData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const DeleteProduct = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const params = {
      id: productId
    };
    /** */
    axios
      .delete(
        Url.BASEURL + 'deleteleadproduct/' + productIndex + '/' + leadIdDelete,
        config
      )
      .then((value) => {
        setIsModalOpen(false);
        setDeleteRow(value.data.response);
        setproductsData(productsData);
        // getData();
      })
      .catch((err) => console.log(err));
  };
  // const handleRadioChange = (event) => {
  //   setPurposeType(event.target.value);
  // };

  const deleteProduct = () => {
    const updatedProducts = [...productsData];
    updatedProducts.splice(productIndexForDelete, 1);
    setproductsData(updatedProducts);
    setIsModalOpen(false);
  };

  const handlePresentClick = () => {
    openNestedModal();
  };
  const closeAllModals = () => {
    closeNestedModal();
    closeModal();
  };
  const closeAllUpdateModals = () => {
    closeNestedUpdateModal();
    closeUpdateModal();
  };
  return (
    <div>
      <Constant>
        <Header
          // btnName="Add Client"
          //   onpage_link="Team's Clients"
          heading="Lead details"
        // btn_path="addclient"
        />
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className={
              loading
                ? 'myclass app-container container-fluid'
                : 'app-container container-fluid'
            }
            style={{ marginBottom: '20px' }}
          >
            {loading ? (
              <>
                {/* <div className="overlay" /> */}
                <div className="loader-container">
                  <ClipLoader color={'#50CD89'} loading={loading} size={60} />
                </div>
              </>
            ) : // <div
              //   className="overlay"
              //   style={{ alignSelf: 'center' }}
              // >
              //   <ClipLoader
              //     color={'#50CD89'}
              //     loading={loading}
              //     css={override}
              //     size={60}
              //   />
              // </div>
              null}
            <div className="row mb-3">
              <div className="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-6 d-flex justify-content-around rounded border border-1 border-dark-subtle p-2 mx-3"
                style={{ backgroundColor: '#F8F2EC',cursor:'pointer' }}>

                <div className={`fs-4 fw-semibold px-5 py-2
                ${activeOption === 'Lead Details' ? 'activ' : null}`}
                  onClick={() => setActiveOption("Lead Details")}
                >
                  Lead Details
                </div>


                {purposeType === 'sales' ? (
                  <div className={`fs-4 fw-semibold px-5 py-2  ${activeOption === 'product' ? 'activ' : null}`}
                    onClick={() => setActiveOption("product")}
                  >
                    Products
                  </div>
                ) : null}
              </div>
            </div>

            {activeOption === 'Lead Details' ? (
              <>
                <div className="clientinfo-box">
                  <div className="dynamic-input ">
                    {clientData.map((field, index) => (
                      <div className="row align-items-center justify-content-between">
                        <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">

                          <div className="dynamic-input ">
                            <div className="row align-items-center justify-content-between">
                              <div className="tab-content d-flex align-items-center justify-content-around inputrow flex-wrap">
                                <div className="col-5">
                                  <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">
                                      GSTIN Number
                                    </span>
                                  </label>
                                  {/* {labelText === 'New' ? ( */}
                                  <input
                                    maxLength={15}
                                    // onChange={handleInputChange}
                                    name="product_gst"
                                    value={leadData?.client?.gst_no}
                                    type="text"
                                    className="form-control text-dark"
                                    placeholder="Enter GSTIN Number"
                                    disabled
                                  />
                                </div>
                                <div className="col-5">
                                  <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">
                                      Organisation
                                    </span>
                                  </label>

                                  <input
                                    // onChange={handleInputChange}
                                    value={leadData?.client?.organisation}
                                    name="organisation"
                                    type="text"
                                    className="form-control text-dark "
                                    placeholder="Enter Organisation"
                                    disabled
                                  />
                                </div>
                                <div className="col-5">
                                  <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">Address</span>
                                  </label>

                                  <input
                                    // onChange={handleInputChange}
                                    value={leadData?.client?.address}
                                    name="address"
                                    type="text"
                                    className="form-control text-dark"
                                    placeholder="Enter Address"
                                    disabled
                                  />
                                </div>

                                <div className="col-5">
                                  <label
                                    htmlFor={`contactPersonName${index}`}
                                    className="d-flex align-items-center fs-6 fw-semibold mb-2"
                                  >
                                    <span className="required">
                                      Contact Person Name
                                    </span>
                                  </label>
                                  <input
                                    onInput={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /[^a-zA-Z ]/g,
                                      ''
                                    ))
                                    }
                                    onChange={(e) =>
                                      handleInputChange1(
                                        index,
                                        'value1',
                                        e.target.value
                                      )
                                    }
                                    value={
                                      field['contact_person_name_' + index.toString()]
                                    }
                                    // value={field.contact_person_name_0}
                                    name="owner_name"
                                    type="text"
                                    className="form-control text-dark"
                                    id={`contactPersonName${index}`}
                                    placeholder={`Enter Name ${index + 1}`}
                                    disabled
                                  />
                                  {error &&
                                    error.clientInfo &&
                                    error.clientInfo[index] && (
                                      <div>
                                        {error.clientInfo[index][
                                          `contact_person_name_${index}`
                                        ] && (
                                            <div style={{ color: 'red' }}>
                                              {
                                                error.clientInfo[index][
                                                `contact_person_name_${index}`
                                                ]
                                              }
                                            </div>
                                          )}
                                      </div>
                                    )}
                                </div>


                                {/* <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                    <span className="required">
                                      Industry Type
                                    </span>
                                  </label> */}
                                {/* {labelText === 'New' ? (
                                    <ReactSelect
                                      // options={clientOptions}
                                      value={IndustryOptions.find(
                                        (e) =>
                                          e.value ==
                                          leadData?.client?.industry_id
                                      )}
                                      options={IndustryOptions}
                                      isDisabled={true}
                                      // value={id}
                                      // onChange={(newSelectedOption) => {
                                      //   setId(newSelectedOption);
                                      // }}
                                    />
                                  ) : (
                                    <ReactSelect
                                      // options={clientOptions}
                                      options={IndustryOptions}
                                      value={id}
                                      onChange={(newSelectedOption) => {
                                        setId(newSelectedOption);
                                      }}
                                    />
                                  )} */}
                                {/* {error && error.industry_id ? (
                                    <div style={{ color: 'red' }}>
                                      {error.industry_id}
                                    </div>
                                  ) : null} */}
                              </div>
                            </div>
                          </div>

                          <div className="col-5">
                            <label
                              htmlFor={`contactPersonEmail${index}`}
                              className="d-flex align-items-center fs-6 fw-semibold mb-2"
                            >
                              <span className="required">
                                Contact Person's Email address
                              </span>
                            </label>
                            <input
                              onChange={(e) =>
                                handleInputChange1(
                                  index,
                                  'value2',
                                  e.target.value
                                )
                              }
                              // value={field.contact_person_email_0}
                              value={
                                field[
                                'contact_person_email_' + index.toString()
                                ]
                              }
                              name="owner_email"
                              type="email"
                              className="form-control text-dark"
                              id={`contactPersonEmail${index}`}
                              placeholder={`Enter email ${index + 1}`}
                              disabled
                            />
                            {error &&
                              error.clientInfo &&
                              error.clientInfo[index] && (
                                <div>
                                  {error.clientInfo[index][
                                    `contact_person_email_${index}`
                                  ] && (
                                      <div style={{ color: 'red' }}>
                                        {
                                          error.clientInfo[index][
                                          `contact_person_email_${index}`
                                          ]
                                        }
                                      </div>
                                    )}
                                  {/* Repeat similar checks for other fields (email, mobile, designation) */}
                                </div>
                              )}
                          </div>
                          <div className="col-5">
                            <label
                              htmlFor={`contactPersonMobile${index}`}
                              className="d-flex align-items-center fs-6 fw-semibold mb-2"
                            >
                              <span className="required">
                                Contact Person's Mobile Number
                              </span>
                            </label>
                            <input
                              style={{
                                cursor: 'not-allowed',
                                pointerEvents: 'none'
                              }}
                              readOnly
                              onInput={(e) =>
                              (e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ''
                              ))
                              }
                              maxLength={10}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  `contact_person_mobile_${index}`,
                                  e.target.value
                                )
                              }
                              value={field[`contact_person_mobile_${index}`]}
                              name="owner_contact_no"
                              type="text"
                              className="form-control text-dark"
                              id={`contactPersonMobile${index}`}
                              placeholder={`Enter Mobile Number ${index + 1}`}
                              disabled
                            />
                            {error &&
                              error.clientInfo &&
                              error.clientInfo[index] && (
                                <div>
                                  {error.clientInfo[index][
                                    `contact_person_mobile_${index}`
                                  ] && (
                                      <div style={{ color: 'red' }}>
                                        {
                                          error.clientInfo[index][
                                          `contact_person_mobile_${index}`
                                          ]
                                        }
                                      </div>
                                    )}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="clientinfo-box mt-6">
                  <div className="modal-body scroll-y px-6 px-lg-15 pt-0 pb-5 ">
                    {/*begin:Form*/}
                    <form
                      id="kt_modal_new_target_form"
                      className="form fv-plugins-bootstrap5 fv-plugins-framework"
                      action="#"
                    >
                      {/*begin::Input group*/}
                      <div className="d-flex flex-column mb-0 fv-row fv-plugins-icon-container">
                        {/*begin::Label*/}
                        <div
                          className="row"
                          style={{
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <div
                            className="row  col-5"
                            style={{
                              flexDirection: 'row'
                              // backgroundColor: 'green'
                              // backgroundColor: 'red',
                              // display: 'flex'
                            }}
                          >
                            <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                              <span className="required">Purpose of visit</span>
                            </label>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row'
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginLeft: '20px'
                                }}
                              >
                                <input
                                  type="radio"
                                  id="sales"
                                  name="fav_language"
                                  value="sales"
                                  checked={purposeType === 'sales'}
                                // onChange={handleRadioChange}
                                />
                                <label
                                  htmlFor="Sales"
                                  style={{
                                    marginLeft: '8px',
                                    fontSize: 'initial'
                                  }}
                                >
                                  Sales
                                </label>
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginLeft: '20px'
                                }}
                              >
                                <input
                                  type="radio"
                                  id="feedback"
                                  name="fav_language"
                                  value="feedback"
                                  checked={purposeType === 'feedback'}
                                // onChange={handleRadioChange}
                                />
                                <label
                                  htmlFor="Feedback"
                                  style={{
                                    marginLeft: '8px',
                                    // font-size: initial;
                                    fontSize: 'initial'
                                  }}
                                  disabled
                                >
                                  Feedback
                                </label>
                              </div>
                            </div>
                          </div>
                          {purposeType == 'sales' ? (
                            <div className="row mt-5 col-7">
                              <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                <span className="required">
                                  Expected Close Date
                                </span>
                              </label>
                              <input
                                min={new Date().toISOString().split('T')[0]}
                                type="date"
                                value={leadData.expt_close_date}
                                onChange={(e) => setLeadDate(e.target.value)}
                                name="leadDate"
                                className="form-control text-dark"
                                placeholder="Enter Expected Close Date"
                                disabled
                              />
                              {error && error.expt_close_date ? (
                                <div style={{ color: 'red' }}>
                                  {error.expt_close_date}
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                        <div
                          className="row"
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '10px',
                            alignItems: 'center'
                          }}
                        >
                          <div
                            className="row mt-5 col-4"
                            style={{
                              marginTop: '2px',
                              marginLeft: '0px'
                            }}
                          >
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span className="required">Title</span>
                            </label>
                            <input
                              type="text"
                              // value={title}
                              value={leadData?.title}
                              // onChange={(e) => setTitle(e.target.value)}
                              name="title"
                              disabled={true}
                              className="form-control text-dark"
                              style={{
                                backgroundColor: 'transparent',
                                border: '1px solid #ced4da'
                              }}
                              placeholder="Enter Title"
                            />
                          </div>

                          <div className="row mt-5 col-7">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span className="required">Stage</span>
                            </label>
                            <ReactSelect
                              value={stageDataOption.find(
                                (e) => e.value == detailStage
                              )}
                              //   onChange={(selectedOption) =>
                              //     handleSelectChange(selectedOption)
                              //   }
                              //   options={stageDataOption}
                              isDisabled
                              className='text-dark'
                            />
                            {error && error.stage ? (
                              <div style={{ color: 'red' }}>{error.stage}</div>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mt-5">
                          <div className="col-md-12">
                            <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                              <span className="required">Description</span>
                            </label>
                            <textarea
                              type="text"
                              // value={description}
                              value={leadData?.description}
                              onChange={(e) => setDescription(e.target.value)}
                              name="descriptipn"
                              className="form-control text-dark "
                              placeholder="Enter Description"
                              style={{
                                backgroundColor: 'transparent',
                                border: '1px solid #ced4da'
                              }}
                              disabled
                            />
                            {error && error.description ? (
                              <div style={{ color: 'red' }}>
                                {error.description}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {/*end::Label*/}
                      </div>
                    </form>
                  </div>

                  <Form>
                    <div
                      id="kt_app_content"
                      className="app-content flex-column-fluid"
                    >
                      <div
                        id="kt_app_content_container"
                        className=" container-fluid"
                      >
                        <div className="col-xxl-12 mb-5 mb-xl-2">
                          <div className="h-xl-100">
                            <Row>
                              <Col
                                xs={12}
                                style={{
                                  paddingLeft: '29px',
                                  paddingLeft: '29px'
                                }}
                              >
                                <Form.Group>
                                  <Form.Label style={{ fontSize: 15 }}>
                                    Lead Communication
                                  </Form.Label>
                                </Form.Group>
                              </Col>
                            </Row>

                            <div className="row">
                              {communicationlead.map((e, index) => (
                                <div className="col-4" key={index}>
                                  <div
                                    className="card card-flush box_shadow"
                                    style={{
                                      boxShadow:
                                        ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
                                      marginTop: '20px'
                                    }}
                                  >
                                    <div className="card-header justify-content-start align-items-center pt-4">
                                      <div className="symbol symbol-45px me-5"></div>
                                      <h3
                                        style={{
                                          color: 'darkslategrey!important'
                                        }}
                                        className=" fw-semibold fs-6"
                                      >
                                        {isExpanded
                                          ? e.communication
                                          : e.communication}
                                        {/* {e.communication.length > maxLength && (
                                          <span>
                                            <a
                                              style={{
                                                marginLeft: '10px'
                                              }}
                                              href="#"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                toggleReadMore(index);
                                              }}
                                            >
                                              {communicationStates[index]
                                                ? 'Read Less'
                                                : 'Read More'}
                                            </a>
                                          </span>
                                        )} */}
                                      </h3>
                                    </div>
                                    <div className="card-footer">
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between'
                                        }}
                                      >
                                        <div className="indicator-label">
                                          <p>communication date</p>
                                          <div>{e.created_at}</div>
                                        </div>
                                        <div className="indicator-label">
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'space-between'
                                            }}
                                          >
                                            <i
                                              style={{
                                                color: 'goldenrod'
                                              }}
                                              class="fa-solid fa-star"
                                            ></i>
                                            <p>Reminder date</p>
                                          </div>
                                          <div>{e.communication_date}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </>
            ) : null}

            {activeOption == 'product' ? (
              <div className="row">
                {/* {slides.map((e, index) => (
            // console.log('leadddddd data',e)
            <div key={index}> */}
                <div
                  className="card card-flush box_shadow "
                  style={{
                    boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
                    marginTop: 20
                  }}
                >
                  <div
                    style={{
                      fontSize: 20,
                      fontWeight: 'bold',
                      marginLeft: 25,
                      marginTop: 15
                    }}
                  >
                    Product Details
                  </div>
                  <div style={{ marginBottom: 10, padding: 24 }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        maxHeight: '200px',
                        overflowY: 'auto'
                      }}
                    >
                      <table class="table">
                        <thead
                          style={{
                            position: 'sticky',
                            top: 0,
                            backgroundColor: 'white',
                            zIndex: 1
                          }}
                        >
                          <tr>
                            <th scope="col">Sr no.</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Category</th>
                            <th scope="col">SubCategory</th>
                            <th scope="col">Totol quanity</th>
                            <th scope="col">Price /pc </th>
                            <th scope="col">Discount</th>
                            <th scope="col">GST</th>
                            <th scope="col">Net-price</th>
                            {/* <th scope="col">Action</th> */}
                          </tr>
                        </thead>

                        <tbody
                        // style={{
                        //   maxHeight: '40px',
                        //   overflowY: 'auto'
                        //   // display: 'block'
                        // }}
                        >
                          {productsData.length == 0 ? (
                            <tr className="text-center">
                              <td
                                colSpan="9"
                                style={{ fontSize: 18, marginTop: '10px' }}
                              >
                                No Data found
                              </td>
                            </tr>
                          ) : (
                            <>
                              {productsData.map((e, index) => {
                                if (Object.keys(e).length === 0) {
                                  return null; // Skip rendering the row
                                }
                                return (
                                  <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      {e.product_name
                                        ? e.product_name
                                        : '------'}
                                    </td>
                                    <td>
                                      {e.category_name
                                        ? e.category_name
                                        : '------'}
                                    </td>
                                    <td>
                                      {e.sub_category_name
                                        ? e.sub_category_name
                                        : '------'}
                                    </td>
                                    <td>
                                      {e.total_quantity
                                        ? e.total_quantity
                                        : '------'}
                                    </td>
                                    {/* <td>120</td> */}
                                    <td>
                                      {e.product_price
                                        ? e.product_price
                                        : '------'}
                                    </td>
                                    {/* <td>24000</td> */}
                                    <td>{e.discount}%</td>
                                    <td>{e.product_gst}%</td>
                                    <td>
                                      {e.total_price ? e.total_price : '------'}
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Constant>
    </div>
  );
};

export default LeadInfo;
