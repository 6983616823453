import React, { useState, useEffect } from 'react';

import DataTable from 'react-data-table-component';
import Constant from '../../Constant/UserLayout';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Url from '../../Elements/Api/Api';
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import Header from '../../Constant/Header';

const TeamClients = (props) => {
  const [show, setShow] = useState(false);
  const [searchStaffByName, setSearhStaffByName] = useState('');
  const [searchClient, setSearchClient] = useState('');
  const [itemOffset, setItemOffset] = useState(1);
  const [clientData, setClientData] = useState([]);
  const [searchClientByName, setSearchClientByName] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [deleteRow, setDeleteRow] = useState([]);
  const [response, setResponse] = useState([]);
  const [clientLists, setClientLists] = useState([]);
  const [id, setId] = useState('');

  const token = useSelector((state) => state.token);
  const [startDate, setDate] = React.useState(new Date());
  const [rangeStart, setRangeStart] = React.useState(new Date());
  const defaultEndDate = new Date();
  defaultEndDate.setDate(defaultEndDate.getDate() + 7);
  const [rangeEnd, setRangeEnd] = React.useState(defaultEndDate);
  const today = new Date();
  // function for date paicker
  const [loading, setLoading] = useState(false);
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate2, setSelectedDate2] = useState('');
  const [isArrowObj, setIsArrowObj] = useState({
    column: 'name',
    orderby: 'asc'
  });
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
  };
  const formattedDate = selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '';
  const formattedDate2 = selectedDate2
    ? format(selectedDate2, 'dd/MM/yyyy')
    : '';

  useEffect(() => {
    getBrandList();
  }, [
    searchClient,
    itemOffset,
    deleteRow,
    id,
    formattedDate,
    formattedDate2,
    isArrowObj,
    pageCount
  ]);
  const getBrandList = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(
        Url.BASEURL +
          'getclients?' +
          'search=' +
          searchClient +
          '&page=' +
          itemOffset +
          '&staffid=' +
          id +
          '&from=' +
          formattedDate +
          '&to=' +
          formattedDate2 +
          '&orderby=' +
          isArrowObj.column +
          '&sortby=' +
          isArrowObj.orderby,
        config
      )
      .then(
        (value) =>
          setClientData(value.data.response.data) &
          setPageCount(value.data.response.last_page) &
          setLoading(false)
      )
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (clientData) {
      setResponse(clientData);
    }
  }, [clientData, searchClient, deleteRow]);
  // function for pagination
  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;

    setItemOffset(newOffset);
  };

  // function for search
  const handleKeyPress = () => {
    setItemOffset(0);
    setSearchClientByName(searchClientByName);
  };
  // function to get clients listing of staff

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    axios
      .get(Url.BASEURL + 'staffnameunderteammanager', config)
      .then(
        (value) =>
          setClientLists(value.data.response) &
          setPageCount(value.data.response.last_page)
      )
      .catch((err) => console.log(err));
  }, []);

  // funciton to get id of selected staff
  const StaffChangeHandler = (event) => {
    setId(event.target.value);
  };
  // FUNCTION FOR SORTING DATA

  const handleArrowClick = (param, orderby) => {
    if (isArrowObj && isArrowObj.column == param) {
      orderby = isArrowObj && isArrowObj.orderby == 'asc' ? 'desc' : 'asc';
    } else {
      orderby = orderby == 'asc' ? 'desc' : 'asc';
    }
    const isArrowobjData = {
      column: param,
      orderby: orderby
    };
    setIsArrowObj(isArrowobjData);
  };
  return (
    <div>
      <Constant>
        <Header
          // btnName="Add Client"
          onpage_link="Team's Clients"
          heading="Team's Clients"
          // btn_path="addclient"
        />
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div className="searchbar_border" style={{ marginTop: '20px' }}>
              <div
                className="search-field d-none d-md-block"
                style={{ marginBottom: '13px' }}
              >
                <div
                  className="d-flex align-items-center h-100 searchbar_parent"
                  action="#"
                >
                  <div
                    className="input-group merchant_search_bar border_radius searchbar_size"
                    style={{
                      borderWidth: 1,
                      borderRadius: 5
                    }}
                  >
                    <div className="input-group-prepend bg-transparent"></div>
                    <input
                      style={{
                        height: 30
                      }}
                      onChange={(e) =>
                        setSearchClient(e.target.value) & handleKeyPress()
                      }
                      type="text"
                      className="form-control bg-transparent border-0"
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label>From</label>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="dd-MM-yyyy"
                placeholderText="Select a date"
                // isClearable={true}
                yearDropdown
                scrollableYearDropdown
                showYearDropdown={true}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label>To</label>
              <DatePicker
                selected={selectedDate2}
                onChange={handleDateChange2}
                dateFormat="dd-MM-yyyy"
                placeholderText="Select a date"
                // isClearable={true}
                yearDropdown
                scrollableYearDropdown
                showYearDropdown={true}
              />
            </div>
            <select
              onChange={StaffChangeHandler}
              id="select1"
              class="ct_listing border_radius ct_listing_float ct_listing_margin "
              style={{
                borderWidth: '1px',
                borderRadius: '8px',
                width: '190px',
                marginBottom: '13px',
                borderColor: '0.5px solid black',
                marginTop: '20px'
              }}
            >
              {clientLists.map((e) => {
                return <option value={e.id}>{e.name}</option>;
              })}
              <option value="">Select Staff</option>
            </select>
          </div>

          <div
            id="kt_app_content_container"
            className="app-container container-fluid"
          >
            <div className="col-xxl-12 mb-5 mb-xl-10">
              <div className="card card-flush h-xl-100">
                {loading ? (
                  <div
                    className="spinner-container"
                    style={{ alignSelf: 'center' }}
                  >
                    <ClipLoader
                      color={'#50CD89'}
                      loading={loading}
                      css={override}
                      size={60}
                    />
                  </div>
                ) : (
                  <div className="">
                    {/*begin::Tab content*/}
                    <div className="tab-content">
                      <div className=" mb-5 mb-xl-8">
                        {/*begin::Body*/}
                        <div className="card-body py-3">
                          {/*begin::Table container*/}
                          <div className="table-responsive">
                            {/*begin::Table*/}
                            <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                              {/*begin::Table head*/}
                              <thead>
                                <tr className="fw-bold text-muted">
                                  <th className="min-w-120px">
                                    {' '}
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick(
                                            'organisation',
                                            'asc'
                                          )
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'organisation' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i>
                                    </span>
                                    Organisation
                                  </th>

                                  <th className="min-w-150px">
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('name', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'name' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i>
                                    </span>
                                    Staff Name
                                  </th>

                                  <th className="min-w-150px">
                                    {' '}
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('clientname', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'clientname' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i>
                                    </span>
                                    Client Name
                                  </th>
                                  <th className="min-w-140px">
                                    {' '}
                                    <span style={{ marginRight: '3px' }}>
                                      <i
                                        onClick={() =>
                                          handleArrowClick('clientemail', 'asc')
                                        }
                                        class={
                                          isArrowObj &&
                                          isArrowObj.column == 'clientemail' &&
                                          isArrowObj.orderby == 'desc'
                                            ? 'fa-solid fa-arrow-down'
                                            : 'fa-solid fa-arrow-up'
                                        }
                                        style={{}}
                                      ></i>
                                    </span>
                                    Email
                                  </th>
                                  <th className="min-w-120px">
                                    {' '}
                                    <span style={{ marginRight: '3px' }}>
                                      {/* <i
                                      onClick={() =>
                                        handleArrowClick('clientcontact', 'asc')
                                      }
                                      class={
                                        isArrowObj &&
                                        isArrowObj.column == 'clientcontact' &&
                                        isArrowObj.orderby == 'desc'
                                          ? 'fa-solid fa-arrow-down'
                                          : 'fa-solid fa-arrow-up'
                                      }
                                      style={{}}
                                    ></i> */}
                                    </span>
                                    Contact Number
                                  </th>

                                  <th className="min-w-120px">
                                    {' '}
                                    <span style={{ marginRight: '3px' }}>
                                      {/* <i
                                      onClick={() =>
                                        handleArrowClick('created_at', 'asc')
                                      }
                                      class={
                                        isArrowObj &&
                                        isArrowObj.column == 'created_at' &&
                                        isArrowObj.orderby == 'desc'
                                          ? 'fa-solid fa-arrow-down'
                                          : 'fa-solid fa-arrow-up'
                                      }
                                      style={{}}
                                    ></i> */}
                                    </span>
                                    Date
                                  </th>
                                </tr>
                              </thead>
                              {/*end::Table head*/}
                              {/*begin::Table body*/}
                              <tbody>
                                {clientData?.length == 0 ? (
                                  <tr>
                                    <td colSpan="5" className="text-center">
                                      No Record found
                                    </td>
                                  </tr>
                                ) : (
                                  clientData?.map((e, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{e.organisation}</td>

                                        <td>{e.staff.name}</td>

                                        <td>{e.clientname}</td>
                                        <td>{e.clientemail}</td>
                                        <td>{e.clientcontact}</td>
                                        <td>{e.created_at}</td>
                                      </tr>
                                    );
                                  })
                                )}
                              </tbody>
                              {/*end::Table body*/}
                            </table>

                            <ReactPaginate
                              previousLabel="Previous"
                              nextLabel="Next"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              pageCount={pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              containerClassName="pagination"
                              activeClassName="active"
                              onPageChange={handlePageClick}
                            />
                            {/*end::Table*/}
                          </div>
                          {/*end::Table container*/}
                        </div>
                        {/*begin::Body*/}
                      </div>

                      {/*begin::Tab pane*/}

                      {/*end::Tab pane*/}
                      {/*begin::Tab pane*/}

                      {/*end::Tab pane*/}
                    </div>
                    {/*end::Tab content*/}
                  </div>
                )}
                {/*end::Body*/}
              </div>
              {/*end::Chart widget 8*/}
            </div>
          </div>
        </div>
      </Constant>
    </div>
  );
};

export default TeamClients;
